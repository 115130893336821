import React, { useState } from "react";
import "../../../Styles/Orders.css";
import Header from "../../header/Header";
import Container from "react-bootstrap/Container";
import Sidebar from "../../Sidenav/Sidebar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
// import Paper from '@mui/material/Paper';

// import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
// import CardActions from '@mui/material/CardActions';
import CardContent from "@mui/material/CardContent";
import SupplierService, {
  getsuppliers,
} from "../../../services/SupplierService";
// import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";

export default function CreateVendor() {
  const [isLoading, setIsLoading] = useState(false);
  const [role, setRole] = useState("");
  const [sname, setSname] = useState("");
  const [esname, setESname] = useState("");
  const [phonecode, setPhoneCode] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [ephone, setEPhone] = useState("");
  const [address, setAddress] = useState("");
  const [eaddress, setEAddress] = useState("");
  const [city, setCity] = useState("");
  const [ecity, setECity] = useState("");
  const [states, setStates] = useState("");
  const [estates, setEStates] = useState("");
  const [pincode, setPincode] = useState("");
  const [epincode, setEPincode] = useState("");
  // const [status, setStatus] = useState('');
  // const [estatus, setEStatus] = useState('');
  const [gst, setGst] = useState("");
  const [egst, setEGst] = useState("");
  const [sbtndis, setSBtndis] = useState(false);
  let navigate = useNavigate();
  // const [role,setRole] = useState('');
  console.log(role, "role");
  const create = async () => {
    // if (!role) {
    //     await setSBtndis(false)
    //     setErrorMessage('This Field is Required!');
    //     toast.error('Select User Role', {
    //         position: toast.POSITION.BOTTOM_LEFT
    //     });
    if (!sname) {
      await setSBtndis(false);
      setESname("Vendor Name is Required!");
      toast.error("Enter Vendor Name", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      // setTimeout(() => {
      //     setErrorMessage('');
      // }, 3000);
      // } else if (!email) {
      //     await setSBtndis(false)
      //     toast.error('Enter Email', {
      //         position: toast.POSITION.BOTTOM_LEFT
      //     });
    } else if (!phone) {
      await setSBtndis(false);
      setESname("");
      setEPhone("Phone Number is Required!");
      toast.error("Enter Phone", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (phone.length != 10 ) {
      await setSBtndis(false);
      toast.error("Invalid Mobile Number", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    
      // } else if (!address) {
      //     await setSBtndis(false)
      //     setEPhone('');
      //     setEAddress('Address is Required!');
      //     toast.error('Enter Address', {
      //         position: toast.POSITION.BOTTOM_LEFT
      //     });
    } else if (!city) {
      await setSBtndis(false);
      setESname("");
      setEPhone("");
      setECity("Area Is Required!");
      toast.error("Enter Area", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      // } else if (!states) {
      //     await setSBtndis(false)
      //     setECity('');
      //     setEStates('State is Required!');
      //     toast.error('Select State', {
      //         position: toast.POSITION.BOTTOM_LEFT
      //     });
      // } else if (!pincode) {
      //     await setSBtndis(false)
      //     setEStates('');
      //     setEPincode('Pin code is Required!');
      //     toast.error('Enter Pincode', {
      //         position: toast.POSITION.BOTTOM_LEFT
      //     });
    // } else if (!gst) {
    //   await setSBtndis(false);
    //   setESname("");
    //   setEPhone("");
    //   setECity("");
    //   setEGst("GST is Required!");
    //   toast.error("Enter GST", {
    //     position: toast.POSITION.BOTTOM_LEFT,
    //   });
      // } else if (!status) {
      //     await setSBtndis(false)
      //     setESname('');
      //     setEPhone('');
      //     setECity('');
      //     setEGst('');
      //     setEStatus('Status is Required!');
      //     toast.error('Select Status', {
      //         position: toast.POSITION.BOTTOM_LEFT
      //     });
    } else {
      setESname("");
      setEPhone("");
      setECity("");
      // setEGst("");
      // setEStatus('');

      try {
        setIsLoading(true);
        const obj = {
          name: sname,
          address: address,
          city: city,
          phoneNo: phone,
          pincode: pincode,
          state: states,
          // "details": "TEST DETAILS",
          // "active": true,
          gstNo: gst,
        };

        const dert = await SupplierService.addsuplier(obj);
        if (dert.data.status === "success") {
          toast.success("Vendor Added Successfully", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          navigate("/vendor");
          getsuppliers(0, 10);
        } else {
          toast.error(dert.data.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      } catch (ex) {
        if (ex.response.status === 400) console.log(ex.response, "ex.response");
        toast.error(ex.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
      finally{
        setIsLoading(false);
      }
    }
  };
  const handlePhone = (event) => {
    const { value } = event.target;
    setPhone(value.replace(/[^0-9.*!,-]/g, ''));
    };

  return (
    <>
      <Header />
      <Container fluid className="containerr">
        <Row>
          <Col lg={2} id="sidebar-wrapper" className="d-none d-md-block">
            <Sidebar />
          </Col>
          <Col lg={6} className="p-3">
            <div>
              <h1 className="fs-3  mt-3">Create New Vendor</h1>

              <nav aria-label="breadcrumb" className="fs-14">
                <ol class="breadcrumb mb-0">
                  <li class="breadcrumb-item">
                    <a href="/dashboard">Home</a>
                  </li>
                  <li class="breadcrumb-item " aria-current="page">
                    <a href="/vendor">Vendor</a>
                  </li>
                  <li class="breadcrumb-item " aria-current="page">
                    Create New Vendor
                  </li>
                </ol>
              </nav>
            </div>
            <Card sx={{ minWidth: 275 }} className="mt-4 rounded-4 col-lg-7">
              <CardContent>
                <div className="row">
                  <div className="form-group col-lg-12 col-md-12">
                    <div className="row">
                      {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group">
                                                    <select value={role} class="form-select text-muted mb-3 " 
                                                    onChange={(e) => setRole(e.target.value)} id='inputcolor' aria-label="Default select example">
                                                        <option selected hidden>Select User Role*</option>
                                                        <option value="1">Job Card</option>
                                                        <option value="2">Cash Receipts</option>
                                                        <option value="3">Material Indent</option>
                                                        <option value="3">Order Booking</option>
                                                        <option value="3">Master Data</option>
                                                        <option value="3">Report</option>
                                                    </select>
                                                </div>
                                            </div> */}

                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <div className="input-group">
                          {/* <input
                            type="text"
                            className="form-control text-muted required"
                            name="name"
                            value={sname}
                            onChange={(e) => setSname(e.target.value)}
                            id="inputcolor"
                            placeholder="Vendor Name*"
                            aria-label="name"
                            aria-describedby="basic-addon1"
                            required
                          /> */}
                          <TextField
                            className="w-100"
                            id="inputcolor"
                            value={sname}
                            onChange={(e) => setSname(e.target.value)}
                            type="text"
                            label="Vendor Name*"
                            //   variant="outlined"
                          />
                        </div>
                      </div>
                      <div style={{ color: "#FF0000", fontSize: "10px" }}>
                        {esname ? esname : null}
                      </div>
                      {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mt-3">
                                                    <input
                                                        type="Email"
                                                        className="form-control text-muted required"
                                                        name="name"
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        value={email}
                                                        id='inputcolor'
                                                        placeholder="Email"
                                                        aria-label="name"
                                                        aria-describedby="basic-addon1"
                                                    />
                                                </div>
                                            </div> */}
                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="input-group mt-3">
                              {/* <input
                                type="phone"
                                className="form-control required"
                                name="phone"
                                value={phone}
                                maxLength={10}
                                id="inputcolor"
                                onChange={(e) => setPhone(e.target.value)}
                                placeholder="Mobile Number*"
                                aria-label="phone"
                                aria-describedby="basic-addon1"
                                required
                              /> */}
                              <TextField
                                className="w-100"
                                id="inputcolor"
                                inputProps={{
                                  maxLength: 10,
                                }}
                                value={phone}
                                onChange={(e) => handlePhone(e)}
                                type="text"
                                label="Mobile Number*"
                                variant="outlined"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{ color: "#FF0000", fontSize: "10px" }}>
                        {ephone ? ephone : null}
                      </div>
                      {/* <div className="form-group col-lg-12 col-md-12 col-12"> */}
                        {/* <div className="input-group mt-3"> */}
                          {/* <input
                            type="text"
                            className="form-control text-muted required"
                            name="name"
                            id="inputcolor"
                            value={address}
                            placeholder="Address"
                            onChange={(e) => setAddress(e.target.value)}
                            aria-label="name"
                            aria-describedby="basic-addon1"
                            required
                          /> */}
                          {/* <TextField
                            className="w-100"
                            id="inputcolor"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            type="text"
                            label="Address"
                            //   variant="outlined"
                          /> */}
                        {/* </div> */}
                      {/* </div> */}
                      <div style={{ color: "#FF0000", fontSize: "10px" }}>
                        {eaddress ? eaddress : null}
                      </div>
                      <div className="form-group col-lg-12 col-md-12 col-12 mt-3">
                        <div className="input-group">
                          {/* <input
                            type="text"
                            className="form-control required"
                            name="name"
                            id="inputcolor"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            placeholder="Area*"
                            aria-label="name"
                            aria-describedby="basic-addon1"
                            required
                          /> */}
                          <TextField
                            className="w-100"
                            id="inputcolor"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            type="text"
                            label="Area*"
                            //   variant="outlined"
                          />
                        </div>
                      </div>
                      <div style={{ color: "#FF0000", fontSize: "10px" }}>
                        {ecity ? ecity : null}
                      </div>
                      {/* <div className="form-group col-lg-12 col-md-12 col-12 mt-3">
                        <div className="input-group"> */}
                          {/* <input
                            type="text"
                            className="form-control required"
                            name="name"
                            id="inputcolor"
                            value={states}
                            onChange={(e) => setStates(e.target.value)}
                            placeholder="State"
                            aria-label="name"
                            aria-describedby="basic-addon1"
                            required
                          /> */}
                          {/* <TextField
                            className="w-100"
                            id="inputcolor"
                            value={states}
                            onChange={(e) => setStates(e.target.value)}
                            type="text"
                            label="State"
                            //   variant="outlined"
                          />
                        </div>
                      </div> */}
                      <div style={{ color: "#FF0000", fontSize: "10px" }}>
                        {estates ? estates : null}
                      </div>
                      {/* <div className="form-group col-lg-12 col-md-12 col-12">
                        <div className="input-group mt-3"> */}
                          {/* <input
                            type="text"
                            className="form-control required"
                            name="name"
                            id="inputcolor"
                            value={pincode}
                            onChange={(e) => setPincode(e.target.value)}
                            placeholder="Pin Code"
                            maxLength={6}
                            aria-label="name"
                            aria-describedby="basic-addon1"
                            required
                          /> */}
                          {/* <TextField
                            className="w-100"
                            id="inputcolor"
                            value={pincode}
                            onChange={(e) => setPincode(e.target.value)}
                            type="text"
                            inputProps={{
                              maxLength: 6,
                            }}
                            label="Pin Code"
                            //   variant="outlined"
                          />
                        </div>
                      </div> */}
                      <div style={{ color: "#FF0000", fontSize: "10px" }}>
                        {epincode ? epincode : null}
                      </div>
                      {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mt-3">
                                                    <input
                                                        type="text"
                                                        className="form-control required"
                                                        name="name"
                                                        value={score}
                                                        id='inputcolor'
                                                        placeholder="Score*"
                                                        onChange={(e) => setScore(e.target.value)}
                                                        aria-label="name"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                </div>
                                            </div> */}
                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <div className="input-group mt-3">
                          {/* <input
                            type="text"
                            className="form-control required"
                            name="name"
                            value={gst}
                            id="inputcolor"
                            placeholder="GST No*"
                            onChange={(e) => setGst(e.target.value)}
                            aria-label="name"
                            aria-describedby="basic-addon1"
                            required
                          /> */}
                          <TextField
                            className="w-100"
                            id="inputcolor"
                            value={gst}
                            onChange={(e) => setGst(e.target.value)}
                            type="text"
                            maxLength={6}
                            label="GST No"
                            //   variant="outlined"
                          />
                        </div>
                      </div>
                      <div style={{ color: "#FF0000", fontSize: "10px" }}>
                        {egst ? egst : null}
                      </div>
                      {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mt-3">
                                                    <select value={status} onChange={(e) => setStatus(e.target.value)} class="form-select text-muted" id='inputcolor' aria-label="Default select example">
                                                        <option selected hidden>Status*</option>
                                                        <option value={true}>Active</option>
                                                        <option value={false}>In Active</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div style={{color:'#FF0000',fontSize:'10px'}}>{estatus ? estatus : null}</div> */}
                      <div className="d-lg-flex">
                        <div className="form-group col-lg-6 col-md-12 col-sm-12 mt-3">
                          <div className="input-group">
                            <Button
                              as={NavLink}
                              to="/vendor"
                              id="pillStyle"
                              className="w-100"
                              variant="outline-primary"
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                        <div className="form-group col-lg-6 col-md-12 col-sm-12 mt-3 ms-1">
                          <div className="input-group ">
                            <div className="w-100">
                            {!isLoading ? (
                              <Button
                                onClick={() => create()}
                                id="pillStyle"
                                className="w-100"
                                variant="primary"
                              >
                                Create Vendor
                              </Button>
                               ) : (
                                <button className="buttonload w-100" id="pillStyle">
                          <i className="fa fa-spinner fa-spin"></i> Loading
                        </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
