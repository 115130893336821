import React, { useEffect, useState, useRef } from "react";
import "../../Styles/Orders.css";
import Header from "../header/Header";
import Container from "react-bootstrap/Container";
import Sidebar from "../Sidenav/Sidebar";
import aretsLogo from "../../Images/aretsLogo.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Modal from "react-bootstrap/Modal";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
// https://app.aretsgraphicprint.com/
import Button from "react-bootstrap/Button";
import uploadCloudImage from "../../Images/uploadCloudImage.png";
import UploadImageIcon from "../../Images/UploadImageIcon.png";
import Multiselect from "multiselect-react-dropdown";
import { connect } from "react-redux";
import Switch from "react-switch";
import axios from "axios";
// import Paper from '@mui/material/Paper';

// import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
// import CardActions from '@mui/material/CardActions';
import CardContent from "@mui/material/CardContent";
import ClientsService from "../../services/ClientsService";
import estimateService from "../../services/estimateService";
import get_userdetails from "../../redux/actions/getuserDetailsAction";
import authService from "../../services/authService";
import { toast } from "react-toastify";
import { Nav } from "react-bootstrap";

import { OutlinedInput } from "@mui/material";
import { color } from "@mui/system";
import { red } from "@mui/material/colors";
import showPwdImg from "../../Images/show-password.svg";
import hidePwdImg from "../../Images/hide-password.svg";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import validator from "validator";

function EditEstimate(props) {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [jddesign, setJddesign] = useState("");
  const [printjob, setprintJob] = useState("");
  const [ecname, setECname] = useState("");
  const [ephone, setEPhone] = useState("");
  const [efaddress, setEFAddress] = useState("");
  const [eordername, setEOrderName] = useState("");
  const [ejobtype, setEJobType] = useState("");
  const [epapertype, setEPapertype] = useState("");
  const [edesignamount, setEDesignAmount] = useState("");
  const [edesign, setEDesign] = useState("");
  const [ecutsize, setECutSize] = useState();
  const [eflength, setEFlength] = useState("");
  const [efquantity, setEFquantity] = useState("");
  const [efbreadth, setEFbreadth] = useState("");
  const [ecutquantity, setECutQuantity] = useState();
  const [emillquantity, setEMillQuantity] = useState();
  const [emillsize, setEMillsize] = useState("");
  const [emodeofprinting, setEModeofPrinting] = useState("");
  const [eprintingside, setEPrintingside] = useState("");
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(
    location.state?.item ? location.state.item.customerId : ""
  );
  const otherAmount =
    location.state.item.othersamount === null
      ? 0
      : location.state.item.othersamount;
  const [gsmtypes, setGsmTypes] = useState([]);
  const [user, setUserdata] = useState({});
  const [cname, setCname] = useState(
    location.state?.item ? location.state.item.customerName : ""
  );
  const [cid, setCid] = useState(
    location.state?.item ? location.state.item.createdBy._id : ""
  );
  const [phone, setPhone] = useState(
    location.state?.item ? location.state.item.createdBy.phoneNo : ""
  );

  const [faddress, setFAddress] = useState(
    location.state?.item ? location.state.item.address : ""
  );
  const [email, setEmail] = useState(
    location.state?.item ? location.state.item.email : ""
  );
  // const [gst, setGst] = useState(
  //   location.state?.item ? location.state.item.gstNo : ""
  // );
  const [size, setSize] = useState("");
  const [ordername, setOrderName] = useState(
    location.state?.item ? location.state.item.name : ""
  );
  const [ordertype, setOrderType] = useState("");
  const [jobtype, setJobType] = useState(
    location.state?.item ? location.state.item.orderType : ""
  );
  const [jobRate, setJobRate] = useState("");
  const [prside, setPrside] = useState("");
  const [quantity, setQuantity] = useState("");
  const [country, setCountry] = useState("");
  const [papertype, setPapertype] = useState("");
  const [bpapertype, setBPaperType] = useState("");
  const [modeofprinting, setModeofPrinting] = useState(
    location.state?.item ? location.state.item.machine : ""
  );
  const [machinetype, setMachineType] = useState("");
  const [bmachinetype, setBMachineType] = useState({});
  const [machines, setMachines] = useState([]);
  const [digitalType, setDigitalType] = useState(location.state?.item ? location.state.item.digitalType : "");
  const [digitalTypes, setDigitalTypes] = useState([]);
  const [gsmoptions, setGsmOptions] = useState([]);
  const [finishSizes, setFinishSizes] = useState([]);
  const [finishsize, setFinishSize] = useState("");
  const [bfinishsize, setBfinishsize] = useState({});
  const [lamlength, setLamlength] = useState(
    location.state?.item ? location.state.item.laminationLength : ""
  );
  const [digiquantity, setDigiQuantity] = useState(
    location.state?.item ? location.state.item.digitalQuanitity : ""
  );
  const [digirate, setDigiRate] = useState(
    location.state?.item ? location.state.item.digitalRate : ""
  );
  const [lambreadth, setLambreadth] = useState(
    location.state?.item ? location.state.item.laminationBreadth : ""
  );
  const [finishoptions, setFinishoptions] = useState([]);
  const [laminations, setLaminations] = useState([]);
  const [lamination, setLamination] = useState(
    location.state?.item ? location.state.item.lamination : ""
  );
  const [blamination, setBLamination] = useState({});
  const [extraquantity, setExtraquantity] = useState("");
  const [millsizes, setMillsizes] = useState([]);
  const [millsize, setMillsize] = useState(
    location.state?.item
      ? location.state.item.millerBreadth +
          "*" +
          location.state.item.millerLength
      : ""
  );
  const [bmillsize, setBMillSize] = useState({});
  const [millerLength, setMillerLength] = useState(
    location.state?.item ? location.state.item.millerLength : ""
  );
  const [millerBreadth, setMillerBreadth] = useState(
    location.state.item ? location.state.item.millerBreadth : ""
  );
  const [printJobTypes, setPrintJobTypes] = useState([]);
  const [printJobType, setPrintJobType] = useState("");
  const [bprintJobType, setBPrintJobType] = useState({});
  const [rate, setRate] = useState(location.state?.item.laminationRate);
  const [punchingTypes, setPunchingTypes] = useState([]);
  const [pastingtypes, setPastingTypes] = useState([]);
  const [punchingType, setPunchingType] = useState(
    location.state?.item ? location.state.item.punching : ""
  );
  const [finishingtype, setFinishingType] = useState(
    location.state?.item ? location.state.item.finishingType : ""
  );
  const [finishingQuantity, setFinishingQuantity] = useState(
    location.state?.item.finishingQuantity
  );
  const [finishingRate, setFinishingRate] = useState(
    location.state?.item ? location.state.item.finishingRate : ""
  );
  const [punchRate, setPunchRate] = useState(location.state?.item.punchingRate);
  const [punchQuantity, setPunchQuantity] = useState(
    location.state?.item.punchingQuantity
  );
  const [pastingtype, setPastingType] = useState(location.state.item.pasting);
  const [pastquantity, setPastquantity] = useState(
    location.state?.item.pastingQuantity
  );
  const [pastRate, setPastRate] = useState(location.state?.item.pastingRate);
  const [pastrimming, setPastRimming] = useState(location.state?.item.rimming);
  const [pastbinding, setPastBinding] = useState(location.state?.item.binding);
  // const [markup, setMarkup] = useState('');
  const [markupPercentage, setMarkupPercentage] = useState(
    location.state?.item?.markupPercentage
  );
  const [date, setDate] = useState(
    moment.utc(location.state?.item.expectedDate).format("YYYY-MM-DD")
  );
  const [extraworkslist, setExtraWorkList] = useState([]);
  const [extraWorks, setExtraWorks] = useState("");
  const [bextraWorks, setBExtraWorks] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [ddoption, setDDoption] = useState(false);
  const [file, setFile] = useState(null);
  const [imloader, setImloader] = useState(null);
  const [sbtndis, setSBtndis] = useState(false);
  const [designamount, setDesignAmount] = useState(
    location.state?.item ? location.state.item.designingAmount : 0
  );
  const [design, setDesign] = useState(
    location.state?.item ? location.state.item.design : ""
  );
  const [dieamount, setDieAmount] = useState(
    location.state?.item ? location.state.item.dieAmount : ""
  );
  const [rimmingamount, setRimmingAmount] = useState("");
  const [bindingamount, setBindingAmount] = useState("");
  const [packingamount, setPackingAmount] = useState(
    location.state?.item ? location.state.item.packingAmount : ""
  );
  const [miscamount, setMiscAmount] = useState("");
  const [jobdetails, setJobDetails] = useState(
    location.state?.item ? location.state.item.jobDetails : []
  );
  const [flength, setFlength] = useState(
    location.state?.item ? location.state.item.finishLength : ""
  );
  const [fbreadth, setFbreadth] = useState(
    location.state?.item ? location.state.item.finishBreadth : ""
  );
  const [fquantity, setFquantity] = useState(
    location.state?.item ? location.state.item.quantity : ""
  );
  const [fextraquantity, setFextraquantity] = useState(
    location.state?.item ? location.state.item.extraquantity : ""
  );
  const [time, setTime] = useState("");
  const [estimatecalc, setEstimatecalc] = useState();
  const [millquantity, setMillQuantity] = useState(
    location.state?.item ? location.state.item.millquantity : ""
  );
  const [cutquantity, setCutQuantity] = useState(
    location.state?.item ? location.state.item.cuttingQuantity : ""
  );
  const [cutsize, setCutsize] = useState(
    location.state?.item ? location.state.item.cuttingSize : ""
  );
  const [cutsizes, setCutsizes] = useState([]);
  const [bcutsize, setBCutSize] = useState({});
  const [printingside, setPrintingside] = useState(
    location.state?.item ? location.state.item.printing : ""
  );
  const [modalShow, setModalShow] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [lamquantity, setLamQuantity] = useState(
    location.state?.item ? location.state.item.laminationQuantity : ""
  );
  const [laminationRate, setLaminationRate] = useState("");
  const [jdfinishing, setJdfinishing] = useState("");
  const [punchAmount, setPunchAmount] = useState("");
  const [bindingtype, setBindingType] = useState(
    location.state?.item ? location.state.item.binding : ""
  );
  const [bindrate, setBindRate] = useState(
    location.state?.item ? location.state.item.bindingAmount : ""
  );
  const [bindquantity, setBindQuantity] = useState(
    parseInt(fquantity) + parseInt(fextraquantity)
  );
  const [bindamount, setBindAmount] = useState("");
  const [others, setOthers] = useState(
    location.state?.item ? location.state.item.others : ""
  );
  const [othersamount, setOtherAmount] = useState(
    location.state?.item ? location.state.item.othersamount : ""
  );
  const [role, setRole] = useState("");
  const [sname, setSname] = useState("");
  const [uname, setUname] = useState("");
  const [order, setOrder] = useState("");
  const [password, setPassword] = useState("");
  const [reEnterpassword, setReEnterPassword] = useState("");
  const [phoneno, setPhoneno] = useState("");
  const [uemail, setUEmail] = useState("");
  const [phonecode, setPhonecode] = useState("");
  const [uphone, setUPhone] = useState("");
  const [access, setAccess] = useState(
    location.state?.item ? location.state.item.gststatus : []
  );
  const [fsize, setfSize] = useState("");
  const [blam, setBlam] = useState("");
  const [moprint, setMoprint] = useState("");
  const [bptype, setBptype] = useState("");
  // const [status, setStatus] = useState('');
  const [city, setCity] = useState("");
  const [states, setStates] = useState("");
  const [clpincode, setCLPincode] = useState("");
  const [gstNo, setGstNo] = useState("");
  // const [clstatus, setCLStatus] = useState('');
  const [clphonecode, setPhoneCode] = useState("");
  const [punchjob, setpunchJob] = useState("");
  const [pastjob, setPastJob] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isBinding, setIsBinding] = useState(false);
  const [isRimming, setIsRimming] = useState(false);
  const [isPinning, setIsPinning] = useState(false);
  const [gstNumber, setGSTNumber] = useState("");
  const [fname, setFname] = useState("");
  const multiselectRef = useRef();
  const papermultiselectRef = useRef();
  const finishmultiselectRef = useRef();
  const machinemultiselectRef = useRef();
  const millimultiselectRef = useRef();
  const laminationmultiselectRef = useRef();
  const extraworksmultiselectRef = useRef();
  const printjobmultiselectRef = useRef();
  const hiddenFileInput = useRef(null);
  const navigate = useNavigate();
  console.log(location.state.item, "location.state.item");
  var str,
    element = document.getElementById("cal_preview");
  if (element != null) {
    str = element.value;
  } else {
    str = null;
  }

  useEffect(() => {
    getuserdetails();
    getclients();
    getgsmtypes();
    // getselclient();
    getfinishsizes();
    getmachines();
    getmillsizes();
    getcutsizes();
    getlaminations();
    getextrajobs();
    getjobprinttypes();
    getpunchtypes();
    getpasttypes();
    getpapertypegsm();
    getselmillsizes();
    getselcutsizes();
    getsellamination();
    getselpunchtypes();
    getdigitaltypes();
    // getselpasttypes();
    getseljobtype();
  }, [props.userdata]);
  useEffect(() => {
    get_userdetails();
  }, []);
  const getuserdetails = async () => {
    setUserdata(props.userdata.user);
  };
  // const getselclient = () => {
  //   let arr = [];
  //   if (clients && clients.length > 0) {
  //     const rty = clients.map((k) => {
  //       if (k.name === location.state.item.customerName) {
  //         arr.push(k);
  //       }
  //     });
  //     console.log(arr, "arredit");
  //     setClient(arr);
  //     setCname(arr[0].name);
  //     setCid(arr[0]._id);
  //   }

  // };
  const selectemps = (e) => {
    if (e.target.value === "Create New Client") {
      setModalShow(true);
      console.log(e.target.value, "e.target.value");
    } else {
      console.log(e.target.selectedOptions[0].dataset.index, "value");
      const selindex = e.target.selectedOptions[0].dataset.index;
      const selclient = clients[selindex].name;
      console.log(selclient, "selclient");
      setClient(clients[selindex]);
      setFAddress(clients[selindex].city);
      setPhone(clients[selindex].phoneNo);
      // setGst(clients[selindex].gstNo);
      setCname(selclient);
      //  setGSTNumber(clients[selindex].gstNo);
      // setGst("");
      // setGstStatus(false);
      // setCid(clients[selindex]._id);
    }

    // setClient(value)
    // setCname(value[0].name);
    // jobdetails.push(value[0].name)
    // setCid(value[0]._id)
  };
  const getpunchtypes = async () => {
    try {
      const der = await estimateService.getpunchingtypes();
      if (der.data.status === "success") {
        setPunchingTypes(der.data.value);
      }
    } catch (ex) {}
  };
  const getpapertypegsm = () => {
    let arr = [];
    if (gsmoptions && gsmoptions.length > 0) {
      gsmoptions.forEach((l) => {
        if (
          l &&
          location.state.item.paperType + location.state.item.gsm === l.paperTypegsm
        ) {
          arr.push(l);
        }
      });
      if (arr.length > 0) {
        console.log(arr[0], "location.papertype");
        setPapertype(arr[0].paperTypegsm);
        setBPaperType(arr[0]);
      } else {
        console.error("No matching paperTypegsm found");
      }
    } else {
      console.error("gsmoptions is either undefined or empty");
    }
  };
  
  const getselmillsizes = () => {
    let arr = [];
    if (millsizes && millsizes.length > 0) {
      arr = millsizes.filter(
        (p) =>
          (location.state.item.millerLength
            ? p.length === location.state.item.millerLength
            : true) &&
          (location.state.item.millerBreadth
            ? p.breadth === location.state.item.millerBreadth
            : true)
      );
      if (arr.length > 0) {
        setMillsize(arr[0].milliText);
        setBMillSize(arr[0]);
      }
    }
  };
  const getselcutsizes = () => {
    let arr = [];
    if (cutsizes && cutsizes.length > 0) {
      arr = cutsizes.filter(
        (p) =>
          (location.state.item.cuttingLength
            ? p.length === location.state.item.cuttingLength
            : true) &&
          (location.state.item.cuttingBreadth
            ? p.breadth === location.state.item.cuttingBreadth
            : true)
      );
      if (arr.length > 0) {
        setCutsize(arr[0].cuttingSize);
        setBCutSize(arr[0]);
      }
    }
  };

  const getselpunchtypes = () => {
    let arr = [];
    // console.log(location.state.item,'punchingTypes');
    if (
      punchingTypes &&
      punchingTypes.length > 0 &&
      location.state.item.punching !== "Na" &&
      location.state.item.punching !== ""
    ) {
      console.log(location.state.item.punching, "locc");
      const ert = punchingTypes.map((l) => {
        if (l.type === location.state.item.punching) {
          arr.push(l);
        }
      });
      console.log(arr, "punching");
      setPunchingType(arr[0].type);
      setPunchRate(arr[0].cost);
      // } else {
      //   setPunchingType(" ");
      //   setPunchRate(0);
    }
  };
  const getseljobtype = () => {
    let arr = [];
    console.log(printJobTypes, "ordertypes");
    if (
      printJobTypes &&
      printJobTypes.length > 0 &&
      location.state.item.orderType !== "Na" &&
      location.state.item.orderType !== ""
    ) {
      const ert = printJobTypes.map((l) => {
        if (l.details === location.state.item.orderType) {
          arr.push(l);
        }
      });
      setJobType(arr[0].details);
      setJobRate(arr[0].cost);
    } else {
      setJobType("Na");
      setJobRate(0);
    }
  };

  const getpasttypes = async () => {
    try {
      const dry = await estimateService.getpastingtypes();
      if (dry.data.status === "success") {
        setPastingTypes(dry.data.value);
      }
    } catch (ex) {}
  };
  const selectpapertype = (e) => {
    // setPapertype(value)
    // setBPaperType(value[0])
    console.log(e.target.value, "value");
    //   const selindex = e.target.selectedOptions[0].dataset.index;
    //   const selclient = gsmoptions[selindex].paperTypegsm
    setPapertype(e.target.value);
    let arr = [];
    if (e.target.value) {
      const dert =
        gsmoptions &&
        gsmoptions.map((k, index) => {
          if (k.paperTypegsm === e.target.value) {
            arr.push(k);
          }
        });
    }
    setBPaperType(arr[0]);
    console.log(arr[0].type, "bpapertype");
    let bptype = `${e.target.value} GSM`;
    setBptype(bptype);
    // getjobdet()
  };
  const getclients = async () => {
    try {
      const dert = await ClientsService.getclients();
      if (dert.data.status === "success") {
        console.log(dert.data.value, "values");
        setClients(dert.data.value);
      }
    } catch (ex) {}
  };
  const getjobprinttypes = async () => {
    try {
      const dty = await estimateService.printjobtypes();
      if (dty.data.status === "success") {
        setPrintJobTypes(dty.data.value);
      }
    } catch (ex) {}
  };
  const getgsmtypes = async () => {
    try {
      const dert = await estimateService.getpapergsmAll();
      if (dert.data.status === "success") {
        if (dert.data.value?.length > 0) {
          let optionss = [];
          dert.data.value.map((k) => {
            const obj = {
              paperTypegsm: k.paperType + k.gsm,
              _id: k._id,
              paperType: k.paperType.length === 0 ? " " : k.paperType,
              perkgRate: k.perkgRate,
              gsm: k.gsm.length === 0 ? " " : k.gsm,
              printmincost: k.printmincost,
              printminImpressionquantity: k.printminImpressionquantity,
              printextraImpressioncost: k.printextraImpressioncost,
              printextraImpressionquantity: k.printextraImpressionquantity,
              isActive: k.isActive,
              isArchive: k.isArchive,
              updatedDate: k.updatedDate,
              createDate: k.createDate,
              __v: 0,
            };
            optionss.push(obj);
          });
          setGsmOptions(optionss);
          setGsmTypes(dert.data.value);
        }
      }
    } catch (ex) {}
  };
  const validateEmail = (e) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError("");
      setEmail(e.target.value);
    } else {
      setEmailError("Enter valid Email!");
    }
  };
  function modalreset() {
    setCname("");
    setFname("");
    setCity("");
    setPhone("");
    setGstNo("");
    setCLPincode("");
    setStates("");
    setModalShow(false);
  }
  const getmachines = async () => {
    try {
      const dert = await estimateService.getmachines();
      if (dert.data.status === "success") {
        console.log(dert.data.value, "dert.data.value");
        setMachines(dert.data.value);
      }
    } catch (ex) {}
  };
  const getdigitaltypes = async () => {
    try {
      const dry = await estimateService.getdigitaltypes();
      if (dry.data.status === "success") {
        setDigitalTypes(dry.data.value);
      }
    } catch (ex) {}
  };
  const getfinishsizes = async () => {
    try {
      const drty = await estimateService.getfinishsize();
      if (drty.data.status === "success") {
        let options = [];
        drty.data.value.map((l, index) => {
          const obj = {
            _id: l._id,
            finishText: l.finishText ? l.finishText : 0,
            length: l.length,
            breadth: l.breadth,
            isActive: l.isActive,
            isArchive: l.isArchive,
            updatedDate: l.updatedDate,
            createDate: l.createDate,
            __v: 0,
          };
          options.push(obj);
        });
        setFinishSizes(options);
      }
    } catch (ex) {}
  };
  const getmillsizes = async () => {
    try {
      const dert = await estimateService.getmillsizes();
      if (dert.data.status === "success") {
        console.log(dert.data.value, "millsizes");
        setMillsizes(dert.data.value);
      }
    } catch (ex) {}
  };
  const getcutsizes = async () => {
    try {
      const dert = await estimateService.getcuttingsize();
      if (dert.data.status === "success") {
        console.log(dert.data.value, "cutsizes");
        setCutsizes(dert.data.value);
      }
    } catch (ex) {}
  };
  const getlaminations = async () => {
    try {
      const drt = await estimateService.getlaminations();
      if (drt.data.status === "success") {
        console.log(drt.data.value, "laminations");
        setLaminations(drt.data.value);
      }
    } catch (ex) {}
  };
  const getextrajobs = async () => {
    try {
      const dey = await estimateService.extrajobs();
      if (dey.data.status === "success") {
        setExtraWorkList(dey.data.value);
      }
    } catch (ex) {}
  };
  const selectExtraworks = (value) => {
    setExtraWorks(value);
    setBExtraWorks(value[0]);
    // jobdetails.push(value[0].details)
    console.log(value, "value");
  };
  const selectfinishsize = (value) => {
    setFinishSize(value);
    setBfinishsize(value[0]);
    // jobdetails.push(value[0].finishText)
    console.log(value, "value");
  };
  const selectmachinetype = (value) => {
    setMachineType(value);
    setBMachineType(value[0]);
    // jobdetails.push(value[0].details)
    console.log(value, "value");
  };
  const selectmillsize = (e) => {
    setMillsize(e.target.value);
    const selectedMillSize = millsizes.find(
      (size) => size.milliText === e.target.value
    );
    if (selectedMillSize) {
      setBMillSize(selectedMillSize);
      setMillerLength(selectedMillSize.length);
      setMillerBreadth(selectedMillSize.breadth);
    }
  };
  const selectcutsize = (e) => {
    const selIndex = e.target.selectedOptions[0].dataset.index;
    setCutsize(e.target.value);
    setLamlength(cutsizes[selIndex].length);
    setLambreadth(cutsizes[selIndex].breadth);
    setBCutSize(cutsizes[selIndex]);
  };

  const setcuttingtqty = (e) => {
    setCutQuantity(e.target.value);
    const det = cutquantity === "" ? 0 : cutquantity;
    const lamqty = parseInt(e.target.value);
    setLamQuantity(lamqty ? lamqty : "");
    setPunchQuantity(lamqty ? lamqty : "");
  };
  const selpunchingtype = (e) => {
    // const selindex = e.target.selectedOptions[0].dataset.index;
    setPunchingType(e.target.value);
    let arr = [];
    if (e.target.value) {
      const erty =
        punchingTypes &&
        punchingTypes.map((l, index) => {
          if (l.type === e.target.value) {
            arr.push(l);
          }
        });
      setPunchRate(arr[0].cost);
    }
    // setPunchRate(punchingTypes[selindex].cost)
    let punchjob = `${e.target.value}`;
    setpunchJob(punchjob);
    // getjobdet();
    // jobdetails.push(punchingTypes[selindex].type)
  };
  const seljobtype = (e) => {
    setJobType(e.target.value);
    const selindex = e.target.selectedOptions[0].dataset.index;
    let printjob = `${printJobTypes[selindex].details}:`;
    setJobType(printJobTypes[selindex].details);
    getjobdet();
    setprintJob(printjob);
    let arr = [];
    if (e.target.value) {
      const grt =
        printJobTypes &&
        printJobTypes.map((k, index) => {
          if (k.details === e.target.value) {
            arr.push(k);
          }
        });
      setJobRate(arr[0].rate);
    }
    //   const selindex = e.target.selectedOptions[0].dataset.index;
    // setPastingType(pastingtypes[selindex].type);
    // setPastRate(pastingtypes[selindex].rate)
    // let pastjob = `Pasting:${e.target.value}`
    // setPastJob(pastjob)
    // const selindex = e.target.selectedOptions[0].dataset.index;
    // // setPrintJobTypes(printJobTypes[selindex].type);
    // setJobRate(printJobTypes[selindex].cost)
    // let printjob = `jobtype:${printJobTypes[selindex].details}`
    // setJobType(printJobTypes[selindex].details)
    // getjobdet()
    // jobdetails.push(punchingTypes[selindex].type)
  };
  const selpasting = (e) => {
    setPastingType(e.target.value);
    const selindex = e.target.selectedOptions[0].dataset.index;
    // setPastingType(pastingtypes[selindex].type);
    // setPastRate(pastingtypes[selindex].rate)
    let pastjob = `${pastingtypes[selindex].type},`;
    setPastJob(pastjob);
    let arr = [];
    if (e.target.value) {
      const grt =
        pastingtypes &&
        pastingtypes.map((k, index) => {
          if (k.type === e.target.value) {
            arr.push(k);
          }
        });
      setPastRate(arr[0].rate);
    }

    // jobdetails.push(pastingtypes[selindex].type)
  };
  const getsellamination = () => {
    let arr = [];
    if (
      laminations &&
      laminations.length > 0 &&
      location.state.item.lamination !== ""
    ) {
      const ert = laminations.map((l) => {
        console.log(location.state.item.lamination, "lamloc");
        if (l.details === location.state.item.lamination) {
          arr.push(l);
        }
      });
      console.log(arr[0], "arr");
      setLamination(arr[0].details);
      setLaminationRate(arr[0].cost);
      setBLamination(arr[0]);
      // } else {
      //   setLamination("Na");
      //   setLaminationRate(0);
      //   setBLamination("Na");
    }
    // let arr = [];
    // if(laminations && laminations.length > 0){
    //   const kuy = laminations.map(j => {
    //     if (j.details === location.state.item.lamination) {
    //       arr.push(j);
    //     }
    //   })
    //   console.log(arr, 'arr');
    //   setLamination(arr[0].details)
    //   setLaminationRate(arr[0].cost)
    //   setBLamination(arr[0])
    // }
  };
  const selectlamination = (e) => {
    // setLamination(value);
    // setBLamination(value[0])

    const selindex = e.target.selectedOptions[0].dataset.index;
    const selclient = laminations[selindex].details;
    let blam = `${laminations[selindex].details}`;
    getjobdet();
    setBlam(blam);
    setLamination(e.target.value);
    let arr = [];
    if (laminations && laminations.length > 0) {
      const dert = laminations.map((k, index) => {
        if (k.details === e.target.value) {
          arr.push(k);
        }
      });
    }
    setBLamination(arr[0]);
    console.log(arr[0], "arrr");
    setLaminationRate(arr[0].cost);
  };
  console.log(bpapertype, "paperType");
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const changeHandler = async (event) => {
    console.log("calling");
    await setImloader(true);

    const formData = new FormData();

    formData.append("image", event.target.files[0]);
    console.log(event.target.files[0], "event.target.files");
    let res = await axios.post(
      "https://aretsapi.azurewebsites.net/api/common/uploadfile",
      formData,

      {
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data; ",
          Accept: "*/*",
        },
      }
    );
    console.log(res.data, "res.data");
    if (res.data.url) {
      await setImloader(false);

      await setFile(res.data.url);
    }
  };
  const delmg = async () => {
    var filename = file.substring(file.lastIndexOf("/") + 1);
    const obj = {
      url: filename,
    };
    const dete = await estimateService.delproimg(obj);
    if (dete.data.status === true) {
      setFile();
      toast.success("File Deleted Successfully", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };
  const payref = useRef();
  const [tab, setTab] = useState("Order Confirmation");
  const [payview, setpayview] = useState(true);
  const savepdf = () => {
    html2canvas(document.querySelector("#capture")).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "in", [13.5, 9]);
      pdf.addImage(imgData, "PNG", 0, 0);
      pdf.save("Order Confirmation.pdf");
    });
  };

  // handle to track if the calculations are submitted or not before creating estimate
  const [calculated, setCalculated] = useState();

  const submit = async () => {
    setCalculated(false);
    if (!cname) {
      await setSBtndis(false);
      setECname("Firm Name is Required!");
      toast.error("Select Firm Name", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!faddress) {
      await setSBtndis(false);
      setECname("");
      setEFAddress("Area is Required!");
      toast.error("Enter Area/Street", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!phone) {
      await setSBtndis(false);
      setECname("");
      setEFAddress("");
      setEPhone("Mobile Number Is Required!");
      toast.error("Enter Phone Number", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (phone.length < 10 && phone.length > 10) {
      await setSBtndis(false);
      toast.error("Invalid Phone", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!ordername) {
      await setSBtndis(false);
      setECname("");
      setEFAddress("");
      setEPhone("");
      setEOrderName("Job Name is Required!");
      toast.error("Enter Job Name", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!jobtype) {
      await setSBtndis(false);
      setECname("");
      setEFAddress("");
      setEPhone("");
      setEOrderName("");
      setEJobType("Job Type is Required!");
      toast.error("Select Job Type", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!flength) {
      await setSBtndis(false);
      setECname("");
      setEFAddress("");
      setEPhone("");
      setEOrderName("");
      setEJobType("");
      setEFlength("Finish Length is Required!");
      toast.error("Enter Finish Length", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!fbreadth) {
      await setSBtndis(false);
      setECname("");
      setEFAddress("");
      setEPhone("");
      setEOrderName("");
      setEJobType("");
      setEFlength("");
      setEFbreadth("Finish Breadth is Required!");
      toast.error("Enter Finish Breadth", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!fquantity) {
      await setSBtndis(false);
      setECname("");
      setEFAddress("");
      setEPhone("");
      setEOrderName("");
      setEJobType("");
      setEFlength("");
      setEFbreadth("");
      setEFquantity("Quantity is Required!");
      toast.error("Enter Finish Quantity", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!papertype === '') {
      await setSBtndis(false);
      setECname("");
      setEFAddress("");
      setEPhone("");
      setEOrderName("");
      setEJobType("");
      setEFlength("");
      setEFbreadth("");
      setEFquantity("");
      setEPapertype("Paper Type is Required!");
      toast.error("Select Paper Type", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!millsize) {
      await setSBtndis(false);
      setECname("");
      setEFAddress("");
      setEPhone("");
      setEOrderName("");
      setEJobType("");
      setEFlength("");
      setEFbreadth("");
      setEFquantity("");
      setEPapertype("");
      setEMillsize("Mill Size is Required!");
      toast.error("Select Mill Sheet Size", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!millquantity === '') {
      await setSBtndis(false);
      setECname("");
      setEFAddress("");
      setEPhone("");
      setEOrderName("");
      setEJobType("");
      setEFlength("");
      setEFbreadth("");
      setEFquantity("");
      setEPapertype("");
      setEMillsize("");
      setEMillQuantity("Mill Quantity is Required!");
      toast.error("Enter Mill Quantity", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!cutsize) {
      await setSBtndis(false);
      setECname("");
      setEFAddress("");
      setEPhone("");
      setEOrderName("");
      setEJobType("");
      setEFlength("");
      setEFbreadth("");
      setEFquantity("");
      setEPapertype("");
      setEMillsize("");
      setEMillQuantity("");
      setECutSize("Cutting Size is Required!");
      toast.error("Enter Cutting Size", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    // } else if (!cutquantity) {
    //   await setSBtndis(false);
    //   setECname("");
    //   setEFAddress("");
    //   setEPhone("");
    //   setEOrderName("");
    //   setEJobType("");
    //   setEFlength("");
    //   setEFbreadth("");
    //   setEFquantity("");
    //   setEPapertype("");
    //   setEMillsize("");
    //   setEMillQuantity("");
    //   setECutSize("");
    //   setECutQuantity("Cutting Quantity is Required!");
    //   toast.error("Enter Cutting Quantity", {
    //     position: toast.POSITION.BOTTOM_LEFT,
    //   });
    } else if (!design) {
      await setSBtndis(false);
      setECname("");
      setEFAddress("");
      setEPhone("");
      setEOrderName("");
      setEJobType("");
      setEFlength("");
      setEFbreadth("");
      setEFquantity("");
      setEPapertype("");
      setEMillsize("");
      setEMillQuantity("");
      setECutSize("");
      // setECutQuantity("");
      setEDesign("Design By is Required!");
      toast.error("Select Design By", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      // } else if (!designamount) {
      //   await setSBtndis(false);
      //   setECname("");
      //   setEFAddress("");
      //   setEPhone("");
      //   setEOrderName("");
      //   setEJobType("");
      //   setEFlength("");
      //   setEFbreadth("");
      //   setEFquantity("");
      //   setEPapertype("");
      //   setEMillsize("");
      //   setEMillQuantity("");
      //   setECutSize("");
      //   setECutQuantity("");
      //   setEDesign("");
      //   setEDesignAmount("Design Amount is Required!");
      //   toast.error("Enter Design Amount", {
      //     position: toast.POSITION.BOTTOM_LEFT,
      //   });
    } else if (!modeofprinting) {
      await setSBtndis(false);
      setECname("");
      setEFAddress("");
      setEPhone("");
      setEOrderName("");
      setEJobType("");
      setEFlength("");
      setEFbreadth("");
      setEFquantity("");
      setEPapertype("");
      setEMillsize("");
      setEMillQuantity("");
      setECutSize("");
      // setECutQuantity("");
      setEDesign("");
      setEDesignAmount("");
      setEModeofPrinting("Mode OF Printing is Required!");
      toast.error("Select Mode of Printing", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!printingside) {
      await setSBtndis(false);
      setECname("");
      setEFAddress("");
      setEPhone("");
      setEOrderName("");
      setEJobType("");
      setEFlength("");
      setEFbreadth("");
      setEFquantity("");
      setEPapertype("");
      setEMillsize("");
      setEMillQuantity("");
      setECutSize("");
      // setECutQuantity("");
      setEDesign("");
      setEDesignAmount("");
      setEModeofPrinting("");
      setEPrintingside("Printing Side is Required!");
      toast.error("Select Printing Side", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    // else if (!packingamount) {
    //   await setSBtndis(false)
    //   toast.error('Enter Packing Amount', {
    //     position: toast.POSITION.BOTTOM_LEFT
    //   });
    // }
    // else if (!dieamount) {
    //   await setSBtndis(false)
    //   toast.error('Enter Die Amount', {
    //     position: toast.POSITION.BOTTOM_LEFT
    //   });
    // } else if (!lamination) {
    //   await setSBtndis(false)
    //   toast.error('Select Lamination Type', {
    //     position: toast.POSITION.BOTTOM_LEFT
    //   });
    // } else if (!lamlength) {
    //   await setSBtndis(false)
    //   toast.error('Enter Lamination Length', {
    //     position: toast.POSITION.BOTTOM_LEFT
    //   });
    // } else if (!lambreadth) {
    //   await setSBtndis(false)
    //   toast.error('Enter Lamination Breadth', {
    //     position: toast.POSITION.BOTTOM_LEFT
    //   });
    // } else if (!punchingType) {
    //   await setSBtndis(false)
    //   toast.error('Select Punching Type', {
    //     position: toast.POSITION.BOTTOM_LEFT
    //   });
    // } else if (!punchQuantity) {
    //   await setSBtndis(false)
    //   toast.error('Enter Punching Quantity', {
    //     position: toast.POSITION.BOTTOM_LEFT
    //   });
    // } else if (!punchRate) {
    //   await setSBtndis(false)
    //   toast.error('Enter Punching Rate', {
    //     position: toast.POSITION.BOTTOM_LEFT
    //   });
    // } else if (!pastingtype) {
    //   await setSBtndis(false)
    //   toast.error('Select Pasting Type', {
    //     position: toast.POSITION.BOTTOM_LEFT
    //   });
    // } else if (!pastquantity) {
    //   await setSBtndis(false)
    //   toast.error('Enter Pasting Quantity', {
    //     position: toast.POSITION.BOTTOM_LEFT
    //   });
    // } else if (!pastRate) {
    //   await setSBtndis(false)
    //   toast.error('Enter Pasting Rate', {
    //     position: toast.POSITION.BOTTOM_LEFT
    //   });
    // }
    else {
      setECname("");
      setEFAddress("");
      setEPhone("");
      setEOrderName("");
      setEJobType("");
      setEFlength("");
      setEFbreadth("");
      setEFquantity("");
      setEModeofPrinting("");
      setEMillsize("");
      setEMillQuantity("");
      setECutSize("");
      // setECutQuantity("");
      setEDesign("");
      setEDesignAmount("");
      setEModeofPrinting("");
      setEPrintingside("");
      setCalculated(true);

      try {
        const obj = {
          laminationdetails: blamination.details ? blamination.details : "",
          punchingType: punchingType,
          finishingType: finishingtype,
          pastingType: pastingtype ? pastingtype : "",
          digitalType: digitalType ? digitalType : "",
          mechinedetails: modeofprinting,
          printing: printingside,
          // "cuttingLength":parseInt(bcutsize.length),
          // "cuttingLength": parseInt(bcutsize.length) === '' ? 0 : parseInt(bcutsize.length),
          // "cuttingBreadth": parseInt(bcutsize.breadth) === '' ? 0 : parseInt(bcutsize.breadth),
          cuttingQuantity:
            parseInt(cutquantity) === "" ? 0 : parseInt(cutquantity),
          cuttingLength: parseFloat(bcutsize.length),
          cuttingBreadth: parseFloat(bcutsize.breadth),
          finishingQuantity: parseInt(finishingQuantity),
          pastingQuantity: parseInt(pastquantity),
          paperBy: "arets",
          gstNo: "",
          gststatus: false,
          calobj: {
            millquantity:
              parseFloat(millquantity) === "" ? 0 : parseFloat(millquantity),
            designingAmount: designamount === 0 ? 0 : parseInt(designamount),
            dieAmount: dieamount === "" ? 0 : parseInt(dieamount),
            // rimmingAmount: 0,
            // bindingAmount: bindrate === "" ? 0 : parseInt(bindrate),
            packingAmount: packingamount === "" ? 0 : parseInt(packingamount),
            paperType: {
              // "sno":2,
              paperType: bpapertype.paperType ? bpapertype.paperType : "",
              perkgRate: parseInt(bpapertype.perkgRate),
              gsm: parseInt(bpapertype.gsm),
              printmincost: parseInt(bpapertype.printmincost),
              printminImpressionquantity: parseInt(
                bpapertype.printminImpressionquantity
              ),
              printextraImpressioncost: parseInt(
                bpapertype.printextraImpressioncost
              ),
              printextraImpressionquantity: parseInt(
                bpapertype.printextraImpressionquantity
              ),
            },
            quantity: parseInt(fquantity),
            finishLength: parseFloat(flength),
            finishBreadth: parseFloat(fbreadth),
            extraquantity: parseInt(fextraquantity),
            millerLength: parseFloat(bmillsize.length),
            millerBreadth: parseFloat(bmillsize.breadth),
            laminationLength: parseFloat(lamlength),
            laminationBreadth: parseFloat(lambreadth),
            laminationRate:
              laminationRate === "" ? 0 : parseFloat(laminationRate),
            laminationQuantity: parseInt(lamquantity),
            punchingQuantity: parseInt(punchQuantity),
            punchingRate: punchRate === "" ? 0 : parseFloat(punchRate),
            pastingQuantity: parseInt(pastquantity),
            pastingRate: parseFloat(pastRate) === "" ? 0 : parseFloat(pastRate),
            finishingRate: finishingRate === "" ? 0 : parseFloat(finishingRate),
            // markup: markup === "" ? 0 : parseInt(markup),
            markupPercentage:
              markupPercentage === "" ? 0 : parseInt(markupPercentage),
            miscAmount: 0,
            // markupPercentage: 0,
            others: others,
            othersamount: parseInt(othersamount),
            finalsheetforperMillisheet: 5,
            totalmilliSheet: 535,
            digitalQuanitity: digiquantity === "" ? 0 : parseInt(digiquantity),
            digitalRate: parseFloat(digirate) === "" ? 0 : parseFloat(digirate),
          },
        };
        console.log(obj, "obj");
        // const obj = {
        //   "calobj": {
        //     "designingAmount": parseInt(designamount),
        //     "dieAmount": parseInt(dieamount),
        //     "rimmingAmount": parseInt(rimmingamount),
        //     "bindingAmount": parseInt(bindingamount),
        //     "packingAmount": parseInt(packingamount),
        //     "paperType": {

        //       "paperType": bpapertype.paperType,
        //       "perkgRate": parseInt(bpapertype.perkgRate),
        //       "gsm": parseInt(bpapertype.gsm),
        //       "printmincost": parseInt(bpapertype.printmincost),
        //       "printminImpressionquantity": parseInt(bpapertype.printminImpressionquantity),
        //       "printextraImpressioncost": parseInt(bpapertype.printextraImpressioncost),
        //       "printextraImpressionquantity": parseInt(bpapertype.printextraImpressionquantity)
        //     },
        //     "quantity": parseInt(fquantity),
        //     "finishLength": parseInt(flength),
        //     "finishBreadth": parseInt(fbreadth),
        //     "extraquantity": parseInt(fextraquantity),
        //     "millerLength": parseInt(bmillsize.length),
        //     "millerBreadth": parseInt(bmillsize.breadth),
        //     "laminationLength": parseInt(lamlength),
        //     "laminationBreadth": parseInt(lambreadth),
        //     "laminationRate": parseFloat(rate),
        //     "punchingQuantity": parseInt(punchQuantity),
        //     "punchingRate": parseInt(punchRate),
        //     "pastingQuantity": parseInt(pastquantity),
        //     "pastingRate": parseInt(pastRate),
        //     "markup": parseInt(markup),
        //     "miscAmount": parseInt(miscamount),
        //   }

        // }
        console.log(obj, "obj");
        const drty = await estimateService.estimatecalculation(obj);
        console.log(drty.data.data, "drty.data");
        if (drty.data) {
          setEstimatecalc(drty.data.data);
          console.log(drty.data, "drty.data");
        }
      } catch (ex) {}
    }
  };
  const handleorderType = (e) => {
    setOrderType(e.target.value);
    // jobdetails.push(e.target.value)
  };
  const selectdigital = (e) => {
    const selindex = e.target.selectedOptions[0].dataset.index;
    setDigitalType(e.target.value);
  };
  const handlemode = (e) => {
    const selindex = e.target.selectedOptions[0].dataset.index;
    // const selclient = punchingTypes[selindex].type
    console.log(
      modeofprinting[selindex].details,
      "punchingTypes[selindex].type"
    );
    setModeofPrinting(e.target.value);
    let moprint = "Multi Colour Printing,";
    if (machines[selindex].details === "KL-20") {
      setMoprint(moprint);
    } else if (machines[selindex].details === "KL-26") {
      setMoprint(moprint);
    } else if (machines[selindex].details === "MSB") {
      setMoprint(moprint);
    } else if (machines[selindex].details) {
      setMoprint(machines[selindex].details);
    }
    // jobdetails.push(machines[selindex].details)

    // let moprint = machines[selindex].details
    // setMoprint(moprint);
    // jobdetails.push(machines[selindex].details)
  };
  const handlesubmit = async () => {
    // setIsLoading(true);
    if (calculated === true) {
      try {
        setIsLoading(true);
        const obj = {
          customerName: cname,
          customerId: cid,
          mobile: phone,
          gstNo: gst,
          gststatus: gststatus,
          email: email,
          quantity: parseInt(fquantity),
          printing: printingside,
          design: design,
          paperType: bpapertype.paperType,
          gsm: bpapertype.gsm === "" ? 0 : parseInt(bpapertype.gsm),
          machine: modeofprinting,
          digitalType: digitalType ? digitalType : "",
          lamination: blamination.details,
          punching: punchingType,
          pasting: pastingtype ? pastingtype : "",
          finishingType: finishingtype ? finishingtype : "",
          binding: bindingtype === "" ? "" : bindingtype,
          finishLength: parseFloat(flength),
          finishBreadth: parseFloat(fbreadth),
          extraquantity: parseInt(fextraquantity),
          millerLength: parseFloat(bmillsize.length),
          millerBreadth: parseFloat(bmillsize.breadth),
          cuttingLength: parseFloat(bcutsize.length),
          cuttingBreadth: parseFloat(bcutsize.breadth),
          laminationLength: parseFloat(lamlength),
          laminationBreadth: parseFloat(lambreadth),
          laminationRate: parseFloat(laminationRate),
          laminationQuantity: parseInt(lamquantity),
          punchingQuantity: parseInt(punchQuantity),
          finishingQuantity: parseInt(finishingQuantity),
          punchingRate: parseFloat(punchRate),
          digitalRate: parseFloat(digirate),
          finishingRate: parseFloat(finishingRate),
          pastingQuantity: parseInt(pastquantity),
          digitalQuanitity: parseInt(digiquantity),
          pastingRate: parseFloat(pastRate),
          designingAmount: parseInt(estimatecalc.designingAmount),
          paperAmount: parseInt(estimatecalc.paperAmount),
          PrintAmount: parseInt(estimatecalc.PrintAmount),
          laminationAmount: parseInt(estimatecalc.laminationAmount),
          dieAmount: parseInt(estimatecalc.dieAmount),
          punchingAmount: parseInt(estimatecalc.punchingAmount),
          pastingAmount: parseInt(estimatecalc.pastingAmount),
          rimmingAmount: parseInt(estimatecalc.rimmingAmount),
          pinningAmount: parseInt(estimatecalc.pinningAmount),
          bindingAmount: parseInt(estimatecalc.bindingAmount),
          digitalAmount: parseInt(estimatecalc.digitalAmount),
          packingAmount: parseInt(estimatecalc.packingAmount),
          totalAmount: parseInt(estimatecalc.totalAmount),
          miscAmount: parseInt(estimatecalc.miscAmount),
          sum_total: parseInt(estimatecalc.sum_total),
          // othersamount: parseInt(estimatecalc.othersamount),
          createdBy: user._id,
          orderType: jobtype,
          name: ordername,
          jobDetails: jobdetails.toString(),
          // "cuttingSize":cutsize,
          millquantity: parseFloat(millquantity),
          cuttingQuantity: parseFloat(cutquantity),
          _id: location.state.item._id,
          markupPercentage: parseInt(markupPercentage),
          others: others,
          othersamount: othersamount,
          finalsheetforperMillisheet: 5,
          totalmilliSheet: 535,
        };
        console.log(obj, "objcal");
        // const obj = {
        //   "deliveryAddress": address,
        //   "paymentType": paymentType,
        //   "paymentStatus": paymentStatus,
        //   "pinCode": pincode,
        //   "customerName": cname,
        //   "customerId": cid,
        //   "mobile": phone,
        //   "gstNo": gst,
        //   "printJobTypeId": bprintJobType._id,
        //   "email": email,
        //   "quantity": parseInt(quantity),
        //   "printing": modeofprinting,
        //   "design": user.username,
        //   "paperType": bpapertype.paperType,
        //   "gsm": parseInt(bpapertype.gsm),
        //   "machine": bmachinetype.details,
        //   "lamination": blamination.details,
        //   "punching": punchingType,
        //   "pasting": pastingtype,
        //   "rimming": pastrimming,
        //   "binding": pastbinding,
        //   "finishLength": parseInt(bfinishsize.length),
        //   "finishBreadth": parseInt(bfinishsize.breadth),
        //   "extraquantity": parseInt(extraquantity),
        //   "millerLength": parseInt(bmillsize.length),
        //   "millerBreadth": parseInt(bmillsize.breadth),
        //   "laminationLength": parseInt(lamlength),
        //   "laminationBreadth": parseInt(lambreadth),
        //   "laminationRate": parseFloat(rate),
        //   "punchingQuantity": parseInt(punchQuantity),
        //   "punchingRate": parseInt(punchRate),
        //   "pastingQuantity": parseInt(pastquantity),
        //   "pastingRate": parseInt(pastRate),
        //   "markupPercentage": parseInt(markup),
        //   "designingAmount": parseInt(estimatecalc.designingAmount),
        //   "paperAmount": parseInt(estimatecalc.paperAmount),
        //   "PrintAmount": parseInt(estimatecalc.PrintAmount),
        //   "laminationAmount": parseInt(estimatecalc.laminationAmount),
        //   "dieAmount": parseInt(estimatecalc.dieAmount),
        //   "punchingAmount": parseInt(estimatecalc.punchingAmount),
        //   "pastingAmount": parseInt(estimatecalc.pastingAmount),
        //   "rimmingAmount": parseInt(estimatecalc.rimmingAmount),
        //   "bindingAmount": parseInt(estimatecalc.bindingAmount),
        //   "packingAmount": parseInt(estimatecalc.packingAmount),
        //   "totalAmount": parseInt(estimatecalc.totalAmount),
        //   "miscAmount": parseInt(estimatecalc.miscAmount),
        //   "sum_total": parseInt(estimatecalc.sum_total),
        //   "createdBy": user._id,
        //   "expectedDate": date,
        //   "expectedTime": time,
        //   "orderType": jobtype,
        //   "attchement": file,
        //   "homeDelivery": ddoption,
        //   "name": ordername,
        //   "jobDetails": jobdetails.toString()
        // }
        const dort = await estimateService.updateestimation(obj);
        console.log(dort, "dort");
        if (dort.status === 200) {
          await setSBtndis(false);
          navigate("/estimates");
          toast.success("Estimate Updated Successfully", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      } catch (ex) {
        await setSBtndis(false);
        console.log(ex.response, "ex.response");
        toast.error(ex.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      setCalculated(false);
      toast.error("Please Calculate the Estimate First", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };
  const handledoption = (e) => {
    console.log(e, "e.target.value");
    setDDoption(e);
  };
  const selectprintjobtype = (value) => {
    setPrintJobType(value);
    setBPrintJobType(value[0]);
  };

  const handprintingside = (e) => {
    setPrintingside(e.target.value);
    let prside = "Front & Back,";
    let prside1 = "One Side,";
    let prside2 = "Back & Back,";
    if (e.target.value === "BB") {
      setPrside(prside2);
    } else if (e.target.value === "FB") {
      setPrside(prside);
    } else if (e.target.value === "Opp.G") {
      setPrside(prside);
    } else if (e.target.value === "OS") {
      setPrside(prside1);
    } else if (e.target.value) {
      setPrside(e.target.value);
    }
  };
  const createclient = async () => {
    if (!cname) {
      await setSBtndis(false);
      toast.error("Select Firm Name", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!phone) {
      await setSBtndis(false);
      toast.error("Enter Phone", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!address) {
      await setSBtndis(false);
      toast.error("Enter Address", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!city) {
      await setSBtndis(false);
      toast.error("Select City", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!clpincode) {
      await setSBtndis(false);
      toast.error("Enter Pincode", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!gstNo) {
      await setSBtndis(false);
      toast.error("Enter Score", {
        position: toast.POSITION.BOTTOM_LEFT,
      });

      // } else if (!clstatus) {
      //     await setSBtndis(false)
      //     toast.error('Select Status', {
      //         position: toast.POSITION.BOTTOM_LEFT
      //     });
    } else {
      try {
        setIsLoading(true);
        const obj = {
          name: cname,
          address: address,
          city: city,
          phoneNo: phone,
          pincode: pincode,
          email: "email",
          details: "TEST DETAILS",
          // "active": clstatus,
          score: "100",
          gstNo: gstNo,
          pincode: clpincode,
          state: "TS",
        };
        const dert = await ClientsService.addclients(obj);
        if (dert.data.status === "success") {
          getclients();
          toast.success("Client Added Successfully", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          navigate("/Clients");
        } else {
        }
      } catch (ex) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  const selfleangth = (value) => {
    setFlength(value);
    // setLamlength(value);
    let size = `${value + "X" + fbreadth},`;
    setfSize(size);
  };
  const selfbreadth = (value) => {
    setFbreadth(value);
    // setLambreadth(value);
    let size = `${flength + "X" + value},`;
    setfSize(size);
  };
  const [gststatus, setGstStatus] = useState(
    location.state?.item ? location.state.item.gststatus : false
  );
  const [gst, setGst] = useState(
    location.state?.item ? location.state.item.gstNo : ""
  );
  const handleAccess = (isChecked) => {
    setGstStatus(isChecked);
    if (isChecked && client && client.gstNo) {
      setGst(client.gstNo);
    } else {
      setGst("");
    }
  };
  const setfinishqty = (e) => {
    setFquantity(e.target.value);
    const det = fextraquantity === "" ? 0 : fextraquantity;
    const lamqty = parseInt(e.target.value) + parseInt(det);

    console.log(lamqty, "lamqty");
    // setLamQuantity(lamqty);
    // setPunchQuantity(lamqty);
    // setPastquantity(lamqty);
    // setBindQuantity(lamqty);
  };
  const setfinishextqty = (e) => {
    setFextraquantity(e.target.value);
    const det = fquantity === "" ? 0 : fquantity;
    const lamqty = parseInt(det) + parseInt(e.target.value);
    console.log(lamqty, "lamqty");
    // setLamQuantity(lamqty);
    // setPunchQuantity(lamqty);
    // setPastquantity(lamqty);
    // setBindQuantity(lamqty);
  };
  const handlejobdet = (value) => {
    setJobDetails(value);
  };
  const clickdesign = (e) => {
    setDesign(e.target.value);
    let jddesign = "Designing,";
    if (e.target.value.length > 0) {
      setJddesign(jddesign);
    } else if (e.target.value) {
      setJddesign("");
    }
  };
  const handleInputChange = (e, setter) => {
    setter(e.target.value);
  };
  const getjobdet = () => {
    const jobDetails = location.state.item.jobDetails;
    const detailsArray = jobDetails.split(/[:,\n]/);
    const selectedPrintJob =
      printjob !== "" ? printjob : detailsArray[0].replace("size", ":");
    const selectedFsize =
      fsize !== "" ? "Size:" + fsize : "Size:" + detailsArray[1];
    const selectedJddesign = jddesign !== "" ? jddesign : detailsArray[3];
    const selectedBptype = bptype !== "" ? bptype : detailsArray[4];
    const selectedPrside = prside !== "" ? prside : detailsArray[5];
    const selectedMoprint = moprint !== "" ? moprint : detailsArray[6];
    const selectedBlam = blam !== "" ? blam : detailsArray[8];
    const selectedPunchjob = punchjob !== "" ? punchjob : detailsArray[9];
    const selectedPastjob = pastjob !== "" ? pastjob : detailsArray[10];
    const selectedJdfinishing =
      jdfinishing !== "" ? jdfinishing : detailsArray[11];

    // let size = '';
    // let bptype = '';
    // let moprint = '';
    // let blam = '';
    // if(flength && fbreadth){

    // }else if(Object.keys(bpapertype).length !== 0){

    // }else if(modeofprinting){

    // }else if(Object.keys(blamination).length !== 0){

    // }

    return (
      <div
        style={{ marginBottom: "20px" }}
        className="form-group col-lg-12 col-md-12 col-12"
      >
        <div class="input-group">
          {/* <textarea className="textarea_job" value={jobdet}></textarea> */}
          {/* <b>{boldLine}</b>
      <p>{normalLine}</p> */}
          <div
            style={{ marginBottom: "20px" }}
            className="form-group col-lg-12 col-md-12 col-12"
          >
            <div
              className="input-group"
              style={{ width: "500px", height: "110px" }}
            >
              <div
                className="border border-dark ps-2 pt-2 rounded-4"
                style={{ width: "100%", height: "100%" }}
              >
                <b>
                  {selectedPrintJob}&nbsp; {selectedFsize}
                </b>
                <p>
                  {selectedJddesign} {selectedBptype} {selectedPrside}{" "}
                  {selectedMoprint} {selectedBlam} {selectedPunchjob}{" "}
                  {selectedPastjob} {selectedJdfinishing}
                </p>
              </div>
            </div>
          </div>
          {/* <div className="input-group" style={{ width: '500px', height: '110px' }}>
        <div className="border border-dark ps-2 pt-2 rounded-4" style={{ width: '100%', height: '100%' }}>
          <b>
            {calenderSize.includes(printjob) ? printjob : calenderSize}
            {jddesign ? jddesign : 'defaultJdDesign'}
          </b>
          <p>
            {jddesign ? jddesign : 'defaultJdDesign'} {bptype ? bptype : 'defaultBpType'} {prside ? prside : 'defaultPrSide'} {moprint ? moprint : 'defaultMoPrint'} {blam ? blam : 'defaultBlam'} {punchjob ? punchjob : 'defaultPunchJob'} {pastjob ? pastjob : 'defaultPastJob'} {jdfinishing ? jdfinishing : 'defaultJdFinishing'}
          </p>
        </div>
      </div> */}

          {/* <div style={{ marginBottom: "20px" }} className="form-group col-lg-12 col-md-12 col-12">
    <div className="input-group" style={{ width: "500px", height: "110px" }}>
      <div className="border border-dark ps-2 pt-2 rounded-4" style={{ width: "100%", height: "100%" }}>
        <b>{printjob.length === 0 ? firstWord : printjob} {fsize.length === 0 ? secondWord : fsize}</b>
        <p>{jddesign === 0 ? thirdWord : jddesign} {bptype.length === 0 ? fourthWord : bptype} {prside.length === 0 ? fifthWord : prside} {moprint.length === 0 ? sixthWord : moprint} {blam.length === 0 ? seventhWord : blam} {punchjob.length === 0 ? eigthWord : punchjob} {pastjob.length === 0 ? ninethWord : pastjob} {jdfinishing.length === 0 ? tenthWord : jdfinishing}</p>
      </div>
    </div>
  </div> */}
        </div>
      </div>
    );
  };

  const finishevent = (e) => {
    const { id, value } = e.target;
    if (value == "Binding") {
      setFinishingType(value);
      setIsBinding(true);
      setIsPinning(false);
      setIsRimming(false);
    } else if (value == "Rimming") {
      setFinishingType(value);
      setIsBinding(false);
      setIsPinning(false);
      setIsRimming(true);
    } else if (value == "Pinning") {
      setFinishingType(value);
      setIsBinding(false);
      setIsPinning(true);
      setIsRimming(false);
    }
    let jdfinishing = "Binding.";
    let jdfinishing1 = "Pinning.";
    let jdfinishing2 = "Rimming.";
    if (e.target.value === "Binding") {
      setJdfinishing(jdfinishing);
    } else if (e.target.value === "Pinning") {
      setJdfinishing(jdfinishing1);
    } else if (e.target.value === "Rimming") {
      setJdfinishing(jdfinishing2);
      // } else if (e.target.value) {
      //   setPrside(e.target.value)
    }
  };
  //

  //   console.log(estimatecalc, 'estim');
  //     return (
  //       <div
  //         style={{ marginBottom: "20px" }}
  //         className="form-group col-lg-12 col-md-12 col-12"
  //       >
  //         <div class="input-group">
  //           <textarea className="textarea_job" value={jobdet}></textarea>
  //         </div>
  //       </div>
  //     );
  //   };
  console.log(location.state.item.lamination, "location.state.item.lamination");
  return (
    <>
      <Header />
      <Container fluid className="containerr">
        <Row>
          <Col lg={2} id="sidebar-wrapper" className="d-none d-md-block">
            <Sidebar />
          </Col>
          <Col lg={10} className="p-3">
            <div>
              <h1 className="fs-3 mt-2">Edit Estimate</h1>
              <Breadcrumb className="fs-14">
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/estimates">Estimates</Breadcrumb.Item>
                <Breadcrumb.Item active>Edit Estimate</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Card sx={{ minWidth: 275 }} className="mt-4 rounded-4">
              <CardContent className="d-lg-flex">
                <Col lg={8} sm={12} className="pe-lg-3">
                  <div>
                    <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex">
                      <div className="col-lg-2 col-sm-3 mt-2">
                        <h6 className="fs-12 fw-bold">Firm Name</h6>
                      </div>
                      <div className="col-lg-5 col-sm-6">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control required me-lg-2 w-100"
                            name="name"
                            id="inputcolorr"
                            value={cname}
                            onChange={(e) => setCname(e.target.value)}
                            aria-label="name"
                            aria-describedby="basic-addon1"
                            disabled
                            required
                          />
                        </div>
                        <div
                          className="ms-2"
                          style={{ color: "#FF0000", fontSize: "11px" }}
                        >
                          {ecname ? ecname : null}
                        </div>
                      </div>
                      <div className="col-lg-1"></div>
                      <div className="col-lg-1 mt-2">
                        <h6 className="fs-12 fw-bold">Area*</h6>
                      </div>
                      <div className="col-lg-3">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control text-muted required"
                            name="address"
                            id="inputcolorr"
                            value={faddress}
                            disabled
                            // onChange={(e) => setFAddress(e.target.value)}
                            aria-label="Address"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                        <div
                          className="ms-2"
                          style={{ color: "#FF0000", fontSize: "11px" }}
                        >
                          {efaddress ? efaddress : null}
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2">
                      <div className="col-lg-2 mt-2">
                        <h6 className="fs-12 fw-bold">Mobile No.*</h6>
                      </div>
                      <div className="col-lg-2 me-lg-2">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control required"
                            name="phone"
                            id="inputcolorr"
                            value={phone}
                            disabled
                            // onChange={(e) => setPhone(e.target.value)}
                            maxLength={10}
                            pattern="[0-9]{10}"
                            aria-label="phone"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                        <div
                          className="ms-2"
                          style={{ color: "#FF0000", fontSize: "11px" }}
                        >
                          {ephone ? ephone : null}
                        </div>
                      </div>
                      <div className="col-lg-1 mt-2">
                        <h6 className="fs-12 fw-bold text-lg-end me-lg-1">
                          GST
                        </h6>
                      </div>
                      <div className="col-lg-1">
                        <div class="form-check form-switch ms-1 mt-1 mb-2">
                          <input
                            // value={gststatus}
                            checked={gststatus}
                            onChange={(e) => handleAccess(e.target.checked)}
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            // checked={gstst
                          />
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control required"
                            name="name"
                            id="inputcolorr"
                            value={gst}
                            onChange={(e) => setGst(e.target.value)}
                            aria-label="name"
                            aria-describedby="basic-addon1"
                            required
                            disabled={!gststatus}
                          />
                        </div>
                        <div
                          className="ms-2"
                          style={{ color: "#FF0000", fontSize: "11px" }}
                        >
                          {ephone ? ephone : null}
                        </div>
                      </div>
                      <div className="col-lg-1 mt-2">
                        <h6 className="fs-12 fw-bold me-lg-1 text-lg-end">
                          Email
                        </h6>
                      </div>
                      <div className="col-lg-3">
                        <div className="input-group pe-lg-2">
                          <input
                            type="email"
                            className="form-control text-muted required"
                            name="name"
                            id="inputcolorr"
                            value={email}
                            onChange={(e) => validateEmail(e)}
                            aria-label="name"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                        <span
                          style={{
                            fontSize: "10px",
                            color: "red",
                          }}
                        >
                          {emailError}
                        </span>
                      </div>
                    </div>
                    <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2 dashedline">
                      <div className="col-lg-2 mt-2">
                        <h6 className="fs-12 fw-bold">Job Name*</h6>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control required"
                            name="name"
                            id="inputcolorr"
                            value={ordername}
                            onChange={(e) => setOrderName(e.target.value)}
                            aria-label="name"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                        <div
                          className="ms-2"
                          style={{ color: "#FF0000", fontSize: "11px" }}
                        >
                          {eordername ? eordername : null}
                        </div>
                      </div>
                      <div className="col-lg-2 mt-2">
                        <h6 className="fs-12 fw-bold me-lg-1 text-lg-end">
                          Job Type*
                        </h6>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-group">
                          <select
                            value={jobtype}
                            onChange={(e) => seljobtype(e)}
                            className="form-select text-muted"
                            id="inputcolorr"
                            aria-label="Default select example"
                          >
                            <option hidden value>
                              Job Type*
                            </option>
                            {printJobTypes &&
                              printJobTypes.map((k, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={k.details}
                                    data-index={index}
                                  >
                                    {k.details}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div
                          className="ms-2"
                          style={{ color: "#FF0000", fontSize: "11px" }}
                        >
                          {ejobtype ? ejobtype : null}
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2 dashedline">
                      <div className="col-lg-1">
                        <h1 className="fs-14 fw-bold">FINISH SIZE</h1>
                      </div>
                      <div className="col-lg-2 me-lg-2 mb-sm-2">
                        <div className="input-group">
                          <h6 className="fs-12 fw-bold me-lg-1 mt-2">L*</h6>
                          <input
                            type="text"
                            className="form-control required"
                            name="quantity"
                            id="inputcolorr"
                            value={flength}
                            onChange={(e) => selfleangth(e.target.value)}
                            maxLength={10}
                            aria-label="quantity"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                        <div
                          className="ms-2"
                          style={{ color: "#FF0000", fontSize: "11px" }}
                        >
                          {eflength ? eflength : null}
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <div className="input-group">
                          <h6 className="fs-12 fw-bold mt-2 me-lg-1">B*</h6>
                          <input
                            type="text"
                            className="form-control required"
                            name="quantity"
                            id="inputcolorr"
                            value={fbreadth}
                            onChange={(e) => selfbreadth(e.target.value)}
                            maxLength={10}
                            aria-label="quantity"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                        <div
                          className="ms-2"
                          style={{ color: "#FF0000", fontSize: "11px" }}
                        >
                          {efbreadth ? efbreadth : null}
                        </div>
                      </div>
                      <div className="col-lg-1 mt-2 ms-lg-2">
                        <h6 className="fs-12 fw-bold text-lg-center">
                          Quantity*
                        </h6>
                      </div>
                      <div className="col-lg-2 ms-lg-2">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control required"
                            name="quantity"
                            id="inputcolorr"
                            value={fquantity}
                            onChange={(e) => setfinishqty(e)}
                            maxLength={7}
                            aria-label="quantity"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                        <div
                          className="ms-2"
                          style={{ color: "#FF0000", fontSize: "11px" }}
                        >
                          {efquantity ? efquantity : null}
                        </div>
                      </div>
                      <div className="col-lg-1 mt-2 me-lg-1">
                        <h6 className="fs-12 fw-bold ms-lg-2">Ex.Quantity</h6>
                      </div>
                      <div className="col-lg-2 ms-lg-4">
                        <div className="input-group ">
                          <input
                            type="text"
                            className="form-control required"
                            name="quantity"
                            id="inputcolorr"
                            value={fextraquantity}
                            onChange={(e) => setfinishextqty(e)}
                            maxLength={7}
                            aria-label="quantity"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2 dashedline">
                      <div className="col-lg-2">
                        <h1 className="fs-14 fw-bold mt-1">DESIGN:</h1>
                      </div>

                      <div className="col-lg-2 mt-2">
                        <h6 className="fs-12 fw-bold text-lg-center">
                          Design By*
                        </h6>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-group">
                          <select
                            value={design}
                            onChange={(e) => clickdesign(e)}
                            type="text"
                            class="form-select text-muted"
                            id="inputcolorr"
                            aria-label="Design Amount"
                          >
                            <option value="Design" hidden>
                              Design
                            </option>
                            <option value="Design By Arets">
                              Design By Arets
                            </option>
                            <option value="Design by party">
                              Design by party
                            </option>
                          </select>
                        </div>
                        <div
                          className="ms-2"
                          style={{ color: "#FF0000", fontSize: "11px" }}
                        >
                          {edesign ? edesign : null}
                        </div>
                      </div>
                      <div className="col-lg-2 mt-2">
                        <h6 className="fs-12 fw-bold text-lg-center">
                          Amount*
                        </h6>
                      </div>
                      <div className="col-lg-2">
                        <div className="input-group ">
                          <input
                            type="text"
                            className="form-control required "
                            name="text"
                            id="inputcolorr"
                            value={designamount}
                            onChange={(e) => setDesignAmount(e.target.value)}
                            maxLength={7}
                            aria-label="design"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                        <div
                          className="ms-2"
                          style={{ color: "#FF0000", fontSize: "11px" }}
                        >
                          {edesignamount ? edesignamount : null}
                        </div>
                      </div>
                    </div>
                    {(papertype === '' || papertype === "Na") && millquantity === 0 ? (
  <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2 dashedline">
  <div className="col-lg-1">
    <h1 className="fs-14 fw-bold mt-1 pt-1">Digital:</h1>
  </div>
  <div className="col-lg-2 ps-lg-3 mt-2">
    <h6 className="fs-12 fw-bold ">Digital Type</h6>
  </div>
  <div className="col-lg-3 me-lg-1">
    <div className="input-group">
      <select
      value={digitalType}
        onChange={(e) => selectdigital(e)}
        class="form-select text-muted"
        id="inputcolorr"
        aria-label="Default select example"
      >
        <option selected hidden>
          Digital Type*
        </option>
        {digitalTypes && 
        digitalTypes.map((k, index) => {
              return (
                <option key={index} value={k.digitalType} data-index={index}>
                  {k.digitalType}
                </option>
              );
            })}
      </select>
    </div>
  </div>
  <div className="col-lg-1 mt-2">
    <h6 className="fs-12 fw-bold text-lg-center ms-lg-2">Qty</h6>
  </div>
  <div className="col-lg-2 ">
    <div className="input-group">
      <input
        type="Quantity"
        className="form-control required"
        name="Quantity"
        value={digiquantity}
        onChange={(e) => setDigiQuantity(e.target.value)}
        id="inputcolorr"
        aria-label="Quantity"
        aria-describedby="basic-addon1"
        required
      />
    </div>
  </div>
  <div className="col-lg-1 mt-2">
    <h6 className="fs-12 fw-bold text-lg-center ms-lg-2">Rate</h6>
  </div>
  <div className="col-lg-2">
    <div className="input-group">
      <input
        type="text"
        className="form-control required"
        name="rate"
        value={digirate}
        onChange={(e) => setDigiRate(e.target.value)}
        id="inputcolorr"
        maxLength={7}
        aria-label="rate"
        aria-describedby="basic-addon1"
        required
      />
    </div>
  </div>
</div>
) : (
            <div>
            <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2">
              <div className="col-lg-1">
                <h1 className="fs-14 fw-bold mt-1">PAPER:</h1>
              </div>
              <div className="col-lg-2 mt-2">
                <h6 className="fs-12 fw-bold text-lg-center">Paper & GSM*</h6>
              </div>
              <div className="col-lg-3">
                <div className="input-group">
                  <select
                    value={papertype}
                    onChange={(e) => selectpapertype(e)}
                    className="form-select text-muted me-2"
                    id="inputcolorr"
                    aria-label="Default select example"
                  >
                    <option hidden value>Paper and GSM*</option>
                    {gsmoptions && gsmoptions.map((k, index) => (
                      <option key={index} value={k.paperTypegsm} data-index={index}>
                        {k.paperType} {k.gsm}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="ms-2" style={{ color: "#FF0000", fontSize: "11px" }}>
                  {ephone ? ephone : null}
                </div>
              </div>
              <div className="col-lg-1 mt-2">
                <h6 className="fs-12 fw-bold">Mill Size*</h6>
              </div>
              <div className="col-lg-2">
                <div className="input-group">
                  <select
                    value={millsize}
                    onChange={(e) => selectmillsize(e)}
                    className="form-select text-muted"
                    id="inputcolorr"
                    aria-label="Default select example"
                  >
                    <option hidden selected>Mill Size*</option>
                    {millsizes && millsizes.map((k, index) => (
                      <option key={index} value={k.milliText} data-index={index}>
                        {k.milliText}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="ms-2" style={{ color: "#FF0000", fontSize: "11px" }}>
                  {emillsize ? emillsize : null}
                </div>
              </div>
              <div className="col-lg-1 mt-2">
                <h6 className="fs-12 fw-bold text-lg-end">Mill.Qty*</h6>
              </div>
              <div className="col-lg-2">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control required"
                    name="quantity"
                    id="inputcolorr"
                    value={millquantity}
                    onChange={(e) => setMillQuantity(e.target.value)}
                    maxLength={10}
                    aria-label="quantity"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
                <div className="ms-2" style={{ color: "#FF0000", fontSize: "11px" }}>
                  {emillquantity ? emillquantity : null}
                </div>
              </div>
            </div>
            <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2 dashedline">
              <div className="col-lg-1">
                <h1 className="fs-14 fw-bold mt-1"></h1>
              </div>
              <div className="col-lg-2 mt-2">
                <h6 className="fs-12 fw-bold ps-lg-2"></h6>
              </div>
              <div className="col-lg-3">
                <div className="input-group"></div>
              </div>
              <div className="col-lg-1 mt-2">
                <h6 className="fs-12 fw-bold">Cut Size*</h6>
              </div>
              <div className="col-lg-2">
                <div className="input-group">
                  <select
                    value={cutsize}
                    onChange={(e) => selectcutsize(e)}
                    className="form-select text-muted"
                    id="inputcolorr"
                    aria-label="Default select example"
                  >
                    <option selected hidden>Cutting Size*</option>
                    {cutsizes && cutsizes.map((k, index) => (
                      <option key={index} value={k.cuttingSize} data-index={index}>
                        {k.cuttingSize}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-1 mt-2">
                <h6 className="fs-12 fw-bold text-lg-end">Cut.Qty*</h6>
              </div>
              <div className="col-lg-2">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control required"
                    name="quantity"
                    id="inputcolorr"
                    value={cutquantity}
                    onChange={(e) => setcuttingtqty(e.target.value)}
                    maxLength={10}
                    aria-label="quantity"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
                <div className="ms-2" style={{ color: "#FF0000", fontSize: "11px" }}>
                  {ecutquantity ? ecutquantity : null}
                </div>
              </div>
            </div>
          </div>
           ) }

                    <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2 dashedline">
                      <div className="col-lg-2">
                        <h1 className="fs-14 fw-bold mt-1">PRINTING:</h1>
                      </div>
                      <div className="col-lg-2 mt-2">
                        <h6 className="fs-12 fw-bold">Printing Mode*</h6>
                      </div>
                      <div className="col-lg-3 ms-lg-2">
                        <div className="input-group">
                          <select
                            value={modeofprinting}
                            onChange={(e) => handlemode(e)}
                            class="form-select text-muted"
                            id="inputcolorr"
                            aria-label="Default select example"
                          >
                            <option selected hidden>
                              Mode of Printing*
                            </option>
                            {/* <option value="MSB">MSB</option>
                          <option value="KL-26">KL-26</option>
                          <option value="KL-20">KL-20</option>
                          <option value="Digital">Digital</option> */}
                            {machines &&
                              machines.map((k, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={k.details}
                                    data-index={index}
                                  >
                                    {k.details}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div
                          className="ms-2"
                          style={{ color: "#FF0000", fontSize: "11px" }}
                        >
                          {emodeofprinting ? emodeofprinting : null}
                        </div>
                      </div>
                      <div className="col-lg-2 mt-2">
                        <h6 className="fs-12 fw-bold text-lg-center">
                          Printing Side*
                        </h6>
                      </div>
                      <div className="col-lg-3">
                        <div className="input-group pe-lg-2">
                          <select
                            value={printingside}
                            onChange={(e) => handprintingside(e)}
                            class="form-select text-muted"
                            id="inputcolorr"
                            aria-label="Default select example"
                          >
                            <option hidden selected>
                              Printing Side
                            </option>
                            <option value="OS">OS</option>
                            <option value="BB">BB</option>
                            <option value="FB">FB</option>
                            <option value="OPPG">OPPG</option>
                          </select>
                        </div>
                        <div
                          className="ms-2"
                          style={{ color: "#FF0000", fontSize: "11px" }}
                        >
                          {eprintingside ? eprintingside : null}
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="input-group mb-2 ms-lg-1">
                          {/* <input
                          type="text"
                          className="form-control required"
                          name="quantity"
                          id='inputcolorr'
                          value={quantity}
                          onChange={(e) => setQuantity(e.target.value)}
                          maxLength={10}
                          aria-label="quantity"
                          aria-describedby="basic-addon1"
                          required
                        /> */}
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-flex flex-column gap-1 gap-lg-0 flex-lg-row mt-2 dashedline">
                      <div className="col-lg-2">
                        <h1 className="fs-14 fw-bold mt-1">LAMINATION:</h1>
                      </div>
                      <div className="col-lg-4 col-sm-12">
                        <div className="input-group">
                          <select
                            value={lamination}
                            onChange={(e) => selectlamination(e)}
                            class="form-select text-muted"
                            id="inputcolorr"
                            aria-label="Default select example"
                          >
                            <option selected hidden>
                              Lamination Type
                            </option>
                            {/* <option value="MSB">MSB</option>
                          <option value="KL-26">KL-26</option>
                          <option value="KL-20">KL-20</option>
                          <option value="Digital">Digital</option> */}
                            {laminations &&
                              laminations.map((k, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={k.details}
                                    data-index={index}
                                  >
                                    {k.details}
                                  </option>
                                );
                              })}
                          </select>
                          {/* <Multiselect
                          options={laminations}
                          id='inputcolorr'
                          selectionLimit={1}
                          singleSelect={true}
                          emptyRecordMsg={'Lamination Types Not Found'}
                           hiddenValues={lamination}
                          defaltValue={lamination}
                          displayValue={'details'}
                          ref={laminationmultiselectRef}
                          onSelect={(value) => selectlamination(value)}
                        /> */}
                        </div>
                      </div>
                      <div className="col-lg-1 me-lg-1 ms-lg-1">
                        <div className="input-group">
                          <h6 className="fs-12 fw-bold mt-2 me-lg-1">L</h6>
                          <input
                            type="length"
                            className="form-control required"
                            name="length"
                            value={lamlength}
                            onChange={(e) => setLamlength(e.target.value)}
                            id="inputcolorr"
                            aria-label="length"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-1 me-lg-1">
                        <div className="input-group">
                          <h6 className="fs-12 fw-bold mt-2 me-lg-1">B</h6>
                          <input
                            type="breadth"
                            className="form-control required"
                            name="breadth"
                            value={lambreadth}
                            onChange={(e) => setLambreadth(e.target.value)}
                            id="inputcolorr"
                            aria-label="Breadth"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-1 mt-2">
                        <h6 className="fs-12 fw-bold text-lg-center ms-lg-2">
                          Qty
                        </h6>
                      </div>
                      <div className="col-lg-1">
                        <div className="input-group">
                          <input
                            type="length"
                            className="form-control required"
                            name="length"
                            id="inputcolorr"
                            value={lamquantity}
                            onChange={(e) => setLamQuantity(e.target.value)}
                            aria-label="length"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-1 mt-2">
                        <h6 className="fs-12 fw-bold text-lg-center ms-lg-2">
                          Rate
                        </h6>
                      </div>
                      <div className="col-lg-1 pe-lg-2">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control required"
                            name="breadth"
                            value={laminationRate}
                            onChange={(e) => setLaminationRate(e.target.value)}
                            id="inputcolorr"
                            maxLength={7}
                            aria-label="Breadth"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2">
                      <div className="col-lg-1">
                        <h1 className="fs-14 fw-bold mt-1">PUNCHING:</h1>
                      </div>
                      <div className="col-lg-2 mt-2">
                        <h6 className="fs-12 fw-bold "></h6>
                      </div>
                      <div className="col-lg-2 me-lg-2">
                        <div className="input-group"></div>
                      </div>

                      <div className="col-lg-1 ms-lg-2">
                        <div className="input-group"></div>
                      </div>
                      <div className="col-lg-3 mt-2">
                        <h6 className="fs-12 fw-bold text-lg-center">
                          Die Amount
                        </h6>
                      </div>
                      <div className="col-lg-3">
                        <div className="input-group pe-lg-3">
                          <input
                            type="text"
                            className="form-control required"
                            name="breadth"
                            value={dieamount}
                            onChange={(e) => setDieAmount(e.target.value)}
                            id="inputcolorr"
                            maxLength={7}
                            aria-label="Breadth"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2 dashedline">
                      <div className="col-lg-2">
                        <h1 className="fs-14 fw-bold mt-1"></h1>
                      </div>
                      <div className="col-lg-2 mt-2">
                        <h6 className="fs-12 fw-bold">Punching Type</h6>
                      </div>
                      <div className="col-lg-2 me-lg-2">
                        <div className="input-group">
                          <select
                            value={punchingType}
                            onChange={(e) => selpunchingtype(e)}
                            class="form-select text-muted"
                            id="inputcolorr"
                            aria-label="Default select example"
                          >
                            {/* <option selected>Punching Type*</option>
                          <option value="Full Punching">Full Punching</option>
                          <option value="Half Punching">Half Punching</option>
                          <option value="None">None</option> */}
                            <option hidden selected>
                              Punching Type
                            </option>
                            {punchingTypes && punchingTypes.length > 0
                              ? punchingTypes.map((k, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={k.type}
                                      data-index={index}
                                    >
                                      {k.type}
                                    </option>
                                  );
                                })
                              : null}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-1 mt-2">
                        <h6 className="fs-12 fw-bold text-lg-center ms-lg-2">
                          Qty
                        </h6>
                      </div>
                      <div className="col-lg-2">
                        <div className="input-group">
                          <input
                            type="length"
                            className="form-control required"
                            name="length"
                            id="inputcolorr"
                            value={punchQuantity}
                            onChange={(e) => setPunchQuantity(e.target.value)}
                            aria-label="length"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-1 mt-2">
                        <h6 className="fs-12 fw-bold text-lg-center ms-lg-2">
                          Rate
                        </h6>
                      </div>
                      <div className="col-lg-2">
                        <div className="input-group pe-lg-1">
                          <input
                            type="breadth"
                            className="form-control required"
                            name="breadth"
                            value={punchRate}
                            onChange={(e) => setPunchRate(e.target.value)}
                            id="inputcolorr"
                            maxLength={7}
                            aria-label="Breadth"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2 dashedline">
                      <div className="col-lg-2">
                        <h1 className="fs-14 fw-bold mt-1">PASTING:</h1>
                      </div>
                      <div className="col-lg-2 mt-2">
                        <h6 className="fs-12 fw-bold ">Pasting Type</h6>
                      </div>
                      <div className="col-lg-2 me-lg-1">
                        <div className="input-group">
                          <select
                            value={pastingtype}
                            onChange={(e) => selpasting(e)}
                            class="form-select text-muted"
                            id="inputcolorr"
                            aria-label="Default select example"
                          >
                            <option hidden selected>
                              Pasting Type
                            </option>
                            {pastingtypes && pastingtypes.length > 0
                              ? pastingtypes.map((k, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={k.type}
                                      data-index={index}
                                    >
                                      {k.type}
                                    </option>
                                  );
                                })
                              : null}
                            {/* <option value="1">Pasting Required</option>
                          <option value="2">None</option> */}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-1 mt-2">
                        <h6 className="fs-12 fw-bold text-lg-center ms-lg-2">
                          Qty
                        </h6>
                      </div>
                      <div className="col-lg-2 ">
                        <div className="input-group">
                          <input
                            type="Quantity"
                            className="form-control required"
                            name="Quantity"
                            value={pastquantity}
                            onChange={(e) => setPastquantity(e.target.value)}
                            id="inputcolorr"
                            aria-label="Quantity"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-1 mt-2">
                        <h6 className="fs-12 fw-bold text-lg-center ms-lg-2">
                          Rate
                        </h6>
                      </div>
                      <div className="col-lg-2">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control required"
                            name="rate"
                            value={pastRate}
                            onChange={(e) => setPastRate(e.target.value)}
                            id="inputcolorr"
                            maxLength={7}
                            aria-label="rate"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2 dashedline">
                      <div className="col-lg-2">
                        <h1 className="fs-14 fw-bold mt-1">FINISHING:</h1>
                      </div>
                      <div className="col-lg-2 mt-2"></div>
                      <div className="col-lg-2 me-lg-1">
                        <div className="input-group">
                          <select
                            value={finishingtype}
                            onChange={(e) => finishevent(e)}
                            class="form-select text-muted"
                            id="inputcolorr"
                            aria-label="Default select example"
                          >
                            <option hidden selected>
                              Finishing
                            </option>
                            <option value="Binding">Binding</option>
                            <option value="Pinning">Pinning</option>
                            <option value="Rimming">Rimming</option>
                            {/* <option value="Perfect Binding">Perfect Binding</option>
                          <option value="Pinning">Pinning</option> */}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-1 mt-2">
                        <h6 className="fs-12 fw-bold text-lg-center ms-lg-2">
                          Qty
                        </h6>
                      </div>
                      <div className="col-lg-2 ">
                        <div className="input-group">
                          <input
                            type="length"
                            className="form-control required"
                            name="length"
                            id="inputcolorr"
                            value={finishingQuantity}
                            onChange={(e) =>
                              setFinishingQuantity(e.target.value)
                            }
                            aria-label="length"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-1 mt-2">
                        <h6 className="fs-12 fw-bold text-lg-center ms-lg-2">
                          Rate
                        </h6>
                      </div>
                      <div className="col-lg-2">
                        <div className="input-group">
                          <input
                            type="breadth"
                            className="form-control required"
                            name="breadth"
                            value={finishingRate}
                            onChange={(e) => setFinishingRate(e.target.value)}
                            id="inputcolorr"
                            maxLength={7}
                            aria-label="Breadth"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2 dashedline">
                      <div className="col-lg-2">
                        <h1 className="fs-14 fw-bold mt-1">OTHERS:</h1>
                      </div>

                      <div className="col-lg-7 me-lg-1">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control required"
                            name="breadth"
                            value={others}
                            onChange={(e) => setOthers(e.target.value)}
                            id="inputcolorr"
                            aria-label="Breadth"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>

                      <div className="col-lg-1 mt-2">
                        <h6 className="fs-12 fw-bold text-lg-center">Amount</h6>
                      </div>
                      <div className="col-lg-2">
                        <div className="input-group pe-lg-1">
                          <input
                            type="text"
                            className="form-control required"
                            name="breadth"
                            maxLength={7}
                            value={othersamount}
                            onChange={(e) => setOtherAmount(e.target.value)}
                            id="inputcolorr"
                            aria-label="Breadth"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2">
                      <div className="col-lg-2">
                        <h1 className="fs-14 fw-bold mt-1">MARKUP:</h1>
                      </div>

                      <div className="col-lg-3 me-lg-1">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control required"
                            name="name"
                            id="inputcolorr"
                            maxLength={7}
                            value={markupPercentage}
                            onChange={(e) =>
                              setMarkupPercentage(e.target.value)
                            }
                            aria-label="name"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2">
                      <div className="col-lg-2">
                        <h1 className="fs-14 fw-bold mt-1">JOB DESCRIPTION:</h1>
                      </div>
                      <div className="col-lg-10 me-lg-1">
                        <div className="input-group">
                          {getjobdet()}
                          <div className="col-lg-8"></div>
                          <div
                            className="col-lg-4"
                            style={{ textAlign: "right" }}
                          >
                            <Button
                              onClick={() => submit()}
                              id="pillStyle"
                              className=" w-100 mb-2"
                              variant="primary"
                            >
                              Calculate
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <div class="d-none d-lg-flex" style={{ height: "950px" }}>
                  <div class="vr"></div>
                </div>
                <hr></hr>
                <Col lg={4} className=" ps-lg-3">
                  <div className="d-flex mb-4">
                    <img
                      src={aretsLogo}
                      alt="branding"
                      className="loginPage-brandImg"
                      onClick={() => window.location.reload()}
                    />
                    <h3
                      style={{
                        fontSize: "25px",
                        fontFamily: "Yeseva One",
                        fontWeight: "400",
                      }}
                    >
                      AReTS Graphics
                      <p
                        style={{
                          fontFamily: "Clash Display",
                          fontSize: "18px",
                        }}
                      >
                        Everything in Printing
                      </p>
                    </h3>
                  </div>
                  {/* <h6><small>John Doe, Hyderabad</small></h6> */}
                  <h6>
                    <small>15-4-64, Gowliguda Chaman, Hyderabad 500012</small>
                  </h6>
                  <h6>
                    <small>Phone: 040-9393009990</small>
                  </h6>
                  <h6>
                    <small>GSTIN: 36AEYPM2529L1ZR</small>
                  </h6>
                  <h5 className="mt-2 mb-2">Client Details</h5>
                  <h6>
                    <small>Firm Name: {cname}</small>
                  </h6>
                  <h6>
                    <small>
                      Address: {faddress}
                      {address}
                      {pincode}
                    </small>
                  </h6>
                  <h6>
                    <small>Phone: {phone}</small>
                  </h6>
                  <h6>
                    <small>GSTIN: {gst}</small>
                  </h6>
                  <h4 className="mt-2 mb-4">Cost Estimate</h4>
                  <div className="d-flex mt-2">
                    <div className="col-lg-8 col-8 text-muted">Designing</div>
                    <div className="col-lg-3 text-end">
                      ₹{" "}
                      {estimatecalc
                        ? parseFloat(estimatecalc.designingAmount.toFixed(2))
                        : location.state.item.designingAmount}
                    </div>
                  </div>
                  {(papertype === '' || papertype === "Na") && millquantity === 0 ? (
                    <div className="d-flex mt-2">
                    <div className="col-lg-8 col-8 text-muted">Digital</div>
                    <div className="col-lg-3 text-end">
                      ₹{" "}
                      {estimatecalc
                        ? parseFloat(estimatecalc.digitalAmount.toFixed(2))
                        : location.state.item.digitalAmount}
                    </div>
                  </div> ) : (
                  <div className="d-flex mt-2">
                    <div className="col-lg-8 col-8 text-muted">Paper</div>
                    <div className="col-lg-3 text-end">
                      ₹{" "}
                      {estimatecalc
                        ? parseFloat(estimatecalc.paperAmount.toFixed(2))
                        : location.state.item.paperAmount}
                    </div>
                  </div>
                  )}
                  <div className="d-flex mt-2">
                    <div className="col-lg-8 col-8 text-muted">Printing</div>
                    <div className="col-lg-3 text-end">
                      ₹{" "}
                      {estimatecalc
                        ? parseFloat(estimatecalc.PrintAmount.toFixed(2))
                        : location.state.item.PrintAmount}
                    </div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="col-lg-8 col-8 text-muted">Lamination</div>
                    <div className="col-lg-3 text-end">
                      ₹{" "}
                      {estimatecalc && estimatecalc.laminationAmount
                        ? estimatecalc.laminationAmount < 300
                          ? 300
                          : parseFloat(estimatecalc.laminationAmount.toFixed(2))
                        : location.state?.item?.laminationAmount ?? 0}
                    </div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="col-lg-8 col-8 text-muted">Die</div>
                    <div className="col-lg-3 text-end">
                      ₹{" "}
                      {estimatecalc
                        ? parseFloat(estimatecalc.dieAmount.toFixed(2))
                        : location.state.item.dieAmount}
                    </div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="col-lg-8 col-8 text-muted">Punching</div>
                    <div className="col-lg-3 text-end">
                      ₹{" "}
                      {estimatecalc
                        ? parseFloat(estimatecalc.punchingAmount.toFixed(2))
                        : location.state.item.punchingAmount}
                    </div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="col-lg-8 col-8 text-muted">Pasting</div>
                    <div className="col-lg-3 text-end">
                      ₹{" "}
                      {estimatecalc
                        ? parseFloat(estimatecalc.pastingAmount.toFixed(2))
                        : location.state.item.pastingAmount}
                    </div>
                  </div>

                  {finishingtype === "Rimming" && (
                    <div className="d-flex mt-2">
                      <div className="col-lg-8 col-8 text-muted">Rimming</div>
                      <div className="col-lg-3 text-end">
                        ₹
                        {estimatecalc
                          ? parseFloat(estimatecalc.rimmingAmount.toFixed(2))
                          : location.state.item.rimmingAmount}
                      </div>
                    </div>
                  )}

                  {finishingtype == "Binding" && (
                    <div className="d-flex mt-2 ">
                      <div className="col-lg-8 col-8 text-muted">Binding</div>
                      <div className="col-lg-3 text-end">
                        ₹
                        {estimatecalc
                          ? parseFloat(estimatecalc.bindingAmount.toFixed(2))
                          : location.state.item.bindingAmount}
                      </div>
                    </div>
                  )}

                  {finishingtype == "Pinning" && (
                    <div className="d-flex mt-2">
                      <div className="col-lg-8 col-8 text-muted">Pinning</div>
                      <div className="col-lg-3 text-end">
                        ₹{" "}
                        {estimatecalc
                          ? parseFloat(estimatecalc.pinningAmount.toFixed(2))
                          : location.state.item.pinningAmount}
                      </div>
                    </div>
                  )}

                  {/* {isBinding ? (
                    isPinning ? (
                      isRimming ? (
                        <div></div>
                      ) : (
                        <div className="row mt-2">
                          <div className="col-lg-8 text-muted">Rimming</div>
                          <div className="col-lg-3 text-end">
                            ₹
                            {estimatecalc && estimatecalc.rimmingAmount
                              ? estimatecalc.rimmingAmount
                              : location.state.item.rimmingAmount}
                          </div>
                        </div>
                      )
                    ) : (
                      <div className="row mt-2">
                        <div className="col-lg-8 text-muted">Binding</div>
                        <div className="col-lg-3 text-end">
                          ₹
                          {estimatecalc && estimatecalc.bindingAmount
                            ? estimatecalc.bindingAmount
                            : location.state.item.bindingAmount}
                        </div>
                      </div>
                    )
                  ) : (
                    <div className="row mt-2">
                      <div className="col-lg-8 text-muted">Pinning</div>
                      <div className="col-lg-3 text-end">
                        ₹
                        {estimatecalc && estimatecalc.pinningAmount
                          ? estimatecalc.pinningAmount
                          : location.state.item.pinningAmount}
                      </div>
                    </div>
                  )} */}
                  <div className="d-flex mt-2">
                    <div className="col-lg-8 col-8 text-muted">
                      {others.length === 0 ? "others" : others}
                    </div>
                    <div className="col-lg-3 text-end">
                      ₹{" "}
                      {estimatecalc
                        ? parseFloat(estimatecalc.othersamount.toFixed(2))
                        : otherAmount}
                    </div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="col-lg-8 col-8 text-muted">Sub Total</div>
                    <div className="col-lg-3 text-end">
                      ₹{" "}
                      {estimatecalc
                        ? parseInt(estimatecalc.totalAmount)
                        : location.state.item.totalAmount}
                    </div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="col-lg-8 col-8 text-muted">Misc %</div>
                    <div className="col-lg-3 text-end">
                      ₹{" "}
                      {estimatecalc
                        ? parseInt(estimatecalc.miscAmount)
                        : location.state.item.miscAmount}
                    </div>
                  </div>
                  {/* <div className="d-flex mt-2">
            <div className="col-lg-8 col-8 text-muted">Gst</div>
            <div className="col-lg-3 text-end">
              ₹ {estimatecalc ? parseInt(estimatecalc.gstamount) : 0}
            </div>
          </div> */}
                  <div className="d-flex mt-2">
                    <div className="col-lg-8 col-8 text-muted">Total</div>

                    <div className="col-lg-3 text-end">
                      ₹{" "}
                      {estimatecalc
                        ? parseInt(estimatecalc.sum_total)
                        : location.state.item.sum_total}
                    </div>
                  </div>
                  <div className="d-flex mb-4">
                    <div className="col-lg-8 col-8 text-muted">
                      <span style={{ fontSize: "11px" }}>* 18% GST Extra</span>
                    </div>
                  </div>

                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="row gap-2 gap-lg-0">
                      <div className="col-lg-6">
                        <div className="input-group">
                          <Button
                            as={NavLink}
                            to="/estimates"
                            id="pillStyle"
                            className="w-100"
                            variant="outline-primary"
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-group">
                          {!isLoading ? (
                            <Button
                              onClick={() => handlesubmit()}
                              id="pillStyle"
                              className="w-100"
                              variant="primary"
                            >
                              Update Estimate
                            </Button>
                          ) : (
                            <button className="buttonload w-100" id="pillStyle">
                              <i className="fa fa-spinner fa-spin"></i> Loading
                            </button>
                          )}
                        </div>
                      </div>
                      {calculated === false && (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            paddingTop: "1rem",
                          }}
                        >
                          Please Calculate the estimate first
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </CardContent>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  console.log(state, "state");
  return {
    userdata: state.userdetails,
  };
};
export default connect(mapStateToProps)(EditEstimate);
