import React, { useEffect, useState, useRef } from "react";
import "../../Styles/JobCards.css";
import Header from "../header/Header";
import Sidebar from "../Sidenav/Sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import filterIcon from "../../Images/filterIcon.png";
import shareIcon from "../../Images/shareIcon.png";
import sortIcon from "../../Images/sortIcon.png";
import calenderIcon from "../../Images/calenderIcon.png";
import UploadImageIcon from "../../Images/UploadImageIcon.png";
import DeleteIcon from "../../Images/DeleteIcon.png";
import eyeIcon from "../../Images/eyeIcon.svg";
import editIcon from "../../Images/editIcon.svg";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Table from "react-bootstrap/Table";
import { NavLink } from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Modal from "react-bootstrap/Modal";
import Moment from "moment";
import JobcardsService from "../../services/JobcardsService";
import axios from "axios";
import estimateService from "../../services/estimateService";
import authService from "../../services/authService";
import Multiselect from "multiselect-react-dropdown";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { toast } from "react-toastify";
import ClientsService from "../../services/ClientsService";



const JobCards = () => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [modalShow, setModalShow] = React.useState(false);
  const [fromAmount, setFromAmount] = useState("");
  const [selectedValue, setselectedValue] = useState([]);
  const [accordion, setAccordion] = useState("printing");
  const [filtstatus, setFiltStatus] = useState("");
  const [toAmount, setToAmount] = useState("");
  const [dates, setDates] = useState([]);
  const [jobcards, setjobcards] = useState([]);
  const [clients, setClients] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [datafilter, setDataFilter] = useState(false);
  const [fromdate, setFromDate] = useState("");
  const [todate, setToDate] = useState("");
  const [datefilter, setDateFilter] = useState(false);
  const [jobcardscount, setJobCardsCount] = useState(0);
  const [client, setClient] = useState();
  const [cname, setCname] = useState();
  const multiselectRef = useRef();
  const [datetab, setDateTab] = useState("From");

  const handleChangePage = (event, newPage) => {
    const dett = newPage * rowsPerPage;
    setPage(newPage);
    getjobcards(dett, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getjobcards(0, event.target.value);
  };
  let navigate = useNavigate();
  function editjob(item) {
    navigate("/editjob", { state: { item: item } });
  }
  // function viewjob() {
  //     navigate('/JobId');
  // };
  const viewjob = (item) => {
    console.log("item==", item);
    setjobcards(item);
    navigate("/JobId", { state: { item: item } });
  };
  // const handleClick = (item) => {
  //     console.log(item,'orderitem');
  //     navigate('/JobId', { state: { item: item } });
  //     // navigate('/editestimate', { state: { item: item } });
  // }
  useEffect(() => {
    getclients();
  }, []);
  useEffect(() => {
    getjobcards(0,10);
    resetorder(0,10)
  }, []);

  const resetorder = async (skps, rpp) => {
    try {
      const obj = {
        skip: skps,
        limit: rpp,
        "searchkeys": '',
      };
      const dert = await JobcardsService.getjobcards(obj);
      if (dert.data.status === "success") {
        console.log(dert.data.value, "values");
        setjobcards(dert.data.value);
        setJobCardsCount(dert.data.count);
      }
    } catch (ex) { }
  };

  const getjobcards = async (skps, rpp) => {
    try {
      const obj = {
        skip: skps,
        limit: rpp,
        searchkeys: searchtext,
      };
  
      // Define the filter criteria
      const filterCriteria = {
        status: filtstatus,
        // customerName: cname,
        fromAmount: fromAmount,
        toAmount: toAmount,
        fromDate: fromdate,
        toDate: todate
      };
  
      // Construct the filterBy object based on non-empty filter criteria
      const filterBy = Object.entries(filterCriteria).reduce((acc, [key, value]) => {
        if (value !== "") {
          return { ...acc, [key]: value };
        }
        return acc;
      }, {});
  
      // Only include filterBy when it is not empty
      if (Object.keys(filterBy).length > 0) {
        obj.filterBy = filterBy;
      }
  
  const dert = await JobcardsService.getjobcards(obj);
  if (dert.data.status === "success") {
        console.log(dert.data.value, "values");
        setJobCardsCount(dert.data.count);
        setjobcards(dert.data.value);
      }
    } catch (ex) {
      // Handle error
    }
  };


  const selectemps = (value) => {
    setCname(value[0].name);
  };
  //SearchAPI
  const [searchtext, setSearchText] = useState("");

  const [search, setSearch] = useState(false);
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      serachclick();
     
    }
  };

  const searchChange = (value) => {
    setSearchText(value);
    // getestimates(0,10)
  };
  const serachclick = async () => {
    const trimmedSearchText = searchtext.trim(); 
    if (trimmedSearchText === "") {
      toast.error("Enter Text", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
   
    }
    else {
      try {
        const obj = {
          skip: 0,
          limit: rowsPerPage,
          searchkeys : trimmedSearchText,
        };
        const dert = await JobcardsService.getjobcards(obj);
        console.log(dert, "dert");
        if (dert.data.status === "success") {
          setSearch(true);
          console.log(dert.data.value, "values");
          setjobcards(dert.data.value);
          setJobCardsCount(dert.data.count);
          // setjobcards(0,10);

        }
      } catch (ex) { }
    }
  };
  const resetclick = () => {
    setSearchText("");
      setSearch(false)
      resetorder(0,rowsPerPage);
  }
  const resclick = () => {
    filterbydata("");
    setFiltStatus("");
    setCname('')
    setFromAmount("");
    setToAmount("");
    setFromDate("");
    setToDate("");
    setDataFilter(false);
    setDateFilter(false);
    resetorder(0,rowsPerPage);
  };

  //SearchAPI Close

  // download Excel

  // const handleDownloadExcel = async () => {
  //   try {
  //     setIsDownloading(true);
  //     const response = await axios.post(
  //       "/jobCards/jsontoexcel",
  //       { data: [] },
  //       { responseType: "blob" }
  //     );
  //     const excelBlob = new Blob([response.data], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });
  //     const url = window.URL.createObjectURL(excelBlob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = "JobCards.xlsx";
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setIsDownloading(false);
  //   }
  // };

  const handleDownloadExcel = async () => {
    try {
      setIsDownloading(true);
      const requestData = {
        // Add your request object properties here
        // For example:
        searchkeys: searchtext,
        "filterBy": {
          status: filtstatus,
          customerName: cname,
          fromAmount: fromAmount,
          toAmount: toAmount,
          fromDate: fromdate,
          toDate: todate,
        }
      };
      const response = await axios.post(
        "/estimate/jobjsontoexcel",
        requestData,
        { responseType: "blob" }
      );
      const excelBlob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(excelBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "JobCards.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    } finally {
      setIsDownloading(false);
    }
  };
  // const seljobstatus = (value) => {
  //     setselectedValue(value)
  // }
  const handleStatus = async (e, item) => {
    console.log(item, "e.target.value");
    try {
      const obj = {
        orderId: item.orderId,
        status: e.target.value,
      };
      const der = await estimateService.updatestatus(obj);
      if (der.data.status === "success") {
        getjobcards(0, 10);
      }
    } catch (ex) { }
  };
  //  Download Excel Close

  const getclients = async () => {
    try {
      const dert = await ClientsService.getclients();
      if (dert.data.status === "success") {
        console.log(dert.data.value, "values");
        setClients(dert.data.value);
      }
    } catch (ex) { }
  };
  const filterbydata = async () => {
    try {
      const obj = {
        skip: 0,
        limit:rowsPerPage,
        // searchkeys: "",
        filterBy: {
         status: filtstatus,
          customerName: cname,
          fromDate: fromdate,
          toDate: todate,
        },
      };
      const dert = await JobcardsService.getjobcards(obj);
      if (dert.data.status === "success") {
        // setFiltStatus("");
        // setFromAmount("");
        // setToAmount("");
        setDataFilter(false);
        // setSearch(true)
        // console.log(dert.data.value, 'values');
        setJobCardsCount(dert.data.count);
        setjobcards(dert.data.value);
        // getjobcards(0,10);
      
      }
    } catch (ex) { }
  };

  const clickfiltdate = async () => {
    try {
      const currentDate = new Date();
      const toDateCopy = new Date(todate);
      const fromDateCopy = new Date(fromdate);


      toDateCopy.setHours(23, 59, 59, 999);
      fromDateCopy.setHours(0, 0, 0, 0);
      const obj = {
        skip: 0,
        limit: rowsPerPage,
        filterBy: {
          fromDate: fromdate > currentDate ? currentDate : fromDateCopy,
          toDate: todate > currentDate ? currentDate : toDateCopy,
        },
      };
  if (fromdate > currentDate || todate > currentDate) {
       toast.error("Please select a valid date.");
        return;
      }
      const dert = await JobcardsService.getjobcards(obj);
      if (dert.data.status === "success") {
        setFiltStatus("");
        // setFromDate("");
        // setToDate("");
        setDateFilter(false);
        // setSearch(true)
        // console.log(dert.data.value, 'values');
        setJobCardsCount(dert.data.count);
        setjobcards(dert.data.value);
        
      }
    } catch (ex) {}
  };
  const filtdrop = () => {
    setDataFilter(!datafilter);
  };
  const datefiltdrop = () => {
    setDateFilter(!datefilter);
  };
  const onDateChange = (e) => {
    console.log(e, "datevalue");
    setFromDate(e);
    setToDate(e);
  };
  const onToDateChange = (e) => {
    setToDate(e);
  };
  const activetabclick = (tab) => {
    setDateTab(tab);
  };
  
  console.log(dates, "dates");
  return (
    <>
      <Header />
      <Container fluid className="containerr">
        <Row>
          <Col lg={2} id="sidebar-wrapper" className="d-none d-md-block">
            <Sidebar />
          </Col>
          <Col lg={10} sm={4} className="containerr px-3 px-lg-4">
            <div className="pt-4 pb-2 d-flex justify-content-between align-items-center">
              <div>
                <h1 className="fs-3">Job Cards</h1>
                <nav aria-label="breadcrumb" className="fs-14">
                  <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item">
                      <a href="/dashboard">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Job Cards
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <Row className="d-flex">
              <Col lg={12} sm={12} md={6} xs={12} className=" mb-5 datacenter">
                <Paper className="col-lg-12 col-sm-8 rounded-4">
                  <Box sx={{}}>
                    <Row>
                      <Col lg={4} sm={12} className="text-lg-start">
                        <h6 className="datahead text-muted">Job Cards</h6>
                      </Col>
                      {/* categories starts */}
                      <Col lg={8} sm={12} className="px-4 pb-4">
                        <Row>
                          <Col lg={5} xs={12} className="middleFilter">
                            <Form className="mt-3 d-flex gap-2 searchField">
                              <div className="col-lg-8 d-flex">
                                <input
                                  value={searchtext}
                                  onChange={(e) => searchChange(e.target.value)}
                                  onKeyDown={handleKeyDown}
                                  className="form-control nosubmit"
                                  style={{ fontSize: "14px" }}
                                  type="search"
                                  placeholder="Search Firm Name"
                                />
                              </div>
                              <div className="col-lg-4">
                                {!search ? (
                                  <Button
                                    onClick={() => serachclick()}
                                    className="w-100 sortFilter1"
                                    variant="primary"
                                  >
                                    Search
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => resetclick()}
                                    className="w-100 sortFilter1"
                                    variant="primary"
                                  >
                                    Reset
                                  </Button>
                                )}
                                {/* <span className="searchcont" onClick={() => serachclick()} style={{cursor:'pointer'}}><i class="fa fa-search empsearch"></i></span> */}
                              </div>
                            </Form>
                          </Col>
                          {/* sort filter */}
                          <Col lg={2} xs={6} className="middleFilter">
                            <Button
                              onClick={() => filtdrop()}
                              id="sortfilter"
                              className="sortFilter1 mt-3"
                              variant="contained"
                            >
                              <img className="menuIcon mx-1" src={filterIcon} />
                              Filter
                            </Button>  
                            {datafilter === true ? (
                              <div
                                style={{
                                  position: "absolute",
                                  backgroundColor: "white",
                                  right: "40px",
                                  boxShadow: "1px 2px 9px #F4AAB9",
                                  zIndex:1
                                }}
                              >
                                <Typography sx={{ p: 2 }} className="col-lg-12">
                                  <h6>Filter</h6>

                                  <div>
                                    <h6 className="mt-2 fs-13">Status</h6>
                                    <Form.Select
                                      value={filtstatus}
                                      onChange={(e) =>
                                        setFiltStatus(e.target.value)
                                      }
                                      aria-label="Default select example"
                                    >
                                      <option selected hidden>All</option>
                                      <option value="Job-Created">
                                        Job Created
                                      </option>
                                      <option value="Job-Cancelled">
                                        Job Cancelled
                                      </option>
                                      <option value="Post-Printing">
                                        Post Printing
                                      </option>
                                      <option value="Pre-Printing">
                                        Pre Printing
                                      </option>
                                      <option value="MaterialIndent-Created">
                                        Material Indent Created
                                      </option>
                                      <option value="Deliever-Completed">
                                        Delivery Completed
                                      </option>
                                    </Form.Select>
                                    <h6 className="mt-2 fs-13">Customer</h6>
                                    <Multiselect
                                      placeholder="Select Firm Name"
                                      className="border rounded"
                                      options={clients}
                                      selectionLimit={1}
                                      singleSelect={true}
                                      emptyRecordMsg={"Customer Not Found"}
                                      selectedValues={client}
                                      defaltValue={client}
                                      displayValue="name"
                                      ref={multiselectRef}
                                      onSelect={(value) => selectemps(value)}
                                    />
                                    <h6 className="mt-2 fs-14">Amount</h6>
                                    <Form>
                                      <Row>
                                        <Col>
                                          <Form.Label>From</Form.Label>
                                          <Form.Control
                                            value={fromAmount}
                                            onChange={(e) =>
                                              setFromAmount(e.target.value)
                                            }
                                            type="number"
                                            className="filterFields"
                                            placeholder="0.00"
                                          />
                                        </Col>
                                        <Col>
                                          <Form.Label>To</Form.Label>
                                          <Form.Control
                                            value={toAmount}
                                            onChange={(e) =>
                                              setToAmount(e.target.value)
                                            }
                                            type="number"
                                            placeholder="0.00"
                                          />
                                        </Col>
                                      </Row>
                                    </Form>
                                    <div className="d-flex">
                                    <Button
                                      onClick={() => filterbydata()}
                                      id="pillStyle"
                                      className="w-100 mt-3"
                                      variant="primary"
                                    >
                                      Filter
                                    </Button>{" "}
                                    <Button
                                    onClick={() => resclick()}
                                    id="pillStyle"
                                    className="w-100 mt-3 ms-1"
                                    variant="primary"
                                  >
                                    Reset
                                  </Button>
                                  </div>
                                   
                                  </div>
                                    
                                  
                                </Typography>
                              </div>
                            ) : null}
                            
                              
                          </Col>
                          {/* date filter */}
                          <Col lg={2} xs={6} className="middleFilter">
                            <Button
                              onClick={() => datefiltdrop()}
                              id="sortfilter"
                              className="sortFilter1 mt-3"
                              variant="contained"
                              
                            >
                              <img
                                className="menuIcon mx-1"
                                src={calenderIcon}
                              />
                              Date
                            </Button>
                            {datefilter ? (
                              <div
                                style={{
                                  position: "absolute",
                                  backgroundColor: "white",
                                  right: "40px",
                                  boxShadow: "1px 2px 9px #F4AAB9",
                                  zIndex:1
                                }}
                              >
                                <div
                                  style={{
                                    flexDirection: "row",
                                    display: "flex",
                                    flex: 1,
                                  }}
                                >
                                  <div
                                    style={{
                                      backgroundColor:
                                        datetab === "From"
                                          ? "#0d6efd"
                                          : "white",
                                      cursor: "pointer",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    onClick={() => activetabclick("From")}
                                    className="col-lg-6 text-center"
                                  >
                                    <p
                                      style={{
                                        color:
                                          datetab === "From"
                                            ? "white"
                                            : "black",
                                      }}
                                      id="pillStyle"
                                      href="#dateRange"
                                    >
                                      From
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      backgroundColor:
                                        datetab === "To" ? "#0d6efd" : "white",
                                      cursor: "pointer",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    onClick={() => activetabclick("To")}
                                    className="col-lg-6 text-center"
                                  >
                                    <p
                                      style={{
                                        color:
                                          datetab === "To" ? "white" : "black",
                                      }}
                                      id="pillStyle"
                                      eventKey="link-2"
                                    >
                                      To
                                    </p>
                                  </div>
                                </div>
                                <Typography sx={{ p: 2 }} className="col-lg-12">
                                  {datetab === "From" ? (
                                    <Calendar
                                      onChange={onDateChange}
                                      value={fromdate}
                                    />
                                  ) : (
                                    <Calendar
                                      onChange={onToDateChange}
                                      value={todate}
                                    />
                                  )}
                              <div className="d-flex">
                                  <Button
                                    onClick={() => clickfiltdate()}
                                    id="pillStyle"
                                    className="w-100 mt-3"
                                    variant="primary"
                                  >
                                    Filter
                                  </Button>
                                    <Button
                                    onClick={() => resclick()}
                                    id="pillStyle"
                                    className="w-100 mt-3 ms-1"
                                    variant="primary"
                                  >
                                    Reset
                                  </Button>
                                  </div>
                                </Typography>
                              </div>
                            ) : null
                            }

                            {/* <PopupState variant="popover" popupId="demo-popup-popover">
                                                            {(popupState) => (
                                                                <div>
                                                                    <Button id='sortfilter' className='sortFilter2 mt-3' variant="contained" {...bindTrigger(popupState)}>
                                                                        <img className="menuIcon mx-1 mb-1" src={calenderIcon} />
                                                                        Filter
                                                                    </Button>
                                                                    <Popover
                                                                        {...bindPopover(popupState)}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'center',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'center',
                                                                        }}
                                                                    >
                                                                        <Typography sx={{ p: 2 }}>
                                                                            <h6 className='mb-3 fw-bolder'>By Date</h6>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3 text-muted">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="This Week"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Last Week"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-2`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3 text-muted">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="This Month"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Last Month"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-2`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3 text-muted">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="This Year"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />&nbsp;
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Last Year"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-2`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <hr className='text-muted'></hr>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            onClick={() => setIsHidden(!isHidden)}
                                                                                            label="Date Range"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            {isHidden ? null : <div>
                                                                                <Nav id='navPill' variant="pills" defaultActiveKey="/home">
                                                                                    <Nav.Item className='col-lg-6 text-center'>
                                                                                        <Nav.Link id='pillStyle' href='#dateRange'>From</Nav.Link>
                                                                                    </Nav.Item>
                                                                                    <Nav.Item className='col-lg-6 text-center'>
                                                                                        <Nav.Link id='pillStyle' eventKey="link-2">To</Nav.Link>
                                                                                    </Nav.Item>
                                                                                </Nav>
                                                                                <Col lg={2} sm={2} md={2} className='mt-4'>
                                                                                    <DateRange
                                                                                        editableDateInputs={true}
                                                                                        onChange={item => setState([item.selection])}
                                                                                        moveRangeOnFirstSelection={false}
                                                                                        ranges={state}
                                                                                    /></Col>
                                                                            </div>}
                                                                            <Button className='w-100 mt-3' id='pillStyle' variant="primary">Filter</Button>{' '}
                                                                        </Typography>
                                                                    </Popover>
                                                                </div>
                                                            )}
                                                        </PopupState> */}
                          </Col>
                          {/* Export Excel */}
                          <Col lg={3} xs={12} className="middleFilter">
                            <Button
                              onClick={handleDownloadExcel}
                              disabled={isDownloading}
                              id="shareIcon"
                              variant="outlined"
                              className=""
                            >
                              <img
                                className="menuIcon mx-1 mb-1"
                                src={shareIcon}
                              />
                              {isDownloading ? "Downloading" : " Export Excel"}
                            </Button>
                          </Col>
                          {/* Bulk Select */}
                          {/* <Col lg={3} sm={2}>
                                                        <Form.Select id='selectBtn' aria-label="Default select example">
                                                            <option hidden className='text-center'>Bulk Action</option>
                                                            <option value="1">Delete</option>
                                                            <option value="2">Cancel</option>
                                                        </Form.Select>
                                                    </Col> */}
                        </Row>
                      </Col>
                      {/* categories starts */}
                    </Row>
                    <Table responsive borderless>
                      <thead>
                        <tr className="theadborder">
                          {/* <th>
                                                        <div className='table-headFilter'>
                                                            <Form.Check aria-label="option 1" /></div>
                                                    </th> */}
                          <th>
                            <div className="table-headFilter ps-2">
                              <span>#</span>
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Firm Name</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Order Date</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Quantity</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Order Type</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Order ID</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Order Total</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Action</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Status</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="tdata">
                        {jobcards && jobcards.length > 0
                          ? jobcards.map((k, index) => {

                              return (
                                <tr>
                                  {/* <Form.Check aria-label="option 1" /> */}
                                  <td className="ps-3">{index + 1 + (page * rowsPerPage)}. </td>
                                  <td>{k.customerName}</td>
                                  <td>
                                    {Moment(k.createdByDate).format(
                                      "DD MMM YYYY"
                                    )}
                                  </td>
                                  <td>{k.orId.quantity + k.orId.estimateId.extraquantity}</td>
                                  <td>{k.orderType}</td>
                                  <td>{k.orderId}</td>
                                  <td className="text-end">{k.orId.sum_total}</td>
                                  <td className="accordionsize">
                                    {" "}
                                    <Form.Select
                                      value={k.status}
                                      onChange={(e) => handleStatus(e, k)}
                                      id="accordion-body"
                                      aria-label="Default select example"
                                    >
                                      <option value="Job-Created">
                                      Job Created
                                    </option>
                                    <option value="Job-Cancelled">Job Cancelled</option>
                                    <option value="Post-Printing">
                                      Post-Printing
                                    </option>
                                    <option value="Pre-Printing">Pre Printing</option>
                                  </Form.Select>
                                </td>
                                <td className={
                                  k.status === "Job-Created" ? 'estimate-created' :
                                  k.status === "Job-Cancelled" ? 'status-order-canceled' :
                                  k.status === "Post-Printing" ? 'status-order-pending' :
                                  k.status === "Pre-Printing" ? 'pre-printing' :
                                  k.status === "MaterialIndent-Created" ? 'order-confirmed' :
                                  ''
                                }>
                                  {k.status}
                                </td>
                                <td onClick={() => viewjob(k)}  className="hand-cursor">
                                  <img
                                    className="menuIcon mx-1"
                                    src={eyeIcon}
                                  />
                                  view
                                </td>
                                <td onClick={() => editjob(k)}  className="hand-cursor">
                                  <img
                                    className="menuIcon mx-1"
                                    src={editIcon}
                                  />
                                  Edit
                                </td>
                              </tr>
                            );
                          })
                          : null}





                        {/* <tr>
                                                    <Form.Check aria-label="option 1" />
                                                    <td onClick={editjob}>Janet Adeboya</td>
                                                    <td onClick={editjob}>12 Aug 2022</td>
                                                    <td onClick={editjob}>1116</td>
                                                    <td onClick={editjob}>Calender</td>
                                                    <td onClick={editjob}>9348fjr73</td>
                                                    <td onClick={editjob}>22,000.00</td>
                                                    <td> <Form.Select value={accordion} onChange={e => setAccordion(e.target.value)} id='accordion-body' aria-label="Default select example">
                                                        <option value="Printing">Printing</option>
                                                        <option value="2">Pre-Printing</option>
                                                        <option value="2">Post-Printing</option>
                                                        <option value="Delivery">Delivery</option>
                                                    </Form.Select></td>
                                                    <td onClick={() => setModalShow(true)}>{accordion}</td>
                                                </tr> */}
                        {/* 
                                                <tr>
                                                    <Form.Check aria-label="option 1" />
                                                    <td onClick={editjob}>Janet Adeboya</td>
                                                    <td onClick={editjob}>12 Aug 2022</td>
                                                    <td onClick={editjob}>1116</td>
                                                    <td onClick={editjob}>Calender</td>
                                                    <td onClick={editjob}>9348fjr73</td>
                                                    <td onClick={editjob}>22,000.00</td>
                                                    <td> <Form.Select id='accordion-body' aria-label="Default select example">
                                                        <option value="1">Post-Printing</option>
                                                        <option value="2">Printing</option>
                                                        <option value="2">Pre-Printing</option>
                                                        <option value="2">Delivery</option>
                                                    </Form.Select></td>
                                                    <td onClick={() => setModalShow(true)}>Post Printing</td>
                                                </tr>
                                                <tr>
                                                    <Form.Check aria-label="option 1" />
                                                    <td onClick={editjob}>Janet Adeboya</td>
                                                    <td onClick={editjob}>12 Aug 2022</td>
                                                    <td onClick={editjob}>1116</td>
                                                    <td onClick={editjob}>Calender</td>
                                                    <td onClick={editjob}>9348fjr73</td>
                                                    <td onClick={editjob}>22,000.00</td>
                                                    <td> <Form.Select id='accordion-body' aria-label="Default select example">
                                                        <option value="1">Delivery</option>
                                                        <option value="2">Printing</option>
                                                        <option value="2">Pre-Printing</option>
                                                        <option value="2">Post-Printing</option>
                                                    </Form.Select></td>
                                                    <td onClick={() => setModalShow(true)}>Delivery</td>
                                                </tr>
                                                <tr>
                                                    <Form.Check aria-label="option 1" />
                                                    <td onClick={editjob}>Janet Adeboya</td>
                                                    <td onClick={editjob}>12 Aug 2022</td>
                                                    <td onClick={editjob}>1116</td>
                                                    <td onClick={editjob}>Calender</td>
                                                    <td onClick={editjob}>9348fjr73</td>
                                                    <td onClick={editjob}>22,000.00</td>
                                                    <td> <Form.Select id='accordion-body' aria-label="Default select example">
                                                        <option value="1">Printing</option>
                                                        <option value="2">Pre-Printing</option>
                                                        <option value="2">Post-Printing</option>
                                                        <option value="2">Delivery</option>
                                                    </Form.Select></td>
                                                    <td onClick={() => setModalShow(true)}>Printing</td>
                                                </tr> */}
                      </tbody>
                    </Table>
                    <Row className="d-flex">
                      <Col>
                        <TablePagination
                          component="div"
                          count={jobcardscount}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Col>
                    </Row>
                  </Box>
                </Paper>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      {/* <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // style={{ width: '35%', marginLeft: '30%' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update Job Card Status                   </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className=" rounded-4">
                        <CardContent>
                            <div className="row">
                                <div className="form-group col-lg-12 col-md-12 col-12">
                                    <div className="input-group">
                                        <select class="form-select text-muted mb-3 " id='inputcolor' aria-label="Default select example">
                                            <option selected hidden>Job Card ID*</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="input-group mb-3">
                                                    <input type="date" id='inputcolor' className="form-control text-muted required" name="date " />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-group mb-3">
                                                    <input type="time" className="form-control text-muted required" id='inputcolor' name="time" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-group">
                                        <select class="form-select text-muted mb-3 " id='inputcolor' aria-label="Default select example">
                                            <option selected hidden>Order ID*</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                id='inputcolor'
                                                placeholder="Order Name*"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="input-group">
                                        <select class="form-select text-muted mb-3 " id='inputcolor' aria-label="Default select example">
                                            <option selected hidden>Order Type*</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                id='inputcolor'
                                                placeholder="Quantity*"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            />
                                        </div>
                                    </div><div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                id='inputcolor'
                                                placeholder="Size*"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="input-group">
                                        <select class="form-select text-muted mb-3 " id='inputcolor' aria-label="Default select example">
                                            <option selected hidden>Paper Type*</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                    <div className="input-group">
                                        <select class="form-select text-muted mb-3 " id='inputcolor' aria-label="Default select example">
                                            <option selected hidden>GSM*</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                id='inputcolor'
                                                placeholder="Amount*"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <button type="button" class="btn btn-outline-primary">
                                                <img className="menuIcon mb-1 mx-1" src={UploadImageIcon} />
                                                Printfile.TTF/.EPS/.AI/.PSD/PDF
                                                <img className="menuIcon mb-1 mx-2" src={DeleteIcon} />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="input-group">
                                        <select class="form-select text-muted mb-3 " id='inputcolor' aria-label="Default select example">
                                            <option selected hidden>Status*</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-lg-1"></div>
                                        <div className="col-lg-5">
                                            <Button onClick={() => setModalShow(false)} id='pillStyle' className='w-100' variant="outline-primary">Back</Button>
                                        </div>
                                        <div className="col-lg-6">
                                            <Button as={NavLink} to='/jobcards' id='pillStyle' className='' variant="primary">Print JobCard</Button>
                                        </div>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="row">
                                            <div className="col-lg-1"></div>
                                            <div className="col-lg-5">
                                                <div className="input-group">
                                                    <Nav.Item id="navAccord" className="navLink">
                                                        <Nav.Link as={NavLink} to='' className="text-primary text-center mt-4 p-0">Download JobCard</Nav.Link>
                                                    </Nav.Item>                            </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-group">
                                                    <Nav.Item id="navAccord" className="navLink">
                                                        <Nav.Link as={NavLink} to='' className="text-primary text-center mt-4 p-0">Send Email</Nav.Link>
                                                    </Nav.Item>                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </CardContent>
                    </Card>
                </Modal.Body>
            </Modal> */}
    </>
  );
};

export default JobCards;
