import React, { useState } from "react";
import "../../Styles/Orders.css";
import Header from "../header/Header";
import Container from "react-bootstrap/Container";
import Sidebar from "../Sidenav/Sidebar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
// import Paper from '@mui/material/Paper';
import Button from "react-bootstrap/Button";
import { NavLink, useNavigate } from "react-router-dom";
import { Nav } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

// import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import Moment from "moment";
// import CardActions from '@mui/material/CardActions';
import CardContent from "@mui/material/CardContent";
// import Button from '@mui/material/Button';
import ClientsService from "../../services/ClientsService";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";

export default function CreateClients() {
  const [isLoading, setIsLoading] = useState(false);
  const [role, setRole] = useState("");
  const [cname, setCname] = useState("");
  const [fname, setFname] = useState("");
  const [email, setEmail] = useState("");
  const [phonecode, setPhoneCode] = useState("");
  const [phone, setPhone] = useState("");
  // const [address, setAddress] = useState('');
  const [city, setCity] = useState("");
  const [states, setStates] = useState("");
  // const [pincode, setPincode] = useState('');
  const [gstNo, setGstNo] = useState("");
  // const [status, setStatus] = useState('');
  const [clientype, setClientType] = useState("");
  const [paymenttype, setPaymentType] = useState("");
  const [bankname, setBankName] = useState("");
  const [accountnumber, setAccountNumber] = useState("");
  const [IFSCcode, setIFSC] = useState("");
  const [branchname, setBranchName] = useState("");
  const [pan, setPanNumber] = useState("");
  const [sbtndis, setSBtndis] = useState(false);

  console.log(role, "role");
  let navigate = useNavigate();
  const create = async () => {
    try {
      const obj = {
        name: cname,
        firmname: fname,
        // "address": address,
        city: city,
        phoneNo: phone,
        // "pincode": pincode,
        state: states,
        // "email": email,
        details: "TEST DETAILS",
        // "active": true,
        // "score": "100",
        gstNo: gstNo,
      };

      const dert = await ClientsService.addclients(obj);
      if (dert.data.status === "success") {
        toast.success("Client Added Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        navigate("/Clients");
      } else {
      }
    } catch (ex) {}
  };
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");

  const [value, setValue] = useState();

  const [modalShow, setModalShow] = React.useState(false);

  //validations
  const submit = async () => {
    // setIsLoading(true);
    if (!cname) {
      await setSBtndis(false);
      toast.error("Enter Firm Name", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } 
     else if (!phone) {
      await setSBtndis(false);
      toast.error("Enter Mobile Number", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (phone.length != 10) {
      await setSBtndis(false);
      toast.error("Invalid Mobile Number", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      // } else if (!address) {
      //     await setSBtndis(false)
      //     toast.error('Enter Address', {
      //         position: toast.POSITION.BOTTOM_LEFT
      //     });
    } else if (!city) {
      await setSBtndis(false);
      toast.error("Enter Area", {
        position: toast.POSITION.BOTTOM_LEFT,
      });

      // } else if (!pincode) {
      //     await setSBtndis(false)
      //     toast.error('Enter Pincode', {
      //         position: toast.POSITION.BOTTOM_LEFT
      //     });
      // } else if (!gstNo) {
      //     await setSBtndis(false)
      //     toast.error('Enter Score', {
      //         position: toast.POSITION.BOTTOM_LEFT
      //     });

      // } else if (!status) {
      //     await setSBtndis(false)
      //     toast.error('Select Status', {
      //         position: toast.POSITION.BOTTOM_LEFT
      //     });
    } else {
      try {
        setIsLoading(true);
        const obj = {
          name: cname,
          firmname: fname,
          // "address": address,
          city: city,
          phoneNo: phone,
          // "pincode": pincode,
          // "email": 'email',
          details: "TEST DETAILS",
          // "active": status,
          // "score": "100",
          gstNo: gstNo,
          // "pincode": 500003,
          state: "TS",
        };
        const dert = await ClientsService.addclients(obj);
        if (dert.data.status === "success") {
          toast.success("Client Added Successfully", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          navigate("/Clients");
          // setIsLoading(false);
        } else {
        }
      } catch (ex) {
        if (ex.response.data.status === "failed")
          toast.error(ex.response.data.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
      }
      finally{
        setIsLoading(false);
      }
    }
  };
  //Validations close

  const handlePhone = (event) => {
    const { value } = event.target;
    setPhone(value.replace(/[^0-9.*!,-]/g, ''));
    };

  return (
    <>
      <Header />
      <Container fluid className="containerr">
        <Row>
          <Col lg={2} id="sidebar-wrapper" className="d-none d-md-block">
            <Sidebar />
          </Col>
          <Col lg={9} className="p-3">
            <div>
              <h1 className="fs-3">Create New Clients</h1>
              <nav aria-label="breadcrumb" className="fs-14">
                <ol class="breadcrumb mb-0">
                  <li class="breadcrumb-item">
                    <a href="/dashboard">Home</a>
                  </li>
                  <li class="breadcrumb-item">
                    <a href="/Clients">Clients</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Create New Client
                  </li>
                </ol>
              </nav>
            </div>
            <Card sx={{ minWidth: 275 }} className="mt-4 rounded-4 col-lg-5">
              <CardContent>
                <div className="row ">
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="row">
                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <div className="input-group mb-3">
                          <TextField
                            className="w-100"
                            value={cname}
                            onChange={(e) => setCname(e.target.value)}
                            id="inputcolor"
                            type="text"
                            label="Firm Name*"
                            variant="outlined"
                          />
                        </div>
                      </div>
                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <div className="input-group mb-3">
                          <TextField
                            className="w-100"
                            value={fname}
                            onChange={(e) => setFname(e.target.value)}
                            id="inputcolor"
                            type="text"
                            label="Client Name"
                            variant="outlined"
                          />
                        </div>
                      </div>

                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="input-group mb-3">
                              <TextField
                                className="w-100"
                                value={phone}
                                onChange={(e) => handlePhone(e)}
                                id="inputcolor"
                                inputProps={{
                                  maxLength: 10,
                                }}
                                type="text"
                                label="Phone*"
                                variant="outlined"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control text-muted required"
                                                        name="name"
                                                        value={address}
                                                        id='inputcolor'
                                                        placeholder="Address"
                                                        onChange={(e) => setAddress(e.target.value)}
                                                        aria-label="name"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                </div>
                                            </div> */}

                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <div className="input-group mb-3">
                          <TextField
                            className="w-100"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            id="inputcolor"
                            type="text"
                            label="Area*"
                            variant="outlined"
                          />
                        </div>
                      </div>

                      {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control text-muted required"
                                                        name="name"
                                                        value={pincode}
                                                        id='inputcolor'
                                                        onChange={(e) => setPincode(e.target.value)}
                                                        placeholder="Pin Code"
                                                        maxLength={6}
                                                        aria-label="name"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                </div>
                                            </div> */}
                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <div className="input-group mb-3">
                          <TextField
                            className="w-100"
                            value={gstNo}
                            onChange={(e) => setGstNo(e.target.value)}
                            id="inputcolor"
                            type="text"
                            label="GST Number"
                            variant="outlined"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <select value={status} onChange={(e) => setStatus(e.target.value)} class="form-select text-muted" id='inputcolor' aria-label="Default select example">
                                                        <option selected hidden>Status*</option>
                                                        <option value={true}>Active</option>
                                                        <option value={false}>In Active</option>
            
                                                    </select>
                                                </div>
                                            </div> */}
                    <div className=" mt-3 gap-1 d-flex flex-column flex-lg-row">
                      <div className="col-lg-6">
                        <Button
                          as={NavLink}
                          to="/Clients"
                          id="pillStyle"
                          className="w-100"
                          variant="outline-primary"
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="col-lg-6">
                      {!isLoading ? (
                        <Button
                          onClick={() => submit()}
                          id="pillStyle"
                          className="w-100"
                          variant="primary"
                        >
                          Create Client
                        </Button>
                        ) : (
                          <button className="buttonload w-100" id="pillStyle">
                          <i className="fa fa-spinner fa-spin"></i> Loading
                        </button>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Second Column */}

                  {/* <div className="form-group col-lg-6 col-md-6 col-12">
                                        <div className="row"> */}

                  {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <select value={paymenttype} onChange={(e) => setPaymentType(e.target.value)} class="form-select text-muted" id='inputcolor' aria-label="Default select example">
                                                        <option selected hidden>Payment Type</option>
                                                        <option value="1">Cash</option>
                                                        <option value="1">UPI</option>
                                                        <option value="2">Credit Card</option>
                                                        <option value="3">Debit Card</option>
                                                    </select>
                                                </div>
                                            </div> */}

                  {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="email"
                                                        className="form-control text-muted required"
                                                        name="name"
                                                        value={bankname}
                                                        id='inputcolor'
                                                        onChange={(e) => setBankName(e.target.value)}
                                                        placeholder="Bank Name"
                                                        aria-label="name"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                </div>
                                            </div> */}

                  {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control text-muted required"
                                                        name="name"
                                                        value={accountnumber}
                                                        id='inputcolor'
                                                        onChange={(e) => setAccountNumber(e.target.value)}
                                                        placeholder="Account Number"
                                                        aria-label="name"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                </div>
                                            </div> */}

                  {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control text-muted required"
                                                        name="name"
                                                        value={IFSCcode}
                                                        id='inputcolor'
                                                        onChange={(e) => setIFSC(e.target.value)}
                                                        placeholder="IFSC Code"
                                                        aria-label="name"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                </div>
                                            </div> */}
                  {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control text-muted required"
                                                        name="name"
                                                        value={branchname}
                                                        id='inputcolor'
                                                        onChange={(e) => setBranchName(e.target.value)}
                                                        placeholder="Branch Name"
                                                        aria-label="name"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control text-muted required"
                                                        name="name"
                                                        value={pan}
                                                        id='inputcolor'
                                                        onChange={(e) => setPanNumber(e.target.value)}
                                                        placeholder="PAN Number"
                                                        aria-label="name"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                </div>
                                            </div> */}

                  {/* </div>
                                    </div> */}
                </div>
              </CardContent>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
