import React, { useEffect, useState, useRef } from "react";
import Header from "../header/Header";
import Sidebar from "../Sidenav/Sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Box from "@mui/material/Box";
import Form from "react-bootstrap/Form";
import Paper from "@mui/material/Paper";
import "../../Styles/JobCards.css";
import Nav from "react-bootstrap/Nav";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import filterIcon from "../../Images/filterIcon.png";
import shareIcon from "../../Images/shareIcon.png";
import sortIcon from "../../Images/sortIcon.png";
import ArrowGreen from "../../Images/ArrowGreen.png";
import ArrowRed from "../../Images/ArrowRed.png";
import calenderIcon from "../../Images/calenderIcon.png";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router";
import Moment from "moment";
import axios from "axios";
import eyeIcon from "../../Images/eyeIcon.svg";

import Card from "@mui/material/Card";
// import CardActions from '@mui/material/CardActions';
import CardContent from "@mui/material/CardContent";

import CustomersIcon from "../../Images/CustomersIcon.png";
import DeliveriesIcon from "../../Images/DeliveriesIcon.png";
import IncomeIcon from "../../Images/IncomeIcon.png";
import SalesIcon from "../../Images/SalesIcon.png";
import DashOrdersIcon from "../../Images/DashOrdersIcon.png";
import TablePagination from "@mui/material/TablePagination";
import { NavLink } from "react-router-dom";
import authService from "../../services/authService";
import Multiselect from "multiselect-react-dropdown";
import { connect } from "react-redux";
import ClientsService from "../../services/ClientsService";
import estimateService from "../../services/estimateService";
import get_userdetails from "../../redux/actions/getuserDetailsAction";
import { right } from "@popperjs/core";
import Calendar from 'react-calendar';
import { toast } from "react-toastify";
import 'react-calendar/dist/Calendar.css';
import editIcon from "../../Images/editIcon.svg";


function DashboardPage(props) {
  const [cname, setCname] = useState("");
  const [client, setClient] = useState();
  const [clients, setClients] = useState([]);
  const [user, setUserdata] = useState({});
  const multiselectRef = useRef();
  const [orders, setOrders] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [orderCount, setOrderCount] = useState(0);
  const [filtstatus, setFiltStatus] = useState('');
  const [fromdate, setFromDate] = useState('');
  const [todate, setToDate] = useState('');
  const [fromAmount, setFromAmount] = useState('')
  const [toAmount, setToAmount] = useState('');
  const [otype, setOtype] = useState('');
  const [dates, setDates] = useState([]);
  const [datafilter, setDataFilter] = useState(false);
  const [datefilter, setDateFilter] = useState(false)
  const [selstatus, setSelstatus] = useState('');
  const [selcname, setSelCname] = useState('');
  const [datetab, setDateTab] = useState('From');
  const [order, setOrder] = useState();
  const [search, setSearch] = useState(false);
  const [searchtext, setSearchText] = useState("");

  const handleChangePage = (event, newPage) => {
    console.log(newPage, "newPage");
    const dett = newPage * rowsPerPage;
    setPage(newPage);
    getorders(dett, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getorders(0, event.target.value);
  };
  useEffect(() => {
    getorders(0, 10);
    resetorder(0,10)
  }, []);

  let navigate = useNavigate();
  const ordertracking = (item) => {
    navigate("/printorder", { state: { item: item } });
  };

  const createjob = (item) => {

    navigate('/createjob', { state: { item: item } });
  };

  useEffect(() => {
    getuserdetails();
    getclients();
  }, [props.userdata]);
  useEffect(() => {
    get_userdetails();
  }, []);

  const getuserdetails = async () => {
    setUserdata(props.userdata.user);
  };
  const selectemps = (value) => {
    setCname(value[0].name);
  };

  const getclients = async () => {
    try {
      const dert = await ClientsService.getclients();
      if (dert.data.status === "success") {
        console.log(dert.data.value, "values");
        setClients(dert.data.value);
      }
    } catch (ex) { }
  };

  const resetorder = async (skps,rpp) => {
    try {
        const obj = {
            skip: skps,
            limit: rpp,
             searchkeys: "",  
                 
        }
        const dert = await authService.getorders(obj);
        if (dert.data.status === 'success') {
            console.log(dert.data.value, 'values');
            setOrders(dert.data.value);
            setOrderCount(dert.data.count)
        }
    } catch (ex) {
    }
}


  const getorders = async (skps, rpp) => {
    try {
      const obj = {
        skip: skps,
        limit: rpp,
        searchkeys: searchtext,
      };
  
      // Define the filter criteria
      const filterCriteria = {
        status: filtstatus,
        customerName: cname,
        fromAmount: fromAmount,
        toAmount: toAmount,
        fromDate: fromdate,
        toDate: todate
      };
  
      // Construct the filterBy object based on non-empty filter criteria
      const filterBy = Object.entries(filterCriteria).reduce((acc, [key, value]) => {
        if (value !== "") {
          return { ...acc, [key]: value };
        }
        return acc;
      }, {});
  
      // Only include filterBy when it is not empty
      if (Object.keys(filterBy).length > 0) {
        obj.filterBy = filterBy;
      }
  
      const dert = await authService.getorders(obj);
      if (dert.data.status === "success") {
        console.log(dert.data.value, "values");
        setOrderCount(dert.data.count);
        setOrders(dert.data.value);
      }
    } catch (ex) {
      // Handle error
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      serachclick();
     
    }
  };
  const searchChange = (value) => {
    setSearchText(value)
    // getestimates(0,10)
  }
  const serachclick = async () => {
    // getestimates(0, 10)
    const trimmedSearchText = searchtext.trim(); 
    if (trimmedSearchText === "") {
      toast.error("Enter Text", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
   
    }
    else {
      try {
        const obj = {
          skip: 0,
          limit: rowsPerPage,
          searchkeys: trimmedSearchText,
        }
        const dert = await authService.getorders(obj);
        if (dert.data.status === 'success') {
          setSearch(true)
          console.log(dert.data.value, 'values');
          setOrderCount(dert.data.count)
          setOrders(dert.data.value);
        }
      } catch (ex) {

      }
    }

  }
  const resetclick = () => {
    setSearchText("");
      setSearch(false)
      resetorder(0,rowsPerPage);
  }
  

  //SearchAPI Close
  //date Range
  const [isHidden, setIsHidden] = useState(true);
  //date Range close

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  // download Excel

  // const [isDownloading, setIsDownloading] = useState(false);

  // const handleDownloadExcel = async () => {
  //   try {
  //     setIsDownloading(true);
  //     const response = await axios.post(
  //       "/orderBookings/jsontoexcel",
  //       { data: [] },
  //       { responseType: "blob" }
  //     );
  //     const excelBlob = new Blob([response.data], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });
  //     const url = window.URL.createObjectURL(excelBlob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = "OrderBookings.xlsx";
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setIsDownloading(false);
  //   }
  // };

  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadExcel = async () => {
    try {
      setIsDownloading(true);
      const requestData = {
        // Add your request object properties here
        // For example:
        searchkeys: searchtext,
        "filterBy": {
          status: filtstatus,
          customerName: cname,
          fromAmount: fromAmount,
          toAmount: toAmount,
          fromDate: fromdate,
          toDate: todate,
        }
      };
      const response = await axios.post(
        "/estimate/Ordersjsontoexcel",
        requestData,
        { responseType: "blob" }
      );
      const excelBlob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(excelBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "OrderBooking.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    } finally {
      setIsDownloading(false);
    }
  };
  const [isSalesDownloading, setIsSalesDownloading] = useState(false);

  const handleSalesExcelDownload = async () => {
    try {
      setIsSalesDownloading(true);
      const requestData = {
        searchkeys: searchtext,
      };
  
      if (filtstatus || cname) {
        requestData.filterBy = {};
  
        if (filtstatus) {
          requestData.filterBy.status = filtstatus;
        }
  
        if (cname) {
          requestData.filterBy.customerName = cname;
        }
      }
  
      if (fromAmount || toAmount || fromdate || todate) {
        requestData.filterBy = requestData.filterBy || {};
  
        if (fromAmount) {
          requestData.filterBy.fromAmount = fromAmount;
        }
  
        if (toAmount) {
          requestData.filterBy.toAmount = toAmount;
        }
  
        if (fromdate) {
          requestData.filterBy.fromDate = fromdate;
        }
  
        if (todate) {
          requestData.filterBy.toDate = todate;
        }
      }
  
      const response = await axios.post(
        "estimate/salesjsontoexcel",
        requestData,
        { responseType: "blob" }
      );
      const excelBlob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(excelBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Order Sales.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSalesDownloading(false);
    }
  };
  //  Download Excel Close

  //update status Integration
  const handleStatus = async (e, item) => {
    console.log(item, "e.target.value");
    try {
      const obj = {
        orderId: item.orderId,
        status: e.target.value,
      };
      console.log(obj, "obj");
      const der = await estimateService.updatestatus(obj);
      console.log(der, "der");
      if (der.data.status === "success") {
        getorders();
      }
    } catch (ex) { }
  };
  //update status Integration close

  const filterbydata = async () => {

    try {
      const obj = {
        skip: 0,
        limit: rowsPerPage,

        filterBy: {
          // "homeDelivery": true,
          status: filtstatus,
          customerName: cname,
          fromAmount: fromAmount,
          toAmount: toAmount,
          // "fromDate":"2022-07-17",
          // "toDate":"2023-02-16" 
        }
      }
      const dert = await authService.getorders(obj);
      if (dert.data.status === 'success') {
        // setFiltStatus('');
        // setFromAmount('');
        // setToAmount('');
        setDataFilter(false)
        // setSearch(true)
        // console.log(dert.data.value, 'values');
        setOrderCount(dert.data.count)
        setOrders(dert.data.value);
      }
    } catch (ex) {

    }
  }
  const filtdrop = () => {
    setDataFilter(!datafilter)
  }
  const datefiltdrop = () => {
    setDateFilter(!datefilter)
  }
  const onDateChange = (e) => {
    console.log(e, 'datevalue');
    setFromDate(e)
  }
  const onToDateChange = (e) => {

    setToDate(e)
  }
  const activetabclick = (tab) => {
    setDateTab(tab)
  }
  const clickfiltdate = async () => {
    try {
      const currentDate = new Date();
      const toDateCopy = new Date(todate);
      const fromDateCopy = new Date(fromdate);


      toDateCopy.setHours(23, 59, 59, 999);
      fromDateCopy.setHours(0, 0, 0, 0);
      const obj = {
        skip: 0,
        limit: rowsPerPage,
        
        filterBy: {
          fromDate: fromdate > currentDate ? currentDate : fromDateCopy,
          toDate: todate > currentDate ? currentDate : toDateCopy,
        },
      };
  if (fromdate > currentDate || todate > currentDate) {
      toast.error("Please select a valid date.");
        return;

      }
      const dert = await authService.getorders(obj);
      if (dert.data.status === 'success') {
        setFiltStatus('');
        // setFromDate('');
        // setToDate('');
        setDateFilter(false)
        // setSearch(true)
        // console.log(dert.data.value, 'values');
        setOrderCount(dert.data.count)
        setOrders(dert.data.value);
      }
    } catch (ex) {

    }
  }

  const resclick = () => {
    filterbydata("");
    setFiltStatus("");
    setCname('')
    setFromAmount("");
    setToAmount("");
    setFromDate("");
    setToDate("");
    setDataFilter(false);
    setDateFilter(false);
    resetorder(0,rowsPerPage);
  };
  console.log(dates, 'dates');
  return (
    <>
      <Header />
      <Container fluid className="containerr">
        <Row>
          <Col lg={2} id="sidebar-wrapper" className="d-none d-md-block">
            <Sidebar />
          </Col>
          <Col lg={10} sm={8} className="containerr px-3 px-lg-4">
            <Row>
              <div className="pt-4 pb-2 d-flex justify-content-between align-items-center">
                <div>
                  <h1 className="fs-3">Dashboard</h1>
                  <nav aria-label="breadcrumb" className="fs-14">
                    <ol class="breadcrumb mb-0">
                      <li class="breadcrumb-item">
                        <a href="/dashboard">Home</a>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Dashboard
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="pt-sm-4 mt-2">
                  <Button
                    as={NavLink}
                    to="/newestimate"
                    id="pillStyle"
                    className="btnnew"
                    variant="primary"
                  >
                    Create New Estimate
                  </Button>
                </div>
              </div>
            </Row>
            <Row>
              {/* <Col lg={6}>
                <Card className="mt-2  rounded-4">
                  <CardContent>
                    <div className="row">
                      <h5 className="col-lg-8 col-md-9 ">Overview</h5>
                      <div className="col-lg-4 col-md-3">
                        <div className="input-group">
                          <select
                            class="form-select"
                            aria-label="Default select example"
                          >
                            <option className="" selected>
                              All time
                            </option>
                            <option value="This Week">This Week</option>
                            <option value="This Month">This Month</option>
                            <option value="This Year">This Year</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 py-2 py-lg-0 col-md-4">
                        <Card className="mt-2 bg-info1 rounded-4">
                          <CardContent>
                            <img
                              className="OrdersIcon mx-1"
                              src={CustomersIcon}
                            />
                            <h5 className="mt-2 text-dark">Customers</h5>
                            <div
                              style={{ fontSize: "42px", fontWeight: "600" }}
                            >
                              62
                            </div>
                            <h6 className="fs-11 mt-1 text-muted">
                              <span style={{ color: "#99CA89" }}>
                                <img
                                  className="ArrowIcon mx-1"
                                  src={ArrowGreen}
                                />
                                37.8%{" "}
                              </span>
                              this week
                            </h6>
                          </CardContent>
                        </Card>
                      </div>
                      <div className="col-lg-4 py-2 py-lg-0 col-md-4">
                        <Card className="mt-2 bg-info2 rounded-4">
                          <CardContent>
                            <img
                              className="OrdersIcon mx-1"
                              src={DeliveriesIcon}
                            />
                            <h5 className="mt-2 text-dark">Deliveries</h5>
                            <div
                              style={{ fontSize: "42px", fontWeight: "600" }}
                            >
                              512
                            </div>
                            <h6 className="fs-11 mt-1 text-muted">
                              <span style={{ color: "#99CA89" }}>
                                <img
                                  className="ArrowIcon mx-1"
                                  src={ArrowGreen}
                                />
                                37.8%{" "}
                              </span>
                              this week
                            </h6>
                          </CardContent>
                        </Card>
                      </div>
                      <div className="col-lg-4 py-2 py-lg-0 col-md-4">
                        <Card className="mt-2 bg-info3 rounded-4">
                          <CardContent>
                            <img className="OrdersIcon mx-1" src={IncomeIcon} />
                            <h5 className="mt-2 text-dark">Income</h5>
                            <div
                              style={{ fontSize: "42px", fontWeight: "600" }}
                            >
                              50K
                            </div>
                            <h6 className="fs-11 mt-1 text-muted">
                              <span style={{ color: "#F63B5D" }}>
                                <img
                                  className="ArrowIcon mx-1"
                                  src={ArrowRed}
                                />
                                37.8%{" "}
                              </span>
                              this week
                            </h6>
                          </CardContent>
                        </Card>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Col> */}
              {/* <Col lg={6}>
                <Row>
                  <Col lg={12}>
                    <Card className="mt-2 mb-1 rounded-4">
                      <CardContent>
                        <div className="d-flex flex-column gap-4">
                          <div className="row">
                            <div className="col-lg-8 col-md-9">
                              <img
                                className="dashCardIcon mx-1"
                                src={SalesIcon}
                              />
                            </div>
                            <div className="col-lg-4 col-md-3">
                              <div className="input-group">
                                <select
                                  class="form-select border-0 text-muted"
                                  aria-label="Default select example"
                                >
                                  <option selected>All time</option>
                                  <option value="This Week">This Week</option>
                                  <option value="This Month">This Month</option>
                                  <option value="This Year">This Year</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="col ">
                              <div className="col-lg-9 col-md-9 text-muted">
                                Sales
                              </div>
                              <div className="col-lg-3 col-md-3">
                                ₹4,000,000.00
                              </div>
                            </div>
                            <div className="col">
                              <div className="col-lg-9 col-md-9 text-muted">
                                Volume
                              </div>
                              <div className="col-lg-3 col-md-3">450</div>
                            </div>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Col>
                  <Col lg={12}>
                    <Card className="mt-2 rounded-4">
                      <CardContent className="d-flex flex-column gap-4">
                        <div className="row">
                          <div className="col-lg-8 col-md-9">
                            <img
                              className="dashCardIcon mx-1"
                              src={DashOrdersIcon}
                            />
                          </div>
                          <div className="col-lg-4 col-md-3">
                            <div className="input-group">
                              <select
                                className="form-select small border-0 text-muted"
                                aria-label="Default select example"
                              >
                                <option selected className="small">
                                  All time
                                </option>
                                <option className="small" value="This Week">
                                  This Week
                                </option>
                                <option className="small" value="This Month">
                                  This Month
                                </option>
                                <option className="small" value="This Year">
                                  This Year
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                          <div className="col ">
                            <div className="col-lg-9 col-md-9 text-muted">
                              All Orders
                            </div>
                            <div className="col-lg-3 col-md-3">450</div>
                          </div>
                          <div className="col">
                            <div className="col-lg-9 col-md-9 text-muted">
                              Pending
                            </div>
                            <div className="col-lg-3 col-md-3">5</div>
                          </div>
                          <div className="col">
                            <div className="col-lg-9 col-md-9 text-muted">
                              Completed
                            </div>
                            <div className="col-lg-3 col-md-3">455</div>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Col>
                </Row>
              </Col> */}
            </Row>
            <Row className='d-flex'>
              <Col lg={12} sm={12} md={6} xs={12} className='mb-5 mt-4 datacenter'>
                <Paper className='col-lg-12 col-sm-8 rounded-4'>
                  <Box sx={{}}>
                  <Row>
                                            <Col lg={2} sm={12} className='text-lg-start'>
                                                <h6 className='datahead text-muted'>Client Orders</h6>
                                            </Col>
                                            {/* categories starts */}
                                            <Col lg={10} sm={12} className=''>
                                                <Row>
                                                    <Col lg={4} sm={6} className="middleFilter">
                                                        <Form className='mt-3 d-flex gap-2 searchField'>
                                                            <div className='col-lg-8 d-flex'>
                                                            <input value={searchtext} onChange={(e) => searchChange(e.target.value)} onKeyDown={handleKeyDown}
                                                            className="form-control nosubmit" style={{fontSize:'14px'}} type="search" placeholder="Search Firm Name" />
                                                            </div>
                                                            <div className='col-lg-4'>
                                                            {!search ? (
                                  <Button
                                    onClick={() => serachclick()}
                                    className="w-100 sortFilter1"
                                    variant="primary"
                                  >
                                    Search
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => resetclick()}
                                    className="w-100 sortFilter1"
                                    variant="primary"
                                  >
                                    Reset
                                  </Button>
                                )}
                                                            </div>
                                                        </Form>
                                                    </Col>
                                                    {/* sort filter */}
                                                    <Col lg={1} xs={6} className="middleFilter" style={{width: '120px'}}>
                                                      
                            <Button
                              onClick={() => filtdrop()}
                              id="sortfilter"
                              className="sortFilter1 mt-3"
                              variant="contained"
                            >
                              <img className="menuIcon mx-1" src={filterIcon} />
                              Filter
                            </Button>  
                                
                              
                            {datafilter === true ? (
                              <div
                                style={{
                                  position: "absolute",
                                  backgroundColor: "white",
                                  right: "7px",
                                  boxShadow: "1px 2px 9px #F4AAB9",
                                  zIndex:1 

                                }}
                              >
                              <Card>
                                <CardContent>
                                <Typography sx={{ p: 2 }} className="col-lg-12">
                                  <h6>Filter</h6>

                                  <div>
                                    <h6 className="mt-2 fs-13">Status</h6>
                                    <Form.Select
                                      value={filtstatus}
                                      onChange={(e) =>
                                        setFiltStatus(e.target.value)
                                      }
                                      aria-label="Default select example"
                                    >
                                      <option selected hidden>All</option>

                                      <option value="Order-Confirmed">
                                        Order Confirmed
                                      </option>
                                      <option value="Job-Created">
                                        Job Created
                                      </option>
                                      <option value="MaterialIndent-Created">
                                        Material Indent Created
                                      </option>
                                      <option value="Order-Cancelled">
                                        Order-Cancelled
                                      </option>
                                      <option value="Order-Pending">
                                        Order-Pending
                                      </option>
                                      <option value="Deliever-Completed">
                                        Delivery Completed
                                      </option>
                                    </Form.Select>
                                    <h6 className="mt-2 fs-13">Customer</h6>
                                    <Multiselect
                                      placeholder="Select Firm Name"
                                      className="border rounded"
                                      options={clients}
                                      selectionLimit={1}
                                      singleSelect={true}
                                      emptyRecordMsg={"Customer Not Found"}
                                      selectedValues={client}
                                      defaltValue={client}
                                      displayValue="name"
                                      ref={multiselectRef}
                                      onSelect={(value) => selectemps(value)}
                                    />
                                    <h6 className="mt-2 fs-14">Amount</h6>
                                    <Form>
                                      <Row>
                                        <Col>
                                          <Form.Label>From</Form.Label>
                                          <Form.Control
                                            value={fromAmount}
                                            onChange={(e) =>
                                              setFromAmount(e.target.value)
                                            }
                                            type="number"
                                            className="filterFields"
                                            placeholder="0.00"
                                          />
                                        </Col>
                                        <Col>
                                          <Form.Label>To</Form.Label>
                                          <Form.Control
                                            value={toAmount}
                                            onChange={(e) =>
                                              setToAmount(e.target.value)
                                            }
                                            type="number"
                                            placeholder="0.00"
                                          />
                                        </Col>
                                      </Row>
                                    </Form>
                                   <div className="d-flex">
                                    <Button
                                      onClick={() => filterbydata()}
                                      id="pillStyle"
                                      className="w-100 mt-3"
                                      variant="primary"
                                    >
                                      Filter
                                    </Button>
                                    <Button
                                    onClick={() => resclick()}
                                    id="pillStyle"
                                    className="w-100 mt-3 ms-1"
                                    variant="primary"
                                  >
                                    Reset
                                  </Button>
                                  </div>
                                  </div>
                                </Typography>
                                </CardContent>
                                </Card>
                              </div>
                            ) : null}
                            {/* <PopupState variant="popover" popupId="demo-popup-popover">
                                                            {(popupState) => (
                                                                <div >
                                                                    <Button id='sortfilter' className='sortFilter1 mt-3' variant="contained" {...bindTrigger(popupState)}>
                                                                        <img className="menuIcon mx-1" src={filterIcon} />
                                                                        Filter
                                                                    </Button>
                                                                    <Popover
                                                                        {...bindPopover(popupState)}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'center',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'center',
                                                                        }}
                                                                    >
                                                                        <Typography sx={{ p: 2 }} className='col-lg-12'>
                                                                            <h6>Filter</h6>
                                                                         
                                                                            <div>
                                                                                <h6 className='mt-2 fs-13'>Status</h6>
                                                                                <Form.Select aria-label="Default select example">
                                                                                    <option>All</option>
                                                                                    <option value="ESTIMATE">ESTIMATE</option>
                                                                                    <option value="ORDER-CONFIRMED">ORDER-CONFIRMED</option>
                                                                                    <option value="JOB-Create">JOB-Create</option>
                                                                                    <option value="DELIVERED">DELIVERED</option>
                                                                                    
                                                                                </Form.Select>
                                                                                <h6 className='mt-2 fs-13'>Client</h6>
                                                                                <Multiselect
                                                                                    placeholder='Select Firm Name'
                                                                                    className='border rounded'
                                                                                    options={clients}
                                                                                    selectionLimit={1}
                                                                                    singleSelect={true}
                                                                                    emptyRecordMsg={'Client Not Found'}
                                                                                    selectedValues={client}
                                                                                    defaltValue={client}
                                                                                    displayValue="name"
                                                                                    ref={multiselectRef}
                                                                                    onSelect={(value) => selectemps(value)}
                                                                                />
                                                                                <h6 className='mt-2 fs-14'>Amount</h6>
                                                                                <Form>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <Form.Label>From</Form.Label>
                                                                                            <Form.Control type='number' className='filterFields' placeholder="0.00" />
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <Form.Label>To</Form.Label>
                                                                                            <Form.Control type='number' placeholder="0.00" />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Form>
                                                                                <Button id='pillStyle' className='w-100 mt-3' variant="primary">Filter</Button>{' '}
                                                                            </div>
                                                                        </Typography>
                                                                    </Popover>
                                                                </div>
                                                            )}
                                                        </PopupState> */}
                          </Col>
                          {/* date filter */}
                          <Col lg={1} xs={6} className="middleFilter" style={{width: '110px'}}>
                            <Button
                              onClick={() => datefiltdrop()}
                              id="sortfilter"
                              className="sortFilter1 mt-3"
                              variant="contained"
                            >
                              <img
                                className="menuIcon mx-1"
                                src={calenderIcon}
                              />
                              Date
                            </Button>
                            {datefilter ? (
                              <div
                                style={{
                                  position: "absolute",
                                  backgroundColor: "white",
                                  boxShadow: "1px 2px 9px #F4AAB9",
                                  right:'7px',
                                  zIndex:1
                                }}
                              >
                                <div
                                  style={{
                                    flexDirection: "row",
                                    display: "flex",
                                    flex: 1,
                                  }}
                                >
                                  <div
                                    style={{
                                      backgroundColor:
                                        datetab === "From"
                                          ? "#0d6efd"
                                          : "white",
                                      cursor: "pointer",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    onClick={() => activetabclick("From")}
                                    className="col-lg-6 text-center"
                                  >
                                    <p
                                      style={{
                                        color:
                                          datetab === "From"
                                            ? "white"
                                            : "black",
                                      }}
                                      id="pillStyle"
                                      href="#dateRange"
                                    >
                                      From
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      backgroundColor:
                                        datetab === "To" ? "#0d6efd" : "white",
                                      cursor: "pointer",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    onClick={() => activetabclick("To")}
                                    className="col-lg-6 text-center"
                                  >
                                    <p
                                      style={{
                                        color:
                                          datetab === "To" ? "white" : "black",
                                      }}
                                      id="pillStyle"
                                      eventKey="link-2"
                                    >
                                      To
                                    </p>
                                  </div>
                                </div>
                                <Typography sx={{ p: 2 }} className="col-lg-12">
                                  {datetab === "From" ? (
                                    <Calendar
                                      onChange={onDateChange}
                                      value={fromdate}
                                    />
                                  ) : (
                                    <Calendar
                                      onChange={onToDateChange}
                                      value={todate}
                                    />
                                  )}
                              <div className="d-flex">
                                  <Button
                                    onClick={() => clickfiltdate()}
                                    id="pillStyle"
                                    className="w-100 mt-3"
                                    variant="primary"
                                  >
                                    Filter
                                  </Button>
                                    <Button
                                    onClick={() => resclick()}
                                    id="pillStyle"
                                    className="w-100 mt-3 ms-1"
                                    variant="primary"
                                  >
                                    Reset
                                  </Button>
                                  </div>
                                </Typography>
                              </div>
                            ) : null}

                            {/* <PopupState variant="popover" popupId="demo-popup-popover">
                                                            {(popupState) => (
                                                                <div>
                                                                    <Button id='sortfilter' className='sortFilter2 mt-3' variant="contained" {...bindTrigger(popupState)}>
                                                                        <img className="menuIcon mx-1 mb-1" src={calenderIcon} />
                                                                        Filter
                                                                    </Button>
                                                                    <Popover
                                                                        {...bindPopover(popupState)}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'center',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'center',
                                                                        }}
                                                                    >
                                                                        <Typography sx={{ p: 2 }}>
                                                                            <h6 className='mb-3 fw-bolder'>By Date</h6>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3 text-muted">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="This Week"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Last Week"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-2`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3 text-muted">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="This Month"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Last Month"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-2`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3 text-muted">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="This Year"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />&nbsp;
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Last Year"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-2`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <hr className='text-muted'></hr>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            onClick={() => setIsHidden(!isHidden)}
                                                                                            label="Date Range"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            {isHidden ? null : <div>
                                                                                <Nav id='navPill' variant="pills" defaultActiveKey="/home">
                                                                                    <Nav.Item className='col-lg-6 text-center'>
                                                                                        <Nav.Link id='pillStyle' href='#dateRange'>From</Nav.Link>
                                                                                    </Nav.Item>
                                                                                    <Nav.Item className='col-lg-6 text-center'>
                                                                                        <Nav.Link id='pillStyle' eventKey="link-2">To</Nav.Link>
                                                                                    </Nav.Item>
                                                                                </Nav>
                                                                                <Col lg={2} sm={2} md={2} className='mt-4'>
                                                                                    <DateRange
                                                                                        editableDateInputs={true}
                                                                                        onChange={item => setState([item.selection])}
                                                                                        moveRangeOnFirstSelection={false}
                                                                                        ranges={state}
                                                                                    /></Col>
                                                                            </div>}
                                                                            <Button className='w-100 mt-3' id='pillStyle' variant="primary">Filter</Button>{' '}
                                                                        </Typography>
                                                                    </Popover>
                                                                </div>
                                                            )}
                                                        </PopupState> */}
                          </Col>
                                                    {/* Export Excel */}
                                                    <Col lg={2} sm={2} className="middleFilter">
                                                        <Button onClick={handleDownloadExcel} disabled={isDownloading}
                                                            id='shareIcon' variant="outlined"><img className="menuIcon mx-1 mb-1" src={shareIcon} />
                                                            {isDownloading ? 'Downloading' : 'Export Excel'}
                                                        </Button>
                                                    </Col>
                                                    <Col lg={2} sm={2} className="middleFilter">
                                                        <Button onClick={handleSalesExcelDownload} disabled={isSalesDownloading}
                                                            id='shareIcon' variant="outlined"><img className="menuIcon mx-1 mb-1" src={shareIcon} />
                                                            {isSalesDownloading ? 'Downloading' : 'Sales export'}
                                                        </Button>
                                                    </Col>
                                                    {/* Bulk Select */}
                                                    {/* <Col lg={2} sm={2} className='ps-1 pe-1'>
                                                        <Form.Select id='selectBtn' aria-label="Default select example">
                                                            <option hidden className='text-center'>Bulk Action</option>
                                                            <option value="1">Delete</option>
                                                            <option value="2">Cancel</option>
                                                        </Form.Select>
                                                    </Col> */}
                        </Row>
                      </Col>
                      {/* categories starts */}
                    </Row>
                    {/* <Row>
                                        <Col lg={3} sm={2} xs={2} className='text-lg-start'>
                                        <h6 className='datahead text-muted'>Client Orders</h6>
                                        </Col>
                                        <Col lg={9} sm={8} xs={8} className='d-inline-flex text-center'>
                                            <Col lg={1} sm={1} xs={1}>
                                            <Form className='mt-3 searchField'>
                                            <span class="fa fa-search"></span>
                                            <input class="nosubmit" type="search" placeholder="Search..." />
                                        </Form>
                                        </Col>
                                        <Col lg={2} sm={10} xs={8} >
                                        <PopupState variant="popover" popupId="demo-popup-popover">
                                            {(popupState) => (
                                                <div >
                                                    <Button id='sortfilter' className='sortFilter1' variant="contained" {...bindTrigger(popupState)}>
                                                        <img className="menuIcon mx-1 mb-1" src={filterIcon} />
                                                        Filter
                                                    </Button>
                                                    <Popover
                                                        {...bindPopover(popupState)}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'center',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'center',
                                                        }}
                                                    >
                                                        <Typography sx={{ p: 2 }}>
                                                            <h6>Filter</h6>
                                                            <h6>Order Type</h6>
                                                            <Form>
                                                                {['checkbox'].map((type) => (
                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                        <Form.Check
                                                                            inline
                                                                            label="Home Delivery"
                                                                            name="group1"
                                                                            type={type}
                                                                            id={`inline-${type}-1`}
                                                                        />
                                                                        <Form.Check
                                                                            inline
                                                                            label="Pickup"
                                                                            name="group1"
                                                                            type={type}
                                                                            id={`inline-${type}-2`}
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </Form>
                                                            <hr className='text-muted'></hr> <hr className='text-muted'></hr>
                                                            <div>
                                                                <h6>Status</h6>
                                                                <Form.Select aria-label="Default select example">
                                                                    <option>All</option>
                                                                    <option value="1">One</option>
                                                                    <option value="2">Two</option>
                                                                </Form.Select>
                                                                <h6>Client</h6>
                                                                <Form.Select aria-label="Default select example">
                                                                    <option>All</option>
                                                                    <option value="1">One</option>
                                                                    <option value="2">Two</option>
                                                                </Form.Select>
                                                                <Form>
                                                                    <Row>
                                                                        <Col>
                                                                            <Form.Label>From</Form.Label>
                                                                            <Form.Control className='filterFields' placeholder="0.00" />
                                                                        </Col>
                                                                        <Col>
                                                                            <Form.Label>To</Form.Label>
                                                                            <Form.Control placeholder="0.00" />
                                                                        </Col>
                                                                    </Row>
                                                                </Form>
                                                                <Button id='pillStyle' className='w-100 mt-3' variant="primary">Filter</Button>{' '}
                                                            </div>
                                                        </Typography>
                                                    </Popover>
                                                </div>
                                            )}
                                        </PopupState>
                                        </Col>
                                        <Col lg={1} sm={2}>
                                        <PopupState variant="popover" popupId="demo-popup-popover">
                                            {(popupState) => (
                                                <div>
                                                    <Button id='sortfilter' className='sortFilter2' variant="contained" {...bindTrigger(popupState)}>
                                                        <img className="menuIcon mx-1 mb-1" src={calenderIcon} />
                                                        Filter
                                                    </Button>
                                                    <Popover
                                                        {...bindPopover(popupState)}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'center',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'center',
                                                        }}
                                                    >
                                                        <Typography sx={{ p: 2 }}>
                                                            <h6 className='mb-3'>By Date</h6>
                                                            <Form>
                                                                {['checkbox'].map((type) => (
                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                        <Form.Check
                                                                            inline
                                                                            label="This Week"
                                                                            name="group1"
                                                                            type={type}
                                                                            id={`inline-${type}-1`}
                                                                        />
                                                                        <Form.Check
                                                                            inline
                                                                            label="Last Week"
                                                                            name="group1"
                                                                            type={type}
                                                                            id={`inline-${type}-2`}
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </Form>
                                                            <Form>
                                                                {['checkbox'].map((type) => (
                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                        <Form.Check
                                                                            inline
                                                                            label="This Month"
                                                                            name="group1"
                                                                            type={type}
                                                                            id={`inline-${type}-1`}
                                                                        />
                                                                        <Form.Check
                                                                            inline
                                                                            label="Last Month"
                                                                            name="group1"
                                                                            type={type}
                                                                            id={`inline-${type}-2`}
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </Form>
                                                            <Form>
                                                                {['checkbox'].map((type) => (
                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                        <Form.Check
                                                                            inline
                                                                            label="This Year"
                                                                            name="group1"
                                                                            type={type}
                                                                            id={`inline-${type}-1`}
                                                                        />&nbsp;
                                                                        <Form.Check
                                                                            inline
                                                                            label="Last Year"
                                                                            name="group1"
                                                                            type={type}
                                                                            id={`inline-${type}-2`}
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </Form>
                                                            <hr className='text-muted'></hr>
                                                            <Form>
                                                                {['checkbox'].map((type) => (
                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                        <Form.Check
                                                                            inline
                                                                            label="Date Range"
                                                                            name="group1"
                                                                            type={type}
                                                                            id={`inline-${type}-1`}
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </Form>
                                                            <Nav id='navPill' variant="pills" defaultActiveKey="/home">
                                                                <Nav.Item>
                                                                    <Nav.Link id='pillStyle' href="/home">From</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item>
                                                                    <Nav.Link id='pillStyle' eventKey="link-1">To</Nav.Link>
                                                                </Nav.Item>
                                                            </Nav>
                                                            <Col lg={2} sm={2} md={2}>
                                                                <DateRange
                                                                    editableDateInputs={true}
                                                                    onChange={item => setState([item.selection])}
                                                                    moveRangeOnFirstSelection={false}
                                                                    ranges={state}
                                                                /></Col>
                                                            <Button className='w-100 mt-3' id='pillStyle' variant="primary">Filter</Button>{' '}
                                                        </Typography>
                                                    </Popover>
                                                </div>
                                            )}
                                        </PopupState></Col>
                                        <Col lg={5} sm={2}>
                                        <Button id='shareIcon' variant="outlined"><img className="menuIcon mx-1 mb-1" src={shareIcon} />
                                            Export Excel</Button></Col>
                                        <Col lg={1} sm={2}>
                                        <Form.Select id='selectBtn' aria-label="Default select example">
                                            <option className='text-center'>Bulk Select</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                        </Form.Select></Col>
                                        </Col>
                                    </Row> */}
                    <Table responsive borderless>
                      <thead>
                        <tr className="theadborder">
                          {/* <th>
                                                        <div className='table-headFilter'>
                                                            <Form.Check aria-label="option 1" /></div>
                                                    </th> */}
                          <th>
                            <div className='table-headFilter'>
                              <span>#</span>
                            </div>
                          </th>
                          <th>
                            <div className='table-headFilter'>
                              <span>Order ID</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className='table-headFilter'>
                              <span>Firm Name</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className='table-headFilter'>
                              <span>Order Date</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className='table-headFilter'>
                              <span>Quantity</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className='table-headFilter'>
                              <span>Order Type</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>

                          <th>
                            <div className="table-headFilter">
                              <span>Order Total</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Action</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Status</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="tdata">
                        {orders && orders.length > 0
                          ? orders.map((k, index) => {
                            return (
                              <tr>
                                {/* <Form.Check aria-label="option 1" /> */}
                                <td className="ps-3">{index + 1 + (page * rowsPerPage)}. </td>
                                <td>{k.orderId}</td>
                                <td>{k.customerName}</td>
                                <td>
                                  {Moment(k.createDate).format("DD MMM YYYY")}
                                </td>
                                <td>{k.quantity + k.estimateId.extraquantity}</td>
                                <td>{k.orderType}</td>

                                <td className="text-end">{k.sum_total}</td>
                                <td className="accordionsize">
  <Form.Select
    value={k.status}
    onChange={(e) => handleStatus(e, k)}
    id="accordion-body"
    aria-label="Default select example"
    className="border-0 bg-light rounded-3"
  >
    <option value="Order-Confirmed">Order Confirmed</option>
    <option value="Order-Canceled">Order Canceled</option>
    <option value="Order-Pending">Order Pending</option>
  </Form.Select>
</td>
<td className={
  k.status === "Order-Confirmed" ? 'status-order-confirmed' :
  k.status === "Order-Canceled" ? 'status-order-canceled' :
  k.status === "Order-Pending" ? 'status-order-pending' :
  k.status === "MaterialIndent-Created" ? 'status-order-indent' :
  ''
}>
  {k.status}
</td>
                                <td onClick={() => ordertracking(k)} className="hand-cursor">
                                  <img
                                    className="menuIcon mx-1"
                                    src={eyeIcon}
                                  />
                                  view
                                </td>
                                <td onClick={() => createjob(k)} className="hand-cursor">
                                  <img
                                    className="menuIcon mx-1"
                                    src={editIcon}
                                  />
                                  Create Job
                                </td>
                              </tr>
                            );
                          })
                          : null}
                      </tbody>
                    </Table>
                    <Row className="d-flex">
                      <Col>
                        <TablePagination
                          component="div"
                          count={orderCount}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Col>
                    </Row>
                  </Box>
                </Paper>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  console.log(state, "state");
  return {
    userdata: state.userdetails,
  };
};

export default connect(mapStateToProps)(DashboardPage);
