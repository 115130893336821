import React, { useState } from "react";
import "../../../Styles/Orders.css";
import Header from "../../header/Header";
import Container from "react-bootstrap/Container";
import Sidebar from "../../Sidenav/Sidebar";
import aretsLogo from "../../../Images/aretsLogo.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import authService from "../../../services/authService";
import uploadCloudImage from "../../../Images/uploadCloudImage.png";
import UploadImageIcon from "../../../Images/UploadImageIcon.png";
import Switch from "react-switch";

// import Paper from '@mui/material/Paper';

// import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
// import CardActions from '@mui/material/CardActions';
import CardContent from "@mui/material/CardContent";
// import Button from '@mui/material/Button';
import showPwdImg from "../../../Images/show-password.svg";
import hidePwdImg from "../../../Images/hide-password.svg";
import ModalSubmit from "../../modalSubmit";

const EditUser = () => {
  const location = useLocation();
  // console.log(location.state?.item, "location");

  const [pwd, setPwd] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [textPassword, setTextPassword] = useState(location.state?.item ? location.state.item.textPassword : "");
  const [sbtndis, setSBtndis] = useState(false);

  const [role, setRole] = useState(
    location.state?.item ? location.state.item.role : ""
  );
  const [sname, setSname] = useState(
    location.state?.item ? location.state.item.name : ""
  );
  const [uname, setUname] = useState(location.state?.item.username);
  const [order, setOrder] = useState("");
  const [password, setPassword] = useState(
    location.state?.item ? location.state.item.textPassword : ""
  );
  const [oldpassword, setOldPassword] = useState(
    location.state?.item ? location.state.item.textPassword : ""
  );
  const [phoneno, setPhoneno] = useState(
    location.state?.item ? location.state.item.phoneNo : ""
  );
  const [email, setEmail] = useState("");
  const [phonecode, setPhonecode] = useState("");
  const [phone, setPhone] = useState("");
  const [access, setAccess] = useState(
    location.state?.item ? location.state.item.access : []
  );
  const [status, setStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  let navigate = useNavigate();

  const handlesubmit = async () => {
    try {
      setIsLoading(false);
      const obj = {
        name: sname,
        username: uname,
        password: oldpassword,
        newPassword: password,
        textPassword: textPassword,
        phoneNo: phoneno,
        admin: true,
        role: role,
        isActive: true,
        access: access,
        createdBy: "admin",
        updatedBy: "ganesh",
      };
      const dort = await authService.updateuser(obj);
      console.log(dort, "dort");
      if (dort.status === 200) {
        await setSBtndis(false);
        navigate("/userManagement");
        toast.success("User Updated Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (ex) {
      await setSBtndis(false);
      console.log(ex.response, "ex.response");
      toast.error(ex.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }finally{
      setIsLoading(false);
    }
  };

  const handleaccess = (value) => {
    if (access.includes(value)) {
      let arr = [...access];
      const index = access.indexOf(value);
      if (index > -1) {
        arr.splice(index, 1);
        setAccess(arr);
      }
    } else {
      let arr = [...access];
      arr.push(value);
      setAccess(arr);
    }
  };
  const handlePhone = (event) => {
    const { value } = event.target;
    setPhoneno(value.replace(/[^0-9.*!,-]/g, ''));
    };
  return (
    <>
      <Header />
      <Container fluid className="containerr">
        <Row>
          <Col lg={2} id="sidebar-wrapper" className="d-none d-md-block">
            <Sidebar />
          </Col>
          <Col lg={6} className="p-3">
            <div>
              <h1 className="fs-3  mt-3">Edit User</h1>
              <Breadcrumb className=" fs-14">
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/userManagement">
                  User Management
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Edit User</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Card sx={{ minWidth: 275 }} className="mt-4 rounded-4 pe-2">
              <CardContent>
                <div className="row">
                  <div className="form-group col-lg-6 col-md-6 col-12">
                    <div className="row">
                      <div>
                        <h6 className="ms-1 mt-2 fs-14 fw-bold">User Name</h6>
                      </div>
                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control py-2 text-muted required"
                            name="name"
                            value={uname}
                            onChange={(e) => setUname(e.target.value)}
                            id="inputcolor"
                            placeholder="User Name*"
                            aria-label="name"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>
                      <h6 className="ms-1 fs-14 fw-bold">Role</h6>
                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control py-2 text-muted required"
                            name="name"
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            id="inputcolor"
                            placeholder="Role*"
                            aria-label="name"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>
                      <h6 className="ms-1 fs-14 fw-bold">Mobile Number</h6>
                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="input-group mb-3">
                              <input
                                type="tel"
                                className="form-control py-2 required"
                                name="phone"
                                value={phoneno}
                                onChange={(e) => handlePhone(e)}
                                id="inputcolor"
                                maxLength={10}
                                placeholder="9876543210*"
                                aria-label="phone"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <h6 className="ms-1 mt-2 fs-14 fw-bold">Password</h6>
                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="input-group mb-3">
                              <input
                                type="tel"
                                className="form-control py-2 required"
                                name="phone"
                                value={oldpassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                                id="inputcolor"
                                maxLength={10}
                                placeholder="9876543210*"
                                aria-label="phone"
                                aria-describedby="basic-addon1"
                                disabled
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <div className="input-group mb-3">
                          <div>
                            <h6 className="ms-1 mt-2">
                              Do You want to Change the Password?
                            </h6>
                          </div>
                          <h6 className="ms-1 mt-2 fs-14 fw-bold">
                            Enter New Password
                          </h6>
                          <div
                            className="input-group form-group  pwd-container "
                            id="pwdWidth"
                          >
                            <input
                              name="pwd"
                              className="border-0 py-2 w-100 required"
                              id="inputcolor"
                              placeholder="Enter Password*"
                              type={isRevealPwd ? "text" : "password"}
                              value={textPassword}
                              onChange={(e) => setTextPassword(e.target.value)}
                            />
                            <img
                              title={
                                isRevealPwd ? "Hide password" : "Show password"
                              }
                              src={isRevealPwd ? hidePwdImg : showPwdImg}
                              onClick={() =>
                                setIsRevealPwd((prevState) => !prevState)
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <div className="input-group mb-3">
                         

                          
                        </div>
                      </div>
                      
                    </div>
                  </div>

                  {/* second row */}

                  <div className="form-group  col-lg-6 col-md-6 col-12">
                    <div className="row">
                      <div
                        className="form-group col-lg-12 col-md-12 col-12 mb-3"
                        id="inputcolor"
                      >
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="input-group">
                              <label
                                class="form-check-label mt-1 mb-2"
                                for="flexSwitchCheckDefault"
                              >
                                Master Access
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="input-group ">
                              <div class="form-check form-switch mt-2 mb-2">
                                <input
                                  checked={access.includes("MD") ? true : false}
                                  onChange={(e) => handleaccess("MD")}
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="form-group col-lg-12 col-md-12 col-12 mb-3"
                        id="inputcolor"
                      >
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="input-group">
                              <label
                                class="form-check-label mt-1 mb-2"
                                for="flexSwitchCheckDefault"
                              >
                                Admin Access
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="input-group ">
                              <div class="form-check form-switch mt-2 mb-2">
                                <input
                                  checked={access.includes("AA") ? true : false}
                                  onChange={(e) => handleaccess("AA")}
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="form-group col-lg-12 col-md-12 col-12 mb-3"
                        id="inputcolor"
                      >
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="input-group">
                              <label
                                class="form-check-label mt-1 mb-2"
                                for="flexSwitchCheckDefault"
                              >
                                Order Management
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="input-group ">
                              <div class="form-check form-switch mt-2 mb-2">
                                <input
                                  checked={access.includes("OB") ? true : false}
                                  onChange={(e) => handleaccess("OB")}
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="form-group col-lg-12 col-md-12 col-12 mb-3"
                        id="inputcolor"
                      >
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="input-group">
                              <label
                                class="form-check-label mt-1 mb-2"
                                for="flexSwitchCheckDefault"
                              >
                                Job Card Management
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="input-group ">
                              <div class="form-check form-switch mt-2 mb-2">
                                <input
                                  checked={access.includes("JC") ? true : false}
                                  onChange={(e) => handleaccess("JC")}
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="form-group col-lg-12 col-md-12 col-12 mb-3"
                        id="inputcolor"
                      >
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="input-group">
                              <label
                                class="form-check-label mt-1 mb-2"
                                for="flexSwitchCheckDefault"
                              >
                                Material Indent Management
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="input-group ">
                              <div class="form-check form-switch mt-2 mb-2">
                                <input
                                  checked={access.includes("MI") ? true : false}
                                  onChange={(e) => handleaccess("MI")}
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="form-group col-lg-12 col-md-12 col-12 mb-3"
                        id="inputcolor"
                      >
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="input-group">
                              <label
                                class="form-check-label mt-1 mb-2"
                                for="flexSwitchCheckDefault"
                              >
                                Printing Management
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="input-group ">
                              <div class="form-check form-switch mt-2 mb-2">
                                <input
                                  checked={access.includes("PM") ? true : false}
                                  onChange={(e) => handleaccess("PM")}
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="form-group col-lg-12 col-md-12 col-12 mb-3"
                        id="inputcolor"
                      >
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="input-group">
                              <label
                                class="form-check-label mt-1 mb-2"
                                for="flexSwitchCheckDefault"
                              >
                                Status Management
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="input-group ">
                              <div class="form-check form-switch mt-2 mb-2">
                                <input
                                  checked={access.includes("SM")}
                                  onChange={(e) =>
                                    handleaccess("SM") ? true : false
                                  }
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                                {/* <Switch
                                                                    onChange={() => handleaccess("SM")}
                                                                    checked={access.includes('SM') } 
                                                                    className="react-switch"
                                                                    id="normal-switch"
                                                                /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="form-group col-lg-12 col-md-12 col-12 mb-3"
                        id="inputcolor"
                      >
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="input-group">
                              <label
                                class="form-check-label mt-1 mb-2"
                                for="flexSwitchCheckDefault"
                              >
                                User Management
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="input-group ">
                              <div class="form-check form-switch mt-2 mb-2">
                                <input
                                  checked={access.includes("UM") ? true : false}
                                  onChange={(e) => handleaccess("UM")}
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="form-group col-lg-12 col-md-12 col-12 mb-3"
                        id="inputcolor"
                      >
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="input-group">
                              <label
                                class="form-check-label mt-1 mb-2"
                                for="flexSwitchCheckDefault"
                              >
                                Delivery Management
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="input-group ">
                              <div class="form-check form-switch mt-2 mb-2">
                                <input
                                  checked={access.includes("DM") ? true : false}
                                  onChange={(e) => handleaccess("DM")}
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="form-group col-lg-12 col-md-12 col-12 mb-3"
                        id="inputcolor"
                      >
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="input-group">
                              <label
                                class="form-check-label mt-1 mb-2"
                                for="flexSwitchCheckDefault"
                              >
                                Receipt Management
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="input-group ">
                              <div class="form-check form-switch mt-2 mb-2">
                                <input
                                  checked={access.includes("CR") ? true : false}
                                  onChange={(e) => handleaccess("CR")}
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="form-group col-lg-12 col-md-12 col-12 mb-3"
                        id="inputcolor"
                      >
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="input-group">
                              <label
                                class="form-check-label mt-1 mb-2"
                                for="flexSwitchCheckDefault"
                              >
                                Reports Management
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="input-group ">
                              <div class="form-check form-switch mt-2 mb-2">
                                <input
                                  checked={access.includes("RT") ? true : false}
                                  onChange={(e) => handleaccess("RT")}
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                      <div className="row mt-3">
                    <div className="col-lg-6">

                       <Button
                          as={NavLink}
                          to="/userManagement"
                          id="pillStyle"
                          className="w-100"
                          variant="outline-primary"
                        >
                          Cancel
                        </Button>
                        </div>
                        <div className="col-lg-6">
                         {!isLoading ? (
                              <Button
                                onClick={() => handlesubmit()}
                                id="pillStyle"
                                className="w-100"
                                variant="primary"
                              >
                                Edit User
                              </Button>
                               ) : (
                                <button className="buttonload w-100" id="pillStyle">
                          <i className="fa fa-spinner fa-spin"></i> Loading
                        </button>
                              )}
                </div>                              </div></div>

              </CardContent>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default EditUser;
