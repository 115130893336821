import React, { useEffect, useState, useRef } from "react";
import "../../Styles/Orders.css";
import Header from "../header/Header";
import Sidebar from "../Sidenav/Sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import filterIcon from "../../Images/filterIcon.png";
import shareIcon from "../../Images/shareIcon.png";
import eyeIcon from "../../Images/eyeIcon.svg";
import editIcon from "../../Images/editIcon.svg";
import calenderIcon from "../../Images/calenderIcon.png";
import sortIcon from "../../Images/sortIcon.png";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Table from "react-bootstrap/Table";
import TablePagination from "@mui/material/TablePagination";
import { Navigate, NavLink } from "react-router-dom";
import { useNavigate } from 'react-router';
import Moment from 'moment';
import authService, { getExcel } from '../../services/authService';
import axios from 'axios';
import Card from "@mui/material/Card";
import { toast } from "react-toastify";
import CardContent from "@mui/material/CardContent";
import Multiselect from 'multiselect-react-dropdown';
import get_userdetails from '../../redux/actions/getuserDetailsAction';
import { connect } from 'react-redux';
import ClientsService from '../../services/ClientsService';
import estimateService, { getOrder, getOrders } from '../../services/estimateService';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import printEstimate from "../OrderTracking/printEstimate";





const OrdersPage = (props) => {
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: null,
            key: 'selection'
        }
    ]);
    const [estcount, setEstcount] = useState(0);
    const [estimates, setEstimates] = useState([])


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderCount, setOrderCount] = useState(0);
  const [searchtext, setSearchText] = useState("");
  const [fromdate, setFromDate] = useState('');
  const [todate, setToDate] = useState('');

  const handleChangePage = (event, newPage) => {
    console.log(newPage, "newPage");
    const dett = (newPage) * rowsPerPage;
    setPage(newPage);
    getorders(dett, rowsPerPage);
  };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        getorders(0,event.target.value)
    };
    
    let navigate = useNavigate();
    const [order, setOrder] = useState();
      const handleClick = (item) => {
        console.log(item, "orderitem");
        navigate("/printorder", { state: { item: item } });
        // navigate('/editestimate', { state: { item: item } });
      };
    const createjob = (item) => {
      console.log(item, "orderjob");
        navigate('/createjob', { state: { item: item } });
    };
  
    const [orders, setOrders] = useState([])
    const [search, setSearch] = useState(false)

    useEffect(() => {
        getorders(0,10);
        resetorder(0,rowsPerPage)
    }, [])

    const resetorder = async (skps,rpp) => {
        try {
            const obj = {
                skip: skps,
                limit: rpp,
                 searchkeys: "",  
                     
            }
            const dert = await authService.getorders(obj);
            if (dert.data.status === 'success') {
                console.log(dert.data.value, 'values');
                setOrders(dert.data.value);
                setOrderCount(dert.data.count)
            }
        } catch (ex) {
        }
    }

    const getorders = async (skps, rpp) => {
      try {
        const obj = {
          skip: skps,
          limit: rpp,
          searchkeys: searchtext,
        };
    
        // Define the filter criteria
        const filterCriteria = {
          status: filtstatus,
          customerName: cname,
          fromAmount: fromAmount,
          toAmount: toAmount,
          fromDate: fromdate,
          toDate: todate
        };
    
        // Construct the filterBy object based on non-empty filter criteria
        const filterBy = Object.entries(filterCriteria).reduce((acc, [key, value]) => {
          if (value !== "") {
            return { ...acc, [key]: value };
          }
          return acc;
        }, {});
    
        // Only include filterBy when it is not empty
        if (Object.keys(filterBy).length > 0) {
          obj.filterBy = filterBy;
        }
    
        const dert = await authService.getorders(obj);
        if (dert.data.status === "success") {
          console.log(dert.data.value, "values");
          setOrderCount(dert.data.count);
          setOrders(dert.data.value);
        }
      } catch (ex) {
        // Handle error
      }
    };

    const vieworder = (item) => {
        setOrder(item)
        navigate('/OrderTracking');
    };
   
 //SearchAPI

 const searchChange =(value) => {
     setSearchText(value);
    
     // getestimates(0,10)
 }
 const handleKeyDown = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault();
    serachclick();
   
  }
};
 const serachclick = async () => {
    // getestimates(0, 10)
    
    const trimmedSearchText = searchtext.trim(); 
    if (trimmedSearchText === "") {
      toast.error("Enter Text", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
   
    }
    else{
    try {
        const obj = {
            skip: 0,
            limit: rowsPerPage,
            searchkeys: trimmedSearchText,
            // filterBy: {
            //     status: filtstatus,
            //     customerName: cname,
            //     fromAmount: fromAmount,
            //     toAmount: toAmount,
            //     fromDate: "2022-07-17",
            //     toDate: "2023-02-15"
            // }
        }
        const dert = await authService.getorders(obj);
        if (dert.data.status === 'success') {
            setSearch(true)
            console.log(dert.data.value, 'values');
            setOrderCount(dert.data.count)
            setOrders(dert.data.value);
        }
    } catch (ex) {

    }
  }

}
const resetclick = (event) => {
  setSearchText("");
    setSearch(false)
    resetorder(0,rowsPerPage);

}
const resclick = () => {
  filterbydata("");
  clickfiltdate("")
  setFiltStatus("");
  setCname('')
  setFromAmount("");
  setToAmount("");
  setFromDate("");
  setToDate("");
  setDataFilter(false);
  setDateFilter(false);
  resetorder(0,rowsPerPage);
};
 //SearchAPI Close


 const [filtstatus, setFiltStatus] = useState('');
    // const [fromdate, setFromDate] = useState('');
    // const [todate, setToDate] = useState('');
    const [fromAmount, setFromAmount] = useState('')
    const [toAmount, setToAmount] = useState('');
    const [otype, setOtype] = useState('');
    const [dates, setDates] = useState([]);
    const [datafilter,setDataFilter] = useState(false);
    const [datefilter,setDateFilter] = useState(false)
    const [selstatus,setSelstatus] = useState('');
    const [selcname,setSelCname] = useState('');
   const [datetab,setDateTab] = useState('From');


    //const [isDownloading, setIsDownloading] = useState(false);

    // const handleDownloadExcel = async () => {
    //     try {
    //         setIsDownloading(true);
    //         const response = await axios.post('/estimate/Ordersjsontoexcel', { data: [] }, { responseType: 'blob' });
    //         const excelBlob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //         const url = window.URL.createObjectURL(excelBlob);
    //         const a = document.createElement('a');
    //         a.href = url;
    //         a.download = 'OrderBookings.xlsx';
    //         document.body.appendChild(a);
    //         a.click();
    //         document.body.removeChild(a);
    //         window.URL.revokeObjectURL(url);
    //     } catch (error) {
    //         console.error(error);
    //     } finally {
    //         setIsDownloading(false);
    //     }
    // };

  //   const [isDownloading, setIsDownloading] = useState(false);

  // const handleDownloadExcel = async () => {
  //   try {
  //     setIsDownloading(true);
  //     const requestData = {
  //       // Add your request object properties here
  //       // For example:
  //       searchkeys: searchtext,
  //       "filterBy": {
  //         status: filtstatus,
  //         customerName: cname,
  //         fromAmount: fromAmount,
  //         toAmount: toAmount,
  //         fromDate: fromdate,
  //         toDate: todate,
  //       }
  //     };
  //     const response = await axios.post(
  //       "/estimate/Ordersjsontoexcel",
  //       requestData,
  //       { responseType: "blob" }
  //     );
  //     const excelBlob = new Blob([response.data], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });
  //     const url = window.URL.createObjectURL(excelBlob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = "OrderBooking.xlsx";
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setIsDownloading(false);
  //   }
  // };

  const [isDownloading, setIsDownloading] = useState(false);

const handleDownloadExcel = async () => {
  try {
    setIsDownloading(true);
    const requestData = {
      searchkeys: searchtext,
    };

    if (filtstatus || cname) {
      requestData.filterBy = {};

      if (filtstatus) {
        requestData.filterBy.status = filtstatus;
      }

      if (cname) {
        requestData.filterBy.customerName = cname;
      }
    }

    if (fromAmount || toAmount || fromdate || todate) {
      requestData.filterBy = requestData.filterBy || {};

      if (fromAmount) {
        requestData.filterBy.fromAmount = fromAmount;
      }

      if (toAmount) {
        requestData.filterBy.toAmount = toAmount;
      }

      if (fromdate) {
        requestData.filterBy.fromDate = fromdate;
      }

      if (todate) {
        requestData.filterBy.toDate = todate;
      }
    }

    const response = await axios.post(
      "/estimate/Ordersjsontoexcel",
      requestData,
      { responseType: "blob" }
    );
    const excelBlob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(excelBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "OrderBooking.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error(error);
  } finally {
    setIsDownloading(false);
  }
};
const [isSalesDownloading, setIsSalesDownloading] = useState(false);

const handleSalesExcelDownload = async () => {
  try {
    setIsSalesDownloading(true);
    const requestData = {
      searchkeys: searchtext,
    };

    if (filtstatus || cname) {
      requestData.filterBy = {};

      if (filtstatus) {
        requestData.filterBy.status = filtstatus;
      }

      if (cname) {
        requestData.filterBy.customerName = cname;
      }
    }

    if (fromAmount || toAmount || fromdate || todate) {
      requestData.filterBy = requestData.filterBy || {};

      if (fromAmount) {
        requestData.filterBy.fromAmount = fromAmount;
      }

      if (toAmount) {
        requestData.filterBy.toAmount = toAmount;
      }

      if (fromdate) {
        requestData.filterBy.fromDate = fromdate;
      }

      if (todate) {
        requestData.filterBy.toDate = todate;
      }
    }

    const response = await axios.post(
      "estimate/salesjsontoexcel",
      requestData,
      { responseType: "blob" }
    );
    const excelBlob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(excelBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Order Sales.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error(error);
  } finally {
    setIsSalesDownloading(false);
  }
};



    const [cname, setCname] = useState('');
    const [client, setClient] = useState();
    const [clients, setClients] = useState([]);
    const [user, setUserdata] = useState({})
    const multiselectRef = useRef();


    useEffect(() => {
        getuserdetails();
        getclients()
    }, [props.userdata])
    useEffect(() => {
        get_userdetails()
    }, [])

    const getuserdetails = async () => {
      console.log(props.userdata.user, 'userss')
        setUserdata(props.userdata.user)
    }
    const selectemps = (value) => {
        setCname(value[0].name);
    }

    const getclients = async () => {

        try {

            const dert = await ClientsService.getclients();
            if (dert.data.status === 'success') {
                console.log(dert.data.value, 'values');
                setClients(dert.data.value);
            }
        } catch (ex) {

        }
    }
    //date Range
const [isHidden, setIsHidden] = useState(true);
//date Range close
  
const handleStatus = async(e,item) => {
    console.log(item,'e.target.value');
    try {
        const obj ={
            "orderId": item.orderId,
            "status": e.target.value
        }
        console.log(obj,'obj');
        const der = await estimateService.updatestatus(obj);
        console.log(der,'der');
        if(der.data.status === 'success'){
            getorders();
        }
    } catch (ex) {
        
    }
}


const filterbydata = async () => {
    try {
      
      const obj = {
        skip: 0,
        limit: rowsPerPage,

        filterBy: {
          status: filtstatus,
          customerName: cname,
          fromAmount: fromAmount,
          toAmount: toAmount,

        },
      };
        const dert = await authService.getorders(obj);
        if (dert.data.status === "success") {
            // setFiltStatus("");
            // setCname('')
            // setFromAmount("");
            // setToAmount("");
            setDataFilter(false);
            setOrderCount(dert.data.count)
            setOrders(dert.data.value);
        }
    } catch (ex) {

    }
}

  // const [fromdate, setFromDate] = useState(new Date('2023-06-03T00:00:00.000Z'));
  // const [todate, setToDate] = useState(new Date('2023-06-14T00:00:00.000Z'));
  const clickfiltdate = async () => {
    try {
      const currentDate = new Date();
      const toDateCopy = new Date(todate);
      const fromDateCopy = new Date(fromdate);


      toDateCopy.setHours(23, 59, 59, 999);
      fromDateCopy.setHours(0, 0, 0, 0);
      const obj = {
        skip: 0,
        limit: rowsPerPage,
        filterBy: {
          fromDate: fromdate > currentDate ? currentDate : fromDateCopy,
          toDate: todate > currentDate ? currentDate : toDateCopy,
        },
      };
  if (fromdate > currentDate || todate > currentDate) {
       toast.error("Please select a valid date.");
        return;
      }
      const dert = await authService.getorders(obj);
      if (dert.data.status === "success") {
        // setFiltStatus("");
        // setFromDate("");
        // setToDate("");
        setDateFilter(false);
        // setSearch(true)
        // console.log(dert.data.value, 'values');
        setOrderCount(dert.data.count);
        setOrders(dert.data.value);
      }
    } catch (ex) {}
  };
  const filtdrop = () => {
    setDataFilter(!datafilter);
  };
  const datefiltdrop = () => {
    setDateFilter(!datefilter);
  };
  const onDateChange = (e) => {
    console.log(e, "datevalue");
    setFromDate(e);
  };
  const onToDateChange = (e) => {
    setToDate(e);
  };
  const activetabclick = (tab) => {
    setDateTab(tab);
  };
  console.log(dates, "dates");
  console.log(fromdate,todate, "dat");

    return (
        <>
            <Header />
            <Container fluid className='containerr'>
                <Row>
                    <Col lg={2} id="sidebar-wrapper" className='d-none d-md-block'>
                        <Sidebar />
                    </Col>
                    <Col lg={10} sm={10} className='containerr'>
                        <div className='d-flex'>
                            <div className='col-lg-9 col-sm-9'>
                                <h1 className='fs-3 mx-5 mt-5'>Orders</h1>
                                <Breadcrumb className='mx-5 fs-14'>
                                    <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Orders</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>

                        </div>
                        <Row className='d-flex ms-1 me-1'>
                            <Col lg={12} sm={12} md={6} xs={12} className='mb-5 datacenter'>
                                <Paper className='col-lg-12 col-sm-8 rounded-4'>
                                    <Box sx={{}}>
                                        <Row>
                                            <Col lg={2} sm={12} className='text-lg-start'>
                                                <h6 className='datahead text-muted'>Client Orders</h6>
                                            </Col>
                                            {/* categories starts */}
                                            <Col lg={10} sm={12} className=''>
                                                <Row>
                                                    <Col lg={4} sm={6} className="middleFilter">
                                                        <Form className='mt-3 d-flex gap-2 searchField'>
                                                            <div className='col-lg-8 d-flex'>
                                                            <input value={searchtext} onChange={(e) => searchChange(e.target.value)} onKeyDown={handleKeyDown}
                                                            className="form-control nosubmit" style={{fontSize:'14px'}} type="search" placeholder="Search Firm Name" />
                                                            </div>
                                                            <div className='col-lg-4'>
                                                            {!search ? (
                                  <Button
                                    onClick={() => serachclick()}
                                    className="w-100 sortFilter1"
                                    variant="primary"
                                  >
                                    Search
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => resetclick()}
                                    className="w-100 sortFilter1"
                                    variant="primary"
                                  >
                                    Reset
                                  </Button>
                                )}
                                                            </div>
                                                        </Form>
                                                    </Col>
                                                    {/* sort filter */}
                                                    <Col lg={1} xs={6} className="middleFilter" style={{width: '120px'}}>
                                                      
                            <Button
                              onClick={() => filtdrop()}
                              id="sortfilter"
                              className="sortFilter1 mt-3"
                              variant="contained"
                            >
                              <img className="menuIcon mx-1" src={filterIcon} />
                              Filter
                            </Button>  
                                
                              
                            {datafilter === true ? (
                              <div
                                style={{
                                  position: "absolute",
                                  backgroundColor: "white",
                                  right: "7px",
                                  boxShadow: "1px 2px 9px #F4AAB9",
                                  zIndex:1 

                                }}
                              >
                              <Card>
                                <CardContent>
                                <Typography sx={{ p: 2 }} className="col-lg-12">
                                  <h6>Filter</h6>

                                  <div>
                                    <h6 className="mt-2 fs-13">Status</h6>
                                    <Form.Select
                                      value={filtstatus}
                                      onChange={(e) =>
                                        setFiltStatus(e.target.value)
                                      }
                                      aria-label="Default select example"
                                    >
                                      <option selected hidden>All</option>

                                      <option value="Order-Confirmed">
                                        Order Confirmed
                                      </option>
                                      <option value="Job-Created">
                                        Job Created
                                      </option>
                                      <option value="MaterialIndent-Created">
                                        Material Indent Created
                                      </option>
                                      <option value="Order-Canceled">
                                        Order-Canceled
                                      </option>
                                      <option value="Order-Pending">
                                        Order-Pending
                                      </option>
                                      <option value="Deliever-Completed">
                                        Delivery Completed
                                      </option>
                                    </Form.Select>
                                    <h6 className="mt-2 fs-13">Customer</h6>
                                    <Multiselect
                                      placeholder="Select Firm Name"
                                      className="border rounded"
                                      options={clients}
                                      selectionLimit={1}
                                      singleSelect={true}
                                      emptyRecordMsg={"Customer Not Found"}
                                      selectedValues={client}
                                      defaltValue={client}
                                      displayValue="name"
                                      ref={multiselectRef}
                                      onSelect={(value) => selectemps(value)}
                                    />
                                    <h6 className="mt-2 fs-14">Amount</h6>
                                    <Form>
                                      <Row>
                                        <Col>
                                          <Form.Label>From</Form.Label>
                                          <Form.Control
                                            value={fromAmount}
                                            onChange={(e) =>
                                              setFromAmount(e.target.value)
                                            }
                                            type="number"
                                            className="filterFields"
                                            placeholder="0.00"
                                          />
                                        </Col>
                                        <Col>
                                          <Form.Label>To</Form.Label>
                                          <Form.Control
                                            value={toAmount}
                                            onChange={(e) =>
                                              setToAmount(e.target.value)
                                            }
                                            type="number"
                                            placeholder="0.00"
                                          />
                                        </Col>
                                      </Row>
                                    </Form>
                                   <div className="d-flex">
                                    <Button
                                      onClick={() => filterbydata()}
                                      id="pillStyle"
                                      className="w-100 mt-3"
                                      variant="primary"
                                    >
                                      Filter
                                    </Button>
                                    <Button
                                    onClick={() => resclick()}
                                    id="pillStyle"
                                    className="w-100 mt-3 ms-1"
                                    variant="primary"
                                  >
                                    Reset
                                  </Button>
                                  </div>
                                  </div>
                                </Typography>
                                </CardContent>
                                </Card>
                              </div>
                            ) : null}
                            {/* <PopupState variant="popover" popupId="demo-popup-popover">
                                                            {(popupState) => (
                                                                <div >
                                                                    <Button id='sortfilter' className='sortFilter1 mt-3' variant="contained" {...bindTrigger(popupState)}>
                                                                        <img className="menuIcon mx-1" src={filterIcon} />
                                                                        Filter
                                                                    </Button>
                                                                    <Popover
                                                                        {...bindPopover(popupState)}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'center',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'center',
                                                                        }}
                                                                    >
                                                                        <Typography sx={{ p: 2 }} className='col-lg-12'>
                                                                            <h6>Filter</h6>
                                                                         
                                                                            <div>
                                                                                <h6 className='mt-2 fs-13'>Status</h6>
                                                                                <Form.Select aria-label="Default select example">
                                                                                    <option>All</option>
                                                                                    <option value="ESTIMATE">ESTIMATE</option>
                                                                                    <option value="ORDER-CONFIRMED">ORDER-CONFIRMED</option>
                                                                                    <option value="JOB-Create">JOB-Create</option>
                                                                                    <option value="DELIVERED">DELIVERED</option>
                                                                                    
                                                                                </Form.Select>
                                                                                <h6 className='mt-2 fs-13'>Client</h6>
                                                                                <Multiselect
                                                                                    placeholder='Select Firm Name'
                                                                                    className='border rounded'
                                                                                    options={clients}
                                                                                    selectionLimit={1}
                                                                                    singleSelect={true}
                                                                                    emptyRecordMsg={'Client Not Found'}
                                                                                    selectedValues={client}
                                                                                    defaltValue={client}
                                                                                    displayValue="name"
                                                                                    ref={multiselectRef}
                                                                                    onSelect={(value) => selectemps(value)}
                                                                                />
                                                                                <h6 className='mt-2 fs-14'>Amount</h6>
                                                                                <Form>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <Form.Label>From</Form.Label>
                                                                                            <Form.Control type='number' className='filterFields' placeholder="0.00" />
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <Form.Label>To</Form.Label>
                                                                                            <Form.Control type='number' placeholder="0.00" />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Form>
                                                                                <Button id='pillStyle' className='w-100 mt-3' variant="primary">Filter</Button>{' '}
                                                                            </div>
                                                                        </Typography>
                                                                    </Popover>
                                                                </div>
                                                            )}
                                                        </PopupState> */}
                          </Col>
                          {/* date filter */}
                          <Col lg={1} xs={6} className="middleFilter" style={{width: '110px'}}>
                            <Button
                              onClick={() => datefiltdrop()}
                              id="sortfilter"
                              className="sortFilter1 mt-3"
                              variant="contained"
                            >
                              <img
                                className="menuIcon mx-1"
                                src={calenderIcon}
                              />
                              Date
                            </Button>
                            {datefilter ? (
                              <div
                                style={{
                                  position: "absolute",
                                  backgroundColor: "white",
                                  boxShadow: "1px 2px 9px #F4AAB9",
                                  right:'7px',
                                  zIndex:1
                                }}
                              >
                                <div
                                  style={{
                                    flexDirection: "row",
                                    display: "flex",
                                    flex: 1,
                                  }}
                                >
                                  <div
                                    style={{
                                      backgroundColor:
                                        datetab === "From"
                                          ? "#0d6efd"
                                          : "white",
                                      cursor: "pointer",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    onClick={() => activetabclick("From")}
                                    className="col-lg-6 text-center"
                                  >
                                    <p
                                      style={{
                                        color:
                                          datetab === "From"
                                            ? "white"
                                            : "black",
                                      }}
                                      id="pillStyle"
                                      href="#dateRange"
                                    >
                                      From
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      backgroundColor:
                                        datetab === "To" ? "#0d6efd" : "white",
                                      cursor: "pointer",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    onClick={() => activetabclick("To")}
                                    className="col-lg-6 text-center"
                                  >
                                    <p
                                      style={{
                                        color:
                                          datetab === "To" ? "white" : "black",
                                      }}
                                      id="pillStyle"
                                      eventKey="link-2"
                                    >
                                      To
                                    </p>
                                  </div>
                                </div>
                                <Typography sx={{ p: 2 }} className="col-lg-12">
                                  {datetab === "From" ? (
                                    <Calendar
                                      onChange={onDateChange}
                                      value={fromdate}
                                    />
                                  ) : (
                                    <Calendar
                                      onChange={onToDateChange}
                                      value={todate}
                                    />
                                  )}
                              <div className="d-flex">
                                  <Button
                                    onClick={() => clickfiltdate()}
                                    id="pillStyle"
                                    className="w-100 mt-3"
                                    variant="primary"
                                  >
                                    Filter
                                  </Button>
                                    <Button
                                    onClick={() => resclick()}
                                    id="pillStyle"
                                    className="w-100 mt-3 ms-1"
                                    variant="primary"
                                  >
                                    Reset
                                  </Button>
                                  </div>
                                </Typography>
                              </div>
                            ) : null}

                            
                          </Col>
                                                    {/* Export Excel */}
                                                    <Col lg={2} sm={2} className="middleFilter">
                                                        <Button onClick={handleDownloadExcel} disabled={isDownloading}
                                                            id='shareIcon' variant="outlined"><img className="menuIcon mx-1 mb-1" src={shareIcon} />
                                                            {isDownloading ? 'Downloading' : 'Export Excel'}
                                                        </Button>
                                                    </Col>
                                                    <Col lg={2} sm={2} className="middleFilter">
                                                        <Button onClick={handleSalesExcelDownload} disabled={isSalesDownloading}
                                                            id='shareIcon' variant="outlined"><img className="menuIcon mx-1 mb-1" src={shareIcon} />
                                                            {isSalesDownloading ? 'Downloading' : 'Sales export'}
                                                        </Button>
                                                    </Col>
                        </Row>
                      </Col>
                      {/* categories starts */}
                    </Row>
                    <Table responsive borderless>
                      <thead>
                        <tr className="theadborder">
                                                     <th>
                                                        <div className='table-headFilter'>
                                                            <span>#</span>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className='table-headFilter'>
                                                            <span>Order ID</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className='table-headFilter'>
                                                            <span>Firm Name</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className='table-headFilter'>
                                                            <span>Order Date</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className='table-headFilter'>
                                                            <span>Quantity</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className='table-headFilter'>
                                                            <span>Order Type</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th>

                          <th>
                            <div className="table-headFilter">
                              <span>Order Total</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Action</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Status</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="tdata">
                        {orders && orders.length > 0
                          ? orders.map((k, index) => {
                              return (
                                <tr>
                                  <td className="ps-3">{index + 1 + (page * rowsPerPage)}. </td>
                                  <td>{k.orderId}</td>
                                  <td>{k.customerName}</td>
                                  <td>
                                    {Moment(k.createDate).format("DD MMM YYYY")}
                                  </td>
                                  <td>{k.quantity + k.estimateId.extraquantity}</td>
                                  <td>{k.orderType}</td>

                                  <td className="text-end">{k.sum_total}</td>
                                  <td className="accordionsize">
  <Form.Select
    value={k.status}
    onChange={(e) => handleStatus(e, k)}
    id="accordion-body"
    aria-label="Default select example"
  >
    <option value="Order-Confirmed">Order Confirmed</option>
    <option value="Order-Canceled">Order Canceled</option>
    <option value="Order-Pending">Order Pending</option>
  </Form.Select>
</td>
<td className={
  k.status === "Order-Confirmed" ? 'status-order-confirmed' :
  k.status === "Order-Canceled" ? 'status-order-canceled' :
  k.status === "Order-Pending" ? 'status-order-pending' :
  k.status === "MaterialIndent-Created" ? 'status-order-indent' :
  ''
}>
  {k.status}
</td>

                                  <td onClick={() =>handleClick(k,order)} className="hand-cursor">
                                    <img
                                      className="menuIcon mx-1"
                                      src={eyeIcon}
                                    />
                                    view
                                  </td>
                                  <td onClick={() => createjob(k)} className="hand-cursor">
                                    <img
                                      className="menuIcon mx-1"
                                      src={editIcon}
                                    />
                                    Create Job
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </Table>
                    <Row className="d-flex">
                      <Col>
                        <TablePagination
                          component="div"
                          count={orderCount}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Col>
                    </Row>
                  </Box>
                </Paper>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  console.log(state, "state");
  return {
    userdata: state.userdetails,
  };
};

export default connect(mapStateToProps)(OrdersPage);
