import React, { useEffect, useState } from 'react'
import '../../../Styles/JobCards.css';
import Header from '../../header/Header';
import Sidebar from '../../Sidenav/Sidebar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import filterIcon from '../../../Images/filterIcon.png';
import shareIcon from '../../../Images/shareIcon.png';
import sortIcon from '../../../Images/sortIcon.png';
import calenderIcon from '../../../Images/calenderIcon.png';
import UploadImageIcon from '../../../Images/UploadImageIcon.png'
import SaveIcon from '../../../Images/SaveIcon.png'
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Table from 'react-bootstrap/Table';
import { NavLink } from "react-router-dom";
import TablePagination from '@mui/material/TablePagination';
import { useNavigate } from 'react-router';
import Modal from 'react-bootstrap/Modal';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MasterService from '../../../services/MasterService';
import { toast } from 'react-toastify';
import axios from 'axios';
import editIcon from '../../../Images/editIcon.svg';
import TextField from "@mui/material/TextField";
import deleteIconRed from '../../../Images/deleteIconRed.svg';
import { connect } from "react-redux";
import get_userdetails from "../../../redux/actions/getuserDetailsAction";

const ModeOfPrinting = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUserdata] = useState();
    useEffect(() => {
        getuserdetails();
      }, [props.userdata]);
      useEffect(() => {
        get_userdetails();
      }, []);
    
      const getuserdetails = async () => {
        console.log(props.userdata.user, "props.userdata.user");
        setUserdata(props.userdata.user);
      };
    const [search, setSearch] = useState(false)
    const [searchtext, setSearchText] = useState('');
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: null,
            key: 'selection'
        }
    ]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [machinesCount, setMachinesCount] = useState(0)
    const handleChangePage = (event, newPage) => {
        const dett = newPage * rowsPerPage
        setPage(newPage);
        getMachines(dett, rowsPerPage)

    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        getMachines(0, event.target.value)
    };
    let navigate = useNavigate();
    function editjob() {
        navigate('/editjob');
    };
    const [modalShow, setModalShow] = React.useState(false);
    const [editmodal, setEditModalShow] = React.useState(false);

    const [machines, setMachines] = useState([])
    useEffect(() => {
        getMachines(0, 10);
    }, [])


    const getMachines = async (skps, rpp) => {
        try {
            const obj = {
                skip: skps,
                limit: rpp,
                // "searchkeys": { "details": searchtext }
            }
            const dert = await MasterService.getMachines(obj);
            if (dert.data.status === 'success') {
                console.log(dert.data.value, 'values');
                setMachines(dert.data.value);
                setMachinesCount(dert.data.count)
            }
        } catch (ex) {

        }

    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          serachclick();
         
        }
      };
    //SearchAPI
    const searchChange = (value) => {
        setSearchText(value)
        // getestimates(0,10)
    }
    const serachclick = async () => {
        // getestimates(0, 10)
        try {
            const obj = {
                skip: 0,
                limit: rowsPerPage,
                "searchkeys": { "details": searchtext }
            }
            const dert = await MasterService.getMachines(obj);
            if (dert.data.status === 'success') {
                setSearch(true)
                console.log(dert.data.value, 'values');
                setMachines(dert.data.value);
                setMachinesCount(dert.data.count)
            }
        } catch (ex) {

        }

    }
    const resetclick = () => {
        setSearchText('')
        setSearch(false)
        getMachines(0, rowsPerPage);
    }
    function modalreset() {

        setDetails('');
        setMinCost('');
        setCharge('')
        setModalShow(false)
        setEMacDetails("");
        setEMacMinCost("");
        setEMacCharge("");
        setEDetails("");
        setEMinCost("");
        setECharge("");
    
    }

    //SearchAPI Close

    const [sbtndis, setSBtndis] = useState(false)
    const [role, setRole] = useState('');
    const [details, setDetails] = useState('');
    const [edetails, setEDetails] = useState('');
    const [mincost, setMinCost] = useState('');
    const [emincost, setEMinCost] = useState('');
    const [charge, setCharge] = useState('');
    const [echarge, setECharge] = useState('');
    // const [status, setStatus] = useState('');

    // const [role,setRole] = useState('');
    console.log(role, 'role');
    const create = async () => {
      
        if (!details) {
            await setSBtndis(false)
            setEDetails("Mode OF Printing Is Required!");
            toast.error('Enter Mode Of Printing', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } else if (!mincost) {
            await setSBtndis(false)
            setEDetails("");
            setEMinCost("Minimum Cost Is Required!");
            toast.error('Enter Minimum Cost', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } else if (!charge) {
            await setSBtndis(false)
            setEDetails("");
            setEMinCost("");
            setECharge("Charge Is Required!");
            toast.error('Enter Charge', {
                position: toast.POSITION.BOTTOM_LEFT
            });
            // } else if (!status) {
            //     await setSBtndis(false)
            //     toast.error('Select Status', {
            //         position: toast.POSITION.BOTTOM_LEFT
            //     });
        }
        else{
            setEDetails("");
            setEMinCost("");
            setECharge("");
            try {
                setIsLoading(true);
                const obj = {
                    "details": details,
                    // "active": status,
                    "minCost": mincost,
                    // "range": {
                    //     "startRange": 0,
                    //     "endRange": 3200
                    // },
                    "charge": charge,
                }
                console.log(obj, 'obj')
                const dert = await MasterService.addmachine(obj);
                console.log(dert.data, 'dert.data')
                if (dert.data.status ==='success') {
                    toast.success('Mode Of Printing Added Successfully', {
                        position: toast.POSITION.BOTTOM_LEFT
                    });
                    navigate('/modeofprinting')
                    getMachines(0, 10);
                    setModalShow(false);
                        setDetails('');
                       setMinCost('');
                       setCharge('')
                       setIsLoading(false);
                } else {
                    toast.error(dert.data.message, {
                        position: toast.POSITION.BOTTOM_LEFT
                    });
                }
            } catch (ex) {
                if (ex.response.status === 400)
                    console.log(ex.response, 'ex.response');
                toast.error(ex.response.data.message, {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            }finally{
                setIsLoading(false);
            }
        
       
    }
}

    //edit integration
    const [edmill, setedmill] = useState({})
    const [macdetails, setMacDetails] = useState('');
    const [emacdetails, setEMacDetails] = useState('');
    const [macmincost, setMacMinCost] = useState('');
    const [emacmincost, setEMacMinCost] = useState('');
    const [maccharge, setMacCharge] = useState('');
    const [emaccharge, setEMacCharge] = useState('');
    // const [macstatus, setMacStatus] = useState('');


    const clickedit = (k) => {
        // console.log(k,'editmill');
        setedmill(k);
        setMacDetails(k.details)
        setMacMinCost(k.minCost)
        setMacCharge(k.charge)
        // setMacStatus(k.active)
        setEditModalShow(true)
    }
    const handlesubmit = async () => {
      
        if (!macdetails) {
            await setSBtndis(false)
            setEMacDetails("Minimum Cost Is Required!");
            toast.error('Enter Mode of Printing', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } else if (!macmincost) {
            setEMacDetails("");
            setEMacMinCost("Minimum Cost Is Required!");
            toast.error('Enter Minimum Cost', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } else if (!maccharge) {
            setEMacDetails("");
            setEMacMinCost("");
            setEMacCharge("Minimum Cost Is Required!");
            toast.error('Enter Charge', {
                position: toast.POSITION.BOTTOM_LEFT
            });
            // } else if (!macstatus) {
            //     await setSBtndis(false)
            //     toast.error('Enter Breadth', {
            //         position: toast.POSITION.BOTTOM_LEFT
            //     });
        } else{
        setEMacDetails("");
            setEMacMinCost("");
            setEMacCharge("");
        try {
            setIsLoading(true);
            const obj = {
                _id: edmill._id,
                // "_id":"62ad8e1afc8d754eb652f747",
                "id": 7,
                "details": macdetails,
                // "active": macstatus,
                "minCost": parseInt(macmincost),
                // "range": {
                //     "startRange": 0,
                //      "endRange": 3200
                // },
                "charge": parseInt(maccharge),
                "__v": 0
            }
            const dort = await MasterService.updatemachine(obj);
            if (dort.status === 200) {
                getMachines(0, 10);
                await setSBtndis(false)
                navigate('/modeofprinting')
                setEditModalShow(false)
              
                toast.success('Mode Of Printing Updated Successfully', {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            }
        } catch (ex) {
            await setSBtndis(false)
            toast.error(ex.response.data.message, {
                position: toast.POSITION.BOTTOM_LEFT
            });
        }finally{
            setIsLoading(false);
        }}
    }

    //edit integration close

    // download Excel

    const [isDownloading, setIsDownloading] = useState(false);

    // const handleDownloadExcel = async () => {
    //     try {
    //         setIsDownloading(true);
    //         const response = await axios.post('/masterData/machines/jsontoexcel', { data: [] }, { responseType: 'blob' });
    //         const excelBlob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //         const url = window.URL.createObjectURL(excelBlob);
    //         const a = document.createElement('a');
    //         a.href = url;
    //         a.download = 'Mode Of Printing.xlsx';
    //         document.body.appendChild(a);
    //         a.click();
    //         document.body.removeChild(a);
    //         window.URL.revokeObjectURL(url);
    //     } catch (error) {
    //         console.error(error);
    //     } finally {
    //         setIsDownloading(false);
    //     }
    // };


    const handleDownloadExcel = async () => {
        try {
          setIsDownloading(true);
          const requestData = {
            // searchkeys : searchtext  
            "searchkeys": { "details": searchtext }    
          };
          const response = await axios.post(
            "/masterData/machines/jsontoexcel",
            requestData,
            { responseType: "blob" }
          );
          const excelBlob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(excelBlob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Mode of printing.xlsx";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error(error);
        } finally {
          setIsDownloading(false);
        }
      };
    
    //  Download Excel Close
    const Delete = async (item) => {
        
        try {
          const obj = {
           "_id":item._id,
          }
       
          const dert = await MasterService.deleteModeOfPrinting(obj);
          console.log(dert, "dert");
    
          if (dert.data.message) {
            toast.success('Mode Of Printing Deleted Successfully', {
                position: toast.POSITION.BOTTOM_LEFT
            });
            getMachines(0, 10);
            // setSearch(true)
            console.log(dert.data.value, "values");
          
               // setBreadth('');
               // setLength('');
               // setDetails('')
          }
        } catch (ex) {
    
        }
      }


    return (
        <>
            <Header />
            <Container fluid className='containerr'>
                <Row>
                    <Col lg={2} id="sidebar-wrapper" className='d-none d-md-block'>
                        <Sidebar />
                    </Col>
                    <Col lg={10} sm={4} className='containerr'>
                    <div className="pt-4 pb-2 d-lg-flex justify-content-between align-items-center">
              <div>
                <h1 className="fs-3">Mode of printing</h1>
                <nav aria-label="breadcrumb" className="fs-14">
                  <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item">
                      <a href="/dashboard">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Mode of printing
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="pt-sm-4 mt-2">
                <Button
                  onClick={() => setModalShow(true)}
                  id="pillStyle"
                  className="btnnew"
                  variant="primary"
                >
                  Create Mode Of Printing
                </Button>
              </div>
            </div>
                        <Row className='d-flex'>
                            <Col lg={12} sm={12} md={6} xs={12} className=' mb-5 datacenter'>
                                <Paper className='col-lg-12 col-sm-8 rounded-4'>
                                    <Box sx={{}}>
                                        <Row>
                                            <Col lg={4} sm={12} className='text-lg-start '>
                                                <h6 className='datahead text-muted'>Mode Of Printing</h6>
                                            </Col>
                                            {/* categories starts */}
                                            <Col lg={8} sm={12} className='text-center'>
                                                <Row className="d-flex justify-content-end ">
                                                    <Col lg={5} sm={6} className='middleFilter'>
                                                        <Form className='mt-3 d-flex gap-1 searchField'>
                                                            <div className='col-lg-9 d-flex'>
                                                                <input value={searchtext} onChange={(e) => searchChange(e.target.value)} onKeyDown={handleKeyDown} className="form-control nosubmit" style={{ fontSize: '14px' }} type="search" placeholder="Search Mode of Printing" />
                                                            </div>
                                                            <div className='col-lg-4'>
                                                            {!search ?
                                                                    <Button onClick={() => serachclick()} className='w-100 sortFilter1' variant="primary">Search</Button> :
                                                                    <Button onClick={() => resetclick()} className='w-100 sortFilter1' variant="primary">Reset</Button>
                                                            }
                                                            </div>
                                                        </Form>
                                                    </Col>
                                                    {/* sort filter */}
                                                    {/* <Col lg={2} sm={6}>
                                                        <PopupState variant="popover" popupId="demo-popup-popover">
                                                            {(popupState) => (
                                                                <div >
                                                                    <Button id='sortfilter' className='sortFilter1 mt-3' variant="contained" {...bindTrigger(popupState)}>
                                                                        <img className="menuIcon mx-1" src={filterIcon} />
                                                                        Filter
                                                                    </Button>
                                                                    <Popover
                                                                        {...bindPopover(popupState)}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'center',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'center',
                                                                        }}
                                                                    >
                                                                        <Typography sx={{ p: 2 }}>
                                                                            <h6>Filter</h6>
                                                                            <h6>Order Type</h6>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Home Delivery"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Pickup"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-2`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <hr className='text-muted'></hr> <hr className='text-muted'></hr>
                                                                            <div>
                                                                                <h6>Status</h6>
                                                                                <Form.Select aria-label="Default select example">
                                                                                    <option>All</option>
                                                                                    <option value="1">One</option>
                                                                                    <option value="2">Two</option>
                                                                                </Form.Select>
                                                                                <h6>Customer</h6>
                                                                                <Form.Select aria-label="Default select example">
                                                                                    <option>All</option>
                                                                                    <option value="1">One</option>
                                                                                    <option value="2">Two</option>
                                                                                </Form.Select>
                                                                                <Form>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <Form.Label>From</Form.Label>
                                                                                            <Form.Control className='filterFields' placeholder="0.00" />
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <Form.Label>To</Form.Label>
                                                                                            <Form.Control placeholder="0.00" />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Form>
                                                                                <Button id='pillStyle' className='w-100 mt-3' variant="primary">Filter</Button>{' '}
                                                                            </div>
                                                                        </Typography>
                                                                    </Popover>
                                                                </div>
                                                            )}
                                                        </PopupState>
                                                    </Col> */}
                                                    {/* date filter */}
                                                    {/* <Col lg={2} sm={6}>
                                                        <PopupState variant="popover" popupId="demo-popup-popover">
                                                            {(popupState) => (
                                                                <div>
                                                                    <Button id='sortfilter' className='sortFilter2 mt-3' variant="contained" {...bindTrigger(popupState)}>
                                                                        <img className="menuIcon mx-1 mb-1" src={calenderIcon} />
                                                                        Filter
                                                                    </Button>
                                                                    <Popover
                                                                        {...bindPopover(popupState)}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'center',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'center',
                                                                        }}
                                                                    >
                                                                        <Typography sx={{ p: 2 }}>
                                                                            <h6 className='mb-3'>By Date</h6>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="This Week"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Last Week"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-2`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="This Month"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Last Month"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-2`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="This Year"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />&nbsp;
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Last Year"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-2`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <hr className='text-muted'></hr>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Date Range"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <Nav id='navPill' variant="pills" defaultActiveKey="/home">
                                                                                <Nav.Item>
                                                                                    <Nav.Link id='pillStyle' href="/home">From</Nav.Link>
                                                                                </Nav.Item>
                                                                                <Nav.Item>
                                                                                    <Nav.Link id='pillStyle' eventKey="link-1">To</Nav.Link>
                                                                                </Nav.Item>
                                                                            </Nav>
                                                                            <Col lg={2} sm={2} md={2}>
                                                                                <DateRange
                                                                                    editableDateInputs={true}
                                                                                    onChange={item => setState([item.selection])}
                                                                                    moveRangeOnFirstSelection={false}
                                                                                    ranges={state}
                                                                                /></Col>
                                                                            <Button className='w-100 mt-3' id='pillStyle' variant="primary">Filter</Button>{' '}
                                                                        </Typography>
                                                                    </Popover>
                                                                </div>
                                                            )}
                                                        </PopupState>
                                                    </Col> */}
                                                    {/* Export Excel */}
                                                    <Col lg={3} sm={2} className='middleFilter'>
                                                        <Button onClick={handleDownloadExcel} disabled={isDownloading}
                                                            id='shareIcon' variant="outlined"><img className="menuIcon mx-1 mb-1" src={shareIcon} />
                                                            {isDownloading ? 'Downloading' : 'Export Excel'}
                                                        </Button>
                                                    </Col>
                                                    {/* Bulk Select */}
                                                    {/* <Col lg={3} sm={2}>
                                                        <Form.Select id='selectBtn' aria-label="Default select example">
                                                            <option hidden className='text-center'>Bulk Action</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </Col> */}
                                                </Row>
                                            </Col>
                                            {/* categories starts */}
                                        </Row>
                                        <Table responsive borderless>
                                            <thead>
                                                <tr className='theadborder'>
                                                    {/* <th>
                                                        <div className='table-headFilter'>
                                                            <Form.Check aria-label="option 1" /></div>
                                                    </th> */}
                                                    <th>
                                                        <div className='table-headFilter ps-2'>
                                                            <span>#</span>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className='table-headFilter'>
                                                            <span>Mode Of Printing</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className='table-headFilter'>
                                                            <span>Minimum Cost</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className='table-headFilter'>
                                                            <span>Charge</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th>

                                                    {/* <th>
                                                        <div className='table-headFilter'>
                                                            <span>Status</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th> */}
                                                </tr>
                                            </thead>
                                            <tbody className='tdata'>
                                                {machines && machines.length > 0 ?
                                                    machines.map((k, index) => {
                                                        return (
                                                            <tr>
                                                                {/* <Form.Check aria-label="option 1" /> */}
                                                                <td className="ps-3">{index + 1 + (page * rowsPerPage)}. </td>
                                                                <td>{k.details}</td>
                                                                <td>{k.minCost}</td>
                                                                <td>{k.charge}</td>
                                                                {/* <td>{k.isActive === true ? 'Active' : 'In Active'}</td> */}
                                                                <td onClick={() => clickedit(k)} className="hand-cursor"><img className="menuIcon mx-1" src={editIcon} />Edit</td>
                                                                {user ? (
                                    user.access.includes("AA") ? (
                                      <td onClick={() => Delete(k)} className="hand-cursor">
                                          <img
                                            className="menuIcon mx-1"
                                            src={deleteIconRed}
                                          />
                                          Delete
                                      </td>
                                    ) : null
                                  ) : null}                                                                 </tr>
                                                        )
                                                    }) : null
                                                }
                                            </tbody>
                                        </Table>
                                        <Row className='d-flex'>
                                            <Col><TablePagination
                                                component="div"
                                                count={machinesCount}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                rowsPerPage={rowsPerPage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            /></Col>
                                        </Row>
                                    </Box>
                                </Paper>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            // style={{ width: '35%', marginLeft: '30%' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Mode Of Printing
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className=" rounded-4">
                        <CardContent>
                            <div className="row">
                                <div className="form-group col-lg-12 col-md-12">
                                    <div className="row">

                                        <div className="form-group col-lg-12 col-md-12 col-12">
                                            <div className="input-group">

                                                <TextField
                                                    className="w-100"
                                                    value={details}
                                                    onChange={(e) => setDetails(e.target.value)}
                                                    id="inputcolor"
                                                    type="text"
                                                    label="Mode Of Printing*"
                                                    variant="outlined"
                                                />
                                            </div>
                                            <div style={{ color: "#FF0000", fontSize: "10px" }}>
                                                {edetails ? edetails : null}
                                            </div>
                                            </div>
                                        <div className="form-group col-lg-12 col-md-12 col-12">
                                            <div className="input-group mt-3">

                                                <TextField
                                                    className="w-100"
                                                    value={mincost}
                                                    onChange={(e) => setMinCost(e.target.value)}
                                                    id="inputcolor"
                                                    type="text"
                                                    label="Minimum Cost*"
                                                    variant="outlined"
                                                />
                                            </div>
                                            <div style={{ color: "#FF0000", fontSize: "10px" }}>
                                                {emincost ? emincost : null}
                                            </div>
                                            </div>

                                        <div className="form-group col-lg-12 col-md-12 col-12 mb-3">
                                            <div className="input-group mt-3">

                                                <TextField
                                                    className="w-100"
                                                    value={charge}
                                                    onChange={(e) => setCharge(e.target.value)}
                                                    id="inputcolor"
                                                    type="text"
                                                    label="Extra Charge*"
                                                    variant="outlined"
                                                />
                                            </div>
                                            <div style={{ color: "#FF0000", fontSize: "10px" }}>
                                                {echarge ? echarge : null}
                                            </div>
                                            </div>
                                        {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                            <div className="input-group mb-3">
                                                <select value={status} onChange={(e) => setStatus(e.target.value)} class="form-select text-muted" id='inputcolor' aria-label="Default select example">
                                                    <option selected hidden>Status</option>
                                                    <option value={true}>Active</option>
                                                    <option value={false}>InActive</option>
                                                </select>
                                            </div>
                                        </div> */}
                                        <div className="form-group col-lg-12 col-md-12 col-12 ">
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="input-group">
                                                        <Button id='pillStyle' onClick={() => modalreset()}
                                                            value='modalreset' className='w-100' variant="outline-primary">Cancel</Button>
                                                    </div>
                                                </div>
                                                <div className="col-lg-8 ">
                                                    <div className="input-group ">
                                                        <div class="form-check form-switch" >
                                                        {!isLoading ? (
                                                            <Button onClick={() => create()} id='pillStyle' className='w-100' variant="primary">Create Mode Of Printing</Button>
                                                            ) : (
                                                                <button className="buttonload w-100" id="pillStyle">
                          <i className="fa fa-spinner fa-spin"></i> Loading
                        </button>
                                                              )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </Modal.Body>
            </Modal>
            <Modal
                show={editmodal}
                onHide={() => setEditModalShow(false)}
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            // style={{ width: '35%', marginLeft: '30%' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Mode Of Printing
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className=" rounded-4">
                        <CardContent>
                            <div className="row">
                                <div className="form-group col-lg-12 col-md-12">
                                    <div className="row">

                                        <div className="form-group col-lg-12 col-md-12 col-12">
                                            <div className="input-group">

                                                <TextField
                                                    className="w-100"
                                                    value={macdetails}
                                                    onChange={(e) => setMacDetails(e.target.value)}
                                                    id="inputcolor"
                                                    type="text"
                                                    label="Mode Of Printing*"
                                                    variant="outlined"
                                                />
                                            </div>
                                            <div style={{ color: "#FF0000", fontSize: "10px" }}>
                                                {emacdetails ? emacdetails : null}
                                            </div>
                                            </div>
                                        <div className="form-group col-lg-12 col-md-12 col-12">
                                            <div className="input-group mt-3">

                                                <TextField
                                                    className="w-100"
                                                    value={macmincost}
                                                    onChange={(e) => setMacMinCost(e.target.value)}
                                                    id="inputcolor"
                                                    type="text"
                                                    label="Minimum Cost*"
                                                    variant="outlined"
                                                />
                                            </div>
                                            <div style={{ color: "#FF0000", fontSize: "10px" }}>
                                                {emacmincost ? emacmincost : null}
                                            </div>
                                            </div>

                                        <div className="form-group col-lg-12 col-md-12 col-12 mb-3">
                                            <div className="input-group mt-3">

                                                <TextField
                                                    className="w-100"
                                                    value={maccharge}
                                                    onChange={(e) => setMacCharge(e.target.value)}
                                                    id="inputcolor"
                                                    type="text"
                                                    label="Extra Charge*"
                                                    variant="outlined"
                                                />
                                            </div>
                                            <div style={{ color: "#FF0000", fontSize: "10px" }}>
                                                {emaccharge ? emaccharge : null}
                                            </div>
                                            </div>
                                        {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                            <div className="input-group mb-3">
                                                <select value={macstatus} onChange={(e) => setMacStatus(e.target.value)} class="form-select text-muted" id='inputcolor' aria-label="Default select example">
                                                    <option selected hidden>Status</option>
                                                    <option value={true}>Active</option>
                                                    <option value={false}>InActive</option>
                                                </select>
                                            </div>
                                        </div> */}
                                        <div className="form-group col-lg-12 col-md-12 col-12">
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="input-group">
                                                        <Button onClick={() => setEditModalShow(false)} id='pillStyle' className='w-100' variant="outline-primary">Cancel</Button>
                                                    </div>
                                                </div>
                                                <div className="col-lg-8 ">
                                                    <div className="input-group ">
                                                        <div class="form-check form-switch" >
                                                        {!isLoading ? (
                                                            <Button onClick={() => handlesubmit()} id='pillStyle' className='W-100' variant="primary">Update Mode Of Printing</Button>
                                                            ) : (
                                                                <button className="buttonload w-100" id="pillStyle">
                          <i className="fa fa-spinner fa-spin"></i> Loading
                        </button>
                                                              )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </Modal.Body>
            </Modal>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
      userdata: state.userdetails,
    };
  };
  export default connect(mapStateToProps)(ModeOfPrinting);
