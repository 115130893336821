import React, { useEffect, useState, useRef } from "react";
import "../../Styles/Orders.css";
import Header from "../header/Header";
import Container from "react-bootstrap/Container";
import Sidebar from "../Sidenav/Sidebar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
// import Paper from '@mui/material/Paper';
import Button from "react-bootstrap/Button";
import { NavLink, useLocation } from "react-router-dom";
import { Nav } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import UploadImageIcon from "../../Images/UploadImageIcon.png";
import DeleteIcon from "../../Images/DeleteIcon.png";
import { useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import { connect } from "react-redux";
// import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
// import CardActions from '@mui/material/CardActions';
import CardContent from "@mui/material/CardContent";
// import Button from '@mui/material/Button';
import JobcardsService from "../../services/JobcardsService";
import ClientsService from "../../services/ClientsService";
import estimateService from "../../services/estimateService";
import get_userdetails from "../../redux/actions/getuserDetailsAction";
import authService, { getorders } from "../../services/authService";
import { toast } from "react-toastify";
import IndentService from "../../services/IndentService";
import axios from "axios";
import moment from "moment";
import SupplierService from "../../services/SupplierService";
import MasterService from "../../services/MasterService";


function EditJobCard(props) {
    let location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    //addjobcardsIntegration
    const [clients, setClients] = useState([]);
    const [orders, setOrders] = useState([]);
    const [order, setOrder] = useState();
    const [client, setClient] = useState();
    const [gsmtypes, setGsmTypes] = useState([]);
    const [user, setUserdata] = useState({});
    const [vendors, setVendors] = useState([]);
    const [cname, setCname] = useState(location.state?.item ? location.state?.item.customerName : '');
    const [id, setID] = useState('');
    const [date, setDate] = useState(moment.utc(location.state?.item.createDate).format("YYYY-MM-DD"));
    const [time, setTime] = useState('');
    const [papertype, setPaperType] = useState(location.state?.item ? location.state?.item.orId.paperType : "");
    const [lamtype, setLamType] = useState(location.state?.item.orId.lamination);
    const [paperrtype, setPaperrType] = useState(location.state?.item ? location.state?.item.orId.paperType : "");
    const [gsm, setGsm] = useState(location.state?.item ? location.state?.item.orId.gsm : "");
    const [bpapertype, setBPaperType] = useState(location.state?.item ? location.state?.item.orId.paperType : "");
    const [jobCardNo, setJobCardNo] = useState('');
    const [jobId, setJobId] = useState(location.state?.item ? location.state?.item.jobId : "");
    const [jname, setJname] = useState(location.state?.item ? location.state?.item.jobName : "");
    const [callParty, setCallParty] = useState(location.state?.item ? location.state.item.callParty : '')
    const [plateBy, setPlateBy] = useState(location.state?.item ? location.state.item.plateBy : '');
    const [colorProof, setColorProof] = useState(location.state?.item ? location.state.item.colorProof : '');
    const [noOfSets, setNoOfSets] = useState(location.state?.item ? location.state.item.noOfSets : '');
    const [noofsets, setNo0fsets] = useState("");
    const [agAou, setAgAou] = useState(location.state?.item ? location.state.item.agAou : '');
    const [agaou, setAgaou] = useState("");
    const [machine, setMachine] = useState('');
    const [bmachinetype, setBMachineType] = useState({})
    const [gsmoptions, setGsmOptions] = useState([])
    const [machines, setMachines] = useState([]);
    // const [bpapertype, setBPaperType] = useState('');
    // const [papertype, setPaperType] = useState(location.state?.item ? location.state?.item.paperType : "");
    const [paptype,setPapType] = useState('');
    const [paperTypes, setpaperTypes] = useState('');
    const [orderType, setOrdertype] = useState('');
    const [plateby, setPlateby] = useState("");
    const [paperGsm, setpaperGsm] = useState(location.state?.item ? location.state?.item.paperGsm : "");
    const [paperSize, setPaperSize] = useState(location.state?.item ? location.state?.item.orId.estimateId.millerLength + "*" +  location.state?.item.orId.estimateId.millerBreadth : "");  const [paperQuantity, setPaperQuantity] = useState(location.state?.item ? location.state?.item.orId.millquantity : "");
  const [paperCuttingSize, setPaperCuttingSize] = useState(location.state?.item ? location.state?.item.paperCuttingSize : "");
  const [paperCuttingQty, setpaperCuttingQty] = useState(location.state?.item ? location.state?.item.paperCuttingQty : "");
    const [machinetype, setMachineType] = useState('');
    const [modeofprinting, setModeofPrinting] = useState(location.state?.item ? location.state?.item.orId.machine : "");
    const [printing, setPrinting] = useState(location.state?.item ? location.state?.item.orId.printing : "");
    const [color, setColor] = useState(4);
    const [totalImpressions, setTotalImpressions] = useState(location.state?.item?.orId.printing === 'BB' || location.state?.item?.orId.printing === 'OPPG'
    ? location.state?.item?.orId.cuttingQuantity * 2
    : location.state?.item?.orId.cuttingQuantity);
    const [finalSheets, setFinalSheets] = useState(location.state?.item ? location.state?.item.finalSheets : "");
    const [printMachine, setPrintMachine] = useState('');
    const [printMedia, setPrintMedia] = useState(location.state?.item ? location.state.item.printMedia : '');
    const [printQuantity, setPrintQuantity] = useState('');
    const [printRate, setPrintRate] = useState('');
    const [printCost, setPrintCost] = useState('');
    const [transport, setTransport] = useState([]);
    const [lBreadth, setlBreadth] = useState(location.state?.item ? location.state?.item.lBreadth : "");
  const [lLength, setlLength] = useState(location.state?.item ? location.state?.item.lLength : "");
  const [lQuantity, setlQuantity] = useState(location.state?.item.orId.lamination === "" ? "" : location.state.item.lQuantity);
  const [lSheet, setlSheet] = useState(location.state?.item.orId.lamination === "" ? "" : location.state?.item.lLength + "*" +  location.state?.item.lBreadth);
    const [customerName, setCustomerName] = useState(location.state?.item ? location.state?.item.customerName :'');
    const [subtotal, setSubTotal] = useState(location.state?.item ? location.state?.item.sum_total : "");
    const [lRate, setLRate] = useState('');
    const [finishingtype, setFinishingType] = useState(location.state?.item ? location.state?.item.finishingType : "");
    const [finishingquantity, setFinishingQuantity] = useState(location.state.item.finishingType === "" ? "" : location.state?.item.finishingQuantity);
    const [othersAmount, setOthersAmount] = useState(location.state?.item ? location.state?.item.othersamount : "");
    const [papersize, setPapersize] = useState("12*20");
    const [orid,setorid] = useState('')
    const [pasting, setPasting] = useState(location.state?.item ? location.state?.item.orId.pasting : "");
    const [pastquantity, setPastQuantity] = useState(location.state.item.orId.pasting === "" ? "" : location.state?.item.orId.pastingQuantity);
    const [lCost, setLCost] = useState('');
    const [pBreadth, setPBreadth] = useState('');
    const [pLength, setPLength] = useState('');
    const [pType, setPType] = useState(location.state?.item ? location.state?.item.orId.punching : "");
    const [punchingTypes, setPunchingTypes] = useState([]);
    const [orderNo, setOrderNo] = useState({})
    const [orderId, setOrderId] = useState(location.state?.item ? location.state?.item.orderId : "");
    const [estimateId, setEstimateId] = useState({})
    const [pQuantity, setPQuantity] = useState(location.state.item.orId.punching === "" ? "" : location.state.item.pQuantity);
    const [pRate, setPRate] = useState('');
    const [pCost, setpCost] = useState([]);
    const [outSouWorks, setOutSouWorks] = useState(location.state?.item ? location.state.item.outSouWorks : '');
    const [transDetails, setTransDetails] = useState(location.state?.item ? location.state.item.transDetails : '');
    const [cutCharge, setCutCharge] = useState([]);
    const [packCharge, setPackCharge] = useState([]);
    const [transCharge, setTransCharge] = useState(location.state?.item ? location.state.item.transCharge : '');
    const [otherCharge1, setOtherCharge1s] = useState([]);
    const [otherCharge2, setOtherCharge2s] = useState([]);
    const [total, setTotal] = useState([]);
    const [Status, setStatus] = useState(location.state?.item ? location.state?.item.status : "");
    const [createdByDate, setcreatedByDate] = useState([]);
    const [updatedDate, setupdatedDate] = useState([]);
    const [jobdetails, setJobdetails] = useState([]);
    const [firstMaterialIndentNo, setFirstMaterialIndentNos] = useState([]);
    const [transName, setTransName] = useState(location.state?.item ? location.state.item.transName : '');
    const [customerId, setCustomerId] = useState(location.state?.item ? location.state?.item.orId._id : "");
    const [paperVendor, setPaperVendor] = useState(location.state?.item ? location.state.item.paperVendor : '');
    const [sbtndis, setSBtndis] = useState(false);
    const [jobtype, setJobtype] = useState(location.state?.item ? location.state?.item.orderType.toLowerCase() : "")
    const [estmid, setEstmid] = useState(location.state?.item ? location.state?.item.orId.estimateId._id : "");
    const multiselectRef = useRef();
    const machinemultiselectRef = useRef();
    const papermultiselectRef = useRef();
    const navigate = useNavigate();
  console.log(location.state.item,'location.state.item edit')
    useEffect(() => {
      // getOrder();
      getclients();
      getmachines();
      getgsmtypes();
      getpapertypes();
      getpunchtypes();
      getselclient();
      getvendors();
      gettransname();
      // selectorder(location.state.item.orderId)
      // selectemps(location.state.item.customerName)
      // getfinishsizes()
  
    }, [props.userdata])
   
  
    const handlemode = (e) => {
      setModeofPrinting(e.target.value);
      jobdetails.push(e.target.value)
    }
    const getselclient = async() => {
      let arr = []
      if(clients && clients.length > 0){
        const rty = clients.map(k => {
          if (k.name === location.state.item.customerName) {
            arr.push(k)
    
          }
        })
        console.log(arr,'arredit');
        setClient(arr)
    
        setCname(arr[0].name);
        setCustomerId(arr[0]._id)
        try {
          const det = await JobcardsService.getordersById(arr[0]._id)
          if(det.data.status === 'success'){
            
             let arrr= []
               setOrders(det.data.value)
               const rty = det.data.value.map(k =>{
                if(k.orderId === location.state.item.orderId){
                    arrr.push(k)
                }
            })
            console.log(arrr[0].status,'arrr[0]')
            setOrderId(arrr[0].orderId);
            setorid(arrr[0]._id)
            setJname(arrr[0].name);
            setDate(moment.utc(arrr[0].createDate).format('YYYY-MM-DD'))
            setTime(moment.utc(arrr[0].createDate).format('HH:MM'))
            setJobtype(arrr[0].orderType)
            setStatus(arrr[0].status)
            setPrintMachine(arrr[0].machine)
            console.log(`${arrr[0].paperType}${arrr[0].gsm}`,'`${arrr[0].paperType}${arrr[0].gsm}`')
            setpaperGsm(`${arrr[0].paperType} ${arrr[0].gsm}`)
            setGsm(arrr[0].gsm)
            setPaperType(arrr[0].paperType)
            // setPapType(arrr[0].paperType)
            setPaperSize(`${arrr[0].estimateId.millerLength}*${arrr[0].estimateId.millerBreadth}`)
            setPaperQuantity(arrr[0].quantity)
            setPaperCuttingSize(arrr[0].cuttingSize)
            setpaperCuttingQty(arrr[0].cuttingQuantity)
            setPrinting(arrr[0].printing)
            setFinalSheets(arrr[0].finalsheets)
            setJobdetails(arrr[0].jobDetails)
            setModeofPrinting(arrr[0].machine)
            setlBreadth(arrr[0].laminationBreadth)
            setlLength(arrr[0].laminationLength)
            // setlQuantity(arrr[0].punchingQuantity)
            setLRate(arrr[0].laminationRate)
            setPType(arrr[0].punching)
            // setPQuantity(arrr[0].punchingQuantity)
            setPRate(arrr[0].punchingRate)
            setEstmid(arrr[0].estimateId._id)
            //  setTimeout(() => {
            //    selectorder(location.state.item.orderId)
            //  }, 6000);
          }
        } catch (ex) {
  
        }
        // setCid(arr[0]._id)
      }
    }
  
      const selectemps = async(value) => {
        let arr = []
        if(clients && clients.length > 0){
          const rty = clients.map(k => {
            if (k.name === value) {
              arr.push(k)
      
            }
          })
          console.log(arr,'arredit');
          setClient(arr)
      
          setCname(arr[0].name);
          setCustomerId(arr[0]._id)
          try {
            const det = await JobcardsService.getordersById(arr[0]._id)
            if(det.data.status === 'success'){
              let arrr= []
               setOrders(det.data.value)
               const rty = det.data.value.map(k =>{
                if(k.orderId === value){
                    arrr.push(k)
                }
            })
            console.log(arrr[0],'arrr[0]')
            setOrderId(arrr[0].orderId);
            setorid(arrr[0]._id)
            setJname(arrr[0].name);
            setDate(moment.utc(arrr[0].createDate).format('YYYY-MM-DD'))
            setTime(moment.utc(arrr[0].createDate).format('HH:MM'))
            setJobtype(arrr[0].orderType)
            setStatus(arrr[0].status)
            setPrintMachine(arrr[0].machine)
            console.log(`${arrr[0].paperType}${arrr[0].gsm}`,'`${arrr[0].paperType}${arrr[0].gsm}`')
            setpaperGsm(`${arrr[0].paperType}${arrr[0].gsm}`)
            setGsm(arrr[0].gsm)
            setPaperType(arrr[0].paperType)
            setPapType(arrr[0].paperType)
            setPaperSize(`${arrr[0].millerLength}*${arrr[0].millerBreadth}`)
            setPaperQuantity(arrr[0].quantity)
            setPaperCuttingSize(arrr[0].cuttingSize)
            setpaperCuttingQty(arrr[0].cuttingQuantity)
            setPrinting(arrr[0].printingside)
            setFinalSheets(arrr[0].finalsheetforperMillisheet)
            setJobdetails(arrr[0].jobDetails)
            setModeofPrinting(arrr[0].machine)
            setlBreadth(arrr[0].laminationBreadth)
            setlLength(arrr[0].laminationLength)
            // setlQuantity(arrr[0].punchingQuantity)
            setLRate(arrr[0].laminationRate)
            setPType(arrr[0].punching)
            // setPQuantity(arrr[0].punchingQuantity)
            setPRate(arrr[0].punchingRate)
            setEstmid(arrr[0].estimateId._id)
            }
          } catch (ex) {
    
          }
          // console.log(e.target.selectedOptions[0].dataset.index, 'value');
          // const selindex = e.target.selectedOptions[0].dataset.index;
          // const selclient = clients[selindex]
          // setCname(selclient.name);
          // setCustomerId(selclient._id)
          // console.log(selclient, 'selclient');
          // try {
          //   const det = await JobcardsService.getordersById(selclient._id)
          //   if(det.data.status === 'success'){
          //      setOrders(det.data.value)
          //   }
          // } catch (ex) {
  
          // }
        }
    }
    const getpunchtypes = async () => {
      try {
        const der = await estimateService.getpunchingtypes();
        if (der.data.status === 'success') {
          setPunchingTypes(der.data.value)
        }
      } catch (ex) {
  
      }
  
    }
    const selectorder = (value) => {
      let arr =[]
      console.log(orders,'selorders');
      if(orders && orders.length > 0){
          const rty = orders.map(k =>{
              if(k.orderId === value){
                  arr.push(k)
              }
          })
          console.log(arr[0],'arr[0]')
          setOrderId(arr[0].orderId);
          setorid(arr[0]._id)
          setJname(arr[0].name);
          setDate(moment.utc(arr[0].createDate).format('YYYY-MM-DD'))
          setTime(moment.utc(arr[0].createDate).format('HH:MM'))
          setJobtype(arr[0].orderType)
          setStatus(arr[0].status)
          setPrintMachine(arr[0].machine)
          console.log(`${arr[0].paperType}${arr[0].gsm}`,'`${arr[0].paperType}${arr[0].gsm}`')
          setpaperGsm(`${arr[0].paperType}${arr[0].gsm}`)
          setGsm(arr[0].gsm)
          setPaperType(arr[0].paperType)
          setPapType(arr[0].paperType)
          setPaperSize(`${arr[0].millerLength}*${arr[0].millerBreadth}`)
          setPaperQuantity(arr[0].quantity)
          setPaperCuttingSize(arr[0].cuttingSize)
          setpaperCuttingQty(arr[0].cuttingQuantity)
          setPrinting(arr[0].printingside)
          setFinalSheets(arr[0].finalsheetforperMillisheet)
          setJobdetails(arr[0].jobDetails)
          setModeofPrinting(arr[0].machine)
          setlBreadth(arr[0].laminationBreadth)
          setlLength(arr[0].laminationLength)
          // setlQuantity(arr[0].punchingQuantity)
          setLRate(arr[0].laminationRate)
          setPType(arr[0].punching)
          // setPQuantity(arr[0].punchingQuantity)
          setPRate(arr[0].punchingRate)
          setEstmid(arr[0].estimateId._id)
      }
     
    }
  
    const selectpapertype = (value) => {
      setPaperType(value)
      setBPaperType(value[0]);
    }

    const getvendors = async () => {
      try {
        const dty = await SupplierService.getsuppliers();
        if (dty.data.status === "success") {
          setVendors(dty.data.value);
        }
      } catch (ex) {}
    };
    const gettransname = async () => {
      try {
        const dty = await MasterService.gettransport();
        if (dty.data.status === "success") {
          setTransport(dty.data.value);
        }
      } catch (ex) {}
    };
    
  
    const getOrder = async () => {
      console.log('calling orders');
      try {
  
        const dert = await estimateService.getOrders();
        console.log(dert, 'orderssss');
        if (dert.data.status === 'success') {
          console.log(dert.data.value, 'orders');
          setOrders(dert.data.value);
        }
      } catch (ex) {
  
      }
    }
    const getclients = async () => {
  
      try {
  
        const dert = await ClientsService.getclients();
        if (dert.data.status === 'success') {
          console.log(dert.data.value, 'values');
          setClients(dert.data.value);
        }
      } catch (ex) {
  
      }
    }
  
    const getgsmtypes = async () => {
      try {
        const dert = await estimateService.getpapergsmAll();
        if (dert.data.status === 'success') {
  
          if (dert.data.value?.length > 0) {
            let optionss = [];
            dert.data.value.map((k) => {
              const obj = {
                paperTypegsm: k.paperType + k.gsm,
                _id: k._id,
                "paperType": k.paperType,
                "perkgRate": k.perkgRate,
                "gsm": k.gsm,
                "printmincost": k.printmincost,
                "printminImpressionquantity": k.printminImpressionquantity,
                "printextraImpressioncost": k.printextraImpressioncost,
                "printextraImpressionquantity": k.printextraImpressionquantity,
                "isActive": k.isActive,
                "isArchive": k.isArchive,
                "updatedDate": k.updatedDate,
                "createDate": k.createDate,
                "__v": 0
              }
              optionss.push(obj)
            })
            setGsmOptions(optionss)
            setGsmTypes(dert.data.value)
          }
  
        }
      } catch (ex) {
  
      }
  
  
    }
  
    const getpapertypes = async () => {
      try {
        const dty = await estimateService.papertypes();
        if (dty.data.status === 'success') {
          console.log(dty.data.value, 'dty.data.value');
          setpaperTypes(dty.data.value)
        }
      } catch (ex) {
  
      }
    }
  
    const getmachines = async () => {
      try {
        const dert = await estimateService.getmachines();
        if (dert.data.status === 'success') {
          console.log(dert.data.value, 'dert.data.value');
          setMachines(dert.data.value)
        }
      } catch (ex) {
  
      }
    }
  
    const selectmachinetype = (value) => {
      setMachineType(value);
      setBMachineType(value[0])
      jobdetails.push(value[0].details)
      console.log(value, 'value')
    }
    // const [estimatecalc, setEstimatecalc] = useState()
    // const multiselectRef = useRef();
    // const papermultiselectRef = useRef();
    // const finishmultiselectRef = useRef();
    // const machinemultiselectRef = useRef();
    // const millimultiselectRef = useRef();
    // const laminationmultiselectRef = useRef();
    // const extraworksmultiselectRef = useRef();
    // const printjobmultiselectRef = useRef();
    const hiddenFileInput = useRef(null);
    const [imloader, setImloader] = useState(null)
    const [file, setFile] = useState(null);
  
  
   
    const handleorderType = (e) => {
      setOrdertype(e)
      jobdetails.push(e)
    }
  
    const handlesubmit = async () => {
      try {
        const obj = {
  
          "jobCardNo": jobCardNo,
          "customerName": cname,
          "jobName": jname,
          "callParty": callParty,
          "plateBy": plateBy,
          "colorProof": colorProof,
          "noOfSets": noOfSets,
          "agAou": agAou,
          "machine": machine,
          "date": date,
          "orderNo": orderNo,
          "orderId": orderId,
          "colors": color,
          "printing": printing,
          "totalImpressions": totalImpressions,
          "finalSheets": finalSheets,
          "paperType": bpapertype.details,
          "paperGsm": paperGsm,
          "paperSize": paperSize,
          "paperQuantity": paperQuantity,
          "paperCuttingSize": paperCuttingSize,
          "paperCuttingQty": paperCuttingQty,
          "printMachine": printMachine,
          "printMedia": printMedia,
          "printQuantity": printQuantity,
          "printRate": printRate,
          "printCost": printCost,
          "lBreadth": lBreadth,
          "lLength": lLength,
          "lSheet": lSheet,
          "lQuantity": lQuantity,
          "lRate": lRate,
          "lCost": lCost,
          "pBreadth": pBreadth,
          "pLength": pLength,
          "pType": pType,
          "pQuantity": pQuantity,
          "pRate": pRate,
          "pCost": pCost,
          "transDetails": transDetails,
          "outSouWorks": outSouWorks,
          "cutCharge": cutCharge,
          "packCharge": packCharge,
          "transCharge": transCharge,
          "otherCharge1": otherCharge1,
          "otherCharge2": otherCharge2,
          "total": total,
          "status": Status,
          "jobDetails": jobdetails,
          "firstMaterialIndentNo": firstMaterialIndentNo,
          "transName": transName,
          "customerId": customerId,
          "paperVendor": paperVendor,
          "estimateId": estimateId,
          "jobDetails": jobdetails,
          "isActive": true,
          "isArchive": false,
          "_id": id,
          "createdByDate": createdByDate,
          "updatedDate": updatedDate,
          "__v": 0
        }
        const dort = await JobcardsService.addjobcards(obj);
        console.log(dort, 'dort');
        if (dort.status === 200) {
          await setSBtndis(false)
          navigate('/jobcards')
          toast.success('Job Card Created Successfully', {
            position: toast.POSITION.BOTTOM_LEFT
          });
        }
      } catch (ex) {
        await setSBtndis(false)
        console.log(ex.response, 'ex.response');
        toast.error(ex.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT
        });
      }
    }
    //addjobcardsIntegration close
  
    //add indent Integration
    const [role, setRole] = useState('');
    const [indentno, setIndentNo] = useState('');
  
    
    const [size, setSize] = useState(location.state?.item ? location.state?.item.paperSize : "");
    const [quantity, setQuantity] = useState('');
    const [sname, setSname] = useState('');
  
    function editjob() {
      navigate('/editjob');
    };
  
   
    //Indent Validations close
  
    const create = async () => {
  
      try {
        const obj = {
          "indentNo": indentno,
          "orderNo": orderNo,
          "jobCardNo": jobCardNo,
          "date": date,
          "paperType": papertype,
          "gsm": gsm,
          "size": size,
          "quantity": quantity,
          "supplierName": sname,
          "status": Status
  
        }
  
        const dert = await IndentService.addindents(obj);
        if (dert.data.status === 'success') {
          toast.success('Indent Added SuccessFully', {
            position: toast.POSITION.BOTTOM_LEFT
          });
          navigate('/indent')
        } else {
  
        }
      } catch (ex) {
  
      }
    }
    const handleClick = event => {
      hiddenFileInput.current.click();
    };
    const changeHandler = async (event) => {
      console.log('calling');
      await setImloader(true)
  
      const formData = new FormData();
  
      formData.append(
        "image",
        event.target.files[0],
      );
      console.log(event.target.files[0], 'event.target.files')
      let res = await axios.post(
        'https://aretsapi.azurewebsites.net/api/common/uploadfile', formData,
  
        {
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data; ',
            'Accept': '*/*'
          },
        }
      );
      console.log(res.data, 'res.data');
      if (res.data.url) {
        await setImloader(false);
  
        await setFile(res.data.url);
      }
  
    };
    const delmg = async () => {
      var filename = file.substring(file.lastIndexOf('/') + 1);
      const obj = {
        url: filename
      }
      const dete = await estimateService.delproimg(obj);
      if (dete.data.status === true) {
        setFile()
        toast.success('File Deleted Successfully', {
          position: toast.POSITION.BOTTOM_LEFT
        });
      }
    }
    //add indent Integration close
  
    const [modalShow, setModalShow] = React.useState(false);
    console.log(printMachine,'printMachine');
    const createjob = async() => {
      // setIsLoading(true)
      if(!cname){
        await setSBtndis(false)
        toast.error('Select Firm Name', {
          position: toast.POSITION.BOTTOM_LEFT
        });
      }else if(!orderId){
        await setSBtndis(false)
        toast.error('Select Order ID', {
          position: toast.POSITION.BOTTOM_LEFT
        });
      }
     else if(!callParty){
      await setSBtndis(false)
        toast.error('Enter Call Party', {
          position: toast.POSITION.BOTTOM_LEFT
        });
      }else if(!plateBy){
        await setSBtndis(false)
        toast.error('Enter Plate By', {
          position: toast.POSITION.BOTTOM_LEFT
        });
      }else if(!noOfSets){
        await setSBtndis(false)
        toast.error('Enter No Of Sets', {
          position: toast.POSITION.BOTTOM_LEFT
        });
      }else if(!colorProof){
        await setSBtndis(false)
        toast.error('Enter Color Proof', {
          position: toast.POSITION.BOTTOM_LEFT
        });
      }else if(!agAou){
        await setSBtndis(false)
        toast.error('Enter AG/AOU', {
          position: toast.POSITION.BOTTOM_LEFT
        });
      // }else if(!outSouWorks){
      //   await setSBtndis(false)
      //   toast.error('Enter Other Source Works', {
      //     position: toast.POSITION.BOTTOM_LEFT
      //   });
      }else if(!paperVendor){
        await setSBtndis(false)
        toast.error('Select Paper Vendor', {
          position: toast.POSITION.BOTTOM_LEFT
        });
      // }else if(!transName){
      //   await setSBtndis(false)
      //   toast.error('Enter Transport Name', {
      //     position: toast.POSITION.BOTTOM_LEFT
      //   });
      }else{
        try {
          setIsLoading(true);
          const obj = {
             "customerName": cname,
             "jobName": jname,
             "orderType":jobtype,
             "callParty": callParty,
             "plateBy": plateBy,
             "colorProof": colorProof,
             "noOfSets": noOfSets,
             "agAou": agAou,
             "machine": printMachine,
             "date": moment.utc(date).format('YYYY-MM-DD'),
             "orderId": orderId,
             "colors": color,
             "printing": printMachine,
             "totalImpressions": totalImpressions,
             "finalSheets": parseInt(finalSheets),
             "paperGsm": paperGsm,
             "paperSize": paperSize,
             "paperQuantity": paperQuantity,
             "paperCuttingSize": paperCuttingSize,
             "paperCuttingQty": paperCuttingQty,
             "printMachine": printMachine,
             "printMedia": printMedia,
             "printQuantity": printQuantity,
             "printRate": printCost,
             "lBreadth": lBreadth,
             "lLength": lLength,
             "lSheet": lSheet,
             "lQuantity": lQuantity,
             "lRate": lCost,
             "pBreadth": pBreadth,
             "pLength": pLength,
             "pType": pType,
             "pQuantity": pQuantity,
            //  "pRate": pRate,
             "transDetails": transDetails,
             "outSouWorks": outSouWorks,
             "status": Status,
             "jobDetails": 0,
             "transName": transName,
             "paperVendor": paperVendor,
             pastingType: pasting,
             pastingQuantity: pastquantity,
             finishingType: finishingtype,
             finishingQuantity: finishingquantity,
             othersamount: othersAmount,
            //  orId:orid,
             "_id":location.state?.item._id
    
            }
            console.log(obj,'jobobj')
            const data = await JobcardsService.updatejobcards(obj);
            if(data.data.status === 'success'){
                const ob = {
                  "gsm":gsm,
                  "date":moment.utc(createdByDate).format('YYYY-MM-DD'),
                  "orderId":orderId,
                  "estimateId":estmid,
                  "jobCardId":location.state?.item.jobId,
                  "paperType":papertype,
                  "customerName": customerName,
                  "customerId": customerId,
                  "size":papersize,
                  "quantity":paperQuantity,
                  "sum_total": subtotal,
                  "supplierName":paperVendor,
                  "status":Status,
                  "path":"job",
                  "noOfSets": noOfSets,
                  "agAou": agAou,
                  "jobId": jobId,
                  "plateBy": plateBy,

                }
                console.log(ob,'obobob');
                const dert = await JobcardsService.updateindent(ob);
                if(dert.data.status === 'success'){
                    console.log(dert.data,'dert.data');
                    toast.success('Job Card and Material Indent Updated Successfully', {
                      position: toast.POSITION.BOTTOM_LEFT
                    });
                    navigate('/jobcards')
                    // setIsLoading(false);
                }
            }
            
        } catch (ex) {
          
        }finally{
          setIsLoading(false);
        }
      }
      
    }
  return (
    <>
      <Header />
      <Container fluid className="containerr">
        <Row>
          <Col lg={2} id="sidebar-wrapper" className="d-none d-md-block">
            <Sidebar />
          </Col>
          <Col lg={10} className="p-3">
            <div>
              <h1 className="fs-3 ms-lg-5 mt-3">Edit Job Card</h1>
              <Breadcrumb className="ms-lg-5 fs-14">
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/jobcards">Job Cards</Breadcrumb.Item>
                <Breadcrumb.Item active>Edit Job Card</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Card sx={{ minWidth: 275 }} className="mt-4 rounded-4">
              <CardContent>
                <Col lg={12} className="pe-3">
                  <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-flex flex-column flex-lg-row gap-1 gap-lg-0 justify-content-start">
                    <div className="col-lg-2 mt-2">
                      <h6 className="fs-12 fw-bold text-lg-center">
                        Firm Name
                      </h6>
                    </div>
                     <div className="col-lg-2">
                      <div className="input-group">
                        <div className="col-lg-12">
                          <div className="input-group">
                            <input
                              value={cname}
                              onChange={(e) => setCname(e.target.value)}
                              type="text"
                              id="inputcolor"
                              className="form-control text-muted   py-2 required"
                              name="date "
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 mt-2">
                      <h6 className="fs-12 fw-bold text-lg-center">Order ID</h6>
                    </div>
                    <div className="col-lg-2">
                      <div className="input-group">
                        <div className="col-lg-12">
                          <div className="input-group">
                            <input
                              value={orderId}
                              onChange={(e) => setOrderId(e.target.value)}
                              type="text"
                              id="inputcolor"
                              className="form-control text-muted   py-2 required"
                              name="date "
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 mt-2">
                      <h6 className="fs-12 fw-bold text-lg-center">
                        Booking Date
                      </h6>
                    </div>
                    <div className="col-lg-2">
                      <div className="input-group">
                        <div className="col-lg-12">
                          <div className="input-group">
                            <input
                              value={date}
                              onChange={(e) => setDate(e.target.value)}
                              type="date"
                              id="inputcolor"
                              className="form-control text-muted   py-2 required"
                              name="date "
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-flex flex-column flex-lg-row gap-1 gap-lg-0 mt-2 dashedline">
                    <div className="col-lg-2 mt-lg-2">
                      <h6 className="fs-12 fw-bold text-lg-center">Job Name</h6>
                    </div>
                    <div className="col-lg-2">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control text-muted required  py-2"
                          name="name"
                          value={jname}
                          onChange={(e) => setJname(e.target.value)}
                          id="inputcolor"
                          aria-label="name"
                          aria-describedby="basic-addon1"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-lg-1 mt-2">
                      <h6 className="fs-12 fw-bold text-lg-center">Job Type</h6>
                    </div>
                    <div className="col-lg-2">
                      <div className="input-group">
                      <input
                                type="text"
                                className="form-control required"
                                name="Quantity"
                                value={jobtype}
                                onChange={(e) => setJobtype(e.target.value)}
                                disabled
                                id="inputcolorr"
                                
                              />
                      </div>
                    </div>
                    <div className="col-lg-2 mt-2">
                      <h6 className="fs-12 fw-bold text-lg-center">Status</h6>
                    </div>
                    <div className="col-lg-2">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control required  py-2"
                          name="quantity"
                          value={Status}
                          onChange={(e) => setStatus(e.target.value)}
                          id="inputcolor"
                          aria-label="quantity"
                          aria-describedby="basic-addon1"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <Row>
                    <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 mt-2 d-lg-flex dashedline">
                      {/* first row */}

                      <div className="col-lg-4">
                        <div className="col-lg-6 mt-2">
                          <h6 className="fs-16 fw-bolder">PAPER DETAILS:</h6>
                        </div>
                        <div className="d-flex">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold ">paper & Gsm</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                            <input
                                type="text"
                                className="form-control required"
                                name="Quantity"
                                value={paperGsm}
                                onChange={(e) => setpaperGsm(e.target.value)}
                                disabled
                                id="inputcolorr"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex  mt-1">
                          <div className="col-lg-4 col-4  mt-2">
                            <h6 className="fs-12 fw-bold">Mill Size</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="length"
                                className="form-control py-2 required"
                                name="length"
                                value={paperSize} 
                                onChange={(e) => setPaperSize(e)}
                                id="inputcolor"
                                aria-label="length"
                                aria-describedby="basic-addon1"
                                required
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Mill Quantity</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control py-2 required"
                                name="Quantity"
                                value={paperQuantity}
                                onChange={(e) => setPaperQuantity(e.target.value)}
                                id="inputcolor"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Cutting Size</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control py-2 required"
                                name="Cut Size"
                                value={paperCuttingSize}
                                onChange={(e) => setPaperCuttingSize(e.target.value)}
                                id="inputcolor"
                                aria-label="Cut Size"
                                aria-describedby="basic-addon1"
                                required
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Cutting Quantity</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control py-2 required"
                                name="Cut Quantity"
                                value={paperCuttingQty}
                                onChange={(e) => setpaperCuttingQty(e.target.value)}
                                id="inputcolor"
                                aria-label="Cut Quantity"
                                aria-describedby="basic-addon1"
                                required
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Paper Vendor</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <select
                                value={paperVendor}
                                onChange={(e) => setPaperVendor(e.target.value)}
                                class="form-select py-2 text-muted"
                                id="inputcolor"
                                aria-label="Default select example"
                              >
                                <option selected hidden>Paper Vendor*</option>
                                {/* <option value="Gayatri">Gayatri</option>
                                <option value="Hari Kisan Paper">
                                  Hari Kisan Paper
                                </option>
                                <option value="Jai Shankar Khagad bhandhar">
                                  Jai Shankar Khagad bhandhar
                                </option> */}
                                {vendors &&
                              vendors.map((k, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={k.name}
                                    data-index={index}
                                    
                                  >
                                    {k.name}
                                  </option>
                                );
                              })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Second Column */}

                      <div className="col-lg-4">
                        <div className="col-lg-6 mt-2">
                          <h6 className="fs-16 fw-bolder">PLATE DETAILS:</h6>
                        </div>
                        <div className="d-flex">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold ">plate By</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <select
                                value={agAou}
                                onChange={(e) => setAgAou(e.target.value)}
                                class="form-select py-2 text-muted"
                                id="inputcolor"
                                aria-label="Default select example"
                              >
                                <option selected hidden>AG/AOU*</option>
                                <option value="AG">AG</option>
                                <option value="AOU">AOU</option>
                                <option value="AG/AOU">AG/AOU</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">No.of Sets</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control py-2 required"
                                name="quantity"
                                value={noOfSets}
                                onChange={(e) => setNoOfSets(e.target.value)}
                                id="inputcolor"
                                aria-label="quantity"
                                aria-describedby="basic-addon1"
                                required
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Plate Vendor</h6>
                          </div>
                          
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <select
                                value={plateBy}
                                onChange={(e) => setPlateBy(e.target.value)}
                                class="form-select py-2 text-muted"
                                id="inputcolor"
                                aria-label="Default select example"
                              >
                                <option selected hidden>Plate Vendor*</option>
                                {/* <option value="Gayatri">Gayatri</option>
                                <option value="Hari Kisan Paper">
                                  Hari Kisan Paper
                                </option>
                                <option value="Jai Shankar Khagad bhandhar">
                                  Jai Shankar Khagad bhandhar
                                </option> */}
                                {vendors &&
                              vendors.map((k, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={k.name}
                                    data-index={index}
                                    
                                  >
                                    {k.name}
                                  </option>
                                );
                              })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Third Column */}

                      <div className="col-lg-4">
                        <div className="col-lg-6 col-4 mt-2">
                          <h6 className="fs-16 fw-bolder">PRINTING DETAILS:</h6>
                        </div>
                        <div className="d-flex">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold ">Mode Of Printing</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control py-2 required"
                                name="quantity"
                                value={modeofprinting}
                                id="inputcolor"
                                aria-label="quantity"
                                aria-describedby="basic-addon1"
                                required
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-lg-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">No.Of Colors</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="length"
                                className="form-control py-2 required"
                                name="length"
                                value={color}
                                onChange={(e) => setColor(e.target.value)}
                                id="inputcolor"
                                aria-label="length"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Printing Side</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                            <input
                                type="text"
                                className="form-control required"
                                name="Quantity"
                                value={printing}
                                onChange={(e) => setPrinting(e.target.value)}
                                disabled
                                id="inputcolorr"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Total Impression</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="length"
                                className="form-control py-2 required"
                                name="length"
                                value={totalImpressions}
                                onChange={(e) =>
                                  setTotalImpressions(e.target.value)
                                }
                                id="inputcolor"
                                aria-label="length"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Final Sheets</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control py-2 required"
                                name="rate"
                                value={finalSheets}
                                onChange={(e) => setFinalSheets(e.target.value)}
                                id="inputcolor"
                                aria-label="rate"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                        </div> */}
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Colour Proof</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <select
                                value={colorProof}
                                onChange={(e) => setColorProof(e.target.value)}
                                class="form-select py-2 text-muted"
                                id="inputcolor"
                                aria-label="Default select example"
                              >
                                <option selected hidden>Color Proof*</option>
                                <option value="YES">Yes</option>
                                <option value="NO">No</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Call Party</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <select
                                value={callParty}
                                onChange={(e) => setCallParty(e.target.value)}
                                class="form-select py-2 text-muted"
                                id="inputcolor"
                                aria-label="Default select example"
                              >
                                <option selected hidden>Call Party*</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 mt-2 d-lg-flex dashedline">
                      {/* first row */}

                      <div className="col-lg-4">
                        <div className="col-lg-6 col-4 mt-2">
                          <h6 className="fs-16 fw-bolder">LAMINATION:</h6>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Lamination Type</h6>
                          </div>
                          <div className="col-lg-6 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control py-2 text-muted required"
                                name="name"
                                style={{fontSize : '13px'}}
                                value={lamtype}
                                onChange={(e) => setLamType(e.target.value)}
                                id="inputcolor"
                                aria-label="name"
                                aria-describedby="basic-addon1"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div className="d-flex">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold ">Length</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="length"
                                className="form-control py-2 required"
                                name="length"
                                value={lLength}
                                onChange={(e) => setlLength(e.target.value)}
                                id="inputcolor"
                                aria-label="length"
                                aria-describedby="basic-addon1"
                              disabled
                              />
                            </div>
                          </div>
                        </div> */}
                        {/* <div className="d-flex">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold ">Breadth</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="Breadth"
                                className="form-control py-2 mt-1  required"
                                name="Breadth"
                                value={lBreadth}
                                onChange={(e) => setlBreadth(e.target.value)}
                                id="inputcolor"
                                aria-label="Breadth"
                                aria-describedby="basic-addon1"
                                disabled
                              />
                            </div>
                          </div>
                        </div> */}
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Size</h6>
                          </div>
                          <div className="col-lg-6 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control py-2 text-muted required"
                                name="name"
                                value={lSheet}
                                onChange={(e) => setlSheet(e.target.value)}
                                id="inputcolor"
                                aria-label="name"
                                aria-describedby="basic-addon1"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Quantity</h6>
                          </div>
                          <div className="col-lg-6 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control py-2 required"
                                name="Quantity"
                                value={lQuantity}
                                onChange={(e) => setlQuantity(e.target.value)}
                                id="inputcolor"
                                aria-label="Quantity"
                                aria-describedby="basic-addon1"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Second Column */}

                      <div className="col-lg-4">
                        <div className="col-lg-6 col-4 mt-2">
                          <h6 className="fs-16 fw-bolder">PUNCHING:</h6>
                        </div>
                        <div className="d-flex">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold ">Punching Type</h6>
                          </div>
                          <div className="col-lg-4">
                        <div className="input-group">
                        <input
                                type="text"
                                className="form-control required"
                                name="Quantity"
                                value={pType} onChange={(e) => setPType(e.target.value)}
                                id='inputcolorr'
                                aria-label="Quantity"
                                aria-describedby="basic-addon1"
                                disabled
                              />
                        </div>
                      </div>
                        </div>
                        {/* <div className="d-flex mt-1">
                      <div className="col-lg-4 mt-2">
                        <h6 className="fs-12 fw-bold">Paper/Gsm</h6>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control text-muted required"
                            name="address"
                            id="inputcolorr"
                            value={''}
                            disabled
                            aria-label="Address"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>
                      </div> */}
                        {/* <div className="d-flex mt-1">
                      <div className="col-lg-4 mt-2">
                        <h6 className="fs-12 fw-bold">Size</h6>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control text-muted required"
                            name="address"
                            id="inputcolorr"
                            value={''}
                            disabled
                            aria-label="Address"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>
                      </div> */}
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Quantity</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control py-2 required"
                                name="rate"
                                value={pQuantity}
                                onChange={(e) => setPQuantity(e.target.value)}
                                id="inputcolor"
                                aria-label="rate"
                                aria-describedby="basic-addon1"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 mt-2">
                          <h6 className="fs-16 fw-bolder">PASTING DETAILS:</h6>
                        </div>
                        <div className="d-flex">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold ">pasting Type</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control required"
                                name="quantity"
                                value={pasting}
                                onChange={(e) => setPasting(e.target.value)}
                                id="inputcolorr"
                                aria-label="quantity"
                                aria-describedby="basic-addon1"
                                disabled
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Qantity</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control required"
                                name="quantity"
                                value={pastquantity}
                                onChange={(e) => setPastQuantity(e.target.value)}
                                id="inputcolorr"
                                aria-label="quantity"
                                aria-describedby="basic-addon1"
                                required
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        </div>
                      

                      {/* Third Column */}

                      <div className="col-lg-4">
                      <div className="col-lg-6 mt-2">
                          <h6 className="fs-16 fw-bolder">Finishing DETAILS:</h6>
                        </div>
                        <div className="d-flex">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold ">Finishing Type</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control required"
                                name="quantity"
                                value={finishingtype}
                                onChange={(e) => setFinishingType(e.target.value)}
                                id="inputcolorr"
                                aria-label="quantity"
                                aria-describedby="basic-addon1"
                                required
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Finishing Qantity</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control required"
                                name="quantity"
                                value={finishingquantity}
                                onChange={(e) => setFinishingQuantity(e.target.value)}
                                id="inputcolorr"
                                aria-label="quantity"
                                aria-describedby="basic-addon1"
                                required
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Others Amount</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control required"
                                name="quantity"
                                value={othersAmount}
                                onChange={(e) => setOthersAmount(e.target.value)}
                                id="inputcolorr"
                                aria-label="quantity"
                                aria-describedby="basic-addon1"
                                required
                                disabled
                              />
                            </div>
                          </div>
                        </div> */}
                        {/* <div className="col-lg-10 mt-2">
                          <h6 className="fs-16 fw-bolder">
                            BINDING / OUT SOURCE WORK:
                          </h6>
                        </div>
                        <div className="d-flex">
                          <div className="col-lg-8">
                            <div className="input-group">
                              <input
                                type="Quantity"
                                className="form-control py-2 required"
                                name="Quantity"
                                value={outSouWorks}
                                onChange={(e) => setOutSouWorks(e.target.value)}
                                id="inputcolor"
                                aria-label="Quantity"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                        </div>  */}
                        <div className="col-lg-10 mt-2">
                          <h6 className="fs-16 fw-bolder">TRANSPORT NAME:</h6>
                        </div>
                        <div className="d-flex">
                          <div className="col-lg-8">
                            <div className="input-group">
                              {/* <input
                                type="Quantity"
                                className="form-control py-2 required"
                                name="Quantity"
                                value={transName}
                                onChange={(e) => setTransName(e.target.value)}
                                id="inputcolorr"
                                aria-label="Quantity"
                                aria-describedby="basic-addon1"
                                required
                              /> */}
                              <select
                               value={transName}
                               onChange={(e) => setTransName(e.target.value)}
                                class="form-select text-muted"
                                id="inputcolorr"
                                aria-label="Default select example"
                              >
                                <option selected hidden>Transport Name</option>
                                {/* <option value="Gayatri">Gayatri</option>
                                <option value="Hari Kisan Paper">
                                  Hari Kisan Paper
                                </option>
                                <option value="Jai Shankar Khagad bhandhar">
                                  Jai Shankar Khagad bhandhar
                                </option> */}
                                {transport &&
                              transport.map((k, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={k.name}
                                    data-index={index}
                                  >
                                    {k.name}
                                  </option>
                                );
                              })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="row ">
                      <div className="col-lg-7"></div>
                      <div className="col-lg-2">
                        <div className="input-group mt-3">
                          <Button
                            as={NavLink}
                            to="/jobcards"
                            id="pillStyle"
                            className="w-100"
                            variant="outline-primary"
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="input-group mt-3">
                        {!isLoading ? (
                          <Button
                            onClick={() => createjob()}
                            id="pillStyle"
                            className="w-100"
                            variant="primary"
                          >
                            Update Job Card
                          </Button>
                           ) : (
                            <button className="buttonload w-100" id="pillStyle">
  <i className="fa fa-spinner fa-spin"></i> Loading
</button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </CardContent>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
const mapStateToProps = (state) => {
  console.log(state, "state");
  return {
    userdata: state.userdetails,
  };
};
export default connect(mapStateToProps)(EditJobCard);
