import React, { useEffect } from "react";
import "../../../Styles/JobCards.css";
import Header from "../../header/Header";
import Sidebar from "../../Sidenav/Sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import filterIcon from "../../../Images/filterIcon.png";
import shareIcon from "../../../Images/shareIcon.png";
import sortIcon from "../../../Images/sortIcon.png";
import calenderIcon from "../../../Images/calenderIcon.png";
import { useState } from "react";
import { DateRange } from "react-date-range";
import { NavLink, useNavigate } from "react-router-dom";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Table from "react-bootstrap/Table";
import TablePagination from "@mui/material/TablePagination";
import SupplierService from "../../../services/SupplierService";
import axios from "axios";
import editIcon from "../../../Images/editIcon.svg";
import Modal from "react-bootstrap/Modal";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import ModalSubmit from "../../modalSubmit";
import MasterService from "../../../services/MasterService";
import deleteIconRed from '../../../Images/deleteIconRed.svg';
import { connect } from "react-redux";
import get_userdetails from "../../../redux/actions/getuserDetailsAction";

const Vendor = (props) => {
  const [user, setUserdata] = useState();
  useEffect(() => {
    getuserdetails();
  }, [props.userdata]);
  useEffect(() => {
    get_userdetails();
  }, []);

  const getuserdetails = async () => {
    console.log(props.userdata.user, "props.userdata.user");
    setUserdata(props.userdata.user);
  };
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const [suppliers, setsuppliers] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [supplierCount, setSupplierCount] = useState(0);
  const [searchtext, setSearchText] = useState("");
  const [search, setSearch] = useState(false);
  // const handleChangePage = (event, newPage) => {
  //     setPage(newPage);
  // };
  const handleChangePage = (event, newPage) => {
    const dett = newPage * rowsPerPage;
    setPage(newPage);
    getsuppliers(dett, rowsPerPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getsuppliers(0, event.target.value);
  };
  useEffect(() => {
    getsuppliers(0, 10);
  }, []);

  const [editmodal, setEditModalShow] = React.useState(false);
  const [modal, setModalShow] = React.useState(false);

  const getsuppliers = async (skps, rpp) => {
    try {
      const obj = {
        skip: skps,
        limit: rpp,
        // searchkeys: { name: searchtext },
      };
      const dert = await SupplierService.getsuppliers(obj);
      if (dert.data.status === "success") {
        console.log(dert.data.value, "values");
        setsuppliers(dert.data.value);
        setSupplierCount(dert.data.count);
      }
    } catch (ex) {}
  };

  // download Excel

  const [isDownloading, setIsDownloading] = useState(false);

  // const handleDownloadExcel = async () => {
  //   try {
  //     setIsDownloading(true);
  //     const response = await axios.post(
  //       "/masterData/suppliers/jsontoexcel",
  //       { data: [] },
  //       { responseType: "blob" }
  //     );
  //     const excelBlob = new Blob([response.data], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });
  //     const url = window.URL.createObjectURL(excelBlob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = "Vendors.xlsx";
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setIsDownloading(false);
  //   }
  // };

  const handleDownloadExcel = async () => {
    try {
      setIsDownloading(true);
      const requestData = {
        // searchkeys : searchtext
        searchkeys: { name: searchtext },      
      };
      const response = await axios.post(
        "/masterData/suppliers/jsontoexcel",
        requestData,
        { responseType: "blob" }
      );
      const excelBlob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(excelBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Vendors.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    } finally {
      setIsDownloading(false);
    }
  };


  //  Download Excel Close

  //edit integration
  const [edmill, setedmill] = useState({});
  const [role, setRole] = useState("");
  const [sname, setSname] = useState("");
  const [phonecode, setPhoneCode] = useState("");
  const [country, setCountry] = useState("");
  const [phoneno, setPhoneNo] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [states, setStates] = useState("");
  const [pincode, setPincode] = useState("");
  // const [status, setStatus] = useState('');
  const [gst, setGst] = useState("");
  const [sbtndis, setSBtndis] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(false);
const [isEditMode, setIsEditMode] = useState(false);
  let navigate = useNavigate();

  const clickedit = (k) => {
    // console.log(k,'editmill');
    setedmill(k);
    setSname(k.name);
    setPhoneNo(k.phoneNo);
    setAddress(k.address);
    setCity(k.city);
    setStates(k.state);
    setPincode(k.pincode);
    setGst(k.gstNo);
    // setStatus(k.active)
    setEditModalShow(true);
  };
  const [isLoading, setIsLoading] = useState(false);
  const handlesubmit = async () => {
    if (!sname) {
      await setSBtndis(false);
      toast.error("Enter Vendor Name", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      // } else if (!email) {
      //     await setSBtndis(false)
      //     toast.error('Enter Email', {
      //         position: toast.POSITION.BOTTOM_LEFT
      //     });
    } else if (!phoneno) {
      await setSBtndis(false);
      toast.error("Enter Phone", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      // } else if (!address) {
      //     await setSBtndis(false)
      //     toast.error('Enter Address', {
      //         position: toast.POSITION.BOTTOM_LEFT
      //     });
    } else if (!city) {
      await setSBtndis(false);
      toast.error("Select City", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      // } else if (!states) {
      //     await setSBtndis(false)
      //     toast.error('Select State', {
      //         position: toast.POSITION.BOTTOM_LEFT
      //     });
      // } else if (!pincode) {
      //     await setSBtndis(false)
      //     toast.error('Enter Pincode', {
      //         position: toast.POSITION.BOTTOM_LEFT
      //     });
    // } else if (!gst) {
    //   await setSBtndis(false);
    //   toast.error("Enter GST", {
    //     position: toast.POSITION.BOTTOM_LEFT,
    //   });
      // } else if (!status) {
      //     await setSBtndis(false)
      //     toast.error('Select Status', {
      //         position: toast.POSITION.BOTTOM_LEFT
      //     });
    } else
    try {
      setIsLoading(true);
      const obj = {
        _id: edmill._id,
        // "_id": "62b03ceafc8d754eb652f989",
        supplierNo: supplierCount,
        name: sname,
        // "address": address,
        city: city,
        phoneNo: phoneno,
        // "pincode": pincode,
        // "state": states,
        gstNo: gst,
        // "details": "HYD",
        // "active": status,
        __v: 0,
      };
      const dort = await SupplierService.updatesupplier(obj);
      if (dort.status === 200) {
        getsuppliers(0, 10);
        await setSBtndis(false);
        navigate("/Vendor");
        setEditModalShow(false);
        toast.success("Vendor Updated Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (ex) {
      await setSBtndis(false);
      toast.error(ex.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }  finally{
      setIsLoading(false);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      serachclick();
     
    }
  };
  //edit integration close
  //SearchAPI

  const searchChange = (value) => {
    setSearchText(value);
    // getestimates(0,10)
  };
  const serachclick = async () => {
    const trimmedSearchText = searchtext.trim(); 
    if (trimmedSearchText === "") {
      toast.error("Enter Text", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
   
    }
   
    
    else{
    // getestimates(0, 10)
    try {
      const obj = {
        skip: 0,
        limit: rowsPerPage,
        searchkeys: { name: trimmedSearchText },
        // "filterBy": {
        //     "status": filtstatus,
        //     "customerName": cname,
        //     "fromAmount": fromAmount,
        //     "toAmount": toAmount,
        //     "fromDate": "2022-07-17",
        //     "toDate": "2023-02-15"
        // }
      };
      // console.log(obj,'objcutting');
      const dert = await SupplierService.getsuppliers(obj);
      if (dert.data.status === "success") {
        setSearch(true);
        console.log(dert.data.value, "values");
        setSupplierCount(dert.data.count);
        setsuppliers(dert.data.value);
      }
    } catch (ex) {}
  }
  };
  const resetclick = () => {
    setSearchText("");
    setSearch(false);
    getsuppliers(0, 10);
  };
  const Delete = async (item) => {
        
    try {
      const obj = {
       "_id":item._id,
      }
   
      const dert = await SupplierService.deletesupplier(obj);
      console.log(dert, "dert");

      if (dert.data.message) {
        toast.success("Vendor Deleted Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        getsuppliers(0, 10);

        // setSearch(true)
        console.log(dert.data.value, "values");
      
           // setBreadth('');
           // setLength('');
           // setDetails('')
      }
    } catch (ex) {

    }
  }

  const handlePhone = (event) => {
    const { value } = event.target;
    setPhoneNo(value.replace(/[^0-9.*!,-]/g, ''));
    };
  //SearchAPI Close
  return (
    <>
      <Header />
      <Container fluid className="containerr">
        <Row>
          <Col lg={2} id="sidebar-wrapper" className="d-none d-md-block">
            <Sidebar />
          </Col>
          <Col lg={10} sm={8} className="containerr px-3 px-lg-4">
            <div className="pt-4 pb-2 d-flex justify-content-between align-items-center">
              <div>
                <h1 className="fs-3">Vendors</h1>
                <nav aria-label="breadcrumb" className="fs-14">
                  <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item">
                      <a href="/dashboard">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Vendors
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="pt-sm-4">
                <Button
                  as={NavLink}
                  to="/createvendor"
                  id="pillStyle"
                  className="btnnew"
                  variant="primary"
                >
                  Create New Vendor
                </Button>
              </div>
            </div>
            <Row className="d-flex">
              <Col
                lg={12}
                sm={12}
                md={6}
                xs={12}
                className="text-center mb-5 datacenter"
              >
                <Paper className="col-lg-12 col-sm-8 rounded-4">
                  <Box sx={{}}>
                    <Row className="d-flex flex-column flex-lg-row justify-content-between">
                      <Col lg={4} sm={12} className="text-start ">
                        <h6 className="datahead text-muted">Vendors</h6>
                      </Col>
                      {/* categories starts */}
                      <Col lg={8} className="px-4 pb-3 pb-lg-2">
                        <Row className="d-flex justify-content-lg-end  ">
                          <Col lg={5} sm={6} className="middleFilter">
                            <Form className="mt-3 d-flex gap-2 searchField">
                              <div className="col-lg-8 d-flex">
                                <input
                                  value={searchtext}
                                  onChange={(e) => searchChange(e.target.value)}
                                  onKeyDown={handleKeyDown}
                                  className="form-control nosubmit"
                                  style={{ fontSize: "14px" }}
                                  type="search"
                                  placeholder="Search Vendor Name"
                                />
                              </div>
                              <div className="col-lg-4">
                                {!search ? (
                                  <Button
                                    onClick={() => serachclick()}
                                    className="w-100 sortFilter1"
                                    variant="primary"
                                  >
                                    Search
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => resetclick()}
                                    className="w-100 sortFilter1"
                                    variant="primary"
                                  >
                                    Reset
                                  </Button>
                                )}
                                {/* <span className="searchcont" onClick={() => serachclick()} style={{cursor:'pointer'}}><i class="fa fa-search empsearch"></i></span> */}
                              </div>
                            </Form>
                          </Col>
                          {/* sort filter */}
                          {/* <Col lg={2} sm={6} >
                                                        <Button onClick={() => setModalShow(true)} id='sortfilter' className='sortFilter1 mt-3' variant="contained">
                                                            <img className="menuIcon mx-1" src={filterIcon} />
                                                            Filter
                                                        </Button>
                                                    </Col>
                                                    <Modal
                                                        show={modal}
                                                        onHide={() => setModalShow(false)}
                                                        // size="lg"
                                                        aria-labelledby="contained-modal-title-vcenter"
                                                        centered
                                                        // style={{ width: '35%', marginLeft: '30%' }}
                                                    >
                                                        <Modal.Header closeButton>
                                                            <Modal.Title id="contained-modal-title-vcenter">
                                                                Filter                 </Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <Card sx={{ minWidth: 275 }} className=" rounded-4 col-lg-12">
                                                                <Typography sx={{ p: 2 }}>
                                                                    <h6>Filter</h6>

                                                                    <div>
                                                                        <h6>Status</h6>
                                                                        <Form.Select aria-label="Default select example">
                                                                            <option>All</option>
                                                                            <option value="1">One</option>
                                                                            <option value="2">Two</option>
                                                                        </Form.Select>
                                                                        <h6>Customer</h6>
                                                                        <Form.Select aria-label="Default select example">
                                                                            <option>All</option>
                                                                            <option value="1">One</option>
                                                                            <option value="2">Two</option>
                                                                        </Form.Select>
                                                                        <Form>
                                                                            <Row>
                                                                                <Col>
                                                                                    <Form.Label>From</Form.Label>
                                                                                    <Form.Control className='filterFields' placeholder="0.00" />
                                                                                </Col>
                                                                                <Col>
                                                                                    <Form.Label>To</Form.Label>
                                                                                    <Form.Control placeholder="0.00" />
                                                                                </Col>
                                                                            </Row>
                                                                        </Form>
                                                                        <Button id='pillStyle' className='w-100 mt-3' variant="primary">Filter</Button>{' '}
                                                                    </div>
                                                                </Typography>
                                                            </Card>
                                                        </Modal.Body>
                                                    </Modal> */}
                          {/* date filter */}
                          {/* <Col lg={2} sm={6}>
                                                        <PopupState variant="popover" popupId="demo-popup-popover">
                                                            {(popupState) => (
                                                                <div>
                                                                    <Button id='sortfilter' className='sortFilter2 mt-3' variant="contained" {...bindTrigger(popupState)}>
                                                                        <img className="menuIcon mx-1 mb-1" src={calenderIcon} />
                                                                        Filter
                                                                    </Button>
                                                                    <Popover
                                                                        {...bindPopover(popupState)}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'center',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'center',
                                                                        }}
                                                                    >
                                                                        <Typography sx={{ p: 2 }}>
                                                                            <h6 className='mb-3'>By Date</h6>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="This Week"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Last Week"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-2`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="This Month"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Last Month"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-2`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="This Year"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />&nbsp;
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Last Year"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-2`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <hr className='text-muted'></hr>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Date Range"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <Nav id='navPill' variant="pills" defaultActiveKey="/home">
                                                                                <Nav.Item>
                                                                                    <Nav.Link id='pillStyle' href="/home">From</Nav.Link>
                                                                                </Nav.Item>
                                                                                <Nav.Item>
                                                                                    <Nav.Link id='pillStyle' eventKey="link-1">To</Nav.Link>
                                                                                </Nav.Item>
                                                                            </Nav>
                                                                            <Col lg={2} sm={2} md={2}>
                                                                                <DateRange
                                                                                    editableDateInputs={true}
                                                                                    onChange={item => setState([item.selection])}
                                                                                    moveRangeOnFirstSelection={false}
                                                                                    ranges={state}
                                                                                /></Col>
                                                                            <Button className='w-100 mt-3' id='pillStyle' variant="primary">Filter</Button>{' '}
                                                                        </Typography>
                                                                    </Popover>
                                                                </div>
                                                            )}
                                                        </PopupState>
                                                    </Col> */}
                          {/* Export Excel */}
                          <Col lg={3} sm={2} className="middleFilter">
                            <Button
                              onClick={handleDownloadExcel}
                              disabled={isDownloading}
                              id="shareIcon"
                              variant="outlined"
                            >
                              <img
                                className="menuIcon mx-1 mb-1"
                                src={shareIcon}
                              />
                              {isDownloading ? "Downloading" : "Export Excel"}
                            </Button>
                          </Col>
                          {/* Bulk Select */}
                          {/* <Col lg={3} sm={2}>
                                                        <Form.Select id='selectBtn' aria-label="Default select example">
                                                            <option hidden className='text-center'>Bulk Action</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </Col> */}
                        </Row>
                      </Col>
                      {/* categories starts */}
                    </Row>
                    <Table responsive borderless>
                      <thead>
                        <tr className="theadborder">
                          {/* <th>
                                                        <div className='table-headFilter'>
                                                            <Form.Check aria-label="option 1" /></div>
                                                    </th> */}
                          <th>
                            <div className="table-headFilter ps-2">
                              <span>#</span>
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Users Name</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>

                          <th>
                            <div className="table-headFilter">
                              <span>Phone</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>GST No</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>

                          <th>
                            <div className="table-headFilter">
                              <span>Address</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          
                          {/* <th>
                                                        <div className='table-headFilter'>
                                                            <span>Status</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th> */}
                        </tr>
                      </thead>
                      <tbody className="tdata">
                        {suppliers && suppliers.length > 0
                          ? suppliers.map((k, index) => {
                              return (
                                <tr onClick="">
                                  {/* <Form.Check aria-label="option 1" /> */}
                                  <td className="ps-3">{index + 1 + (page * rowsPerPage)}. </td>
                                  <td>{k.name}</td>
                                  <td>{k.phoneNo}</td>
                                  <td>{k.gstNo}</td>
                                  <td>{k.city}</td>
                                  <td>{k.pincode}</td>
                                  {/* <td>{k.isActive === true ? 'Active' : 'In Active'}</td> */}
                                  <td onClick={() => clickedit(k)} className="hand-cursor">
                                    <img
                                      className="menuIcon mx-1"
                                      src={editIcon}
                                    />
                                    Edit
                                  </td>
                                  {user ? (
                                    user.access.includes("AA") ? (
                                      <td onClick={() => Delete(k)} className="hand-cursor">
                                          <img
                                            className="menuIcon mx-1"
                                            src={deleteIconRed}
                                          />
                                          Delete
                                      </td>
                                    ) : null
                                  ) : null}                                     </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </Table>
                    <Row className="d-flex">
                      <Col>
                        <TablePagination
                          component="div"
                          count={supplierCount}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Col>
                    </Row>
                  </Box>
                </Paper>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Modal
        show={editmodal}
        onHide={() => setEditModalShow(false)}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{ width: '35%', marginLeft: '30%' }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Vendor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card sx={{ minWidth: 275 }} className="mt-4 rounded-4 col-lg-12">
            <CardContent>
              <div className="row">
                <div className="form-group col-lg-12 col-md-12">
                  <div className="row">
                    {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                            <div className="input-group">
                                                <select value={role} class="form-select text-muted mb-3 "
                                                    onChange={(e) => setRole(e.target.value)} id='inputcolor' aria-label="Default select example">
                                                    <option selected hidden>Select User Role*</option>
                                                    <option value="1">Job Card</option>
                                                    <option value="2">Cash Receipts</option>
                                                    <option value="3">Material Indent</option>
                                                    <option value="3">Order Booking</option>
                                                    <option value="3">Master Data</option>
                                                    <option value="3">Report</option>
                                                </select>
                                            </div>
                                        </div> */}

                    <div className="form-group col-lg-12 col-md-12 col-12">
                      <div className="input-group mb-3">
                        {/* <input
                          type="text"
                          className="form-control text-muted required"
                          name="name"
                          value={sname}
                          onChange={(e) => setSname(e.target.value)}
                          id="inputcolor"
                          placeholder="Vendor Name*"
                          aria-label="name"
                          aria-describedby="basic-addon1"
                          required
                        /> */}
                        <TextField
                          className="w-100"
                          value={sname}
                          id="inputcolor"
                          onChange={(e) => setSname(e.target.value)}
                          type="text"
                          label="Vendor Name*"
                          //   variant="outlined"
                        />
                      </div>
                    </div>
                    {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                            <div className="input-group mb-3">
                                                <input
                                                    type="Email"
                                                    className="form-control text-muted required"
                                                    name="name"
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    value={email}
                                                    id='inputcolor'
                                                    placeholder="Email"
                                                    aria-label="name"
                                                    aria-describedby="basic-addon1"
                                                    required
                                                />
                                            </div>
                                        </div> */}
                    <div className="form-group col-lg-12 col-md-12 col-12">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="input-group mb-3">
                            {/* <input
                              type="phone"
                              className="form-control required"
                              name="phone"
                              value={phoneno}
                              id="inputcolor"
                              onChange={(e) => setPhoneNo(e.target.value)}
                              maxLength={10}
                              placeholder="9876543210*"
                              aria-label="phone"
                              aria-describedby="basic-addon1"
                              required
                            /> */}
                            <TextField
                              className="w-100"
                              value={phoneno}
                              id="inputcolor"
                              onChange={(e) => handlePhone(e)}
                              inputProps={{
                                maxLength: 10,
                              }}
                              type="text"
                              label="Mobile Number*"
                              //   variant="outlined"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="form-group col-lg-12 col-md-12 col-12">
                      <div className="input-group mb-3"> */}
                        {/* <input
                          type="text"
                          className="form-control text-muted required"
                          name="name"
                          id="inputcolor"
                          value={address}
                          placeholder="Address"
                          onChange={(e) => setAddress(e.target.value)}
                          aria-label="name"
                          aria-describedby="basic-addon1"
                          required
                        /> */}
                        {/* <TextField
                          className="w-100"
                          id="inputcolor"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          type="text"
                          label="Address"
                          //   variant="outlined"
                        /> */}
                      {/* </div>
                    </div> */}
                    <div className="form-group col-lg-12 col-md-12 col-12">
                      <div className="input-group mb-3">
                        {/* <select value={city} class="form-select text-muted mb-3 " onChange={(e) => setCity(e.target.value)} id='inputcolor' aria-label="Default select example">
                                                    <option selected hidden>City*</option>
                                                    <option value="1">Hyderabad</option>
                                                    <option value="2">Warangal</option>
                                                    <option value="3">Sircilla</option>
                                                    <option value="3">Kamareddy</option>
                                                    <option value="3">Wanaparthy</option>
                                                    <option value="3">Rangareddy</option>
                                                    <option value="3">Karimnagar</option>
                                                </select> */}
                        {/* <input
                          type="text"
                          className="form-control text-muted required"
                          name="name"
                          id="inputcolor"
                          value={city}
                          placeholder="Area*"
                          onChange={(e) => setCity(e.target.value)}
                          aria-label="name"
                          aria-describedby="basic-addon1"
                          required
                        /> */}
                        <TextField
                          className="w-100"
                          id="inputcolor"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          type="text"
                          label="Area*"
                          //   variant="outlined"
                        />
                      </div>
                    </div>
                    {/* <div className="form-group col-lg-12 col-md-12 col-12">
                      <div className="input-group mb-3"> */}
                        {/* <select value={states} class="form-select text-muted mb-3 " onChange={(e) => setStates(e.target.value)} id='inputcolor' aria-label="Default select example">
                                                    <option selected hidden>State*</option>
                                                    <option value="1">Telangana</option>
                                                    <option value="2">TS</option>
                                                    <option value="2">Maharashtra</option>
                                                    <option value="2">Tamil Nadu</option>
                                                    <option value="3">Andhra Pradesh</option>
                                                </select> */}
                        {/* <input
                          type="text"
                          className="form-control text-muted required"
                          name="name"
                          id="inputcolor"
                          value={states}
                          placeholder="State"
                          onChange={(e) => setStates(e.target.value)}
                          aria-label="name"
                          aria-describedby="basic-addon1"
                          required
                        /> */}
                        {/* <TextField
                          className="w-100"
                          id="inputcolor"
                          value={states}
                          onChange={(e) => setStates(e.target.value)}
                          type="text"
                          label="State"
                          //   variant="outlined"
                        /> */}
                      {/* </div>
                    </div> */}
                    {/* <div className="form-group col-lg-12 col-md-12 col-12">
                      <div className="input-group mb-3"> */}
                        {/* <input
                          type="text"
                          className="form-control required"
                          name="name"
                          id="inputcolor"
                          value={pincode}
                          onChange={(e) => setPincode(e.target.value)}
                          placeholder="Pin Code"
                          maxLength={6}
                          aria-label="name"
                          aria-describedby="basic-addon1"
                          required
                        /> */}
                        {/* <TextField
                          className="w-100"
                          id="inputcolor"
                          value={pincode}
                          onChange={(e) => setPincode(e.target.value)}
                          inputProps={{
                            maxLength: 6,
                          }}
                          type="text"
                          label="Pin Code"
                          //   variant="outlined"
                        />
                      </div>
                    </div> */}

                    {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                            <div className="input-group mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control required"
                                                    name="name"
                                                    value={score}
                                                    id='inputcolor'
                                                    placeholder="Score*"
                                                    onChange={(e) => setScore(e.target.value)}
                                                    aria-label="name"
                                                    aria-describedby="basic-addon1"
                                                    required
                                                />
                                            </div>
                                        </div> */}
                    <div className="form-group col-lg-12 col-md-12 col-12">
                      <div className="input-group mb-3">
                        {/* <input
                          type="text"
                          className="form-control required"
                          name="name"
                          value={gst}
                          id="inputcolor"
                          placeholder="GST No*"
                          onChange={(e) => setGst(e.target.value)}
                          aria-label="name"
                          aria-describedby="basic-addon1"
                          required
                        /> */}
                        <TextField
                          className="w-100"
                          id="inputcolor"
                          value={gst}
                          onChange={(e) => setGst(e.target.value)}
                          type="text"
                          label="GST No"
                          //   variant="outlined"
                        />
                      </div>
                    </div>
                    {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                            <div className="input-group mb-3">
                                                <select value={status} onChange={(e) => setStatus(e.target.value)} class="form-select text-muted" id='inputcolor' aria-label="Default select example">
                                                    <option selected hidden>Status*</option>
                                                    <option value={true}>Active</option>
                                                    <option value={false}>InActive</option>
                                                </select>
                                            </div>
                                        </div> */}

                    {/* <ModalSubmit
                      cancelOnclick={() => setEditModalShow(false)}
                      submitOnclick={() => handlesubmit()}
                      submitText={"Update Vendor"}
                    /> */}
                    <div className="row mt-3">
                    <div className="col-lg-6">
                      <Button
                        onClick={() => setEditModalShow(false)}
                        id="pillStyle"
                        className="w-100"
                        variant="outline-primary"
                      >
                        Cancel
                      </Button>
                    </div>
                        <div className="col-lg-6">
                        {!isLoading ? (
                          <Button
                            onClick={() => handlesubmit()}
                            id="pillStyle"
                            className="w-100"
                            variant="primary"
                          >
                            Update Vendor
                          </Button>
                           ) : (
                            <button className="buttonload w-100" id="pillStyle">
                          <i className="fa fa-spinner fa-spin"></i> Loading
                        </button>
                          )}
                        </div>
                        </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    userdata: state.userdetails,
  };
};
export default connect(mapStateToProps)(Vendor);