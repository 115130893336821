import React, { useEffect, useState, useRef } from "react";
import "../../Styles/Orders.css";
import Header from "../header/Header";
import Container from "react-bootstrap/Container";
import Sidebar from "../Sidenav/Sidebar";
import aretsLogo from "../../Images/aretsLogo.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import moment from "moment";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import QrCode from "../../Images/QrCode.png";
import Button from "react-bootstrap/Button";
import { NavLink, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Nav } from "react-bootstrap";
import estimateService from "../../services/estimateService";
import authService from "../../services/authService";
import ClientsService from "../../services/ClientsService";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

function PrintIndent() {
  const location = useLocation();

  console.log(location, "print-estimate item");

  // const getclients = async () => {

  //     try {

  //         const dert = await ClientsService.getclients();
  //         if (dert.data.status === 'success') {
  //             console.log(dert.data.value, 'values');
  //             setClients(dert.data.value);
  //         }
  //     } catch (ex) {

  //     }
  // }
  const scaleFactor = 210 / 120; // Assuming elementWidth is the width of the "capture" element
  const scaledHeight = 100 * scaleFactor;
  const payref = useRef();
  const [tab, setTab] = useState("Order Confirmation");
  const [payview, setpayview] = useState(true);
  const savepdf = () => {
    html2canvas(document.querySelector("#capture"), { dpi: 300 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 0, 0, 210, scaledHeight); 
      pdf.save("Paper Indent.pdf");
    });
  };

  const [orders, setOrders] = useState([]);
  useEffect(() => {
    getorders(0, 10);
  }, []);

  const getorders = async (skps, rpp) => {
    try {
      const obj = {
        skip: skps,
        limit: rpp,
        // "searchkeys": '',
      };
      const dert = await authService.getorders(obj);
      if (dert.data.status === "success") {
        console.log(dert.data.value, "values");
        setOrders(dert.data.value);
        // setOrderCount(dert.data.count)
      }
    } catch (ex) {}
  };
  // console.log('order============', order)
  console.log(location.state.item, "location.state.item");
  // console.log(order, 'order');
  return (
    <>
      <Header />
      <Container fluid className="containerr">
        <Row>
          <Col lg={12} className="p-3">
            <div>
              <Breadcrumb className="ms-lg-5">
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/indent">Indents</Breadcrumb.Item>
                <Breadcrumb.Item active>Paper Indent</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Card className="mt-3 rounded-4">
              <CardContent>
                <section>
                  <div id="capture" ref={payref}>
                  <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12 col-sm-12 col-md-12">
                                                <div className="d-lg-flex">
                                                  <div className="col-lg-3 me-lg-2"></div>
                                                <div className="col-lg-3 mb-2 mt-4">
                              <img
                                src={aretsLogo}
                                alt="branding"
                                className="loginPage-brandImg mb-1"
                                style={{width: '70px', height: '60px', marginLeft : '70px',}}
                              />
                              <h3  style={{  fontSize: '29px', fontWeight: "400px" }}>
                               <span style={{color : '#1896cc'}}> AReTS GRAPHICS</span>
                                <h6 className='ms-lg-4 text-middle' style={{ fontSize: "13px", fontWeight: '400' }}>
                                  EVERYTHING IN PRINTING
                                </h6>
                                </h3>
                            </div>
                                                    <div className="col-lg-4 col-sm-12 col-12 mt-4 mb-2">
                                                        <h6 className='fw-bolder m-0'>Regd. Office :</h6>
                                                        <h6 className='fw-normal m-0'>15-4-64, Gowliguda Chaman, Hyderabad 500012</h6>
                                                        <h6 className='fw-normal m-0'><span className='fw-bold'>Works :</span> 7-3-50, Airport road, Gagan Pahad,<br></br> Rajendra Nagar, GHMC</h6>
                                                        <h6 className='fw-normal m-0'>Phone: 9908085293 / 80192 09990 / 9393 00 9992</h6>
                                                        <h6 className='fw-normal m-0'>GSTIN: 36AEYPM2529L1ZR</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Container>
                                      <Row>
                                      <Col lg={3}></Col>
                                    <Col lg={7}>
                                    <div className="container w-100">
                      <div className="row mb-4 border-dark border-end border-start border-top border-bottom">
                        <div className="col-lg-12 col-sm-12 col-md-12 mt-3 mb-3 pb-4 border-dark border-bottom">
                        <div className="d-lg-flex">
                         <div className="col-lg-6">
                         <h5>To,</h5>
                          <h6>{location.state?.item?.supplierName}</h6>
                         </div>
                          <div className="col-lg-4"><span className="fw-bolder">Date :</span> {moment(location.state.item.date).format("DD-MM-YYYY")}</div>
                          </div>
                          <div className="col-lg-12 d-flex mt-3">
                         <div className="col-lg-6 col-5 fw-bold">Firm Name: </div>
                          <div className="col-lg-6">{location.state?.item?.customerName}</div>
                         </div>                        
                         <div className="col-lg-12 d-flex">
                         <div className="col-lg-6 col-5 fw-bold">Job Name : </div>
                          <div className="col-lg-6">{location.state?.item?.orId.name}</div>
                         </div>
                         <div className="col-lg-12 d-flex">
                         <div className="col-lg-6 col-5 fw-bold">Order ID : </div>
                          <div className="col-lg-6">{location.state?.item?.orderId}</div>
                         </div>
                        </div>
                        <div className="col-lg-12 col-sm-12 col-md-12 mt-1 mb-3">
                        <div className="col-lg-12 d-flex">
                         <div className="col-lg-6 col-7 fw-bold">Machine : </div>
                          <div className="col-lg-6">{location.state?.item?.orId.machine}</div>
                         </div>
                        <div className="col-lg-12 d-flex">
                         <div className="col-lg-6 col-7 fw-bold">Paper Type : </div>
                          <div className="col-lg-6">{location.state?.item?.paperType}</div>
                         </div>
                        <div className="col-lg-12 d-flex">
                         <div className="col-lg-6 col-7 fw-bold">Gsm : </div>
                          <div className="col-lg-6">{location.state?.item?.gsm}</div>
                         </div>
                        <div className="col-lg-12 d-flex">
                         <div className="col-lg-6 col-7 fw-bold">Mill Size : </div>
                          <div className="col-lg-6">{location.state?.item?.orId.estimateId.millerLength+"*"+location.state?.item?.orId.estimateId.millerBreadth}</div>
                         </div>
                        <div className="col-lg-12 d-flex">
                         <div className="col-lg-6 col-7 fw-bold">Mill Quantity : </div>
                          <div className="col-lg-6">{location.state?.item?.orId.millquantity}</div>
                         </div>
                        {/* <div className="col-lg-12 d-flex">
                         <div className="col-lg-6 fw-bold">paper Vendor: </div>
                          <div className="col-lg-6">{location.state?.item?.supplierName}</div>
                         </div> */}
                          
                        </div>
                      </div>
                    </div>
                                    </Col>
                                    <Col lg={2}></Col>
                                      </Row>
                                    </Container>
                  <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12 col-sm-12 col-md-12">
                                                <div className="d-lg-flex">
                                                  <div className="col-lg-3 me-lg-2"></div>
                                                <div className="col-lg-3 mb-2 mt-4">
                              <img
                                src={aretsLogo}
                                alt="branding"
                                className="loginPage-brandImg mb-1"
                                style={{width: '70px', height: '60px', marginLeft : '70px',}}
                              />
                              <h3  style={{  fontSize: '29px', fontWeight: "400px" }}>
                               <span style={{color : '#1896cc'}}> AReTS GRAPHICS</span>
                                <h6 className='ms-lg-4 text-middle' style={{ fontSize: "13px", fontWeight: '400' }}>
                                  EVERYTHING IN PRINTING
                                </h6>
                                </h3>
                            </div>
                                                    <div className="col-lg-4 col-sm-12 col-12 mt-4 mb-2">
                                                        <h6 className='fw-bolder m-0'>Regd. Office :</h6>
                                                        <h6 className='fw-normal m-0'>15-4-64, Gowliguda Chaman, Hyderabad 500012</h6>
                                                        <h6 className='fw-normal m-0'><span className='fw-bold'>Works :</span> 7-3-50, Airport road, Gagan Pahad,<br></br> Rajendra Nagar, GHMC</h6>
                                                        <h6 className='fw-normal m-0'>Phone: 9908085293 / 80192 09990 / 9393 00 9992</h6>
                                                        <h6 className='fw-normal m-0'>GSTIN: 36AEYPM2529L1ZR</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Container>
                                      <Row>
                                      <Col lg={3}></Col>
                                    <Col lg={7}>
                                    <div className="container w-100">
                      <div className="row mb-4 border-dark border-end border-start border-top border-bottom">
                        <div className="col-lg-12 col-sm-12 col-md-12 mt-3 mb-3 pb-4 border-dark border-bottom">
                        <div className="d-lg-flex">
                         <div className="col-lg-6">
                         <h5>To,</h5>
                          <h6>{location.state?.item?.supplierName}</h6>
                         </div>
                          <div className="col-lg-4"><span className="fw-bolder">Date :</span> {moment(location.state.item.date).format("DD-MM-YYYY")}</div>
                          </div>
                          <div className="col-lg-12 d-flex mt-3">
                         <div className="col-lg-6 col-5 fw-bold">Firm Name: </div>
                          <div className="col-lg-6">{location.state?.item?.customerName}</div>
                         </div>                        
                         <div className="col-lg-12 d-flex">
                         <div className="col-lg-6 col-5 fw-bold">Job Name : </div>
                          <div className="col-lg-6">{location.state?.item?.orId.name}</div>
                         </div>
                         <div className="col-lg-12 d-flex">
                         <div className="col-lg-6 col-5 fw-bold">Order ID : </div>
                          <div className="col-lg-6">{location.state?.item?.orderId}</div>
                         </div>
                        </div>
                        <div className="col-lg-12 col-sm-12 col-md-12 mt-1 mb-3">
                        <div className="col-lg-12 d-flex">
                         <div className="col-lg-6 col-7 fw-bold">Machine : </div>
                          <div className="col-lg-6">{location.state?.item?.orId.machine}</div>
                         </div>
                        <div className="col-lg-12 d-flex">
                         <div className="col-lg-6 col-7 fw-bold">Paper Type : </div>
                          <div className="col-lg-6">{location.state?.item?.paperType}</div>
                         </div>
                        <div className="col-lg-12 d-flex">
                         <div className="col-lg-6 col-7 fw-bold">Gsm : </div>
                          <div className="col-lg-6">{location.state?.item?.gsm}</div>
                         </div>
                        <div className="col-lg-12 d-flex">
                         <div className="col-lg-6 col-7 fw-bold">Mill Size : </div>
                          <div className="col-lg-6">{location.state?.item?.orId.estimateId.millerLength+"*"+location.state?.item?.orId.estimateId.millerBreadth}</div>
                         </div>
                        <div className="col-lg-12 d-flex">
                         <div className="col-lg-6 col-7 fw-bold">Mill Quantity : </div>
                          <div className="col-lg-6">{location.state?.item?.orId.millquantity}</div>
                         </div>
                        {/* <div className="col-lg-12 d-flex">
                         <div className="col-lg-6 fw-bold">paper Vendor: </div>
                          <div className="col-lg-6">{location.state?.item?.supplierName}</div>
                         </div> */}
                          
                        </div>
                      </div>
                    </div>
                                    </Col>
                                    <Col lg={2}></Col>
                                      </Row>
                                    </Container>
                  {/* <div className="container">
                      <div className="row">
                        <div className="col-lg-12 col-sm-12 col-md-12 mt-3 mb-5">
                          <table>
                            <tbody>
                              <tr className="col-lg-12">
                                <td colspan="7">
                                  <span className="ms-2">To,</span>
                                  <br />
                                  <div className="d-flex">
                                    <div className="col-lg-9 ms-2">
                                      {location.state?.item?.customerName}
                                    </div>
                                    <div>
                                      Date: &nbsp;{" "}
                                      {Moment(location.state.item?.date).format(
                                        "DD MMM YYYY"
                                      )}
                                    </div>
                                    
                                  </div>
                                  <div className="d-flex">
                                    <div className="col-lg-9 ms-2">
                                      Hyderabad
                                    </div>
                                    
                                  </div>
                                  <br />
                                  <span className="ms-2">
                                    {" "}
                                    Phone:{" "}
                                    {location.state?.item?.customerId.phoneNo}
                                  </span>
                                  <br />
                                  <br />
                                  <span className="ms-2">
                                    GST IN: {location.state?.item?.gstNo}
                                  </span>
                                  <br />
                                  <br />
                                  <div className="d-flex">
                                    <div className="col-lg-9 ms-2">
                                      Order Type:{" "}
                                      {location.state?.item?.orderType}
                                    </div>
                                   
                                  </div>
                                  <br />
                                </td>
                              </tr>
                              <tr className="col-lg-12 border border-dark">
                                <td className="col-lg-1">S.No.</td>
                                <td className="col-lg-7 border-end border-start border-dark">
                                  Description
                                </td>
                                <td className="col-lg-1 ">HSN Code</td>
                               
                              </tr>
                              <tr>
                                <td className="align-text-top" rowspan="4">
                                  1.
                                </td>
                                <td
                                  className="align-text-top border-end border-start border-dark"
                                  rowspan="4"
                                >
                                  <div>
                                    {location.state?.item?.orId.jobDetails}
                                  </div>
                                </td>
                                <td rowspan="4">
                                  {location.state?.item?.hsnCode}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="col-lg-12 text-center">
                    <Button
                      onClick={() => savepdf()}
                      id="pillStyle"
                      className="w-lg-25 "
                      variant="primary"
                    >
                      Print Paper Indent
                    </Button>
                  </div>
                </section>
              </CardContent>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PrintIndent;
