import React, { useEffect, useState, useRef } from "react";
import "../../Styles/Orders.css";
import Header from "../header/Header";
import Container from "react-bootstrap/Container";
import Sidebar from "../Sidenav/Sidebar";
import aretsLogo from "../../Images/aretsLogo.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Modal from "react-bootstrap/Modal";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
// https://app.aretsgraphicprint.com/
import Button from "react-bootstrap/Button";
import uploadCloudImage from "../../Images/uploadCloudImage.png";
import UploadImageIcon from "../../Images/UploadImageIcon.png";
import Multiselect from "multiselect-react-dropdown";
import { connect } from "react-redux";
import Switch from "react-switch";
import axios from "axios";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ClientsService from "../../services/ClientsService";
import estimateService from "../../services/estimateService";
import get_userdetails from "../../redux/actions/getuserDetailsAction";
import authService, { getestimates } from "../../services/authService";
import { toast } from "react-toastify";
import { Nav } from "react-bootstrap";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { OutlinedInput } from "@mui/material";
import { color } from "@mui/system";
import { red } from "@mui/material/colors";
import showPwdImg from "../../Images/show-password.svg";
import hidePwdImg from "../../Images/hide-password.svg";
import validator from "validator";

const DigitalEstimate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState();
  const [gsmtypes, setGsmTypes] = useState([]);
  const [user, setUserdata] = useState({});
  const [cname, setCname] = useState("");
  const [newcname, setNewCName] = useState("");
  const [ecname, setECname] = useState("");
  const [ephone, setEPhone] = useState("");
  const [efaddress, setEFAddress] = useState("");
  const [eordername, setEOrderName] = useState("");
  const [ejobtype, setEJobType] = useState("");
//   const [epapertype, setEPapertype] = useState("");
  const [edesign, setEDesign] = useState("");
  const [ecutsize, setECutSize] = useState();
  const [eflength, setEFlength] = useState("");
  const [efquantity, setEFquantity] = useState("");
  const [efbreadth, setEFbreadth] = useState("");
  const [ecutquantity, setECutQuantity] = useState();
  const [emillquantity, setEMillQuantity] = useState();
  const [efextraquantity, setEFextraquantity] = useState("");
  const [digitalType, setDigitalType] = useState();
  const [digitalTypes, setDigitalTypes] = useState([]);
  const [paperBy, setpaperBy ] = useState();
  const [cid, setCid] = useState("");
  const [phone, setPhone] = useState("");
  const [newphone, setNewPhone] = useState("");
  const [faddress, setFAddress] = useState("");
  const [email, setEmail] = useState("");
  const [fname, setFname] = useState("");
  const [gst, setGst] = useState("");
  const [size, setSize] = useState("");
  const [ordername, setOrderName] = useState("");
  const [ordertype, setOrderType] = useState("");
  const [jobtype, setJobType] = useState("");
  const [quantity, setQuantity] = useState("");
  const [country, setCountry] = useState("");
//   const [papertype, setPapertype] = useState("");
//   const [bpapertype, setBPaperType] = useState("");
  const [modeofprinting, setModeofPrinting] = useState("");
  const [emodeofprinting, setEModeofPrinting] = useState("");
  const [machinetype, setMachineType] = useState("");
  const [bmachinetype, setBMachineType] = useState({});
  const [machines, setMachines] = useState([]);
  const [gsmoptions, setGsmOptions] = useState([]);
  const [finishSizes, setFinishSizes] = useState([]);
  const [finishsize, setFinishSize] = useState("");
  const [bfinishsize, setBfinishsize] = useState({});
  const [lamlength, setLamlength] = useState("");
  const [lambreadth, setLambreadth] = useState("");
  const [lamquantity, setLamQuantity] = useState("");
  const [digiquantity, setDigiQuantity] = useState("");
  const [finishoptions, setFinishoptions] = useState([]);
  const [laminations, setLaminations] = useState([]);
  const [lamination, setLamination] = useState("");
  const [blamination, setBLamination] = useState({});
  const [digital, setDigital] = useState("");
  const [bdigital, setBDigital] = useState({});
  const [extraquantity, setExtraquantity] = useState("");
  const [millsizes, setMillsizes] = useState([]);
  const [emillsize, setEMillsize] = useState("");
  const [millsize, setMillsize] = useState("");
  const [bmillsize, setBMillSize] = useState({});

  const [finishingtype, setFinishingType] = useState("");
  const [bcutsize, setBCutSize] = useState({});
  const [millerLength, setMillerLength] = useState("");
  const [millerBreadth, setMillerBreadth] = useState("");
  const [printJobTypes, setPrintJobTypes] = useState([]);
  const [printJobType, setPrintJobType] = useState("");
  const [bprintJobType, setBPrintJobType] = useState({});
  const [jobRate, setJobRate] = useState("");
  const [rate, setRate] = useState("");
  const [punchingType, setPunchingType] = useState("");
  const [punchingTypes, setPunchingTypes] = useState([]);
  const [punchRate, setPunchRate] = useState("");
  const [punchQuantity, setPunchQuantity] = useState("");
  const [pastingtype, setPastingType] = useState("");
  const [pastingtypes, setPastingTypes] = useState([]);
  const [pastquantity, setPastquantity] = useState("");
  const [pastRate, setPastRate] = useState("");
  const [pastrimming, setPastRimming] = useState("");
  const [pastbinding, setPastBinding] = useState("");
  // const [markup, setMarkup] = useState("");
  const [date, setDate] = useState(new Date());
  const [extraworkslist, setExtraWorkList] = useState([]);
  const [extraWorks, setExtraWorks] = useState("");
  const [bextraWorks, setBExtraWorks] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [ddoption, setDDoption] = useState(false);
  const [file, setFile] = useState(null);
  const [imloader, setImloader] = useState(null);
  const [sbtndis, setSBtndis] = useState(false);
  const [designamount, setDesignAmount] = useState("");
  const [gstamount, setGstAmount] = useState('');
  const [design, setDesign] = useState("");
  const [dieamount, setDieAmount] = useState("");
  const [rimmingamount, setRimmingAmount] = useState("");
  const [pinningamount, setPinningAmount] = useState("");
  const [bindingamount, setBindingAmount] = useState("");
  const [finishingQuantity, setFinishingQuantity] = useState("");
  const [finishingRate, setFinishingRate] = useState("");
  const [packingamount, setPackingAmount] = useState("");
  const [miscamount, setMiscAmount] = useState("");
  const [jobdetails, setJobDetails] = useState([]);
  const [flength, setFlength] = useState("");
  const [fbreadth, setFbreadth] = useState("");
  const [fquantity, setFquantity] = useState("");
  const [fextraquantity, setFextraquantity] = useState("");
  const [time, setTime] = useState("");
  const [estimatecalc, setEstimatecalc] = useState();
  const [millquantity, setMillQuantity] = useState("");
  const [cutquantity, setCutQuantity] = useState();
  const [cutlength, setCutLength] = useState();
  const [cutbreadth, setCutBreadth] = useState();
  const [cutsize, setCutSize] = useState();
  const [cutsizes, setCutsizes] = useState();
  const [printingside, setPrintingside] = useState("");
  const [eprintingside, setEPrintingside] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [lamrate, setLamRate] = useState("");
  const [digirate, setDigiRate] = useState("");
  const [punchAmount, setPunchAmount] = useState("");
  const [bindingtype, setBindingType] = useState("");
  const [bindrate, setBindRate] = useState("");
  const [bindquantity, setBindQuantity] = useState("");
  const [bindamount, setBindAmount] = useState("");
  const [others, setOthers] = useState("");
  const [bothers, setBOthers] = useState("");
  const [othersamount, setOtherAmount] = useState("");
  const [markupPercentage, setMarkupPercentage] = useState("");
  const [hsncode, setHsnCode] = useState("");
  const [role, setRole] = useState("");
  const [sname, setSname] = useState("");
  const [uname, setUname] = useState("");
  const [order, setOrder] = useState("");
  const [password, setPassword] = useState("");
  const [reEnterpassword, setReEnterPassword] = useState("");
  const [phoneno, setPhoneno] = useState("");
  const [uemail, setUEmail] = useState("");
  const [phonecode, setPhonecode] = useState("");
  const [uphone, setUPhone] = useState("");
  const [access, setAccess] = useState([]);
  const [fsize, setfSize] = useState("");
  const [blam, setBlam] = useState("");
  const [bdigi, setBDigi] = useState("");
  const [jddesign, setJddesign] = useState("");
  const [prside, setPrside] = useState("");
  const [moprint, setMoprint] = useState("");
  const [bptype, setBptype] = useState("");
  const [jdfinishing, setJdfinishing] = useState("");
  // const [status, setStatus] = useState('');
  const [city, setCity] = useState("");
  const [states, setStates] = useState("");
  const [state, setState] = useState("");
  const [clpincode, setCLPincode] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [modle, setModle] = useState("");
  // const [clstatus, setCLStatus] = useState('');
  const [clphonecode, setPhoneCode] = useState("");
  const [punchjob, setpunchJob] = useState("");
  const [printjob, setprintJob] = useState("");
  const [pastjob, setPastJob] = useState("");
  const [isBinding, setIsBinding] = useState(false);
  const [isRimming, setIsRimming] = useState(false);
  const [isPinning, setIsPinning] = useState(false);
  const multiselectRef = useRef();
  const papermultiselectRef = useRef();
  const finishmultiselectRef = useRef();
  const machinemultiselectRef = useRef();
  const millimultiselectRef = useRef();
  const laminationmultiselectRef = useRef();
  const extraworksmultiselectRef = useRef();
  const printjobmultiselectRef = useRef();
  const hiddenFileInput = useRef(null);
  const navigate = useNavigate();
  let jodt = "";
  useEffect(() => {
    getuserdetails();
    getclients();
    getgsmtypes();
    getfinishsizes();
    getmachines();
    getmillsizes();
    getlaminations();
    getextrajobs();
    getjobprinttypes();
    getpunchtypes();
    getpasttypes();
    getcutsizes();
    getdigitaltypes();
  }, [props.userdata]);
  useEffect(() => {
    get_userdetails();
  }, []);
  const getuserdetails = async () => {
    setUserdata(props.userdata.user);
  };
  const selectemps = (e) => {
    if (e.target.value === "Create New Client") {
      setModalShow(true);
      console.log(e.target.value, "e.target.value");
    } else {
      console.log(e.target.selectedOptions[0].dataset.index, "value");
      const selindex = e.target.selectedOptions[0].dataset.index;
      const selclient = clients[selindex].name;
      console.log(clients[selindex], "selclient");
      setClient(clients[selindex]);
      setFAddress(clients[selindex].city);
      setPhone(clients[selindex].phoneNo);
      setGstStatus(false);
      setGst("");
      setEmail(clients[selindex].email);
      setCname(selclient);
      setCid(clients[selindex]._id);

      // setGstStatus(gstNo !== null && gstNo !== undefined);
    }
    // setGstStatus(false);
    // setGst("");

    // setClient(value)
    // setCname(value[0].name);
    // jobdetails.push(value[0].name)
    // setCid(value[0]._id)
  };
  const getpunchtypes = async () => {
    try {
      const der = await estimateService.getpunchingtypes();
      if (der.data.status === "success") {
        setPunchingTypes(der.data.value);
      }
    } catch (ex) {}
  };
  const getpasttypes = async () => {
    try {
      const dry = await estimateService.getpastingtypes();
      if (dry.data.status === "success") {
        setPastingTypes(dry.data.value);
      }
    } catch (ex) {}
  };
  const getdigitaltypes = async () => {
    try {
      const dry = await estimateService.getdigitaltypes();
      if (dry.data.status === "success") {
        setDigitalTypes(dry.data.value);
      }
    } catch (ex) {}
  };
//   const selectpapertype = (e) => {
//     // setPapertype(value)
//     // setBPaperType(value[0])
//     console.log(e.target.selectedOptions[0].dataset.index, "value");
//     const selindex = e.target.selectedOptions[0].dataset.index;
//     const selclient = gsmoptions[selindex].paperTypegsm;
//     setPapertype(gsmoptions[selindex].paperTypegsm);
//     setBPaperType(gsmoptions[selindex]);
//     let bptype = `${gsmoptions[selindex].paperTypegsm} Gsm,`;
//     setBptype(bptype);
//     getjobdet();
//   };
  const getclients = async () => {
    try {
      const dert = await ClientsService.getclients();
      if (dert.data.status === "success") {
        console.log(dert.data.value, "values");
        setClients(dert.data.value);
      }
    } catch (ex) {}
  };
  const getjobprinttypes = async () => {
    try {
      const dty = await estimateService.printjobtypes();
      if (dty.data.status === "success") {
        setPrintJobTypes(dty.data.value);
      }
    } catch (ex) {}
  };
  const getgsmtypes = async () => {
    try {
      const dert = await estimateService.getpapergsmAll();
      if (dert.data.status === "success") {
        if (dert.data.value?.length > 0) {
          let optionss = [];
          dert.data.value.map((k) => {
            const obj = {
              paperTypegsm: k.paperType + k.gsm,
              _id: k._id,
              paperType: k.paperType,
              perkgRate: k.perkgRate,
              gsm: k.gsm,
              printmincost: k.printmincost,
              printminImpressionquantity: k.printminImpressionquantity,
              printextraImpressioncost: k.printextraImpressioncost,
              printextraImpressionquantity: k.printextraImpressionquantity,
              isActive: k.isActive,
              isArchive: k.isArchive,
              updatedDate: k.updatedDate,
              createDate: k.createDate,
              __v: 0,
            };
            optionss.push(obj);
          });
          setGsmOptions(optionss);
          setGsmTypes(dert.data.value);
        }
      }
    } catch (ex) {}
  };
  const getmachines = async () => {
    try {
      const dert = await estimateService.getmachines();
      if (dert.data.status === "success") {
        console.log(dert.data.value, "dert.data.value");
        setMachines(dert.data.value);
      }
    } catch (ex) {}
  };
  const getfinishsizes = async () => {
    try {
      const drty = await estimateService.getfinishsize();
      if (drty.data.status === "success") {
        let options = [];
        drty.data.value.map((l, index) => {
          const obj = {
            _id: l._id,
            finishText: l.finishText ? l.finishText : 0,
            length: l.length,
            breadth: l.breadth,
            isActive: l.isActive,
            isArchive: l.isArchive,
            updatedDate: l.updatedDate,
            createDate: l.createDate,
            __v: 0,
          };
          options.push(obj);
        });
        setFinishSizes(options);
      }
    } catch (ex) {}
  };
  const getmillsizes = async () => {
    try {
      const dert = await estimateService.getmillsizes();
      if (dert.data.status === "success") {
        console.log(dert.data.value, "millsizes");
        setMillsizes(dert.data.value);
      }
    } catch (ex) {}
  };
  const getcutsizes = async () => {
    try {
      const dert = await estimateService.getcuttingsize();
      if (dert.data.status === "success") {
        console.log(dert.data.value, "cutsizes");
        setCutsizes(dert.data.value);
      }
    } catch (ex) {}
  };

  //fininshing dropdown event

  const finishevent = (e) => {
    const { id, value } = e.target;
    if (value == "Binding") {
      setFinishingType(value);
      setIsBinding(true);
      setIsPinning(false);
      setIsRimming(false);
    } else if (value == "Rimming") {
      setFinishingType(value);
      setIsBinding(false);
      setIsPinning(false);
      setIsRimming(true);
    } else if (value == "Pinning") {
      setFinishingType(value);
      setIsBinding(false);
      setIsPinning(true);
      setIsRimming(false);
    }

    let jdfinishing = "Binding.";
    let jdfinishing1 = "Pinning.";
    let jdfinishing2 = "Rimming.";
    if (e.target.value === "Binding") {
      setJdfinishing(jdfinishing);
    } else if (e.target.value === "Pinning") {
      setJdfinishing(jdfinishing1);
    } else if (e.target.value === "Rimming") {
      setJdfinishing(jdfinishing2);
      // } else if (e.target.value) {
      //   setPrside(e.target.value)
    }
  };
  const getlaminations = async () => {
    try {
      const drt = await estimateService.getlaminations();
      if (drt.data.status === "success") {
        console.log(drt.data.value, "laminations");
        setLaminations(drt.data.value);
      }
    } catch (ex) {}
  };
  const getextrajobs = async () => {
    try {
      const dey = await estimateService.extrajobs();
      if (dey.data.status === "success") {
        setExtraWorkList(dey.data.value);
      }
    } catch (ex) {}
  };
  const selectExtraworks = (value) => {
    setExtraWorks(value);
    setBExtraWorks(value[0]);
    // jobdetails.push(value[0].details)
    console.log(value, "value");
  };
  const selectfinishsize = (value) => {
    setFinishSize(value);
    setBfinishsize(value[0]);
    // jobdetails.push(value[0].finishText)
    console.log(value, "value");
  };
  const selectmachinetype = (value) => {
    setMachineType(value);
    setBMachineType(value[0]);
    // jobdetails.push(value[0].details)
    console.log(value, "value");
  };
  const selectmillsize = (e) => {
    const selindex = e.target.selectedOptions[0].dataset.index;
    const selclient = millsizes[selindex].milliText;
    console.log(selclient, "selmillsize");
    setMillsize(millsizes[selindex].milliText);
    setBMillSize(millsizes[selindex]);
    // jobdetails.push(millsizes[selindex].milliText)
    // console.log(value, 'value')
  };
  const selectcutsize = (e) => {
    const selindex = e.target.selectedOptions[0].dataset.index;
    const selclient = cutsizes[selindex].cuttingSize;
    console.log(cutsizes[selindex], "selcutsize");
    setCutSize(cutsizes[selindex].cuttingSize);
    setBCutSize(cutsizes[selindex]);
    // jobdetails.push(millsizes[selindex].milliText)
    // console.log(value, 'value')
  };
  const selpunchingtype = (e) => {
    const selindex = e.target.selectedOptions[0].dataset.index;
    setPunchingType(punchingTypes[selindex].type);
    setPunchRate(punchingTypes[selindex].cost);
    let punchjob = `${punchingTypes[selindex].type},`;
    setpunchJob(punchjob);
    getjobdet();
    // jobdetails.push(punchingTypes[selindex].type)
  };
  const seljobtype = (e) => {
    const selindex = e.target.selectedOptions[0].dataset.index;
    // setPrintJobTypes(printJobTypes[selindex].type);
    setJobRate(printJobTypes[selindex].cost);
    let printjob = `${printJobTypes[selindex].details}`;
    console.log(printjob, "text");
    setJobType(printJobTypes[selindex].details);
    getjobdet();
    setprintJob(printjob);
    // jobdetails.push(punchingTypes[selindex].type)
  };
  const selpasting = (e) => {
    const selindex = e.target.selectedOptions[0].dataset.index;
    setPastingType(pastingtypes[selindex].type);
    setPastRate(pastingtypes[selindex].rate);
    let pastjob = `${pastingtypes[selindex].type},`;
    setPastJob(pastjob);
  };
  const selectlamination = (e) => {
    // setLamination(value);
    // setBLamination(value[0])
    console.log(e.target.selectedOptions[0].dataset.index, "value");
    const selindex = e.target.selectedOptions[0].dataset.index;
    const selclient = laminations[selindex].details;
    setLamination(laminations[selindex].details);
    // setLamlength(laminations[selindex].length);
    // setLambreadth(laminations[selindex].breadth);
    // setLamQuantity(laminations[selindex].quantity);
    setBLamination(laminations[selindex]);
    setLamRate(laminations[selindex].cost);
    let blam = `${laminations[selindex].details},`;
    setBlam(blam);
    getjobdet();
  };
  const selectdigital = (e) => {
    console.log(e.target.selectedOptions[0].dataset.index, "value");
    const selindex = e.target.selectedOptions[0].dataset.index;
    const selclient = digitalTypes[selindex].digitalType;
    setDigital(digitalTypes[selindex].digitalType);
    setBDigital(digitalTypes[selindex]);
    setDigiRate(digitalTypes[selindex].rate);
    let bdigi = `${digitalTypes[selindex].digitalType},`;
    setBDigi(bdigi);
    getjobdet();
  };
//   console.log(bpapertype, "props.userdata");
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const changeHandler = async (event) => {
    console.log("calling");
    await setImloader(true);

    const formData = new FormData();

    formData.append("image", event.target.files[0]);
    console.log(event.target.files[0], "event.target.files");
    let res = await axios.post(
      "https://aretsapi.azurewebsites.net/api/common/uploadfile",
      formData,

      {
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data; ",
          Accept: "*/*",
        },
      }
    );
    console.log(res.data, "res.data");
    if (res.data.url) {
      await setImloader(false);

      await setFile(res.data.url);
    }
  };
  const delmg = async () => {
    var filename = file.substring(file.lastIndexOf("/") + 1);
    const obj = {
      url: filename,
    };
    const dete = await estimateService.delproimg(obj);
    if (dete.data.status === true) {
      setFile();
      toast.success("File Deleted Successfully", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  // handle to track if the calculations are submitted or not before creating estimate
  const [calculated, setCalculated] = useState();

  const submit = async () => {
    setCalculated(false);
    // setSBtndis(true)
    if (!cname) {
      await setSBtndis(false);
      setECname("Firm Name is Required!");
      toast.error("Select Firm Name", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!faddress) {
      await setSBtndis(false);
      setECname("");
      setEFAddress("Area is Required!");
      toast.error("Enter Area/Street", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!phone) {
      await setSBtndis(false);
      setECname("");
      setEFAddress("");
      setEPhone("Mobile Number is Required!");
      toast.error("Enter Phone Number", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (phone.length < 10 && phone.length > 10) {
      await setSBtndis(false);
      toast.error("Invalid Phone", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!ordername) {
      await setSBtndis(false);
      setECname("");
      setEFAddress("");
      setEPhone("");
      setEOrderName("Job Name is Required!");
      toast.error("Enter Job Name", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!jobtype) {
      await setSBtndis(false);
      setECname("");
      setEFAddress("");
      setEPhone("");
      setEOrderName("");
      setEJobType("Job Type is Required!");
      toast.error("Select Job Type", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!flength) {
      await setSBtndis(false);
      setECname("");
      setEFAddress("");
      setEPhone("");
      setEOrderName("");
      setEJobType("");
      setEFlength("Finish Length is Required!");
      toast.error("Enter Finish Length", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!fbreadth) {
      await setSBtndis(false);
      setECname("");
      setEFAddress("");
      setEPhone("");
      setEOrderName("");
      setEJobType("");
      setEFlength("");
      setEFbreadth("Finish Breadth is Required!");
      toast.error("Enter Finish Breadth", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!fquantity) {
      await setSBtndis(false);
      setECname("");
      setEFAddress("");
      setEPhone("");
      setEOrderName("");
      setEJobType("");
      setEFlength("");
      setEFbreadth("");
      setEFquantity("Quantity is Required!");
      toast.error("Enter Finish Quantity", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!design) {
      await setSBtndis(false);
      setECname("");
      setEFAddress("");
      setEPhone("");
      setEOrderName("");
      setEJobType("");
      setEFlength("");
      setEFbreadth("");
      setEFquantity("");
      setEDesign("Design By is Required!");
      toast.error("Select Design By", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
   
    } else if (!modeofprinting) {
      await setSBtndis(false);
      setECname("");
      setEFAddress("");
      setEPhone("");
      setEOrderName("");
      setEJobType("");
      setEFlength("");
      setEFbreadth("");
      setEFquantity("");
      setEDesign("");
      setEModeofPrinting("Mode Of Printing is Required!");
      toast.error("Select Mode of Printing", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    // } else if (!printingside) {
    //   await setSBtndis(false);
    //   setECname("");
    //   setEFAddress("");
    //   setEPhone("");
    //   setEOrderName("");
    //   setEJobType("");
    //   setEFlength("");
    //   setEFbreadth("");
    //   setEFquantity("");
    //   setEDesign("");
    //   setEModeofPrinting("");
    //   setEPrintingside("Select Printing Side");
    //   toast.error("Select Printing Side", {
    //     position: toast.POSITION.BOTTOM_LEFT,
    //   });
    } else {
      setECname("");
      setEFAddress("");
      setEPhone("");
      setEOrderName("");
      setEJobType("");
      setEFlength("");
      setEFbreadth("");
      setEFquantity("");
      setEDesign("");
      setEModeofPrinting("");
    //   setEPrintingside("");
      setCalculated(true);
      try {
        const obj = {
          laminationdetails: blamination.details ? blamination.details : "",
          punchingType: punchingType ? punchingType : "",
          finishingType: finishingtype ? finishingtype : "",
          pastingType: pastingtype ? pastingtype : "",
          mechinedetails: modeofprinting ? modeofprinting : "",
        //   printing: printingside ? printingside : "",
        //   cuttingLength:
        //     parseFloat(bcutsize.length) === ""
        //       ? 0
        //       : parseFloat(bcutsize.length),
        //   cuttingBreadth:
        //     parseFloat(bcutsize.breadth) === ""
        //       ? 0
        //       : parseFloat(bcutsize.breadth),
        //   cuttingQuantity:
        //     parseInt(cutquantity) === "" ? 0 : parseInt(cutquantity),
          finishingQuantity:
            parseInt(finishingQuantity) === ""
              ? 0
              : parseInt(finishingQuantity),
          pastingQuantity: parseInt(pastquantity),
        //   paperBy: paperBy,
          digitalType: digital ? digital : "",
          calobj: {
            // millquantity:
            //   parseFloat(millquantity) === "" ? 0 : parseFloat(millquantity),
            designingAmount: designamount === "" ? 0 : parseInt(designamount),
            dieAmount: dieamount === "" ? 0 : parseInt(dieamount),
            packingAmount: packingamount === "" ? 0 : parseInt(packingamount),
            // paperType: {
            //   // "sno":2,
            //   paperType: bpapertype.paperType ? bpapertype.paperType : "",
            //   perkgRate: parseInt(bpapertype.perkgRate),
            //   gsm: parseInt(bpapertype.gsm),
            //   printmincost: parseInt(bpapertype.printmincost),
            //   printminImpressionquantity: parseInt(
            //     bpapertype.printminImpressionquantity
            //   ),
            //   printextraImpressioncost: parseInt(
            //     bpapertype.printextraImpressioncost
            //   ),
            //   printextraImpressionquantity: parseInt(
            //     bpapertype.printextraImpressionquantity
            //   ),
            // },
            quantity: parseInt(fquantity) === "" ? 0 : parseInt(fquantity),
            finishLength: parseFloat(flength) === "" ? 0 : parseFloat(flength),
            finishBreadth:
              parseFloat(fbreadth) === "" ? 0 : parseFloat(fbreadth),
            extraquantity:
              parseInt(fextraquantity) === "" ? 0 : parseInt(fextraquantity),
            // millerLength:
            //   parseFloat(bmillsize.length) === ""
            //     ? 0
            //     : parseFloat(bmillsize.length),
            // millerBreadth:
            //   parseFloat(bmillsize.breadth) === ""
            //     ? 0
            //     : parseFloat(bmillsize.breadth),
            laminationLength:
              parseFloat(lamlength) === "" ? 0 : parseFloat(lamlength),
            // laminationLength: "lamleng",
            laminationBreadth:
              parseFloat(lambreadth) === "" ? 0 : parseFloat(lambreadth),
            laminationRate:
              parseFloat(lamrate) === "" ? 0 : parseFloat(lamrate),
            laminationQuantity: lamquantity === "" ? 0 : parseInt(lamquantity),
            punchingQuantity: parseInt(punchQuantity),
            punchingRate:
              parseFloat(punchRate) === "" ? 0 : parseFloat(punchRate),
            pastingQuantity: parseInt(pastquantity),
            pastingRate: parseFloat(pastRate) === "" ? 0 : parseFloat(pastRate),
            finishingRate:
              parseFloat(finishingRate) === "" ? 0 : parseFloat(finishingRate),
            markupPercentage:
              markupPercentage === "" ? 0 : parseInt(markupPercentage),
            miscAmount: 0,
            others: others,
            othersamount: othersamount === "" ? 0 : parseInt(othersamount),
            gstamount: gstamount === "" ? 0 : parseInt(gstamount),
            finalsheetforperMillisheet: 5,
            totalmilliSheet: 535,
            gststatus: false,
            digitalQuanitity: digiquantity === "" ? 0 : parseInt(digiquantity),
            digitalRate: parseFloat(digirate) === "" ? 0 : parseFloat(digirate),
          },
        };
        console.log(obj, "obj");
        console.log(millerLength, "mill");
        const drty = await estimateService.estimatecalculation(obj);
        console.log(drty.data.data, "drty.data");
        if (drty.data) {
          setEstimatecalc(drty.data.data);
          console.log(drty.data, "drty.data");
        }
      } catch (ex) {}
    }
  };
  const payref = useRef();
  const [tab, setTab] = useState("Order Confirmation");
  const [payview, setpayview] = useState(true);
  const savepdf = () => {
    html2canvas(document.querySelector("#capture")).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "in", [13.5, 9]);
      pdf.addImage(imgData, "PNG", 0, 0);
      pdf.save("Print Estimate.pdf");
    });
  };
  const handleorderType = (e) => {
    setOrderType(e.target.value);
    // jobdetails.push(e.target.value)
  };
  const handlemode = (e) => {
    const selindex = e.target.selectedOptions[0].dataset.index;
    // const selclient = punchingTypes[selindex].type
    console.log(machines[selindex].details, "machines[selindex].type");
    setModeofPrinting(machines[selindex].details);
    let moprint = "Multi Colour Printing,";
    if (machines[selindex].details === "KL-20") {
      setMoprint(moprint);
    } else if (machines[selindex].details === "KL-26") {
      setMoprint(moprint);
    } else if (machines[selindex].details === "MSB") {
      setMoprint(moprint);
    } else if (machines[selindex].details) {
      setMoprint(machines[selindex].details);
    }
    // jobdetails.push(machines[selindex].details)
  };

  const handprintingside = (e) => {
    setPrintingside(e.target.value);
    let prside = "Front & Back,";
    let prside1 = "One Side,";
    let prside2 = "Back & Back,";
    if (e.target.value === "BB") {
      setPrside(prside2);
    } else if (e.target.value === "FB") {
      setPrside(prside);
    } else if (e.target.value === "Opp.G") {
      setPrside(prside);
    } else if (e.target.value === "OS") {
      setPrside(prside1);
    } else if (e.target.value) {
      setPrside(e.target.value);
    }
  };
  const clickdesign = (e) => {
    setDesign(e.target.value);
    let jddesign = "Designing,";
    if (e.target.value.length > 0) {
      setJddesign(jddesign);
    } else if (e.target.value) {
      setJddesign("");
    }
  };
  const handlesubmit = async () => {
    console.log(jodt, "jodt");

    if (calculated === true) {
      try {
        setIsLoading(true);
        const obj = {
          customerName: cname,
          customerId: cid,
          mobile: phone,
          gstNo: gst,
          gststatus: gststatus,
          email: email,
          address: faddress,
          quantity: parseInt(fquantity),
          laminationRate: parseFloat(lamrate),
        //   printing: printingside ? printingside : "Na",
          design: design ? design : "Na",
        //   paperType: bpapertype.paperType ? bpapertype.paperType : "",
        //   gsm: bpapertype.gsm === "" ? 0 : parseInt(bpapertype.gsm),
          machine: modeofprinting ? modeofprinting : "Na",
          lamination: blamination.details ? blamination.details : "",
          punching: punchingType ? punchingType : "",
          pasting: pastingtype ? pastingtype : "",
          digitalType: digital ? digital : "",
          finishingType: finishingtype ? finishingtype : "",
          // binding: bindingtype ? bindingtype : "Na",
          finishLength: parseFloat(flength) === "" ? 0 : parseFloat(flength),
          finishBreadth: parseFloat(fbreadth) === "" ? 0 : parseFloat(fbreadth),
          extraquantity:
            parseInt(fextraquantity) === "" ? 0 : parseInt(fextraquantity),
        //   millerLength:
        //     parseFloat(bmillsize.length) === ""
        //       ? 0
        //       : parseFloat(bmillsize.length),
        //   millerBreadth:
        //     parseFloat(bmillsize.breadth) === ""
        //       ? 0
        //       : parseFloat(bmillsize.breadth),
        //   cuttingLength:
        //     parseFloat(bcutsize.length) === ""
        //       ? 0
        //       : parseFloat(bcutsize.length),
        //   cuttingBreadth:
        //     parseFloat(bcutsize.breadth) === ""
        //       ? 0
        //       : parseFloat(bcutsize.breadth),
          laminationLength:
            parseFloat(lamlength) === "" ? 0 : parseFloat(lamlength),
          laminationBreadth:
            parseFloat(lambreadth) === "" ? 0 : parseFloat(lambreadth),
          // laminationRate: parseInt(lamrate) === "" ? 0 : parseInt(lamrate),
          punchingQuantity:
            parseInt(punchQuantity) === "" ? 0 : parseInt(punchQuantity),
          digitalQuanitity:
            parseInt(digiquantity) === "" ? 0 : parseInt(digiquantity),
          laminationQuantity:
            parseInt(lamquantity) === "" ? 0 : parseInt(lamquantity),
          punchingRate:
            parseFloat(punchRate) === "" ? 0 : parseFloat(punchRate),
          markupPercentage:
            parseInt(markupPercentage) === "" ? 0 : parseInt(markupPercentage),
          finishingRate:
            parseFloat(finishingRate) === "" ? 0 : parseFloat(finishingRate),
          pastingQuantity:
            parseInt(pastquantity) === "" ? 0 : parseInt(pastquantity),
            digitalQuanitity:
            parseInt(digiquantity) === "" ? 0 : parseInt(digiquantity),
          finishingQuantity:
            parseInt(finishingQuantity) === ""
              ? 0
              : parseInt(finishingQuantity),
          pastingRate: parseFloat(pastRate) === "" ? 0 : parseFloat(pastRate),
          digitalRate: parseFloat(digirate) === "" ? 0 : parseFloat(digirate),
          designingAmount: parseFloat(estimatecalc.designingAmount.toFixed(2)),
        //   paperAmount: parseFloat(estimatecalc.paperAmount.toFixed(2)),
          PrintAmount: parseFloat(estimatecalc.PrintAmount.toFixed(2)),
          laminationAmount: parseFloat(
            estimatecalc.laminationAmount.toFixed(2)
          ),
          dieAmount: parseFloat(estimatecalc.dieAmount.toFixed(2)),
          punchingAmount: parseFloat(estimatecalc.punchingAmount.toFixed(2)),
          pastingAmount: parseFloat(estimatecalc.pastingAmount.toFixed(2)),
          rimmingAmount: parseFloat(estimatecalc.rimmingAmount.toFixed(2)),
          pinningAmount: parseFloat(estimatecalc.pinningAmount.toFixed(2)),
          bindingAmount: parseFloat(estimatecalc.bindingAmount.toFixed(2)),
          packingAmount: parseFloat(estimatecalc.packingAmount.toFixed(2)),
          digitalAmount: parseInt(estimatecalc.digitalAmount.toFixed(2)),
          gstamount: parseInt(estimatecalc.gstamount.toFixed(2)),
          totalAmount: parseInt(estimatecalc.totalAmount),
          miscAmount: parseInt(estimatecalc.miscAmount),
          sum_total: parseInt(estimatecalc.sum_total),
          // othersamount: parseInt(estimatecalc.othersamount),
          createdBy: user._id,
          orderType: jobtype,
          name: ordername,
          jobDetails: jodt,
        //   cuttingSize: bcutsize.cuttingSize,
        //   cuttingQuantity: parseFloat(cutquantity),
        //   millquantity: parseFloat(millquantity),
          hsnCode: hsncode,
          // markupPercentage: parseInt(markupPercentage),
          others: others,
          othersamount: othersamount,
          finalsheetforperMillisheet: 5,
          totalmilliSheet: 535,
        };
        console.log(obj, "objcal");
        const dort = await estimateService.addestimation(obj);
        console.log(dort, "dort");
        if (dort.status === 200) {
          await setSBtndis(false);
          navigate("/estimates");
          toast.success("Estimate Created Successfully", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      } catch (ex) {
        await setSBtndis(false);
        console.log(ex, "ex.response");
        toast.error(ex.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      setCalculated(false);
      toast.error("Please Calculate the Estimate First", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };
  const handledoption = (e) => {
    console.log(e, "e.target.value");
    setDDoption(e);
  };
  const selectprintjobtype = (value) => {
    setPrintJobType(value);
    setBPrintJobType(value[0]);
  };

  const createclient = async () => {
    // setIsLoading(true);
    if (!newcname) {
      await setSBtndis(false);
      toast.error("Enter Firm Name", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!newphone) {
      await setSBtndis(false);
      toast.error("Enter Phone", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (newphone.length != 10) {
      await setSBtndis(false);
      toast.error("Invalid Mobile Number", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!city) {
      await setSBtndis(false);
      toast.error("Enter Area", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      try {
        setIsLoading(true);
        const obj = {
          name: newcname,
          firmname: fname,
          // "address": address,
          city: city,
          phoneNo: newphone,
          // "pincode": pincode,
          state: states,
          // email: email,
          details: "TEST DETAILS",
          // "active": true,
          // "score": "100",
          gstNo: gstNo,
        };
        const dert = await ClientsService.addclients(obj);
        if (dert.data.status === "success") {
          setClient(dert.data.value);
          setCname(dert.data.value.name);
          setPhone(dert.data.value.phoneNo);
          setFAddress(dert.data.value.city);
          // setAddress(dert.data.value.address);
          setCity(dert.data.value.city);
          setGst(dert.data.value.gstNo);

          toast.success("Client Added Successfully", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          // setIsLoading(false);
          navigate("/newestimate");
          setModalShow(false);
          getclients(0, 10);
          getestimates(0, 10);
          setNewCName("");
          setFname("");
          setCity("");
          setNewPhone("");
          setGstNo("");
          setCLPincode("");
          setState("");
        } else {
        }
      } catch (ex) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  function modalreset() {
    setNewCName("");
    setFname("");
    setCity("");
    setNewPhone("");
    setGstNo("");
    setCLPincode("");
    setState("");
    setModalShow(false);
  }
  //Email Validation
  const [emailError, setEmailError] = useState("");
  const validateEmail = (e) => {
    setEmail(e.target.value);
    var email = e.target.value;
    if (validator.isEmail(email)) {
      setEmailError("");
    } else {
      setEmailError("Enter valid Email!");
    }
    //setEmail("");
  };
  //Email Validation close

  const selfleangth = (value) => {
    setFlength(value);
    // setLamlength(value);
    let size = `size : ${value + "x" + fbreadth},`;
    setfSize(size);
  };
  const selcutSize = (value) => {
    const selIndex = value.target.selectedOptions[0].dataset.index;
    console.log(cutsizes[selIndex].length, "cutvaluekj");
    setCutSize(value.target.value);
    setLamlength(cutsizes[selIndex].length);
    setLambreadth(cutsizes[selIndex].breadth);
    setBLamination(cutsizes[selIndex]);
    setBCutSize(cutsizes[selIndex]);
    // let size = `size:${value},`;
    // setfSize(size);
  };
  const selfbreadth = (value) => {
    setFbreadth(value);
    // setLambreadth(value);
    let size = `size : ${flength + "x" + value},`;
    setfSize(size);
  };

  const [gststatus, setGstStatus] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const handleAccess = (isChecked) => {
    setGstStatus(isChecked);
    if (isChecked && client && client.gstNo) {
      setGst(client.gstNo);
    } else {
      setGst("");
    }
  };

  const setfinishqty = (e) => {
    setFquantity(e.target.value);
    const det = fextraquantity === "" ? 0 : fextraquantity;
    const lamqty = parseInt(e.target.value) + parseInt(det);

    console.log(lamqty, "lamqty");
  };
  const setfinishextqty = (e) => {
    setFextraquantity(e.target.value);
    const det = fquantity === "" ? 0 : fquantity;
    const lamqty = parseInt(det) + parseInt(e.target.value);
    console.log(lamqty, "lamqty");
  };
  const setcuttingtqty = (e) => {
    setCutQuantity(e.target.value);
    const det = cutquantity === "" ? 0 : cutquantity;
    const lamqty = e.target.value !== "" ? parseInt(e.target.value) : "";
    setLamQuantity(lamqty);
    setPunchQuantity(lamqty);
  };
  const handlejobdet = (value) => {
    setJobDetails(value);
  };
  const getjobdet = (item) => {
    const jobdet = `${printjob} ${fsize}
${jddesign} ${bptype} ${prside} ${moprint} 
${blam} ${punchjob} ${pastjob} ${jdfinishing}`;
    console.log(jobdet);
    jodt = jobdet;
    return (
      <div
        style={{ marginBottom: "20px" }}
        className="form-group col-lg-12 col-md-12 col-12"
      >
        <div
          className="input-group"
          style={{ width: "500px", height: "110px" }}
        >
          <div
            value={jobdet}
            className="border border-dark ps-2 pt-2 rounded-4"
            style={{ width: "100%", height: "100%" }}
          >
            <b>
              {printjob}: {fsize}
            </b>
            <p>
              {jddesign} {bptype} {prside} {moprint} {blam} {punchjob} {pastjob}{" "}
              {jdfinishing}
            </p>
          </div>
        </div>
      </div>
    );
  };
  //

  const handleNewPhone = (event) => {
    const { value } = event.target;
    setNewPhone(value.replace(/[^0-9.*!,-]/g, ""));
  };

  return (
    <div>
      <CardContent className="d-lg-flex">
        <Col lg={8} sm={12} className="pe-lg-3">
          <div>
            <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex">
              <div className="col-lg-2 col-sm-3 mt-2">
                <h6 className="fs-12 fw-bold">Customer Name</h6>
              </div>
              <div className="col-lg-5 col-sm-6">
                <div className="input-group">
                  <select
                    value={cname}
                    onChange={(e) => selectemps(e)}
                    className="form-select text-muted me-lg-2 w-100"
                    id="inputcolorr"
                    aria-label="Default select example"
                  >
                    <option hidden value>
                      Customer Name*
                    </option>
                    <option value="Create New Client">Create New Client</option>
                    {clients &&
                      clients.map((k, index) => {
                        return (
                          <option key={index} value={k.name} data-index={index}>
                            {k.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div
                  className="ms-2"
                  style={{ color: "#FF0000", fontSize: "11px" }}
                >
                  {ecname ? ecname : null}
                </div>
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-1 mt-2">
                <h6 className="fs-12 fw-bold">Area*</h6>
              </div>
              <div className="col-lg-3">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control text-muted required"
                    name="address"
                    id="inputcolorr"
                    value={faddress}
                    disabled
                    // onChange={(e) => setFAddress(e.target.value)}
                    aria-label="Address"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
                <div
                  className="ms-2"
                  style={{ color: "#FF0000", fontSize: "11px" }}
                >
                  {efaddress ? efaddress : null}
                </div>
              </div>
            </div>
            <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2">
              <div className="col-lg-2 mt-2">
                <h6 className="fs-12 fw-bold">Mobile No.*</h6>
              </div>
              <div className="col-lg-2 me-lg-2">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control required"
                    name="phone"
                    id="inputcolorr"
                    value={phone}
                    disabled
                    // onChange={(e) => setPhone(e.target.value)}
                    maxLength={10}
                    pattern="[0-9]{10}"
                    aria-label="phone"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
                <div
                  className="ms-2"
                  style={{ color: "#FF0000", fontSize: "11px" }}
                >
                  {ephone ? ephone : null}
                </div>
              </div>
              <div className="col-lg-1 mt-2">
                <h6 className="fs-12 fw-bold text-lg-center">GST</h6>
              </div>
              <div className="col-lg-1">
                <div class="form-check form-switch ms-1 mt-1 mb-2">
                  <input
                    checked={gststatus}
                    onChange={(e) => handleAccess(e.target.checked)}
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </div>
              <div className="col-lg-2">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control required"
                    name="name"
                    id="inputcolorr"
                    value={gst}
                    onChange={(e) => setGst(e.target.value)}
                    aria-label="name"
                    aria-describedby="basic-addon1"
                    disabled={!gststatus}
                    required
                  />
                </div>
                <div
                  className="ms-2"
                  style={{ color: "#FF0000", fontSize: "11px" }}
                >
                  {ephone ? ephone : null}
                </div>
              </div>
              <div className="col-lg-1 mt-2">
                <h6 className="fs-12 fw-bold me-lg-1 text-lg-end">Email</h6>
              </div>
              <div className="col-lg-3">
                <div className="input-group pe-lg-2">
                  <input
                    type="email"
                    className="form-control text-muted required"
                    name="name"
                    id="inputcolorr"
                    value={email}
                    onChange={(e) => validateEmail(e)}
                    aria-label="name"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
                <span
                  style={{
                    fontSize: "10px",
                    color: "red",
                  }}
                >
                  {emailError}
                </span>
              </div>
            </div>
            <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2 dashedline">
              <div className="col-lg-2 mt-2">
                <h6 className="fs-12 fw-bold">Job Name*</h6>
              </div>
              <div className="col-lg-4">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control required"
                    name="name"
                    id="inputcolorr"
                    value={ordername}
                    onChange={(e) => setOrderName(e.target.value)}
                    aria-label="name"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
                <div
                  className="ms-2"
                  style={{ color: "#FF0000", fontSize: "11px" }}
                >
                  {eordername ? eordername : null}
                </div>
              </div>
              <div className="col-lg-2 mt-2">
                <h6 className="fs-12 fw-bold me-lg-1 text-lg-center">
                  Job Type*
                </h6>
              </div>
              <div className="col-lg-4">
                <div className="input-group">
                  <select
                    onChange={(e) => seljobtype(e)}
                    className="form-select text-muted"
                    id="inputcolorr"
                    aria-label="Default select example"
                  >
                    <option hidden value>
                      Job Type*
                    </option>
                    {printJobTypes &&
                      printJobTypes.map((k, index) => {
                        return (
                          <option
                            key={index}
                            value={k.details}
                            data-index={index}
                          >
                            {k.details}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div
                  className="ms-2"
                  style={{ color: "#FF0000", fontSize: "11px" }}
                >
                  {ejobtype ? ejobtype : null}
                </div>
              </div>
            </div>
            <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2 dashedline">
              <div className="col-lg-1">
                <h1 className="fs-14 fw-bold">FINISH SIZE</h1>
              </div>
              <div className="col-lg-2 me-lg-2 mb-sm-2">
                <div className="input-group">
                  <h6 className="fs-12 fw-bold me-lg-1 mt-2">L*</h6>
                  &nbsp;
                  <input
                    type="text"
                    className="form-control required"
                    name="quantity"
                    id="inputcolorr"
                    value={flength}
                    onChange={(e) => selfleangth(e.target.value)}
                    maxLength={10}
                    aria-label="quantity"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
                <div
                  className="ms-2"
                  style={{ color: "#FF0000", fontSize: "11px" }}
                >
                  {eflength ? eflength : null}
                </div>
              </div>
              <div className="col-lg-2">
                <div className="input-group">
                  <h6 className="fs-12 fw-bold mt-2 me-lg-1">B*</h6>
                  &nbsp;
                  <input
                    type="text"
                    className="form-control required"
                    name="quantity"
                    id="inputcolorr"
                    value={fbreadth}
                    onChange={(e) => selfbreadth(e.target.value)}
                    maxLength={10}
                    aria-label="quantity"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
                <div
                  className="ms-2"
                  style={{ color: "#FF0000", fontSize: "11px" }}
                >
                  {efbreadth ? efbreadth : null}
                </div>
              </div>
              <div className="col-lg-1 mt-2 ms-lg-2">
                <h6 className="fs-12 fw-bold text-lg-center">Quantity*</h6>
              </div>
              <div className="col-lg-2 ms-lg-2">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control required"
                    name="quantity"
                    id="inputcolorr"
                    value={fquantity}
                    onChange={(e) => setfinishqty(e)}
                    maxLength={7}
                    aria-label="quantity"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
                <div
                  className="ms-2"
                  style={{ color: "#FF0000", fontSize: "11px" }}
                >
                  {efquantity ? efquantity : null}
                </div>
              </div>
              <div className="col-lg-1 mt-2 me-lg-1">
                <h6 className="fs-12 fw-bold ms-lg-3">Ex.Quantity</h6>
              </div>
              <div className="col-lg-2 ms-lg-4">
                <div className="input-group ">
                  <input
                    type="text"
                    className="form-control required"
                    name="quantity"
                    id="inputcolorr"
                    value={fextraquantity}
                    onChange={(e) => setfinishextqty(e)}
                    maxLength={7}
                    aria-label="quantity"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2 dashedline">
              <div className="col-lg-2">
                <h1 className="fs-14 fw-bold mt-1">DESIGN:</h1>
              </div>

              <div className="col-lg-2 mt-2">
                <h6 className="fs-12 fw-bold text-lg-center">Design By*</h6>
              </div>
              <div className="col-lg-4">
                <div className="input-group">
                  <select
                    value={design}
                    onChange={(e) => clickdesign(e)}
                    type="text"
                    class="form-select text-muted"
                    id="inputcolorr"
                    aria-label="Design Amount"
                  >
                    <option value="Design" hidden>
                      Designing*
                    </option>
                    <option value="Design By Arets">Design By Arets</option>
                    <option value="Design by party">Design by party</option>
                  </select>
                </div>
                <div
                  className="ms-2"
                  style={{ color: "#FF0000", fontSize: "11px" }}
                >
                  {edesign ? edesign : null}
                </div>
              </div>
              <div className="col-lg-2 mt-2">
                <h6 className="fs-12 fw-bold text-lg-center">Amount</h6>
              </div>
              <div className="col-lg-2">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control required "
                    name="text"
                    id="inputcolorr"
                    value={designamount}
                    onChange={(e) => setDesignAmount(e.target.value)}
                    maxLength={7}
                    aria-label="design"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
              </div>
            </div>
            {/* <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2">
              <div className="col-lg-1">
                <h1 className="fs-14 fw-bold mt-1">PAPER:</h1>
              </div>
              <div className="col-lg-2 mt-2">
                <h6 className="fs-12 fw-bold text-lg-center">Paper & GSM*</h6>
              </div>
              <div className="col-lg-3">
                <div className="input-group">
                  <select
                    onChange={(e) => selectpapertype(e)}
                    className="form-select text-muted me-lg-2"
                    id="inputcolorr"
                    aria-label="Default select example"
                  >
                    <option hidden value>
                      Paper and GSM*
                    </option>
                    {gsmoptions &&
                      gsmoptions.map((k, index) => {
                        return (
                          <option key={index} value={k} data-index={index}>
                            {k.paperType} {k.gsm}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div
                  className="ms-2"
                  style={{ color: "#FF0000", fontSize: "11px" }}
                >
                  {epapertype ? epapertype : null}
                </div>
              </div>
              <div className="col-lg-1 mt-2">
                <h6 className="fs-12 fw-bold">Mill Size*</h6>
              </div>
              <div className="col-lg-2">
                <div className="input-group">
                  <select
                    onChange={(e) => selectmillsize(e)}
                    class="form-select text-muted"
                    id="inputcolorr"
                    aria-label="Default select example"
                  >
                    <option selected hidden>
                      Mill Size*
                    </option>
                    {millsizes &&
                      millsizes.map((k, index) => {
                        return (
                          <option key={index} value={k} data-index={index}>
                            {k.milliText}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div
                  className="ms-2"
                  style={{ color: "#FF0000", fontSize: "11px" }}
                >
                  {emillsize ? emillsize : null}
                </div>
              </div>
              <div className="col-lg-1 mt-2">
                <h6 className="fs-12 fw-bold text-lg-end">Mill.Qty*</h6>
              </div>
              <div className="col-lg-2">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control required"
                    name="quantity"
                    id="inputcolorr"
                    value={millquantity}
                    onChange={(e) => setMillQuantity(e.target.value)}
                    maxLength={10}
                    aria-label="quantity"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
                <div
                  className="ms-2"
                  style={{ color: "#FF0000", fontSize: "11px" }}
                >
                  {emillquantity ? emillquantity : null}
                </div>
              </div>
            </div> */}
            <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2 dashedline">
              <div className="col-lg-2">
                <h1 className="fs-14 fw-bold mt-1">Digital:</h1>
              </div>
              <div className="col-lg-2 mt-2">
                <h6 className="fs-12 fw-bold ">Digital Type</h6>
              </div>
              <div className="col-lg-2 me-lg-1">
                <div className="input-group">
                  <select
                    onChange={(e) => selectdigital(e)}
                    class="form-select text-muted"
                    id="inputcolorr"
                    aria-label="Default select example"
                  >
                    <option selected hidden>
                      Digital Type*
                    </option>
                    {digitalTypes && digitalTypes.length > 0
                      ? digitalTypes.map((k, index) => {
                          return (
                            <option key={index} value={k} data-index={index}>
                              {k.digitalType}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </div>
              </div>
              <div className="col-lg-1 mt-2">
                <h6 className="fs-12 fw-bold text-lg-center ms-lg-2">Qty</h6>
              </div>
              <div className="col-lg-2 ">
                <div className="input-group">
                  <input
                    type="Quantity"
                    className="form-control required"
                    name="Quantity"
                    value={digiquantity}
                    onChange={(e) => setDigiQuantity(e.target.value)}
                    id="inputcolorr"
                    aria-label="Quantity"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
              </div>
              <div className="col-lg-1 mt-2">
                <h6 className="fs-12 fw-bold text-lg-center ms-lg-2">Rate</h6>
              </div>
              <div className="col-lg-2">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control required"
                    name="rate"
                    value={digirate}
                    onChange={(e) => setDigiRate(e.target.value)}
                    id="inputcolorr"
                    maxLength={7}
                    aria-label="rate"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
              </div>
            </div>
            {/* <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2 dashedline">
              <div className="col-lg-1">
                <h1 className="fs-14 fw-bold mt-1"></h1>
              </div>
              <div className="col-lg-2 mt-2">
                <h6 className="fs-12 fw-bold ps-lg-2"></h6>
              </div>
              <div className="col-lg-3">
                <div className="input-group"></div>
              </div>
              <div className="col-lg-1 mt-2">
                <h6 className="fs-12 fw-bold">Cut Size*</h6>
              </div>
              <div className="col-lg-2">
                <div className="input-group">
                  <select
                    // value={cutsize}
                    onChange={(e) => selcutSize(e)}
                    class="form-select text-muted"
                    id="inputcolorr"
                    aria-label="Default select example"
                  >
                    <option selected hidden>
                      Cutting Size*
                    </option>
                    {cutsizes &&
                      cutsizes.map((k, index) => {
                        return (
                          <option key={index} value={k} data-index={index}>
                            {k.cuttingSize}
                            {console.log(k)}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div
                  className="ms-2"
                  style={{ color: "#FF0000", fontSize: "11px" }}
                >
                  {ecutsize ? ecutsize : null}
                </div>
              </div>

              <div className="col-lg-1 mt-2">
                <h6 className="fs-12 fw-bold text-lg-end">Cut.Qty*</h6>
              </div>
              <div className="col-lg-2">
                <div className="input-group ">
                  <input
                    type="text"
                    className="form-control required"
                    name="quantity"
                    id="inputcolorr"
                    value={cutquantity}
                    onChange={(e) => setcuttingtqty(e)}
                    maxLength={10}
                    aria-label="quantity"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
                <div
                  className="ms-2"
                  style={{ color: "#FF0000", fontSize: "11px" }}
                >
                  {ecutquantity ? ecutquantity : null}
                </div>
              </div>
            </div> */}
            <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2 dashedline">
              <div className="col-lg-2">
                <h1 className="fs-14 fw-bold mt-1">PRINTING:</h1>
              </div>
              <div className="col-lg-2 mt-2">
                <h6 className="fs-12 fw-bold">Printing Mode*</h6>
              </div>
              <div className="col-lg-3 ms-lg-2">
                <div className="input-group">
                  <select
                    onChange={(e) => handlemode(e)}
                    class="form-select text-muted"
                    id="inputcolorr"
                    aria-label="Default select example"
                  >
                    <option selected hidden>
                      Mode of Printing*
                    </option>
                    {machines &&
                      machines.map((k, index) => {
                        return (
                          <option key={index} value={k} data-index={index}>
                            {k.details}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div
                  className="ms-2"
                  style={{ color: "#FF0000", fontSize: "11px" }}
                >
                  {emodeofprinting ? emodeofprinting : null}
                </div>
              </div>
              {/* <div className="col-lg-2 mt-2">
                <h6 className="fs-12 fw-bold text-lg-center">Printing Side*</h6>
              </div>
              <div className="col-lg-3">
                <div className="input-group pe-lg-2">
                  <select
                    onChange={(e) => handprintingside(e)}
                    class="form-select text-muted"
                    id="inputcolorr"
                    aria-label="Default select example"
                  >
                    <option selected hidden>
                      Printing Side
                    </option>
                    <option value="OS">OS</option>
                    <option value="BB">BB</option>
                    <option value="FB">FB</option>
                    <option value="OPPG">OPPG</option>
                  </select>
                </div>
                <div
                  className="ms-2"
                  style={{ color: "#FF0000", fontSize: "11px" }}
                >
                  {eprintingside ? eprintingside : null}
                </div>
              </div>

              <div className="col-lg-3">
                <div className="input-group mb-2 ms-lg-1"></div>
              </div> */}
            </div>
            <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2 dashedline">
              <div className="col-lg-2 ">
                <h1 className="fs-14 fw-bold mt-1">LAMINATION:</h1>
              </div>
              <div className="col-lg-4 col-sm-12 mb-2">
                <div className="input-group">
                  <select
                    onChange={(e) => selectlamination(e)}
                    class="form-select text-muted"
                    id="inputcolorr"
                    aria-label="Default select example"
                  >
                    <option selected hidden>
                      Lamination Type
                    </option>
                    {laminations &&
                      laminations.map((k, index) => {
                        return (
                          <option key={index} value={k} data-index={index}>
                            {k.details}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="col-lg-1 me-lg-1 ms-lg-1 mb-2">
                <div className="input-group">
                  <h6 className="fs-12 fw-bold mt-2 me-lg-1">L</h6>
                  &nbsp;
                  <input
                    type="length"
                    className="form-control required"
                    name="length"
                    value={lamlength}
                    onChange={(e) => setLamlength(e.target.value)}
                    id="inputcolorr"
                    aria-label="length"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
              </div>
              <div className="col-lg-1 me-lg-1">
                <div className="input-group">
                  <h6 className="fs-12 fw-bold mt-2 me-lg-1">B</h6>
                  &nbsp;
                  <input
                    type="breadth"
                    className="form-control required"
                    name="breadth"
                    value={lambreadth}
                    onChange={(e) => setLambreadth(e.target.value)}
                    id="inputcolorr"
                    aria-label="Breadth"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
              </div>
              <div className="col-lg-1 mt-2">
                <h6 className="fs-12 fw-bold text-lg-center ms-lg-2">Qty</h6>
              </div>
              <div className="col-lg-1">
                <div className="input-group">
                  <input
                    type="length"
                    className="form-control required"
                    name="length"
                    id="inputcolorr"
                    value={lamquantity}
                    onChange={(e) => setLamQuantity(e.target.value)}
                    aria-label="length"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
              </div>
              <div className="col-lg-1 mt-2">
                <h6 className="fs-12 fw-bold text-lg-center ms-lg-2">Rate</h6>
              </div>
              <div className="col-lg-1 pe-lg-2">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control required"
                    name="rate"
                    value={lamrate}
                    onChange={(e) => setLamRate(e.target.value)}
                    id="inputcolorr"
                    maxLength={7}
                    aria-label="rate"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2">
              <div className="col-lg-1">
                <h1 className="fs-14 fw-bold mt-1">PUNCHING:</h1>
              </div>
              <div className="col-lg-2 mt-2">
                <h6 className="fs-12 fw-bold "></h6>
              </div>
              <div className="col-lg-2 me-lg-2">
                <div className="input-group"></div>
              </div>

              <div className="col-lg-1 ms-lg-2">
                <div className="input-group"></div>
              </div>
              <div className="col-lg-3 mt-2">
                <h6 className="fs-12 fw-bold text-lg-center">Die Amount</h6>
              </div>
              <div className="col-lg-3">
                <div className="input-group pe-lg-3">
                  <input
                    type="text"
                    className="form-control required"
                    name="breadth"
                    value={dieamount}
                    onChange={(e) => setDieAmount(e.target.value)}
                    id="inputcolorr"
                    maxLength={7}
                    aria-label="Breadth"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2 dashedline">
              <div className="col-lg-2">
                <h1 className="fs-14 fw-bold mt-1"></h1>
              </div>
              <div className="col-lg-2 mt-2">
                <h6 className="fs-12 fw-bold">Punching Type</h6>
              </div>
              <div className="col-lg-2 me-lg-2">
                <div className="input-group">
                  <select
                    onChange={(e) => selpunchingtype(e)}
                    class="form-select text-muted"
                    id="inputcolorr"
                    aria-label="Default select example"
                  >
                    <option selected hidden>
                      Punching Type
                    </option>
                    {punchingTypes && punchingTypes.length > 0
                      ? punchingTypes.map((k, index) => {
                          return (
                            <option key={index} value={k} data-index={index}>
                              {k.type}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </div>
              </div>
              <div className="col-lg-1 mt-2">
                <h6 className="fs-12 fw-bold text-lg-center ms-lg-2">Qty</h6>
              </div>
              <div className="col-lg-2">
                <div className="input-group">
                  <input
                    type="length"
                    className="form-control required"
                    name="length"
                    id="inputcolorr"
                    value={punchQuantity}
                    onChange={(e) => setPunchQuantity(e.target.value)}
                    aria-label="length"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
              </div>
              <div className="col-lg-1 mt-2">
                <h6 className="fs-12 fw-bold text-lg-center ms-lg-2">Rate</h6>
              </div>
              <div className="col-lg-2">
                <div className="input-group pe-lg-1">
                  <input
                    type="breadth"
                    className="form-control required"
                    name="breadth"
                    value={punchRate}
                    onChange={(e) => setPunchRate(e.target.value)}
                    id="inputcolorr"
                    maxLength={7}
                    aria-label="Breadth"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2 dashedline">
              <div className="col-lg-2">
                <h1 className="fs-14 fw-bold mt-1">PASTING:</h1>
              </div>
              <div className="col-lg-2 mt-2">
                <h6 className="fs-12 fw-bold ">Pasting Type</h6>
              </div>
              <div className="col-lg-2 me-lg-1">
                <div className="input-group">
                  <select
                    onChange={(e) => selpasting(e)}
                    class="form-select text-muted"
                    id="inputcolorr"
                    aria-label="Default select example"
                  >
                    <option selected hidden>
                      Pasting Type*
                    </option>
                    {pastingtypes && pastingtypes.length > 0
                      ? pastingtypes.map((k, index) => {
                          return (
                            <option key={index} value={k} data-index={index}>
                              {k.type}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </div>
              </div>
              <div className="col-lg-1 mt-2">
                <h6 className="fs-12 fw-bold text-lg-center ms-lg-2">Qty</h6>
              </div>
              <div className="col-lg-2 ">
                <div className="input-group">
                  <input
                    type="Quantity"
                    className="form-control required"
                    name="Quantity"
                    value={pastquantity}
                    onChange={(e) => setPastquantity(e.target.value)}
                    id="inputcolorr"
                    aria-label="Quantity"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
              </div>
              <div className="col-lg-1 mt-2">
                <h6 className="fs-12 fw-bold text-lg-center ms-lg-2">Rate</h6>
              </div>
              <div className="col-lg-2">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control required"
                    name="rate"
                    value={pastRate}
                    onChange={(e) => setPastRate(e.target.value)}
                    id="inputcolorr"
                    maxLength={7}
                    aria-label="rate"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2 dashedline">
              <div className="col-lg-2">
                <h1 className="fs-14 fw-bold mt-1">FINISHING:</h1>
              </div>
              <div className="col-lg-2 mt-2">
              <h6 className="fs-12 fw-bold ">Finishing Type</h6>
              </div>
              <div className="col-lg-2 me-lg-1">
                <div className="input-group">
                  <select
                    value={finishingtype}
                    onChange={finishevent}
                    class="form-select text-muted"
                    id="inputcolorr"
                    aria-label="Default select example"
                  >
                    <option selected hidden>
                      Finishing
                    </option>
                    <option value="Pinning">Pinning</option>
                    <option value="Rimming">Rimming</option>
                    <option value="Binding">Binding</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-1 mt-2">
                <h6 className="fs-12 fw-bold text-lg-center ms-lg-2">Qty</h6>
              </div>
              <div className="col-lg-2 ">
                <div className="input-group">
                  <input
                    type="length"
                    className="form-control required"
                    name="length"
                    id="inputcolorr"
                    value={finishingQuantity}
                    onChange={(e) => setFinishingQuantity(e.target.value)}
                    aria-label="length"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
              </div>
              <div className="col-lg-1 mt-2">
                <h6 className="fs-12 fw-bold text-lg-center ms-lg-2">Rate</h6>
              </div>
              <div className="col-lg-2">
                <div className="input-group">
                  <input
                    type="breadth"
                    className="form-control required"
                    name="breadth"
                    value={finishingRate}
                    onChange={(e) => setFinishingRate(e.target.value)}
                    id="inputcolorr"
                    maxLength={7}
                    aria-label="Breadth"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2 dashedline">
              <div className="col-lg-2">
                <h1 className="fs-14 fw-bold mt-1">OTHERS:</h1>
              </div>
              <div className="col-lg-7 me-lg-1">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control required"
                    name="breadth"
                    value={others}
                    onChange={(e) => setOthers(e.target.value)}
                    id="inputcolorr"
                    aria-label="Breadth"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
              </div>

              <div className="col-lg-1 mt-2">
                <h6 className="fs-12 fw-bold text-lg-center">Amount</h6>
              </div>
              <div className="col-lg-2">
                <div className="input-group pe-lg-1">
                  <input
                    type="text"
                    className="form-control required"
                    name="breadth"
                    maxLength={7}
                    value={othersamount}
                    onChange={(e) => setOtherAmount(e.target.value)}
                    id="inputcolorr"
                    aria-label="Breadth"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2">
              <div className="col-lg-2">
                <h1 className="fs-14 fw-bold mt-1">MARKUP:</h1>
              </div>

              <div className="col-lg-3 me-lg-1">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control required"
                    name="name"
                    id="inputcolorr"
                    maxLength={7}
                    value={markupPercentage}
                    onChange={(e) => setMarkupPercentage(e.target.value)}
                    aria-label="name"
                    aria-describedby="basic-addon1"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2">
              <div className="col-lg-2">
                <h1 className="fs-14 fw-bold mt-1">JOB DESCRIPTION:</h1>
              </div>

              <div className="col-lg-10 col-sm-6 sm- me-lg-1">
                <div className="input-group">
                  {getjobdet()}
                  <div className="col-lg-8"></div>
                  <div className="col-lg-4" style={{ textAlign: "right" }}>
                    <Button
                      onClick={() => submit()}
                      id="pillStyle"
                      className="w-100 mb-2"
                      variant="primary"
                    >
                      Calculate
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <div className=" d-none d-sm-block">
          <div className="vr" style={{ height: "950px" }}></div>
        </div>
        <hr></hr>
        <Col lg={4} sm={12} className=" ps-lg-3">
          <div className="d-flex mb-4">
            <img
              src={aretsLogo}
              alt="branding"
              className="loginPage-brandImg"
              onClick={() => window.location.reload()}
            />
            <h3
              style={{
                fontSize: "25px",
                fontFamily: "Yeseva One",
                fontWeight: "400",
              }}
            >
              AReTS Graphics
              <p
                style={{
                  fontFamily: "Clash Display",
                  fontSize: "18px",
                }}
              >
                Everything in Printing
              </p>
            </h3>
          </div>
          <h6>
            <small>15-4-64, Gowliguda Chaman, Hyderabad 500012</small>
          </h6>
          <h6>
            <small>Phone: 040-9393009990</small>
          </h6>
          <h6>
            <small>GSTIN: 36AEYPM2529L1ZR</small>
          </h6>
          <h5 className="mt-2 mb-2">Client Details</h5>
          <h6>
            <small>
              Firm Name: {cname},{faddress}
              {address}
              {pincode}
            </small>
          </h6>
          <h6>
            <small>Phone: {phone}</small>
          </h6>
          <h6>
            <small>GSTIN: {gst}</small>
          </h6>
          <h4 className="mt-2 mb-4">Cost Estimate</h4>
          <div className="d-flex mt-2">
            <div className="col-lg-8 col-8 text-muted">Designing</div>
            <div className="col-lg-3 text-end">
              ₹{" "}
              {estimatecalc
                ? parseFloat(estimatecalc.designingAmount.toFixed(2))
                : 0}
            </div>
          </div>
          {/* <div className="d-flex mt-2">
            <div className="col-lg-8 col-8 text-muted">Paper</div>
            <div className="col-lg-3 text-end ">
              ₹{" "}
              {estimatecalc
                ? parseFloat(estimatecalc.paperAmount.toFixed(2))
                : 0}
            </div>
          </div> */}
          {/* <div className="d-flex mt-2">
            <div className="col-lg-8 col-8 text-muted">Printing</div>
            <div className="col-lg-3 text-end ">
              ₹{" "}
              {estimatecalc
                ? parseFloat(estimatecalc.PrintAmount.toFixed(2))
                : 0}
            </div>
          </div> */}
          <div className="d-flex mt-2">
            <div className="col-lg-8 col-8 text-muted">Lamination</div>
            <div className="col-lg-3 text-end">
              ₹{" "}
              {estimatecalc && estimatecalc.laminationAmount
    ? estimatecalc.laminationAmount < 300
      ? 300
      : parseFloat(estimatecalc.laminationAmount.toFixed(2))
    : 0}
            </div>
          </div>
          <div className="d-flex mt-2">
            <div className="col-lg-8 col-8 text-muted">Die</div>
            <div className="col-lg-3 text-end">
              ₹{" "}
              {estimatecalc ? parseFloat(estimatecalc.dieAmount.toFixed(2)) : 0}
            </div>
          </div>
          <div className="d-flex mt-2">
            <div className="col-lg-8 col-8 text-muted">Punching</div>
            <div className="col-lg-3 text-end">
              ₹{" "}
              {estimatecalc
                ? parseFloat(estimatecalc.punchingAmount.toFixed(2))
                : 0}
            </div>
          </div>
          <div className="d-flex mt-2">
            <div className="col-lg-8 col-8 text-muted">Pasting</div>
            <div className="col-lg-3 text-end">
              ₹{" "}
              {estimatecalc
                ? parseFloat(estimatecalc.pastingAmount.toFixed(2))
                : 0}
            </div>
          </div>
          <div className="d-flex mt-2">
            <div className="col-lg-8 col-8 text-muted">Digital</div>
            <div className="col-lg-3 text-end">
              ₹{" "}
              {estimatecalc
                ? parseFloat(estimatecalc.digitalAmount.toFixed(2))
                : 0}
            </div>
          </div>
          {!isBinding && !isPinning && isRimming && (
            <div className="d-flex mt-2">
              <div className="col-lg-8 col-8 text-muted">Rimming</div>
              <div className="col-lg-3 text-end">
                ₹{" "}
                {estimatecalc
                  ? parseFloat(estimatecalc.rimmingAmount.toFixed(2))
                  : 0}
              </div>
            </div>
          )}

          {isBinding && !isPinning && !isRimming && (
            <div className="d-flex mt-2">
              <div className="col-lg-8 col-8 text-muted">Binding</div>
              <div className="col-lg-3 text-end">
                ₹{" "}
                {estimatecalc
                  ? parseFloat(estimatecalc.bindingAmount.toFixed(2))
                  : 0}
              </div>
            </div>
          )}

          {!isBinding && isPinning && !isRimming && (
            <div className="d-flex mt-2">
              <div className="col-lg-8 col-8 text-muted">Pinning</div>
              <div className="col-lg-3 text-end">
                ₹{" "}
                {estimatecalc
                  ? parseFloat(estimatecalc.pinningAmount.toFixed(2))
                  : 0}
              </div>
            </div>
          )}

          <div className="d-flex mt-2">
            <div className="col-lg-8 col-8 text-muted">
              {others.length === 0 ? "others" : others}
            </div>
            <div className="col-lg-3 text-end">
              ₹{" "}
              {estimatecalc
                ? parseFloat(estimatecalc.othersamount.toFixed(2))
                : 0}
            </div>
          </div>
          <div className="d-flex mt-2">
            <div className="col-lg-8 col-8 text-muted">Sub Total</div>
            <div className="col-lg-3 text-end">
              ₹ {estimatecalc ? parseInt(estimatecalc.totalAmount) : 0}
            </div>
          </div>
          <div className="d-flex mt-2">
            <div className="col-lg-8 col-8 text-muted">Misc %</div>
            <div className="col-lg-3 text-end">
              ₹ {estimatecalc ? parseInt(estimatecalc.miscAmount) : 0}
            </div>
          </div>
          {/* <div className="d-flex mt-2">
            <div className="col-lg-8 col-8 text-muted">Gst</div>
            <div className="col-lg-3 text-end">
              ₹ {estimatecalc ? parseInt(estimatecalc.gstamount) : 0}
            </div>
          </div> */}

          <div className="d-flex mt-2">
            <div className="col-lg-8 col-8 text-muted">Total</div>

            <div className="col-lg-3 text-end">
              ₹ {estimatecalc ? parseInt(estimatecalc.sum_total) : 0}
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-8 text-muted">
              <span style={{ fontSize: "11px" }}>* 18% GST Extra</span>
            </div>
          </div>

          <div className="form-group col-lg-12 col-md-12 col-12">
            <div className="d-flex gap-2">
              <div className="col-lg-6">
                <div className="input-group">
                  <Button
                    as={NavLink}
                    to="/estimates"
                    id="pillStyle"
                    className="w-100"
                    variant="outline-primary"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-group">
                  {!isLoading ? (
                    <Button
                      onClick={() => handlesubmit()}
                      id="pillStyle"
                      className="w-100"
                      variant="primary"
                    >
                      {sbtndis ? "Processing.." : "Create Estimate"}
                    </Button>
                  ) : (
                    <button className="buttonload w-100" id="pillStyle">
                          <i className="fa fa-spinner fa-spin"></i> Loading
                        </button>
                  )}
                </div>
              </div>
              {calculated === false && (
                <div
                  style={{
                    fontSize: "12px",
                    color: "red",
                    paddingTop: "1rem",
                  }}
                >
                  Please Calculate the estimate first
                </div>
              )}
            </div>
          </div>
        </Col>
      </CardContent>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Create New Client
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Card className=" rounded-4">
            <CardContent>
              <div className="row ">
                <div className="form-group col-lg-12 col-md-12 col-12">
                  <div className="row">
                    <div className="form-group col-lg-12 col-md-12 col-12">
                      <div className="input-group mb-2">
                        <input
                          type="text"
                          className="form-control text-muted required"
                          name="name"
                          value={newcname}
                          onChange={(e) => setNewCName(e.target.value)}
                          id="inputcolorr"
                          placeholder="Firm Name*"
                          aria-label="name"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group col-lg-12 col-md-12 col-12">
                      <div className="input-group mb-2">
                        <input
                          type="text"
                          className="form-control text-muted required"
                          name="name"
                          value={fname}
                          onChange={(e) => setFname(e.target.value)}
                          id="inputcolorr"
                          placeholder="Client Name"
                          aria-label="name"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group col-lg-12 col-md-12 col-12">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="input-group mb-2">
                            <input
                              type="text"
                              className="form-control required"
                              name="phone"
                              value={newphone}
                              onChange={(e) => handleNewPhone(e)}
                              id="inputcolorr"
                              placeholder="Mobile Number*"
                              aria-label="phone"
                              maxLength={10}
                              aria-describedby="basic-addon1"
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-lg-12 col-md-12 col-12">
                      <div className="input-group mb-2">
                        <input
                          type="text"
                          className="form-control text-muted required"
                          name="Area"
                          value={city}
                          id="inputcolorr"
                          placeholder="Area*"
                          onChange={(e) => setCity(e.target.value)}
                          aria-label="Area"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group col-lg-12 col-md-12 col-12">
                      <div className="input-group mb-2">
                        <input
                          type="text"
                          className="form-control text-muted required"
                          name="name"
                          value={gstNo}
                          id="inputcolorr"
                          onChange={(e) => setGstNo(e.target.value)}
                          placeholder="GST Number"
                          aria-label="name"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-6">
                      <Button
                        onClick={() => modalreset()}
                        value="modalreset"
                        id="pillStyle"
                        className="w-100"
                        variant="outline-primary"
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="col-lg-6">
                      {!isLoading ? (
                        <Button
                          onClick={() => createclient()}
                          id="pillStyle"
                          className="w-100"
                          variant="primary"
                        >
                          Create Client
                        </Button>
                      ) : (
                        <button className="buttonload w-100" id="pillStyle">
                          <i className="fa fa-spinner fa-spin"></i> Loading
                        </button>
                      )}

                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log(state, "state");
  return {
    userdata: state.userdetails,
  };
};

export default connect(mapStateToProps)(DigitalEstimate);
