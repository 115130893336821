import React, { useState, useEffect, useRef } from "react";
import "../../Styles/JobCards.css";
import Header from "../header/Header";
import Sidebar from "../Sidenav/Sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import filterIcon from "../../Images/filterIcon.png";
import shareIcon from "../../Images/shareIcon.png";
import calenderIcon from "../../Images/calenderIcon.png";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Table from "react-bootstrap/Table";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import sortIcon from "../../Images/sortIcon.png";
import eyeIcon from "../../Images/eyeIcon.svg";
import Card from "@mui/material/Card";
import Delivery from "../Delivery/Delivery";
// import CardActions from '@mui/material/CardActions';
import CardContent from "@mui/material/CardContent";
import SalesIcon from "../../Images/SalesIcon.png";
import DashOrdersIcon from "../../Images/DashOrdersIcon.png";
import TablePagination from "@mui/material/TablePagination";
import Modal from "react-bootstrap/Modal";
import TrashIcon from "../../Images/TrashIcon.svg";
import ReceiptService, {
  getreceipts,
  addreceipts,
} from "../../services/ReceiptService";
import axios from "axios";
import Moment from "moment";
import { toast } from "react-toastify";
import ClientsService from "../../services/ClientsService";
import Multiselect from "multiselect-react-dropdown";
import get_userdetails from "../../redux/actions/getuserDetailsAction";
import JobcardsService from "../../services/JobcardsService";
import validator from "validator";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import authService from "../../services/authService";
import estimateService from "../../services/estimateService";
import { TextField, MenuItem } from "@mui/material";
import { connect } from 'react-redux';


const CashReceipt = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const [page, setPage] = React.useState(0);
  const [search, setSearch] = React.useState('');
  const [invoice,setInvoice]=React.useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [recieptcount, setReceiptCount] = useState(0);
  const [fromAmount, setFromAmount] = useState("");
  const [filtstatus, setFiltStatus] = useState("");
  const [toAmount, setToAmount] = useState("");
  const [fromdate, setFromDate] = useState("");
  const [todate, setToDate] = useState("");
  const [datafilter, setDataFilter] = useState(false);
  const [datefilter, setDateFilter] = useState(false);
  const [datetab, setDateTab] = useState("From");
  const [users, setUsers] = useState([]);
  const [uuserID, setUuserID] = useState([]);
  const [user, setUser] = useState({});
  const [userid, setuseriD] = useState('');
  const [username, setUserName] = useState('');
  const [uusername, setUUserName] = useState('');
  const [userID, setUserID] = useState('');

  // const [subtotal, setSubTotal] = useState("");
  const location = useLocation();
  //SearchAPI Close

  //add cash receipt Integration
  const navigate = useNavigate();
  const [cname, setCname] = useState("");
  const [Cid, setCid] = useState("");
  const [email, setEmail] = useState("");
  const [phonecode, setPhoneCode] = useState("");
  const [phone, setPhone] = useState("");
  const [paytype, setPayType] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [transid, setTransID] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [invoiceid, setInvoiceID] = useState("");
  const [orderNo, setOrderNo] = useState("");
  const [orderId, setOrderId] = useState("");
  const [orId, setorid] = useState("");
  const [jname, setJname] = useState("");
  const [sumtotal, setSumTotal] = useState("");
  const [paidAmount, setPaidAmount] = useState("");
  const [pendingAmount, setPendingAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [subTotalAmount, setSubTotalAmount] = useState("");
  const [sbtndis, setSBtndis] = useState(false);
  const [estimatecalc, setEstimatecalc] = useState();
  const [inarr, setinarr] = useState([]);
  const [calarr, setcalarr] = useState([]);
  const [addbutton, setAddButton] = useState(false);
  const [pend, setpendAmount] = useState([]);
  const [toamount, setTotAmount] = useState('');
  const [stotal,setSubTotal]= useState('');
  const [amount, setAmount] = useState(0);

 

  let totpaidamount = 0;
  let subtotal = 0;
  let sum_total = 0;
  let pendamount = 0;
  

  // const handleChangePage = (event, newPage) => {
  //     setPage(newPage);
  // };
  const handleChangePage = (event, newPage) => {
    const dett = newPage * rowsPerPage;
    setPage(newPage);
    getreceipts(dett, rowsPerPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getreceipts(0, event.target.value);
  };
  const [modalShow, setModalShow] = React.useState(false);

  const [receipts, setreceipts] = useState([]);
  useEffect(() => {
    getreceipts(0, 10);
    getUsers();
  }, []);

  const resetorder = async (skps, rpp) => {
    try {
      const obj = {
        skip: skps,
        limit: rpp,
        searchkeys: '',
       
      };
      const dert = await ReceiptService.getreceipts(obj);
      if (dert.data.status === "success") {
        console.log(dert.data.value, "values");
        setreceipts(dert.data.value);
        setReceiptCount(dert.data.count);
      }
    } catch (ex) {}
  };
  const getUsers = async () => {
    try {
      const dry = await authService.getusers();
      if (dry.data.status === "success") {
        console.log(dry.data.value, 'userdata')
        setUsers(dry.data.value);
      }
    } catch (ex) {}
  };
  const getreceipts = async (skps, rpp) => {
    try {
      const obj = {
        skip: skps,
        limit: rpp,
       searchkeys: { customerName: searchtext },
      };
      const dert = await ReceiptService.getreceipts(obj);
      if (dert.data.status === "success") {
        console.log(dert.data.value, "values");
        setreceipts(dert.data.value);
        setReceiptCount(dert.data.count);
      }
    } catch (ex) {}
  };

  // download Excel

  const [isDownloading, setIsDownloading] = useState(false);

  // const handleDownloadExcel = async () => {
  //   try {
  //     setIsDownloading(true);
  //     const response = await axios.post(
  //       "/cashReceipts/jsontoexcel",
  //       { data: [] },
  //       { responseType: "blob" }
  //     );
  //     const excelBlob = new Blob([response.data], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });
  //     const url = window.URL.createObjectURL(excelBlob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = "Receipts.xlsx";
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setIsDownloading(false);
  //   }
  // };

  const handleDownloadExcel = async () => {
    try {
      setIsDownloading(true);
      const requestData = {
        searchkeys: { customerName: searchtext }, 
      };
      const response = await axios.post(
        "/cashReceipts/jsontoexcel",
        requestData,
        { responseType: "blob" }
      );
      const excelBlob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(excelBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Receipts.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    } finally {
      setIsDownloading(false);
    }
  };

  //  Download Excel Close

  //get clients integration
  const [client, setClient] = useState([]);
  const [clients, setClients] = useState([]);
  const [customerId, setCustomerId] = useState("");
  const [estmid, setEstmid] = useState("");
  const [orders, setOrders] = useState([]);
  // const [user, setUserdata] = useState({});
  const multiselectRef = useRef();

  useEffect(() => {
    getuserdetails();
    getclients();
  }, [props.userdata]);
  useEffect(() => {
    get_userdetails();
  }, []);

  const getuserdetails = async () => {
    setUser(props.userdata.user);
  };
  const selectemps = async (e) => {
    const selindex = e.target.selectedOptions[0].dataset.index;
    const selclient = clients[selindex];
    setCname(selclient.name);
    setPhone(selclient.phoneNo);
    setCustomerId(selclient._id);
    setCid(selclient._id);
    setEmail(selclient.email);
    setGstNo(selclient.gstNo);
    setTotAmount(selclient.toamount);
    setTotalAmount("");
    console.log(selclient, "selclient");
    try {
        const det = await JobcardsService.getordersById(selclient._id);
        if (det.data.status === "success") {
            setOrders(det.data.value);
        }
    } catch (ex) {
        console.error("Error loading orders:", ex);
    }
};


  const getclients = async () => {
    try {
      const dert = await ClientsService.getclients();
      if (dert.data.status === "success") {
        console.log(dert.data.value, "values");
        setClients(dert.data.value);
      }
    } catch (ex) {}
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      serachclick();
     
    }
  };
  //get clients integration close
  //SearchAPI
  const [searchtext, setSearchText] = useState("");


  const searchChange = (value) => {
    setSearchText(value);
    // getestimates(0,10)
  };
  const serachclick = async () => {
    // getestimates(0, 10)
    const trimmedSearchText = searchtext.trim(); 
    if (trimmedSearchText === "") {
      toast.error("Enter Text", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
   
    }
    else{
    try {
      const obj = {
        skip: 0,
        limit: rowsPerPage,
        searchkeys: { customerName: trimmedSearchText }, 
      };
      const dert = await ReceiptService.getreceipts(obj);
      console.log(dert, "dert");
      if (dert.data.status === "success") {
        setSearch(true);
        setInvoice(true);
        console.log(dert.data.value, "values");
        setreceipts(dert.data.value);
        setReceiptCount(dert.data.count);
      }
    } catch (ex) { }
  }
  };
  const resetclick = () => {
    setSearchText("");
      setSearch(false)
      resetorder(0,rowsPerPage);
  }

  const delclick =() => {
    setInvoice(false);
    setOrderId("");
    setPaidAmount("");
    setTotalAmount("");
    setPendingAmount("");
    setSubTotal("")
    setcalarr([]);
  }

  
  
  const create = async () => {
    // setIsLoading(true)
    if (!cname) {
      toast.error("Select Firm Name", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!phone) {
      toast.error("Enter Phone", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!paytype) {
      toast.error("Enter Payment Type", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }  else if (!calarr.length > 0) {
      toast.error("Add Invoice", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      try {
        setIsLoading(true);
        const obj = {
          customerName: cname,
          customerId: Cid,
          email: email,
          phone: phone,
          gstNo: gstNo,
          orderList: calarr,
          subTotalAmount: 0,
          pendingAmount: pendingAmount,
          paidAmount: totpaidamount,
          totalAmount: totalAmount,
          authorizedBy: username,
          paymentType: paytype
        };

        const dert = await ReceiptService.addreceipts(obj);
        if (dert.data.status === "success") {
          toast.success("Cash Receipt Added Successfully", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          navigate("/receipt");
          closemodal();
          getreceipts();
        } else {
        }
      } catch (ex) {}
      finally{
        setIsLoading(false)
      }
    }
  };
  useEffect(() => {
    getclients();
  }, []);
  const closemodal = () => {
    setClient([]);
    setCname("");
    setCid("");
    setGstNo("");
    setEmail("");
    setPhone("");
    setcalarr([]);
    setinarr([]);
    setInvoice(false)
    setPayType("");
    setTransID("");
    handlepaidamount("");
    setTotAmount("");
    setPendingAmount("");
    setTotalAmount('');
    setModalShow(false);
    setOrderId('')
    // setIsLoading(false);
  };

  const paymentOptions = [
    { value: 'CASH', label: 'CASH' },
    { value: 'CHEQUE', label: 'CHEQUE' },
    { value: 'PHONEPE', label: 'UPI' },
    { value: 'BANKTRANSFER', label: 'BANK TRANSFER' },
  ];
  const submit = async () => {
    if (!cname) {
      await setSBtndis(false);
      toast.error("Select Firm Name", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!phone) {
      await setSBtndis(false);
      toast.error("Enter Mobile Number", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
   
    } else if (phone.length < 10 && phone.length > 10) {
      await setSBtndis(false);
      toast.error("Invalid Phone", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      // } else if (!email) {
      //     await setSBtndis(false)
      //     toast.error('Enter Email', {
      //         position: toast.POSITION.BOTTOM_LEFT
      //     });
      // } else if (!gstNo) {
      //     await setSBtndis(false)
      //     toast.error('Enter GST Number', {
      //         position: toast.POSITION.BOTTOM_LEFT
      //     });
   
    } else if (!date) {
      await setSBtndis(false);
      toast.error("Select Date", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!time) {
      await setSBtndis(false);
      toast.error("Select Time", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }  else if (!paidAmount) {
      await setSBtndis(false);
      toast.error("Enter Received Amount", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } 
    
    //   if  (pendingAmount<=0)  {
    //     debugger;
        
    //       await setSBtndis(false);
    //       toast.error("Invalid Amount", {
    //         position: toast.POSITION.BOTTOM_LEFT,
    //       });
        
     
    // } 
    // else if(pendingAmount > 0){
    //   await setSBtndis(true);
    // }
    else {
      try {
      } catch (ex) {}
    }
  };
  const selectorder = (e) => {
    console.log("Selected Order ID:", e.target.value);
    const selectedOrder = orders.find((order) => order.orderId === e.target.value);
    console.log("Selected Order:", selectedOrder);
    if (selectedOrder) {
        setOrderId(selectedOrder.orderId);
        setorid(selectedOrder._id);
        setJname(selectedOrder.name);
        setTotalAmount(selectedOrder.pendingAmount);
        setEstmid(selectedOrder.estimateId._id);
        setTotAmount(selectedOrder.toamount);
    }
};


  
  const handlepaidamount = (value) => {
    setPaidAmount(value);
  };

  let pamount = pendingAmount + totalAmount - paidAmount;

  const calinvoice = () => {
    if (!cname) {
      toast.error("Select Firm Name", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!phone) {
      toast.error("Enter Phone", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!paytype) {
      toast.error("Select Payment Type", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }  else if (!paidAmount) {
      toast.error("Enter Amount", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
   
    } else {
   // alert(totalAmount + '' + paidAmount+ '' + pendingAmount);
    setPendingAmount(pamount);
    if (!calarr.includes(orderId)) {
      let arr = [...calarr];
      const obj = {
        orderId: orderId,
        sum_total: totalAmount,
        paidAmount: paidAmount,
        pendingAmount: pamount
      };
      if  (pamount < 0)  {
         toast.error("Invalid Amount", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setcalarr('')
    }
    else{
      arr.push(obj);
      setcalarr(arr);
      setInvoice(true);

    }
  }
}
  };
  const addinvoice = () => {
    if (!cname) {
      toast.error("Select Firm Name", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    
    } else if (!phone) {
      toast.error("Enter Phone", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!paytype) {
      toast.error("Enter Payment Type", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }  else if  (pamount < 0)  {
      toast.error("Invalid Amount", {
         position: toast.POSITION.BOTTOM_LEFT,
       });
       setcalarr('')

    // } else if  (!inarr.includes(orderId))  {
    //   toast.error("Order ID already Selected", {
    //      position: toast.POSITION.BOTTOM_LEFT,
    //    });
      } else {
    if (!inarr.includes()) {
      let arr = [...inarr];
      console.log(arr, 'arrrrrr');
      const obj = {
        orderId: orderId,
        sum_total: totalAmount,
        paidAmount: paidAmount,
        pendingAmount: totalAmount-paidAmount
      };
      arr.push(obj);
      setinarr(arr);
      // setOrderId("");
      setPaidAmount("");
      setTotalAmount("");
      setInvoice(false)
    }
  }
  };
 
  const delinvoice_old = (item, index) => {
    let ar = [];     
    const delitem = inarr.map((k, ind) => {
      if (index !== ind) {
        ar.push(k);
      }
    });
    setinarr(ar);
  
  };

  const delinvoice = (ite, index) => {

    
    let cal_ar = calarr.filter((cal, calid) => index !== calid);
    let ina_ar = inarr.filter((ina, inaid) => index !== inaid);
    let pendi = pend.filter((pe, peid) => index !== peid);
    setcalarr(cal_ar);
    setinarr(ina_ar);
    setpendAmount(pendi);
   getpendingamount("")
  //  setPendingAmount("")
  };

  const getsum_total = (item) => {
    item.orderList.forEach((it) => {
      sum_total += parseFloat(it.sum_total)  
    })
    return sum_total.toFixed(2);
  }
  
  const getsubtotal = () => {
    // if (inarr && inarr.includes(orderId)){
    //   calarr.forEach((item) => {
    //     subtotal = item.totalAmount - item.paidAmount; 
    //   });
    // }
  //  else 
   if (calarr && calarr.length > 0) {
      // const ret = calarr.reduce((a,b) =>{
      //     subtotal = a.sum_total + b.sum_total
      // })
      calarr.forEach((item) => {
        subtotal = item.sum_total; 
      });
      return <div className="col-lg-4 text-success">₹ {subtotal}</div>;
    }
  };

  const getpaidamount = () => {
    if (calarr && calarr.length > 0) {
      // const ret = calarr.reduce((a,b) =>{
      //     totpaidamount = parseInt(a.paidAmount) + parseInt(b.paidAmount)
      // })
      calarr.forEach((item) => {
        totpaidamount += parseInt(item.paidAmount)
      });
      

      return <div className="col-lg-4 text-success">₹ {totpaidamount}</div>;
    }
  
  };
  const getpendingamount = () => {
   //alert("test" +totalAmount+"-"+totpaidamount)
  //  pendamount = parseInt(totalAmount) - parseInt(totpaidamount);
   // alert(pendingAmount);
  
    // };
    
    
    return <div className="col-lg-4 text-danger">₹ {pendingAmount.toFixed(2)}</div>;
  };

  //add cash receipt Integration close

  // const [emailError, setEmailError] = useState("");
  // const validateEmail = (e) => {
  //   var email = e.target.value;

  //   if (validator.isEmail(email)) {
  //     setEmailError("");
  //   } else {
  //     setEmailError("Enter valid Email!");
  //   }
  // };
  const selectuser = (e) => {
    const selindex = e.target.selectedOptions[0].dataset.index;
    const seluser = users[selindex];
    console.log(seluser, "seluser");
    setUserID(seluser.username);
    setUser(seluser.userId);
    setuseriD(seluser._id);
  };

  const viewreceipt = (item) => {
    console.log("item==", item);
    setreceipts(item);
    // setModalShow(true)
    navigate("/printreceipt", { state: { item: item } });
  };
  
  console.log(receipts, "receipts");
  
  
  return (
    <>
      <Header />
      <Container fluid className="containerr">
        <Row>
          <Col lg={2} id="sidebar-wrapper" className="d-none d-md-block">
            <Sidebar />
          </Col>
          <Col lg={10} sm={8} className="containerr px-3 px-lg-4">
            <div className="pt-4 pb-2 d-flex justify-content-between align-items-center">
              <div>
                <h1 className="fs-3">Receipts</h1>
                <nav aria-label="breadcrumb" className="fs-14">
                  <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item">
                      <a href="/dashboard">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Receipts
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="pt-sm-4">
                <Button
                  onClick={() => setModalShow(true)}
                  id="pillStyle"
                  className="btnnew"
                  variant="primary"
                >
                  Create New Cash Receipt
                </Button>
              </div>
            </div>
            <Row className="d-flex flex-column flex-lg-row align-align-items-lg-center gap-4 gap-lg-0 ">
              <Col lg={5}>
                <Card className="tabcenter  rounded-4">
                  <CardContent className="d-flex flex-column gap-4">
                    <div className="d-flex flex-column flex-lg-row gap-3 ">
                      <div className="col-lg-8 col-md-9">
                        <img className="dashCardIcon1 " src={DashOrdersIcon} />
                      </div>
                      <div className="col-lg-4 col-md-3 ">
                        <div className="input-group">
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            className="small border-0 text-muted"
                          >
                            <option selected>All Time</option>
                            <option value="This Week">This Week</option>
                            <option value="This Month">This Month</option>
                            <option value="This Year">This Year</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* <div className='row mt-4'></div> */}

                    <div className="d-flex flex-column flex-lg-row justify-content-between align-align-items-lg-center gap-2 gap-lg-0">
                      <div className="col ">
                        <div className="col-lg-9 col-md-9 text-muted">
                          All Orders
                        </div>
                        <div className="col-lg-3 col-md-3">450</div>
                      </div>
                      <div className="col">
                        <div className="col-lg-9 col-md-9 text-muted">
                          Pending
                        </div>
                        <div className="col-lg-3 col-md-3">130</div>
                      </div>
                      <div className="col">
                        <div className="col-lg-9 col-md-9 text-muted">
                          Completed
                        </div>
                        <div className="col-lg-3 col-md-3">320</div>
                      </div>
                      <div className="col">
                        <div className="col-lg-9 col-md-9 text-muted">
                          Cancelled
                        </div>
                        <div className="col-lg-3 col-md-3 d-flex align-items-end">
                          {" "}
                          30<span className="text-danger ms-1 fs-11">-20%</span>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Col>
              <Col lg={7}>
                <Card className="  rounded-4">
                  <CardContent className="d-flex flex-column gap-3">
                    <div className="d-flex flex-column flex-lg-row gap-3">
                      <div className="col-lg-8 col-md-9">
                        <img className="dashCardIcon" src={SalesIcon} />
                      </div>
                      <div className="col-lg-4 col-md-3">
                        <div className="input-group ">
                          <select
                            class="form-select "
                            aria-label="Default select example"
                            className="small border-0 text-muted"
                          >
                            <option selected>All Time</option>
                            <option value="This Week">This Week</option>
                            <option value="This Month">This Month</option>
                            <option value="This Year">This Year</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* <div className='row mt-4'></div> */}

                    <div className="d-flex flex-column flex-lg-row justify-content-between align-align-items-lg-center gap-2 gap-lg-0">
                      <div className="col ">
                        <div className="col-lg-9 col-md-9 text-muted">
                          Income
                        </div>
                        <div className="col-lg-3 col-md-3">
                          {" "}
                          ₹4,000,000.00
                          <span className="text-success mx-2 fs-11">
                            +20.00%
                          </span>
                        </div>
                      </div>
                      <div className="col">
                        <div className="col-lg-9 col-md-9 text-muted">
                          Expenses
                        </div>
                        <div className="col-lg-3 col-md-3">
                          {" "}
                          ₹3,55,000.00
                          <span className="text-success mx-2 fs-11">
                            +20.00%
                          </span>
                        </div>
                      </div>
                      <div className="col">
                        <div className="col-lg-9 col-md-9 text-muted">
                          Profit
                        </div>
                        <div className="col-lg-3 col-md-3">
                          ₹45,000.00
                          <span className="text-success mx-2 fs-11">
                            +20.00%
                          </span>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Col>
            </Row>
            <Row className="d-flex">
              <Col
                lg={12}
                sm={12}
                md={6}
                xs={12}
                className="mb-5 mt-3 datacenter"
              >
                <Paper className="col-lg-12 col-sm-8 rounded-4">
                  <Box sx={{}}>
                    <Row>
                      <Col lg={4} sm={12} className="text-lg-start ">
                        <h6 className="datahead text-muted">Customers</h6>
                      </Col>
                      {/* categories starts */}
                      <Col lg={8} sm={12} className="px-4 pb-2">
                        <Row className="d-flex justify-content-end">
                          <Col lg={5} xs={12} className="middleFilter">
                            <Form className="mt-3 d-flex gap-2 searchField">
                              <div className="col-lg-8 d-flex">
                                <input
                                  value={searchtext}
                                  onChange={(e) => searchChange(e.target.value)}
                                  onKeyDown={handleKeyDown}
                                  className="form-control py-2 nosubmit"
                                  style={{ fontSize: "14px" }}
                                  type="search"
                                  placeholder="Search Firm Name"
                                />
                              </div>
                              <div className="col-lg-4">
                                {!search ? (
                                  <Button
                                    onClick={() => serachclick()}
                                    className="w-100 sortFilter1"
                                    variant="primary"
                                  >
                                    Search
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => resetclick()}
                                    className="w-100 sortFilter1"
                                    variant="primary"
                                  >
                                    Reset
                                  </Button>
                                )}
                                {/* <span className="searchcont" onClick={() => serachclick()} style={{cursor:'pointer'}}><i class="fa fa-search empsearch"></i></span> */}
                              </div>
                            </Form>
                          </Col>
                     
                          {/* Export Excel */}
                          <Col lg={3} sm={2}  xs={12} className="middleFilter">
                            <Button
                              onClick={handleDownloadExcel}
                              disabled={isDownloading}
                              id="shareIcon"
                              variant="outlined"
                            >
                              <img
                                className="menuIcon mx-1 mb-1"
                                src={shareIcon}
                              />
                              {isDownloading ? "Downloading" : "Export Excel"}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                      {/* categories starts */}
                    </Row>
                    <Table responsive borderless>
                      <thead>
                        <tr className="theadborder">
                          <th>
                            <div className="table-headFilter ps-2">
                              <span>#</span>
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Firm Name</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Phone</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Order ID</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Receipt ID</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Total Amount</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Paid Amount</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Pending Amount</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Payments Date</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Payments Mode</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="tdata">
                        {receipts && receipts.length > 0
                          ? receipts.map((k, index) => {
                              return (
                                <tr>
                                  {/* <Form.Check aria-label="option 1" /> */}
                                  <td className="ps-3">{index + 1 + (page * rowsPerPage)}. </td>
                                  <td>{k.customerName}</td>
                                  <td>{k.phone}</td>
                                  <td>{k.orderList[0].orderId}</td>
                                  <td>{k.receiptId}</td>
                                  <td className="text-primary fw-semibold text-end">
                                    {/* {k.orderList[0].sum_total} */}
                                    {k.totalAmount}
                                  </td>
                                  <td className="txt-green fw-semibold text-end">{k.paidAmount.toFixed(2)}</td>
                                  <td className="text-danger fw-semibold text-end">
                                  {k.pendingAmount}
                                  </td>
                                  
                                  <td>
                                    {Moment(k.updatedDate).format(
                                      "DD MMM YYYY"
                                    )}
                                  </td>
                                  <td>{k.paymentType === 'PHONEPE' ? 'UPI' : k.paymentType}</td>
                                  {/* <td>{k.active === true ? 'Active' : 'In Active'}</td> */}
                                  <td onClick={() => viewreceipt(k)} className="hand-cursor">
                                    <img
                                      className="menuIcon mx-1"
                                      src={eyeIcon}
                                    />
                                    view
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </Table>
                    <Row className="d-flex">
                      <Col>
                        <TablePagination
                          component="div"
                          count={recieptcount}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Col>
                    </Row>
                  </Box>
                </Paper>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Modal
        show={modalShow}
        onHide={() => closemodal()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            New Cash Receipt
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className=" rounded-4">
            <CardContent>
              <div className="row">
                <div className="form-group col-lg-12 col-md-12 col-12">
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="input-group mb-3">
                    <TextField
                        select
                        onChange={(e) => selectemps(e)}
                        className="form-select text-muted me-2"
                        id="inputcolorrr"
                        variant="outlined"
                        label="Firm Name*"
                        SelectProps={{
                          native: true,
                        }}
                      >
                        {/* <option hidden value>
                          Select Firm Name*
                        </option> */}
                        {clients &&
                          clients.map((k, index) => {
                            return (
                              <option
                                key={index}
                                value={k.name}
                                data-index={index}
                              >
                                {k.name}
                              </option>
                            );
                          })}
                      </TextField>     
                    </div>
                  </div>
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="input-group ">
                    {/* <TextField
                        select
                        onChange={(e) => selectuser(e)}
                        className="form-select text-muted mb-3 me-2"
                        id="inputcolorrr"
                        variant="outlined"
                        label="Authorized By"
                        SelectProps={{
                          native: true,
                        }}

                      >
                        <option hidden value>
                          Select User Name*
                        </option>
                        {users
                      ? users.map((k, index) => {
                          return (
                            <option key={index} value={k} data-index={index}>
                              {k.username}
                            </option>
                          );
                        })
                      : null}
                      </TextField>                     */}
                      <TextField
                            type="text"
                            className="form-control required mb-3"
                            value={username}
                            onChange={(e) => setUserName(e.target.value)}
                            id="inputcolor"
                            maxLength={10}
                            label="Author Name*"
                            variant="outlined"
                          />
                      </div>
                  </div>
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="input-group mb-3">
                        <TextField
                            className="w-100"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            id="inputcolorrr"
                            type="text"
                            label="Mobile Number*"
                            variant="outlined"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="form-group col-lg-12 col-md-12 col-12 mb-4">
                    <div className="input-group ">
                    <TextField
                        className="w-100"
                        value={email}
                        onChange={(e) => validateEmail(e.target.value)}
                        id="inputcolorrr"
                        type="text"
                        label="Email"
                        variant="outlined"
                      />
                    </div>
                    <span
                      style={{
                        fontSize: "10px",
                        color: "red",
                      }}
                    >
                      {emailError}
                    </span>
                  </div> */}
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="input-group mb-3">
                    <TextField
                        className="w-100"
                        value={gstNo}
                        onChange={(e) => setGstNo(e.target.value)}
                        id="inputcolorrr"
                        type="text"
                        label="Gst Number"
                        variant="outlined"
                      />
                    </div>
                  </div>
                  <div className="input-group">
                  <TextField
                    className="w-100"
                    select
                    id="inputcolorrr"
                    label="Payment Type"
                    value={paytype}
                    onChange={(e) => setPayType(e.target.value)}
                    variant="outlined"
                  >
                    {/* <MenuItem value="" disabled>
                      Payment Type*
                    </MenuItem> */}
                    {paymentOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  </div>
                  {/* <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control py-2 text-muted required"
                        name="name"
                        value={transid}
                        onChange={(e) => setTransID(e.target.value)}
                        id="inputcolor"
                        placeholder="Transaction ID*"
                        aria-label="name"
                        aria-describedby="basic-addon1"
                        required
                      />
                    </div>
                  </div> */}
                  {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="input-group mb-3">
                                                    <input type="date" value={date} onChange={(e) => setDate(e.target.value)} id='inputcolorr' className="form-control py-2 text-muted required" name="date " />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-group mb-3">
                                                    <input type="time" value={time} onChange={(e) => setTime(e.target.value)} className="form-control py-2 text-muted required" id='inputcolorr' name="time" />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                  {inarr && inarr.length > 0
                    ? inarr.map((l, index) => {
                        return (
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  className="form-control py-2 required"
                                  name="size"
                                  id="inputcolorr"
                                  disabled
                                  value={l.orderId}
                                  aria-label="name"
                                  aria-describedby="basic-addon1"
                                  required
                                />
                                {/* <select disabled value={l.orderId} className="form-select text-muted me-2" id='inputcolorr' aria-label="Default select example"
                          placeholder='Select/Enter Order ID*'>
                          <option hidden value>Select/Enter Invoice ID*</option>
                          {orders && orders.map((k, index) => {
                            return (
                              <option key={index} value={k} data-index={index}>{k.orderId}</option>
                            )
                          })}
                        </select> */}
                              </div>
                            </div>
                            <div className="col-lg-3">
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control  required"
                          name="size"
                          id="inputcolorr"
                          value={l.pendingAmount}
                          onChange={(e) => setTotalAmount(e.target.value)}
                          placeholder="Total Amount"
                          aria-label="name"
                          aria-describedby="basic-addon1"
                          disabled
                        />
                      </div>
                    </div>
                            <div className="col-lg-3">
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  className="form-control py-2 required"
                                  name="size"
                                  id="inputcolorr"
                                  disabled
                                  value={l.paidAmount}
                                  onChange={(e) =>
                                    handlepaidamount(e.target.value)
                                  }
                                  placeholder="Amount Received*"
                                  aria-label="name"
                                  aria-describedby="basic-addon1"
                                  required
                                />
                              </div>
                            </div>
                            <div
                              onClick={() => delinvoice(l, index)}
                              className="col-lg-2 mt-1"
                            >
                              <img className="mt-2 mx=lg-1" src={TrashIcon} />
                            </div>
                          </div>
                        );
                      })
                    : null}
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="input-group mb-2">
                      <TextField
    select
    onChange={(e) => selectorder(e)}
    className="text-muted w-100 mt-4 ms-0"
    id="inputcolorrr"
    label="Select Order ID*"
    variant="outlined"
    style={{ marginLeft: '13px' }}
>
    {orders &&
        orders.map((k, index) => {
            return (
                <MenuItem key={index} value={k.orderId} data-index={index}>
                  {orders && console.log("Number of orders:", orders.length)}
                    {k.orderId}
                </MenuItem>
            );
        })}
</TextField>

                      </div>
                    </div>
                    <div className="col-lg-3 mt-4">
                      <div className="input-group mb-3">
                      <TextField
                        type="text"
                        className="form-control required"
                        name="size"
                        id="inputcolorrr"
                        value={totalAmount}
                        onChange={(e) => setTotalAmount(e.target.value)}
                        placeholder="Total Amount"
                        label="Total Amount"
                        variant="outlined"
                        required
                      />
                      </div>
                    </div>
                    <div className="col-lg-3 mt-4">
                      <div className="input-group mb-3">
                      <TextField
                        type="text"
                        className="form-control required "
                        name="size"
                        id="inputcolorrr"
                        value={paidAmount}
                        onChange={(e) => handlepaidamount(e.target.value)}
                        placeholder="Amount Received*"
                        label="Amount Received*"
                        variant="outlined"
                        required
                      />
                      </div>
                    </div>
                    {!invoice ?
                    (<div
                      style={{
                        width: 30,
                        height: 30,
                        borderRadius: 100,
                        borderColor: "green",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "green",
                        display: "flex",
                        cursor: "pointer",
                      }}
                      onClick={() => calinvoice()}
                      className="col-lg-1 mt-4"
                    >
                      
                     <span
                        style={{
                          fontSize: "24px",
                          color: "white",
                          marginTop: -4,
                        }}
                      >
                        +
                      </span>
                    </div>
                    ):(
                      <div className="col-lg-1 mt-4 mb-3"  onClick={() => delclick()}>                                                    
                        <img src={TrashIcon} />
                      </div>
                                )}

                  </div>
                  {/* <div className="row">
                                        <div className="col-lg-5">
                                            <div className="input-group mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control py-2 required"
                                                    name="size"
                                                    id='inputcolorr'
                                                    value={invoiceid} onChange={(e) => setInvoiceID(e.target.value)}
                                                    placeholder="Invoice ID*"
                                                    aria-label="name"
                                                    aria-describedby="basic-addon1"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                            <div className="input-group mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control py-2 required"
                                                    name="size"
                                                    id='inputcolorr'
                                                    value={paidAmount} onChange={(e) => setPaidAmount(e.target.value)}
                                                    placeholder="Amount Received*"
                                                    aria-label="name"
                                                    aria-describedby="basic-addon1"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-2 mt-1">
                                            <img className="mt-2 mx-1" src={TrashIcon} />
                                        </div>
                                    </div> */}
                  {/* <div className={`row mt-2 ${ orderId==="" && totalAmount==="" && paidAmount==="" ?  "d-none": "d-block"}`}>
                    <div className="input-group">
                      { calarr && calarr.length > 0 ?
                         <div
                         onClick={() => addinvoice()}
                         className="text-primary fs-6 mb-2 p-0">
                          +Add New Invoice
                       </div> : null

                      }
                       */}
                     
                      {/* <Nav.Item id="navAccord" className="navLink">
                                                <Nav.Link as={NavLink} to='' ></Nav.Link>
                                            </Nav.Item> */}
                    {/* </div>
                  </div> */}
                  <h4 className="fs-5 mb-3">Payment Summary</h4>
                  {calarr && calarr.length > 0 ? (
                    <div>
                      <div className="d-flex mt-2">
                        <div className="col-lg-8 col-7 text-success"
                        >
                          Invoice Amount</div>
                        {getsubtotal()}
                        {/* <div className="col-lg-4 text-success">
                                            ₹ 15,000.00
                                        </div> */}
                      </div>
                      <div className="d-flex mt-2">
                        <div className="col-lg-8 col-7 text-success">Paid Amount</div>
                        {getpaidamount()}
                        {/* <div className="col-lg-4 text-success">
                                            ₹ 15,000.00
                                        </div> */}
                      </div>
                      <div className="d-flex mt-2">
                        <div className="col-lg-8 col-7 text-danger">
                          Balance Amount
                        </div>
                        {getpendingamount()}
                      </div>
                      <hr className="text-muted"></hr>
                      {/* <div className="row mt-2">
                                        <div className="col-lg-8 text-primary">
                                            Total
                                        </div>
                                        <div className="col-lg-4 text-primary">
                                            ₹ {getpaidamount()}
                                        </div>
                                    </div> */}
                    </div>
                  ) : null}

                  {/* <div className="row mt-2">
                                        <div className="col-lg-8 text-muted">
                                            Invoice ID
                                        </div>
                                        <div className="col-lg-4">
                                            ₹ 10,500.00
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-lg-8 text-muted">
                                            Invoice ID
                                        </div>
                                        <div className="col-lg-4">
                                            ₹ 4,500.00
                                        </div>
                                    </div> */}

                  <div className="row gap-2 gap-lg-0 mt-3">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-5">
                      <Button
                        onClick={() => closemodal()}
                        id="pillStyle"
                        className="w-100"
                        variant="outline-primary"
                      >
                        Back
                      </Button>
                    </div>
                    <div className="col-lg-6">
                    {!isLoading ? (
                      <Button
                        onClick={() => create()}
                        id="pillStyle"
                        className="w-100"
                        variant="primary"
                      >
                        Create Payment
                      </Button>
                      ) : (
                        <button className="buttonload w-100" id="pillStyle">
                          <i className="fa fa-spinner fa-spin"></i> Loading
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  console.log(state, "state");
  return {
    userdata: state.userdetails,
  };
};

export default connect(mapStateToProps)(CashReceipt);
