import React, { useEffect, useState, useRef } from "react";
import "../../Styles/Orders.css";
import Header from "../header/Header";
import Container from "react-bootstrap/Container";
import Sidebar from "../Sidenav/Sidebar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
// import Paper from '@mui/material/Paper';
import Button from "react-bootstrap/Button";
import { NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import UploadImageIcon from "../../Images/UploadImageIcon.png";
import DeleteIcon from "../../Images/DeleteIcon.png";
import { useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import { connect } from "react-redux";
// import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
// import CardActions from '@mui/material/CardActions';
import CardContent from "@mui/material/CardContent";
// import Button from '@mui/material/Button';
import JobcardsService from "../../services/JobcardsService";
import ClientsService from "../../services/ClientsService";
import estimateService from "../../services/estimateService";
import get_userdetails from "../../redux/actions/getuserDetailsAction";
import authService, { getorders } from "../../services/authService";
import { toast } from "react-toastify";
import IndentService from "../../services/IndentService";
import axios from "axios";
import moment from "moment";
import { useLocation } from "react-router-dom";
import SupplierService from "../../services/SupplierService";
import MasterService, { gettransport } from "../../services/MasterService";


function AddNewJobCard(props) {
  const [isLoading, setIsLoading] = useState(false);
  let location = useLocation();
  //addjobcardsIntegration
  const [clients, setClients] = useState([]);
  const [orders, setOrders] = useState([]);
  const [order, setOrder] = useState();
  const [client, setClient] = useState();
  const [gsmtypes, setGsmTypes] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [transport, setTransport] = useState([]);
  const [user, setUserdata] = useState({});
  const [cname, setCname] = useState(location.state?.item ? location.state?.item.customerName : "");
  const [id, setID] = useState("");
  // const [date, setDate] = useState(location.state?.item ? location.state?.item?.createDate : "");
  const [date, setDate] = useState(moment.utc(location.state?.item.createDate).format("YYYY-MM-DD"));
  const [time, setTime] = useState("");
  const [jobCardNo, setJobCardNo] = useState("");
  const [jobId, setJobId] = useState(location.state?.item ? location.state?.item.jobId : "");
  const [lamtype, setLamType] = useState(location.state?.item ? location.state?.item.lamination : "");
  const [jname, setJname] = useState(location.state?.item ? location.state?.item.name : "");
  const [callParty, setCallParty] = useState("");
  const [plateBy, setPlateBy] = useState(location.state?.item ? location.state?.item.plateBy : "");
  const [plateby, setPlateby] = useState("");
  const [colorProof, setColorProof] = useState("");
  const [noOfSets, setNoOfSets] = useState(location.state?.item.printing === "FB" ? '2' : '1');
  const [noofsets, setNo0fsets] = useState(location.state?.item ? location.state?.item.noOfSets : "");
  const [agAou, setAgAou] = useState(location.state?.item ? location.state?.item.agAou : "");
  const [agaou, setAgaou] = useState(location.state?.item ? location.state?.item.agAou : "");
  const [machine, setMachine] = useState("");
  const [bmachinetype, setBMachineType] = useState({});
  const [gsmoptions, setGsmOptions] = useState([]);
  const [machines, setMachines] = useState([]);
  const [bpapertype, setBPaperType] = useState("");
  const [gsm, setGsm] = useState(location.state?.item ? location.state?.item.gsm : "");
  const [papertype, setPaperType] = useState(location.state?.item ? location.state?.item.paperType : "");
  const [paptype, setPapType] = useState("");
  const [paperTypes, setpaperTypes] = useState("");
  const [orderType, setOrdertype] = useState("");
  const [paperGsm, setpaperGsm] = useState(location.state?.item ? location.state?.item.paperType+" "+gsm : "");
  const [paperSize, setPaperSize] = useState(location.state?.item ? location.state?.item?.estimateId.millerLength + "*" +  location.state?.item?.estimateId.millerBreadth : '');
  // const [papersize, setPapersize] = useState("12*12");
  const [paperQuantity, setPaperQuantity] = useState(location.state?.item ? location.state?.item.millquantity : "");
  const [paperCuttingSize, setPaperCuttingSize] = useState(location.state?.item ? location.state?.item.cuttingSize : "");
  const [paperCuttingQty, setpaperCuttingQty] = useState(location.state?.item ? location.state?.item.cuttingQuantity : "");
  const [machinetype, setMachineType] = useState("");
  const [modeofprinting, setModeofPrinting] = useState(location.state?.item ? location.state?.item.machine : "");
  const [printing, setPrinting] = useState(location.state?.item ? location.state?.item?.estimateId.printing : "");
  const [color, setColor] = useState(4);
  const [totalImpressions, setTotalImpressions] = useState(location.state?.item?.printing === 'BB' || location.state?.item?.printing === 'OPPG'
  ? location.state?.item?.cuttingQuantity * 2
  : location.state?.item?.cuttingQuantity);
  const [finalSheets, setFinalSheets] = useState(location.state?.item ? location.state?.item.finalSheets : "");
  const [printMachine, setPrintMachine] = useState("");
  const [printMedia, setPrintMedia] = useState("");
  const [printQuantity, setPrintQuantity] = useState("");
  const [printRate, setPrintRate] = useState("");
  const [pasting, setPasting] = useState(location.state?.item ? location.state?.item.pasting : "");
  const [pastquantity, setPastQuantity] = useState(location.state.item.pasting === "" ? "" : location.state?.item.pastingQuantity);
  const [printCost, setPrintCost] = useState("");
  const [lBreadth, setlBreadth] = useState(location.state?.item ? location.state?.item.laminationBreadth : "");
  const [lLength, setlLength] = useState(location.state?.item ? location.state?.item.laminationLength : "");

  const [lSheet, setlSheet] = useState(location.state?.item.lamination === "" ? "" : location.state?.item.laminationLength + "*" +  location.state?.item.laminationBreadth);
  const [lQuantity, setlQuantity] = useState(location.state?.item.lamination === "" ? "" : location.state?.item.estimateId.laminationQuantity);
  const [lRate, setLRate] = useState("");
  const [orid, setorid] = useState(location.state?.item ? location.state?.item._id : "");
  const [lCost, setLCost] = useState("");
  const [pBreadth, setPBreadth] = useState("");
  const [customerName, setCustomerName] = useState(location.state?.item ? location.state?.item.customerName :'');
  const [subtotal, setSubTotal] = useState(location.state?.item ? location.state?.item.sum_total : "");
  const [pLength, setPLength] = useState( "");
  const [pType, setPType] = useState(location.state?.item ? location.state?.item.punching : "");
  const [punchingTypes, setPunchingTypes] = useState([]);
  const [orderNo, setOrderNo] = useState({});
  const [orderId, setOrderId] = useState(location.state?.item ? location.state?.item.orderId : "");
  const [estimateId, setEstimateId] = useState( "");
  const [pQuantity, setPQuantity] = useState(location.state.item.punching === "" ? "" : location.state?.item.punchingQuantity);
  const [pRate, setPRate] = useState('');
  const [finishingtype, setFinishingType] = useState(location.state?.item ? location.state?.item.estimateId.finishingType : "");
  const [finishingquantity, setFinishingQuantity] = useState(location.state.item.estimateId.finishingType === "" ? "" : location.state?.item.finishingQuantity);
  const [othersAmount, setOthersAmount] = useState(location.state?.item ? location.state?.item.estimateId.othersamount : "");
  const [pCost, setpCost] = useState([]);
  const [outSouWorks, setOutSouWorks] = useState('');
  const [transDetails, setTransDetails] = useState('');
  const [cutCharge, setCutCharge] = useState([]);
  const [packCharge, setPackCharge] = useState([]);
  const [transCharge, setTransCharge] = useState([]);
  const [otherCharge1, setOtherCharge1s] = useState([]);
  const [otherCharge2, setOtherCharge2s] = useState([]);
  const [total, setTotal] = useState([]);
  const [status, setStatus] = useState(location.state?.item ? location.state?.item.status : "");
  const [indentstatus, setIndentStatus] = useState("");
  const [createdByDate, setcreatedByDate] = useState(location.state?.item ? location.state?.item.estimateId.createDate : "");
  const [updatedDate, setupdatedDate] = useState([]);
  const [jobdetails, setJobdetails] = useState([]);
  const [firstMaterialIndentNo, setFirstMaterialIndentNos] = useState([]);
  const [transName, setTransName] = useState('');
  const [customerId, setCustomerId] = useState(location.state?.item ? location.state?.item._id : "");
  const [paperVendor, setPaperVendor] = useState('');
  const [sbtndis, setSBtndis] = useState(false);
  const [jobtype, setJobtype] = useState(location.state?.item ? location.state?.item.orderType.toLowerCase() : "")
  const [estmid, setEstmid] = useState(location.state?.item ? location.state?.item.estimateId._id : "");
  const multiselectRef = useRef();
  const machinemultiselectRef = useRef();
  const papermultiselectRef = useRef();
  const navigate = useNavigate();
console.log(location.state.item, 'location');
console.log(location.state?.item?.estimateId.printing, 'mill');
  useEffect(() => {
    getclients(); 
    getmachines();
    getgsmtypes();
    getpapertypes();
    getpunchtypes();
    getselclient();
    getvendors();
    gettransname();
  }, [props.userdata]);

  const handlemode = (e) => {
    setModeofPrinting(e.target.value);
    jobdetails.push(e.target.value);
  };

  const getselclient = async () => {
    let arr = [];
    if (clients && clients.length > 0) {
      const rty = clients.map((k) => {
        if (k.name === location.state.item.customerName) {
          arr.push(k);
        }
      });
      console.log(arr, "arredit");
      setClient(arr); 

      setCname(arr[0].name);
      setCustomerId(arr[0]._id);
      try {
        const det = await JobcardsService.getordersById(arr[0]._id);
        if (det.data.status === "success") {
          let arrr = [];
          setOrders(det.data.value);
          const rty = det.data.value.map((k) => {
            if (k.orderId === location.state.item.orderId) {
              arrr.push(k);
            }
          });
          console.log(arrr[0].status, "arrr[0]");
          setOrderId(arrr[0].orderId);
          setorid(arrr[0]._id);
          setJname(arrr[0].name);
          setDate(moment.utc(arrr[0].createDate).format("YYYY-MM-DD"));
          setTime(moment.utc(arrr[0].createDate).format("HH:MM"));
          setJobtype(arrr[0].orderType);
          setStatus(arrr[0].status);
          setPrintMachine(arrr[0].machine);
          console.log(
            `${arrr[0].paperType}${arrr[0].gsm}`,
            "`${arrr[0].paperType}${arrr[0].gsm}`"
          );
          setpaperGsm(`${arrr[0].paperType} ${arrr[0].gsm}`);
          setGsm(arrr[0].gsm);
          setAgaou(arrr[0].agAou); 
          setPaperType(arrr[0].paperType);
          setPapType(arrr[0].paperType);
          setPaperSize(`${arrr[0].estimateId.millerLength}*${arrr[0].estimateId.millerBreadth}`);
          // setPapersize(`${arrr[0].millerLength}*${arrr[0].millerBreadth}`);
          // setPaperQuantity(arrr[0].quantity);
          setPaperCuttingSize(arrr[0].cuttingSize);
          setpaperCuttingQty(arrr[0].cuttingQuantity);
          setPrinting(arrr[0].printing);
          setFinalSheets(arrr[0].finalsheets);
          setJobdetails(arrr[0].jobDetails);
          setModeofPrinting(arrr[0].machine);
          setlBreadth(arrr[0].laminationBreadth);
          setlLength(arrr[0].laminationLength);
          // setlQuantity(arrr[0].punchingQuantity);
          setLRate(arrr[0].laminationRate);
          setPType(arrr[0].punching);
          // setPQuantity(arrr[0].punchingQuantity);
          setPRate(arrr[0].punchingRate);
          setEstmid(arrr[0].estimateId._id);
          //  setTimeout(() => {
          //    selectorder(location.state.item.orderId)
          //  }, 6000);
        }
      } catch (ex) {}
      // setCid(arr[0]._id)
    }
  };

  const selectemps = async (e) => {
    console.log(e.target.selectedOptions[0].dataset.index, "value");
    const selindex = e.target.selectedOptions[0].dataset.index;
    const selclient = clients[selindex];
    setCname(selclient.name);
    setCustomerId(selclient._id);
    console.log(selclient, "selclient");
    try {
      const det = await JobcardsService.getordersById(selclient._id);
      if (det.data.status === "success") {
        setOrders(det.data.value);
      }
    } catch (ex) {}
  };
  const getpunchtypes = async () => {
    try {
      const der = await estimateService.getpunchingtypes();
      if (der.data.status === "success") {
        setPunchingTypes(der.data.value);
      }
    } catch (ex) {}
  };

  // const getnoofsets = (item) => {
  //   // setNo0fsets(e.target.value)
  //   connumsetssole.log(location.state.item.estimateId.printing, 'prside');

  //   let  = 1;
  //   let numsets1 = 2;
  //   if ( location.state.item.estimateId.printing === "FB"){
  //     setNoOfSets(numsets)
  //   } else if (location.state.item.estimateId.printing === "BB"){
  //     setNoOfSets(numsets1)
    
  //   } else if (location.state.item.estimateId.printing === "OS"){
  //     setNoOfSets(numsets1)
    
  //   } else if (location.state.item.estimateId.printing === "OPPG"){
  //     setNoOfSets(numsets1)
  //   }
  // }

  const selectorder = (e) => {
    const selindex = e.target.selectedOptions[0].dataset.index;
    const selclient = orders[selindex];
    console.log(selclient, "selorder");
    setOrderId(selclient.orderId);
    setorid(selclient._id);
    setJname(selclient.name);

    setDate(moment.utc(selclient.createDate).format("YYYY-MM-DD"));
    setTime(moment.utc(selclient.createDate).format("HH:MM"));
    setJobtype(selclient.orderType);
    setStatus(selclient.status);
    setPrintMachine(selclient.machine);
    console.log(
      `${selclient.paperType} ${selclient.gsm}`,
      "`${selclient.paperType}${selclient.gsm}`"
    );
    setpaperGsm(`${selclient.paperType} ${selclient.gsm}`);
    setGsm(selclient.gsm);
    setPaperType(selclient.paperType);
    setPapType(selclient.paperType);
    setPaperSize(`${selclient.estimateid.millerLength}*${selclient.estimateId.millerBreadth}`);
    // setPaperQuantity(selclient.quantity);
    setPaperCuttingSize(selclient.cuttingSize);
    setpaperCuttingQty(selclient.cuttingQuantity);
    setPrinting(selclient.printing);
    setFinalSheets(selclient.finalsheets);
    setJobdetails(selclient.jobDetails);
    setModeofPrinting(selclient.machine);
    setlBreadth(selclient.laminationBreadth);
    setlLength(selclient.laminationLength);
    // setlQuantity(selclient.punchingQuantity);
    setLRate(selclient.laminationRate);
    setPType(selclient.punching);
    // setPQuantity(selclient.punchingQuantity);
    setPRate(selclient.punchingRate);
    setEstmid(selclient.estimateId._id);
    setJobId(selclient.jobCardId);
  };

  // const selectpapertype = (value) => {
  //   setPapertype(value);
  //   setBPaperType(value[0]);
  // };

  const getOrder = async () => {
    console.log("calling orders");
    try {
      const dert = await estimateService.getOrders();
      console.log(dert, "orderssss");
      if (dert.data.status === "success") {
        console.log(dert.data.value, "orders");
        setOrders(dert.data.value);
      }
    } catch (ex) {}
  };
  const getclients = async () => {
    try {
      const dert = await ClientsService.getclients();
      if (dert.data.status === "success") {
        console.log(dert.data.value, "values");
        setClients(dert.data.value);
      }
    } catch (ex) {}
  };

  const getgsmtypes = async () => {
    try {
      const dert = await estimateService.getpapergsmAll();
      if (dert.data.status === "success") {
        if (dert.data.value?.length > 0) {
          let optionss = [];
          dert.data.value.map((k) => {
            const obj = {
              paperTypegsm: k.paperType + k.gsm,
              _id: k._id,
              paperType: k.paperType,
              perkgRate: k.perkgRate,
              gsm: k.gsm,
              printmincost: k.printmincost,
              printminImpressionquantity: k.printminImpressionquantity,
              printextraImpressioncost: k.printextraImpressioncost,
              printextraImpressionquantity: k.printextraImpressionquantity,
              isActive: k.isActive,
              isArchive: k.isArchive,
              updatedDate: k.updatedDate,
              createDate: k.createDate,
              __v: 0,
            };
            optionss.push(obj);
          });
          setGsmOptions(optionss);
          setGsmTypes(dert.data.value);
        }
      }
    } catch (ex) {}
  };
  const getpapertypes = async () => {
    try {
      const dty = await estimateService.papertypes();
      if (dty.data.status === "success") {
        console.log(dty.data.value, "dty.data.value");
        setpaperTypes(dty.data.value);
      }
    } catch (ex) {}
  };

  const getmachines = async () => {
    try {
      const dert = await estimateService.getmachines();
      if (dert.data.status === "success") {
        console.log(dert.data.value, "dert.data.value");
        setMachines(dert.data.value);
      }
    } catch (ex) {}
  };

  const selectmachinetype = (value) => {
    setMachineType(value);
    setBMachineType(value[0]);
    jobdetails.push(value[0].details);
    console.log(value, "value");
  };
  // const [estimatecalc, setEstimatecalc] = useState()
  // const multiselectRef = useRef();
  // const papermultiselectRef = useRef();
  // const finishmultiselectRef = useRef();
  // const machinemultiselectRef = useRef();
  // const millimultiselectRef = useRef();
  // const laminationmultiselectRef = useRef();
  // const extraworksmultiselectRef = useRef();
  // const printjobmultiselectRef = useRef();
  const hiddenFileInput = useRef(null);
  const [imloader, setImloader] = useState(null);
  const [file, setFile] = useState(null);

  const submit = async () => {
    if (!orderId) {
      await setSBtndis(false)
      toast.error('Enter Firm Name', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else if (!date) {
      await setSBtndis(false);
      toast.error("Select Date", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!time) {
      await setSBtndis(false);
      toast.error("Select Time", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!jname) {
      await setSBtndis(false);
      toast.error("Select Job Name", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!orderType) {
      await setSBtndis(false);
      toast.error("Select Order Type", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!status) {
      await setSBtndis(false);
      toast.error("Select Status", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!printMachine) {
      await setSBtndis(false);
      toast.error("Select Print Machine", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!callParty) {
      await setSBtndis(false);
      toast.error("Select Call Party", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!plateBy) {
      await setSBtndis(false);
      toast.error("Select PlateBy", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!noOfSets) {
      await setSBtndis(false);
      toast.error("Enter No of Sets", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!colorProof) {
      await setSBtndis(false);
      toast.error("Select Color Proof", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!agAou) {
      await setSBtndis(false);
      toast.error("Select Ag/Aou", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!papertype) {
      await setSBtndis(false);
      toast.error("Select Paper Type", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!paperGsm) {
      await setSBtndis(false);
      toast.error("Select GSM", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!paperSize) {
      await setSBtndis(false);
      toast.error("Enter Paper Size", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!paperQuantity) {
      await setSBtndis(false);
      toast.error("Select Paper Quantity", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!paperCuttingSize) {
      await setSBtndis(false);
      toast.error("Enter Cutting Size", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!paperCuttingQty) {
      await setSBtndis(false);
      toast.error("Enter Cutting Quantity", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!color) {
      await setSBtndis(false);
      toast.error("Enter Color", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!printing) {
      await setSBtndis(false);
      toast.error("Select Printing", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!totalImpressions) {
      await setSBtndis(false);
      toast.error("Enter Total Impressions", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    // } else if (!finalSheets) {
    //   await setSBtndis(false);
    //   toast.error("Enter Final Sheets", {
    //     position: toast.POSITION.BOTTOM_LEFT,
    //   });
    } else if (!jobdetails) {
      await setSBtndis(false);
      toast.error("Enter Job Details", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!printMachine) {
      await setSBtndis(false);
      toast.error("Select Print Machine", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!printMedia) {
      await setSBtndis(false);
      toast.error("Select Print Media", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!printQuantity) {
      await setSBtndis(false);
      toast.error("Select Print Quantity", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!printRate) {
      await setSBtndis(false);
      toast.error("Enter Print Rate", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!printCost) {
      await setSBtndis(false);
      toast.error("Select Print Cost", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!lBreadth) {
      await setSBtndis(false);
      toast.error("Enter Lamination Breadth", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!lLength) {
      await setSBtndis(false);
      toast.error("Enter Lamination Length", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!lSheet) {
      await setSBtndis(false);
      toast.error("Enter Lamination Sheet", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!lQuantity) {
      await setSBtndis(false);
      toast.error("Enter Lamination Quantity", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!lRate) {
      await setSBtndis(false);
      toast.error("Enter Lamination Rate", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!lCost) {
      await setSBtndis(false);
      toast.error("Enter Lamination Cost", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!pBreadth) {
      await setSBtndis(false);
      toast.error("Enter Punching Breadth", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!pLength) {
      await setSBtndis(false);
      toast.error("Enter Punching Length", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!pType) {
      await setSBtndis(false);
      toast.error("Select Punching Type", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!pQuantity) {
      await setSBtndis(false);
      toast.error("Enter Punching Quantity", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!pRate) {
      await setSBtndis(false);
      toast.error("Enter Punching Rate", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!pCost) {
      await setSBtndis(false);
      toast.error("Enter Punching Cost", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!cutCharge) {
      await setSBtndis(false);
      toast.error("Enter Cutting Charge", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!packCharge) {
      await setSBtndis(false);
      toast.error("Enter Packing Charge", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!transDetails) {
      await setSBtndis(false);
      toast.error("Enter Transport Details", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!transCharge) {
      await setSBtndis(false);
      toast.error("Enter Transport Charge", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!outSouWorks) {
      await setSBtndis(false);
      toast.error("Enter Out Source Works", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!otherCharge1) {
      await setSBtndis(false);
      toast.error("Enter Other Charges", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!otherCharge2) {
      await setSBtndis(false);
      toast.error("Enter Other Charges", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!total) {
      await setSBtndis(false);
      toast.error("Enter Total", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    // } else if (!paperVendor) {
    //   await setSBtndis(false);
    //   toast.error("Select Paper Vendor", {
    //     position: toast.POSITION.BOTTOM_LEFT,
    //   });
    // } else if (!transName) {
    //   await setSBtndis(false);
    //   toast.error("Enter Transport Name", {
    //     position: toast.POSITION.BOTTOM_LEFT,
    //   });
    } else if (!transDetails) {
      await setSBtndis(false);
      toast.error("Enter Transport Name", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!outSouWorks) {
      await setSBtndis(false);
      toast.error("Enter Transport Name", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    // } else if (!paperVendor) {
    //   await setSBtndis(false);
    //   toast.error("Enter Transport Name", {
    //     position: toast.POSITION.BOTTOM_LEFT,
    //   });
    } else {
      try {
      } catch (ex) {}
    }
  };
  const handleorderType = (e) => {
    setOrdertype(e);
    jobdetails.push(e);
  };

  const handlesubmit = async () => {
    try {
      const obj = {
        jobCardNo: jobCardNo,
        customerName: cname,
        jobName: jname,
        callParty: callParty,
        plateBy: plateBy,
        colorProof: colorProof,
        noOfSets: noOfSets,
        agAou: agAou,
        machine: machine,
        date: date,
        orderNo: orderNo,
        orderId: orderId,
        colors: color,
        printing: printing,
        totalImpressions: totalImpressions,
        finalSheets: finalSheets,
        paperType: bpapertype.details,
        paperGsm: paperGsm,
        paperSize: paperSize,
        paperQuantity: paperQuantity,
        paperCuttingSize: paperCuttingSize,
        paperCuttingQty: paperCuttingQty,
        printMachine: printMachine,
        printMedia: printMedia,
        printQuantity: printQuantity,
        printRate: printRate,
        printCost: printCost,
        lBreadth: lBreadth,
        lLength: lLength,
        lSheet: lSheet,
        lQuantity: lQuantity,
        lRate: lRate,
        lCost: lCost,
        pBreadth: pBreadth,
        pLength: pLength,
        pType: pType,
        pQuantity: pQuantity,
        // pRate: pRate,
        pCost: pCost,
        transDetails: transDetails,
        outSouWorks: outSouWorks,
        cutCharge: cutCharge,
        packCharge: packCharge,
        transCharge: transCharge,
        otherCharge1: otherCharge1,
        otherCharge2: otherCharge2,
        total: total,
        jobDetails: jobdetails,
        firstMaterialIndentNo: firstMaterialIndentNo,
        transName: transName,
        customerId: customerId,
        paperVendor: paperVendor,
        estimateId: estimateId,
        jobDetails: jobdetails,
        isActive: true,
        isArchive: false,
        _id: id,
        createdByDate: createdByDate,
        updatedDate: updatedDate,
        __v: 0,
      };
      const dort = await JobcardsService.addjobcards(obj);
      console.log(dort, "dort");
      if (dort.status === 200) {
        await setSBtndis(false);
        navigate("/jobcards");
        toast.success("Job Card Created Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (ex) {
      await setSBtndis(false);
      console.log(ex.response, "ex.response");
      toast.error(ex.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };
  //addjobcardsIntegration close

  //add indent Integration
  const [role, setRole] = useState("");
  const [indentno, setIndentNo] = useState("");

  // const [papertype, setPaperType] = useState("");
  const [size, setSize] = useState("");
  const [quantity, setQuantity] = useState("");
  const [sname, setSname] = useState("");

  function editjob() {
    navigate("/editjob");
  }

  //Indent validation
  const modalsubmit = async () => {
    if (!indentno) {
      await setSBtndis(false);
      toast.error("Select Indent ID", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!date) {
      await setSBtndis(false);
      toast.error("Select Date", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!time) {
      await setSBtndis(false);
      toast.error("Select Time", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!orderNo) {
      await setSBtndis(false);
      toast.error("Select Order ID", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!jobCardNo) {
      await setSBtndis(false);
      toast.error("Select Job Card ID", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!status) {
      await setSBtndis(false);
      toast.error("Select Status", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!sname) {
      await setSBtndis(false);
      toast.error("Select Supplier", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!papertype) {
      await setSBtndis(false);
      toast.error("Select Paper Type", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!gsm) {
      await setSBtndis(false);
      toast.error("Select GSM", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!quantity) {
      await setSBtndis(false);
      toast.error("Enter Quantity", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!size) {
      await setSBtndis(false);
      toast.error("Enter Size", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };
  //Indent Validations close

  const create = async () => {
    try {
  
      const obj = {
        indentNo: indentno,
        orderNo: orderNo,
        jobCardNo: jobCardNo,
        date: date,
        paperType: papertype,
        gsm: gsm,
        size: size,
        quantity: quantity,
        supplierName: sname,
      };

      const dert = await IndentService.addindents(obj);
      if (dert.data.status === "success") {
        toast.success("Indent Added SuccessFully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        navigate("/indent");
      } else {
      }
    } catch (ex) {}
    finally{
     
    }
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const changeHandler = async (event) => {
    console.log("calling");
    await setImloader(true);

    const formData = new FormData();

    formData.append("image", event.target.files[0]);
    console.log(event.target.files[0], "event.target.files");
    let res = await axios.post(
      "https://aretsapi.azurewebsites.net/api/common/uploadfile",
      formData,

      {
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data; ",
          Accept: "*/*",
        },
      }
    );
    console.log(res.data, "res.data");
    if (res.data.url) {
      await setImloader(false);

      await setFile(res.data.url);
    }
  };
  const delmg = async () => {
    var filename = file.substring(file.lastIndexOf("/") + 1);
    const obj = {
      url: filename,
    };
    const dete = await estimateService.delproimg(obj);
    if (dete.data.status === true) {
      setFile();
      toast.success("File Deleted Successfully", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };
  //add indent Integration close

  const [modalShow, setModalShow] = React.useState(false);
  console.log(printMachine, "printMachine");
  const createjob = async () => {
    
    if (!callParty) {
      await setSBtndis(false);
      toast.error("Select Call Party", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!agAou) {
      await setSBtndis(false);
      toast.error("Select Plate By", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!plateBy) {
      await setSBtndis(false);
      toast.error("Select Plate Vendor", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } 
    else if (!paperVendor) {
      await setSBtndis(false);
      toast.error("Select Paper Vendor", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!noOfSets) {
      await setSBtndis(false);
      toast.error("Enter NoOfSets", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!colorProof) {
      await setSBtndis(false);
      toast.error("Select Color Proof", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!agAou) {
      await setSBtndis(false);
      toast.error("Select plate vendor", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    
    // } else if (!outSouWorks) {
    //   await setSBtndis(false);
    //   toast.error("Enter Out Source Works", {
    //     position: toast.POSITION.BOTTOM_LEFT,
    //   });
     
    // } else if (!transName) {
    //   await setSBtndis(false);
    //   toast.error("Enter Transport Name", {
    //     position: toast.POSITION.BOTTOM_LEFT,
    //   });
    } else {
      try {
        setIsLoading(true);
        const obj = {
          customerName: cname,
          jobName: jname,
          orderType: jobtype,
          callParty: callParty,
          plateBy: plateBy,
          colorProof: colorProof,
          noOfSets: noOfSets,
          agAou: agAou,
          machine: printMachine,
          date: moment.utc(date).format("YYYY-MM-DD"),
          orderId: orderId,
          colors: color,
          printing: printing,
          totalImpressions: totalImpressions,
          finalSheets: parseInt(finalSheets),
          paperGsm: paperGsm,
          paperSize: paperSize,
          paperQuantity: paperQuantity,
          paperCuttingSize: paperCuttingSize,
          paperCuttingQty: paperCuttingQty,
          printMachine: printMachine,
          printMedia: printMedia,
          printQuantity: printQuantity,
          printRate: printRate,
          lBreadth: lBreadth,
          lLength: lLength,
          lSheet: lSheet,
          lQuantity: lQuantity,
          lRate: lCost,
          pBreadth: pBreadth,
          pLength: pLength,
          pType: pType,
          pQuantity: pQuantity,
          // pRate: pRate,
          transDetails: transDetails,
          outSouWorks: outSouWorks,
          status: status,
          jobDetails: 0,
          transName: transName,
          paperVendor: paperVendor,
          orId: orid,
          pastingType: pasting,
          pastingQuantity: pastquantity,
          finishingType: finishingtype,
          finishingQuantity: finishingquantity,
          othersamount: othersAmount
        };
        console.log(obj, "jobobj");
        const data = await JobcardsService.addjobcards(obj);
        if (data.data.status === "success") {
          const ob = {
            gsm: gsm,
            date: moment.utc(createdByDate).format("YYYY-MM-DD"),
            orderId: orderId,
            estimateId: estmid,
            jobCardId: data.data.value._id,
            paperType: papertype,
            size: paperSize,
            customerName: customerName,
            customerId: customerId,
            quantity: paperQuantity,
            supplierName: paperVendor,
            sum_total: subtotal,
            status: indentstatus,
            path: "job",
            plateBy: plateBy,
            noOfSets: noOfSets,
            agAou: agAou,
            jobId: jobId
          };
          console.log(ob, "obobob");
          const dert = await JobcardsService.addindent(ob);
          if (dert.data.status === "success") {
            toast.success("Job Card and Indent Created Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            console.log(dert.data, "dert.data");
            navigate("/jobcards");
            // setIsLoading(false);
          }
        }
      } catch (ex) {

      }
      finally{
        setIsLoading(false);
      }
    }
  };

  const getvendors = async () => {
    try {
      const dty = await SupplierService.getsuppliers();
      if (dty.data.status === "success") {
        setVendors(dty.data.value);
      }
    } catch (ex) {}
  };
  
  const gettransname = async () => {
    try {
      const dty = await MasterService.gettransport();
      if (dty.data.status === "success") {
        setTransport(dty.data.value);
      }
    } catch (ex) {}
  };
  
  return (
    <>
      <Header />
      <Container fluid className="containerr">
        <Row>
          <Col lg={2} id="sidebar-wrapper" className="d-none d-md-block">
            <Sidebar />
          </Col>
          <Col lg={10} className="p-3">
            <div>
              <h1 className="fs-3 ms-lg-5 mt-3">Create New Job Card</h1>
              <Breadcrumb className="ms-lg-5 fs-14">
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/jobcards">Orders</Breadcrumb.Item>
                <Breadcrumb.Item active>Create New Job Card</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Card sx={{ minWidth: 275 }} className="mt-4 rounded-4">
              <CardContent>
                <Col lg={12} className="pe-3">
                <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex">
                      <div className="col-lg-2 mt-2">
                        <h6 className="fs-12 fw-bold text-lg-center">Firm Name</h6>
                      </div>
                      <div className="col-lg-3">
                        <div className="input-group">
                        <input
                          type="text"
                          className="form-control text-muted required"
                          name="name"
                          value={cname}
                          onChange={(e) => setCname(e.target.value)}
                          id="inputcolorr"
                          aria-label="name"
                          aria-describedby="basic-addon1"
                          required
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-lg-1 mt-2">
                      <h6 className="fs-12 fw-bold text-lg-center">Order ID</h6>
                    </div>
                    <div className="col-lg-2">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control text-muted required"
                          name="name"
                          value={orderId}
                          onChange={(e) => setOrderId(e.target.value)}
                          id="inputcolorr"
                          aria-label="name"
                          aria-describedby="basic-addon1"
                          required
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 mt-2">
                      <h6 className="fs-12 fw-bold text-lg-center">
                        Booking Date
                      </h6>
                    </div>
                    <div className="col-lg-2">
                      <div className="input-group">
                        <div className="input-group">
                          <input
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            type="date"
                            id="inputcolorr"
                            className="form-control text-muted required"
                            name="date "
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex mt-2 dashedline">
                    <div className="col-lg-2 mt-1">
                      <h6 className="fs-12 fw-bold text-lg-center">Job Name</h6>
                    </div>
                    <div className="col-lg-3">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control text-muted required"
                          name="name"
                          value={jname}
                          onChange={(e) => setJname(e.target.value)}
                          id="inputcolorr"
                          aria-label="name"
                          aria-describedby="basic-addon1"
                          required
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-lg-1 mt-2">
                      <h6 className="fs-12 fw-bold text-lg-center">Job Type</h6>
                    </div>
                    <div className="col-lg-2">
                      <div className="input-group">
                      <input
                                type="text"
                                className="form-control required"
                                name="Quantity"
                                value={jobtype}
                                onChange={(e) => setJobtype(e.target.value)}
                                disabled
                                id="inputcolorr"
                              />
                      </div>
                    </div>
                    <div className="col-lg-2 mt-2">
                      <h6 className="fs-12 fw-bold text-lg-center">Status</h6>
                    </div>
                    <div className="col-lg-2">
                      <div className="input-group">
                        {/* <select
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                          class="form-select text-muted"
                          id="inputcolorr"
                          aria-label="Default select example"
                        >
                          <option selected hidden>Status*</option>
                          <option value="ESTIMATE">Estimate</option>
                          <option value="ORDER-CONFIRMED">
                            Order Confirmed
                          </option>
                          <option value="JOB-Create">Job Created</option>
                          <option value="PRINTED">Printed</option>
                          <option value="DELIVERED">Delivered</option>
                        </select> */}
                        <input
                                type="text"
                                className="form-control required"
                                name="Quantity"
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                                disabled
                                id="inputcolorr"
                              />
                      </div>
                    </div>
                  </div>
                  <Row>
                    <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex dashedline">
                      {/* first row */}

                      <div className="col-lg-4">
                        <div className="col-lg-6 mt-2">
                          <h6 className="fs-16 fw-bolder">PAPER DETAILS:</h6>
                        </div>
                        <div className="d-flex">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold ">paper & Gsm</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                            <input
                                type="text"
                                className="form-control required"
                                name="Quantity"
                                value={paperGsm}
                                onChange={(e) => setpaperGsm(e.target.value)}
                                disabled
                                id="inputcolorr"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12  fw-bold">Mill Size</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="length"
                                className="form-control required"
                                name="length"
                                value={paperSize}
                                disabled
                                // value={paperSize} onChange={(e) => setPaperSize(e.target.value)}
                                id="inputcolorr"
                                aria-label="length"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Mill Quantity</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control required"
                                name="Quantity"
                                value={paperQuantity}
                                disabled
                                id="inputcolorr"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Cutting Size</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control required"
                                name="Cut Size"
                                value={paperCuttingSize}
                                disabled
                                id="inputcolorr"
                                aria-label="Cut Size"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Cutting Quantity</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control required"
                                name="Cut Quantity"
                                value={paperCuttingQty}
                                disabled
                                id="inputcolorr"
                                aria-label="Cut Quantity"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Paper Vendor</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <select
                                value={paperVendor}
                                onChange={(e) => setPaperVendor(e.target.value)}
                                class="form-select text-muted"
                                id="inputcolorr"
                                aria-label="Default select example"
                              >
                                <option selected hidden>Paper Vendor*</option>
                                {/* <option value="Gayatri">Gayatri</option>
                                <option value="Hari Kisan Paper">
                                  Hari Kisan Paper
                                </option>
                                <option value="Jai Shankar Khagad bhandhar">
                                  Jai Shankar Khagad bhandhar
                                </option> */}
                                {vendors &&
                              vendors.map((k, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={k.name}
                                    data-index={index}
                                    
                                  >
                                    {k.name}
                                  </option>
                                );
                              })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Second Column */}

                      <div className="col-lg-4">
                        <div className="col-lg-6 mt-2">
                          <h6 className="fs-16 fw-bolder">PLATE DETAILS:</h6>
                        </div>
                        <div className="d-flex">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold ">plate By</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <select
                                value={agAou}
                                onChange={(e) => setAgAou(e.target.value)}
                                class="form-select text-muted"
                                id="inputcolorr"
                                aria-label="Default select example"
                              >
                                <option selected hidden>plate By*</option>
                                <option value="AG">AG</option>
                                <option value="AOU">AOU</option>
                                <option value="AG/AOU">AG/AOU</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">No.of Sets</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control required"
                                name="quantity"
                                value={noOfSets}
                                onChange={(e) => setNoOfSets(e.target.value)}
                                id="inputcolorr"
                                aria-label="quantity"
                                aria-describedby="basic-addon1"
                                required
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Plate Vendor</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <select
                                value={plateBy}
                                onChange={(e) => setPlateBy(e.target.value)}
                                class="form-select text-muted"
                                id="inputcolorr"
                                aria-label="Default select example"
                              >
                                <option selected hidden>Plate vendor*</option>
                                {/* <option value="Gayatri">Gayatri</option>
                                <option value="Hari Kisan Paper">
                                  Hari Kisan Paper
                                </option>
                                <option value="Jai Shankar Khagad bhandhar">
                                  Jai Shankar Khagad bhandhar
                                </option> */}
                                {vendors &&
                              vendors.map((k, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={k.name}
                                    data-index={index}
                                    
                                  >
                                    {k.name}
                                  </option>
                                );
                              })}
                              </select>
                            </div>
                          </div>
                          
                        </div>
                        
                      </div>
                        

                      {/* Third Column */}

                      <div className="col-lg-4">
                        <div className="col-lg-6 mt-2">
                          <h6 className="fs-16 fw-bolder">PRINTING DETAILS:</h6>
                        </div>
                        <div className="d-flex">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold ">Mode Of Printing</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control required"
                                name="quantity"
                                value={modeofprinting}
                                id="inputcolorr"
                                aria-label="quantity"
                                aria-describedby="basic-addon1"
                                required
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">No.Of Colors</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="length"
                                className="form-control required"
                                name="length"
                                value={color}
                                onChange={(e) => setColor(e.target.value)}
                                id="inputcolorr"
                                aria-label="length"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Printing Side</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control required"
                                name="Quantity"
                                value={printing}
                                onChange={(e) => setPrinting(e.target.value)}
                                disabled
                                id="inputcolorr"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Total Impression</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="length"
                                className="form-control required"
                                name="length"
                                value={totalImpressions}
                                onChange={(e) =>
                                  setTotalImpressions(e.target.value)
                                }
                                id="inputcolorr"
                                aria-label="length"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Colour Proof</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <select
                                value={colorProof}
                                onChange={(e) => setColorProof(e.target.value)}
                                class="form-select text-muted"
                                id="inputcolorr"
                                aria-label="Default select example"
                              >
                                <option selected hidden>Color Proof*</option>
                                <option value="YES">Yes</option>
                                <option value="NO">No</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Call Party</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <select
                                value={callParty}
                                onChange={(e) => setCallParty(e.target.value)}
                                class="form-select text-muted"
                                id="inputcolorr"
                                aria-label="Default select example"
                              >
                                <option selected hidden>Call Party*</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-lg-flex dashedline">
                      {/* first row */}
                      
                      <div className="col-lg-4">
                        <div className="col-lg-6 mt-2">
                          <h6 className="fs-16 fw-bolder">LAMINATION:</h6>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Lamination Type</h6>
                          </div>
                          <div className="col-lg-6 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control py-2 text-muted required"
                                name="name"
                                style={{fontSize : '13px'}}
                                value={lamtype}
                                onChange={(e) => setLamType(e.target.value)}
                                id="inputcolor"
                                aria-label="name"
                                aria-describedby="basic-addon1"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Size</h6>
                          </div>
                          <div className="col-lg-6 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control text-muted required"
                                name="name"
                                value={lSheet}
                                onChange={(e) => setlSheet(e.target.value)}
                                id="inputcolorr"
                                aria-label="name"
                                aria-describedby="basic-addon1"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Quantity</h6>
                          </div>
                          <div className="col-lg-6 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control required"
                                name="Quantity"
                                value={lQuantity}
                                onChange={(e) => setlQuantity(e.target.value)}
                                id="inputcolorr"
                                aria-label="Quantity"
                                aria-describedby="basic-addon1"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Second Column */}

                      <div className="col-lg-4">
                        <div className="col-lg-6 mt-2">
                          <h6 className="fs-16 fw-bolder">PUNCHING DETAILS:</h6>
                        </div>
                        <div className="d-flex">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold ">Punching Type</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                            <input
                                value={pType}
                                onChange={(e) => setPType(e.target.value)}
                            type="text"
                            id="inputcolorr"
                            className="form-control text-muted required"
                            name="date "
                            disabled
                          />
                            </div>
                          </div>
                        </div>
                        
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Quantity</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control required"
                                name="rate"
                                value={pQuantity}
                                onChange={(e) => setPQuantity(e.target.value)}
                                id="inputcolorr"
                                aria-label="rate"
                                aria-describedby="basic-addon1"
                                required
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 mt-2">
                          <h6 className="fs-16 fw-bolder">PASTING DETAILS:</h6>
                        </div>
                        <div className="d-flex">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold ">pasting Type</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control required"
                                name="quantity"
                                value={pasting}
                                onChange={(e) => setPasting(e.target.value)}
                                id="inputcolorr"
                                aria-label="quantity"
                                aria-describedby="basic-addon1"
                                required
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Quantity</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control required"
                                name="quantity"
                                value={pastquantity}
                                onChange={(e) => setPastQuantity(e.target.value)}
                                id="inputcolorr"
                                aria-label="quantity"
                                aria-describedby="basic-addon1"
                                required
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Third Column */}

                      <div className="col-lg-4">
                      <div className="col-lg-6 mt-2">
                          <h6 className="fs-16 fw-bolder">Finishing DETAILS:</h6>
                        </div>
                        <div className="d-flex">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold ">Finishing Type</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control required"
                                name="quantity"
                                value={finishingtype}
                                onChange={(e) => setFinishingType(e.target.value)}
                                id="inputcolorr"
                                aria-label="quantity"
                                aria-describedby="basic-addon1"
                                required
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Finishing Quantity</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control required"
                                name="quantity"
                                value={finishingquantity}
                                onChange={(e) => setFinishingQuantity(e.target.value)}
                                id="inputcolorr"
                                aria-label="quantity"
                                aria-describedby="basic-addon1"
                                required
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div className="d-flex mt-1">
                          <div className="col-lg-4 col-4 mt-2">
                            <h6 className="fs-12 fw-bold">Others Amount</h6>
                          </div>
                          <div className="col-lg-4 col-9">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control required"
                                name="quantity"
                                value={othersAmount}
                                onChange={(e) => setOthersAmount(e.target.value)}
                                id="inputcolorr"
                                aria-label="quantity"
                                aria-describedby="basic-addon1"
                                required
                                disabled
                              />
                            </div>
                          </div>
                        </div> */}
                        {/* <div className="col-lg-12 col-12 mt-2">
                          <h6 className="fs-16 fw-bolder">
                            BINDING / OUT SOURCE WORK:
                          </h6>
                        </div>
                        <div className="col-lg-8 col-12">
                          <div className="input-group">
                            <input
                              type="Quantity"
                              className="form-control required"
                              name="Quantity"
                              value={outSouWorks}
                              onChange={(e) => setOutSouWorks(e.target.value)}
                              id="inputcolorr"
                              aria-label="Quantity"
                              aria-describedby="basic-addon1"
                              required
                            />
                          </div>
                        </div> */}
                       
                        <div className="col-lg-10 mt-2">
                          <h6 className="fs-16 fw-bolder">TRANSPORT NAME:</h6>
                        </div>
                        <div className="col-lg-8 col-12">
                          <div className="input-group">
                            {/* <input
                              type="Quantity"
                              className="form-control required"
                              name="Quantity"
                              value={transName}
                              onChange={(e) => setTransName(e.target.value)}
                              id="inputcolorr"
                              aria-label="Quantity"
                              aria-describedby="basic-addon1"
                              required
                            /> */}
                             <select
                               value={transName}
                               onChange={(e) => setTransName(e.target.value)}
                                class="form-select text-muted"
                                id="inputcolorr"
                                aria-label="Default select example"
                              >
                                <option selected hidden>Transport Name</option>
                                {transport &&
                              transport.map((k, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={k.name}
                                    data-index={index}
                                    
                                  >
                                    {k.name}
                                  </option>
                                );
                              })}
                              </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="row">
                      <div className="col-lg-8"></div>
                      <div className="col-lg-2">
                        <div className="input-group mt-3">
                          <Button
                            as={NavLink}
                            to="/jobcards"
                            id="pillStyle"
                            className="w-100"
                            variant="outline-primary"
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <div className="input-group mt-3">
                        {!isLoading ? (
                          <Button
                            onClick={() => createjob()}
                            id="pillStyle"
                            className="w-100"
                            variant="primary"
                          >
                            Create Job Card
                          </Button>
                          ) : (
                            <button className="buttonload w-100" id="pillStyle">
                          <i className="fa fa-spinner fa-spin"></i> Loading
                        </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </CardContent>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
const mapStateToProps = (state) => {
  console.log(state, "state");
  return {
    userdata: state.userdetails,
  };
};
export default connect(mapStateToProps)(AddNewJobCard);
