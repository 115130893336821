import React, { useEffect, useState } from "react";
import "../../../Styles/JobCards.css";
import Header from "../../header/Header";
import Sidebar from "../../Sidenav/Sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import filterIcon from "../../../Images/filterIcon.png";
import shareIcon from "../../../Images/shareIcon.png";
import sortIcon from "../../../Images/sortIcon.png";
import calenderIcon from "../../../Images/calenderIcon.png";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
// import Table from 'react-bootstrap/Table';
import { NavLink } from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router";
import Modal from "react-bootstrap/Modal";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import digitalService from "../../../services/digitalService";
import MasterService from "../../../services/MasterService";
import { toast } from "react-toastify";
import axios from "axios";
import editIcon from "../../../Images/editIcon.svg";
import { Table, Pagination } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import deleteIconRed from '../../../Images/deleteIconRed.svg';
import { connect } from "react-redux";
import get_userdetails from "../../../redux/actions/getuserDetailsAction";

const Digital = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUserdata] = useState();
  useEffect(() => {
    getuserdetails();
  }, [props.userdata]);
  useEffect(() => {
    get_userdetails();
  }, []);

  const getuserdetails = async () => {
    console.log(props.userdata.user, "props.userdata.user");
    setUserdata(props.userdata.user);
  };
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [digicount, setDigicount] = useState(0);
  const [searchtext, setSearchText] = useState("");
  const [search, setSearch] = useState(false)


  const handleChangePage = (event, newPage) => {
    const dett = newPage * rowsPerPage;
    setPage(newPage);
    getdigital(dett, rowsPerPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getdigital(0, event.target.value);
  };
  let navigate = useNavigate();

  const [modalShow, setModalShow] = React.useState(false);
  const [editmodal, setEditModalShow] = React.useState(false);

  const [digital, setDigital] = useState([]);
  useEffect(() => {
    getdigital(0, 10);
  }, []);

  const getdigital = async (skps, rpp) => {
    try {
      const obj = {
        skip: skps,
        limit: rpp,
        // searchkeys: { details: searchtext },
      };
      const dert = await digitalService.getdigital(obj);
      console.log(dert, "derattt");
      if (dert.data.status === "success") {
        console.log(dert.data.value, "values");
        setDigital(dert.data.value);
        setDigicount(dert.data.count);
      }
    } catch (ex) {}
  };

  const [sbtndis, setSBtndis] = useState(false);
  const [role, setRole] = useState("");
  const [id, setID] = useState("");
  const [digitalType, setDigitalType] = useState("") 
  const [edigitalType, setEDigitalType] = useState("");
  const create = async () => {
  
    if (!digitalType) {
      await setSBtndis(false);
      setEDigitalType("Digital Is Required!");
      toast.error("Enter Digital Details", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else{
      setEDigitalType("");
    try {
      setIsLoading(true);
      const obj = {
        digitalType: digitalType,
      };
      console.log(obj, "objjj");
      const dert = await digitalService.adddigital(obj);
      console.log(dert.data, "dert.data");
      if (dert.data.status === "success") {
        toast.success("Digital Added Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        // navigate("/digital");
        getdigital(0, 10);
        setModalShow(false);
        setDigital("");
      
      } else {
        toast.error(dert.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (ex) {
      if (ex.response.status === 400) console.log(ex.response, "ex.response");
      toast.error(ex.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }finally{
      setIsLoading(false);
    }}
  };

  // download Excel

  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadExcel = async () => {
    try {
      setIsDownloading(true);
      const requestData = {
        // searchkeys : searchtext 
        searchkeys: { details: searchtext }     
      };
      const response = await axios.post(
        "/newMaster/digital/jsontoexcel",
        requestData,
        { responseType: "blob" }
      );
      const excelBlob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(excelBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Diigtal.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    } finally {
      setIsDownloading(false);
    }
  };
  //  Download Excel Close

  //edit integration
  const [edmill, setedmill] = useState({});
  const [digidetails, setDigiDetails] = useState("");
  const [edigidetails, setEDigiDetails] = useState("");
  const [digicost, setDigiCost] = useState("");
  const [edigicost, setEDigiCost] = useState("");
  // const [digistatus, setDigiStatus] = useState('');

  const clickedit = (k) => {
    setedmill(k);
    setDigiDetails(k.details);
    setEditModalShow(true);
  };
  const handlesubmit = async () => {
   
    if (!digidetails) {
      await setSBtndis(false);
      setEDigiDetails("Digital Is Required!");
      toast.error("Enter Digital Details", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else{
    setEDigiDetails("");
    try {
      setIsLoading(true);
      const obj = {
        _id: edmill._id,
        id: 3,
        digitalType: digidetails,
        __v: 0,
      };
      const dort = await MasterService.updatedigital(obj);
      if (200 == 200) {
        getdigital(0, 10);
        await setSBtndis(false);
        navigate("/digital");
        setEditModalShow(false);
      
        toast.success("Digital Updated Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (ex) {
      await setSBtndis(false);
      toast.error(ex.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }finally{
      setIsLoading(false);
    }}
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      serachclick();
     
    }
  };

  //edit integration close

  const searchChange = (value) => {
    setSearchText(value);
    // getestimates(0,10)
  };
  const serachclick = async () => {
    const trimmedSearchText = searchtext.trim(); 
    if (trimmedSearchText === "") {
      toast.error("Enter Text", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
   
    }
    try {
      const obj = {
        skip: 0,
        limit: rowsPerPage,
        searchkeys: { digitalType: searchtext }
        // "filterBy": {
        //     "status": filtstatus,
        //     "customerName": cname,
        //     "fromAmount": fromAmount,
        //     "toAmount": toAmount,
        //     "fromDate": "2022-07-17",
        //     "toDate": "2023-02-15"
        // }
      }
   
      const dert = await digitalService.getdigital(obj);
      console.log(dert, "dert");
      if (dert.data.status === "success") {
        setSearch(true)
        console.log(dert.data.value, "values");
        setDigital(dert.data.value);
        setDigicount(dert.data.count);
      }
    } catch (ex) {

    }

  }
  const Delete = async (item) => {
        
    try {
      const obj = {
       "_id":item._id,
      }
   
      const dert = await MasterService.deleteDigital(obj);
      console.log(dert, "dert");

      if (dert.data.message) {
        toast.success("Digital Deleted Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        getdigital(0, 10);
        // setSearch(true)
        console.log(dert.data.value, "values");
      
           // setBreadth('');
           // setLength('');
           // setDetails('')
      }
    } catch (ex) {

    }
  }
  const resetclick = () => {
    setSearchText('')
    setSearch(false)
    getdigital(0, rowsPerPage);
}
  function modalreset() {
    setDigital("");
    setEDigiDetails("");
    setEDigitalType("");
    setModalShow(false);
  }
  return (
    <>
      <Header />
      <Container fluid className="containerr">
        <Row>
          <Col lg={2} id="sidebar-wrapper" className="d-none d-md-block">
            <Sidebar />
          </Col>
          <Col lg={10} sm={4} className="containerr">
          <div className="pt-4 pb-2 d-flex justify-content-between align-items-center">
              <div className="ms-2">
                <h1 className="fs-3">Digital</h1>
                <nav aria-label="breadcrumb" className="fs-14">
                  <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item">
                      <a href="/dashboard">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Digital
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="pt-sm-4 mt-2">
                <Button
                  onClick={() => setModalShow(true)}
                  id="pillStyle"
                  className="btnnew"
                  variant="primary"
                >
                  Create Digital
                </Button>
              </div>
            </div>
            <Row className="d-flex">
              <Col lg={12} sm={12} md={6} xs={12} className="mb-5 datacenter">
                <Paper className="col-lg-12 col-sm-8 rounded-4">
                  <Box sx={{}}>
                    <Row>
                      <Col lg={4} sm={12} className="text-lg-start ">
                        <h6 className="datahead text-muted">Digital List</h6>
                      </Col>
                      {/* categories starts */}
                      <Col lg={8} sm={12}>
                        <Row className="d-flex justify-content-end ">
                          <Col lg={6} sm={6} className="middleFilter">
                            <Form className="mt-3 d-flex gap-2 searchField">
                              <div className="col-lg-8 d-flex">
                                <input
                                  value={searchtext}
                                  onChange={(e) => searchChange(e.target.value)}
                                  onKeyDown={handleKeyDown}
                                  className="form-control nosubmit"
                                  style={{ fontSize: "14px" }}
                                  type="search"
                                  placeholder="Search Digital Type"
                                />
                              </div>
                              <div className="col-lg-4">
                              {!search ?
                                  <Button onClick={() => serachclick()} className='w-100 sortFilter1' variant="primary">Search</Button> :
                                  <Button onClick={() => resetclick()} className='w-100 sortFilter1' variant="primary">Reset</Button>
                                }
                                {/* <span className="searchcont" onClick={() => serachclick()} style={{cursor:'pointer'}}><i class="fa fa-search empsearch"></i></span> */}
                              </div>
                            </Form>
                          </Col>
                          {/* Export Excel */}
                          <Col lg={3} sm={2} className="middleFilter">

                            <Button
                              onClick={handleDownloadExcel}
                              disabled={isDownloading}
                              id="shareIcon"
                              variant="outlined"
                            >
                              <img
                                className="menuIcon mx-1 mb-1"
                                src={shareIcon}
                              />
                              {isDownloading ? "Downloading" : "Export Excel"}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                      {/* categories starts */}
                    </Row>
                    <Table responsive borderless>
                      <thead>
                        <tr className="theadborder">
                          <th>
                            <div className="table-headFilter ps-2">
                              <span>#</span>
                            </div>
                          </th>

                          <th>
                            <div className="table-headFilter">
                              <span>Digital Details</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="tdata">
                        {digital && digital.length > 0
                          ? digital.map((k, index) => {
                              return (
                                <tr>
                                  <td className="ps-3">{index + 1 + (page * rowsPerPage)}. </td>
                                  <td>{k.digitalType}</td>
                                  {/* <td>{k.isActive === true ? 'Active' : 'In Active'}</td> */}
                                  <td onClick={() => clickedit(k)} className="hand-cursor">
                                    <img
                                      className="menuIcon mx-1"
                                      src={editIcon}
                                    />
                                    Edit
                                  </td>
                                  {user ? (
                                    user.access.includes("AA") ? (
                                      <td onClick={() => Delete(k)} className="hand-cursor">
                                          <img
                                            className="menuIcon mx-1"
                                            src={deleteIconRed}
                                          />
                                          Delete
                                      </td>
                                    ) : null
                                  ) : null}                                     
                                  </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </Table>
                    <Row className="d-flex">
                      <Col>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={digicount}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Col>
                    </Row>
                  </Box>
                </Paper>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Modal
        show={modalShow}
        onHide={() => modalreset()}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{ width: '35%', marginLeft: '30%' }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Digital
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className=" rounded-4">
            <CardContent>
              <div className="row">
                <div className="form-group col-lg-12 col-md-12 col-12">
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="input-group">
                      <TextField
                        className="w-100"
                        value={digitalType}
                        onChange={(e) => setDigitalType(e.target.value)}
                        id="inputcolor"
                        type="text"
                        label="Digital*"
                        variant="outlined"
                      />
                    </div>
                    <div style={{ color: "#FF0000", fontSize: "10px" }}>
                        {edigitalType ? edigitalType : null}
                      </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-6">
                      <Button
                        id="pillStyle"
                        onClick={() => modalreset()}
                        value="modalreset"
                        className="w-100"
                        variant="outline-primary"
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="col-lg-6">
                    {!isLoading ? (
                      <Button
                        onClick={() => create()}
                        id="pillStyle"
                        className="W-100"
                        variant="primary"
                      >
                        Create Digital
                      </Button>
                       ) : (
                        <button className="buttonload w-100" id="pillStyle">
                          <i className="fa fa-spinner fa-spin"></i> Loading
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Modal.Body>
      </Modal>
      <Modal
        show={editmodal}
        onHide={() => setEditModalShow(false)}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{ width: '35%', marginLeft: '30%' }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Digital
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className=" rounded-4">
            <CardContent>
              <div className="row">
                <div className="form-group col-lg-12 col-md-12 col-12">
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="input-group ">
                      <TextField
                        className="w-100"
                        value={digidetails}
                        onChange={(e) => setDigiDetails(e.target.value)}
                        id="inputcolor"
                        type="text"
                        label="Digital*"
                        variant="outlined"
                      />
                    </div>
                    <div style={{ color: "#FF0000", fontSize: "10px" }}>
                        {edigidetails ? edigidetails : null}
                      </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-6">
                      <Button
                        onClick={() => setEditModalShow(false)}
                        id="pillStyle"
                        className="w-100"
                        variant="outline-primary"
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="col-lg-6">
                    {!isLoading ? (
                      <Button
                        onClick={() => handlesubmit()}
                        id="pillStyle"
                        className="w-100"
                        variant="primary"
                      >
                        Update Digital
                      </Button>
                       ) : (
                        <button className="buttonload w-100" id="pillStyle">
                          <i className="fa fa-spinner fa-spin"></i> Loading
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    userdata: state.userdetails,
  };
};
export default connect(mapStateToProps)(Digital);
