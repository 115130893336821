import httpService from "./httpService";
export async function getdigital(obj) {
	
	const data = await httpService.post('/newMaster/digital/getAll',obj);
	return data;
}
export async function adddigital(obj) {
	
	const data = await httpService.post('/newMaster/digital/add',obj);
	return data;
}

export default {
	getdigital,
	adddigital
}