import React, { useEffect, useState } from "react";
import "../../../Styles/JobCards.css";
import Header from "../../header/Header";
import Sidebar from "../../Sidenav/Sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import filterIcon from "../../../Images/filterIcon.png";
import shareIcon from "../../../Images/shareIcon.png";
import sortIcon from "../../../Images/sortIcon.png";
import calenderIcon from "../../../Images/calenderIcon.png";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
// import Table from 'react-bootstrap/Table';
import { NavLink } from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router";
import Modal from "react-bootstrap/Modal";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import estimateService from "../../../services/estimateService";
import MasterService from "../../../services/MasterService";
import { toast } from "react-toastify";
import axios from "axios";
import editIcon from "../../../Images/editIcon.svg";
import { Table, Pagination } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import deleteIconRed from '../../../Images/deleteIconRed.svg';
import { connect } from "react-redux";
import get_userdetails from "../../../redux/actions/getuserDetailsAction";

const PaperGsm = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUserdata] = useState();
  useEffect(() => {
    getuserdetails();
  }, [props.userdata]);
  useEffect(() => {
    get_userdetails();
  }, []);

  const getuserdetails = async () => {
    console.log(props.userdata.user, "props.userdata.user");
    setUserdata(props.userdata.user);
  };
  const [search, setSearch] = useState(false);
  const [searchtext, setSearchText] = useState("");
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pgcount, setPGcount] = useState(0);

  const handleChangePage = (event, newPage) => {
    const dett = newPage * rowsPerPage;
    setPage(newPage);
    getpapergsmAll(dett, rowsPerPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getpapergsmAll(0, event.target.value);
  };
  let navigate = useNavigate();
  function editjob() {
    navigate("/editjob");
  }
  const [modalShow, setModalShow] = React.useState(false);
  const [editmodal, setEditModalShow] = React.useState(false);

  const [PaperGsm, setPaperGsm] = useState([]);
  useEffect(() => {
    getpapergsmAll(0, 10);
  }, []);

  const getpapergsmAll = async (skps, rpp) => {
    try {
      const obj = {
        skip: skps,
        limit: rpp,
        // "searchkeys": {"paperType":searchtext}
      };
      const dert = await estimateService.getpapergsmAll(obj);
      console.log(dert, "dert");
      if (dert.data.status === "success") {
        console.log(dert.data.value, "values");
        setPaperGsm(dert.data.value);
        setPGcount(dert.data.count);
      }
    } catch (ex) {}
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      serachclick();
     
    }
  };
  //SearchAPI
  const searchChange = (value) => {
    setSearchText(value);
    // getestimates(0,10)
  };
  const serachclick = async () => {
    const trimmedSearchText = searchtext.trim(); 
    if (trimmedSearchText === "") {
      toast.error("Enter Text", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
   
    }
    try {
      const obj = {
        skip: 0,
        limit: rowsPerPage,
        searchkeys: { paperType: trimmedSearchText },
      };
      const dert = await estimateService.getpapergsmAll(obj);
      console.log(dert, "dert");
      if (dert.data.status === "success") {
        setSearch(true);
        console.log(dert.data.value, "values");
        setPaperGsm(dert.data.value);
        setPGcount(dert.data.count);
      }
    } catch (ex) {}
  };
  const resetclick = () => {
    setSearchText("");
    setSearch(false);
    getpapergsmAll(0, rowsPerPage);
  };
  function modalreset() {
    setPaperType("");
    setPerKgRate("");
    setGsm("");
    setEgsmPaperType("");
    setEPGsm("");
    setEPGPerKgRate("");
    setEPaperType("");
    setEGsm("");
    setEPerKgRate("");
    setModalShow(false);
  }
  //SearchAPI Close

  const [papertype, setPaperType] = useState("");
  const [epapertype, setEPaperType] = useState("");
  // const [printextraImpressionquantity, setPrintExtraImpressionQuantity] = useState('');
  const [perkgRate, setPerKgRate] = useState("");
  const [eperkgRate, setEPerKgRate] = useState("");
  const [gsm, setGsm] = useState("");
  const [egsm, setEGsm] = useState("");
  // const [status, setStatus] = useState('');
  // const [printmincost, setPrintMinCost] = useState('');
  // const [printextraImpressioncost, setPrintExtraImpressionCost] = useState('');
  // const [printminImpressionquantity, setPrintMinImpressionQuantity] = useState('');

  // function editjob() {
  //     navigate('/editjob');
  // };
  const handleChangePerkgRate = (event) => {
    const { value } = event.target;
    setPerKgRate(value.replace(/\D/g, ''));
  };

  const handleChangeGsm = (event) => {
    const { value } = event.target;
    setGsm(value.replace(/\D/g, ''));
  };

  const create = async () => {
    
    if (!papertype) {
      await setSBtndis(false);
      setEPaperType("Paper Type Is Required!");
      toast.error("Enter Paper Type", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!gsm) {
      await setSBtndis(false);
      setEPaperType("");
      setEGsm("Gsm Is Required!");
      toast.error("Enter Gsm", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!perkgRate) {
      await setSBtndis(false);
      setEPaperType("");
      setEGsm("");
      setEPerKgRate("Per Kg Rate Is Required!");
      toast.error("Enter Per Kg Rate", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
   
      // } else if (!printmincost) {
      //     await setSBtndis(false)
      //     toast.error('Enter Per Kg Rate', {
      //         position: toast.POSITION.BOTTOM_LEFT
      //     });
      // } else if (!printminImpressionquantity) {
      //     await setSBtndis(false)
      //     toast.error('Enter Per Kg Rate', {
      //         position: toast.POSITION.BOTTOM_LEFT
      //     });
      // } else if (!printextraImpressioncost) {
      //     await setSBtndis(false)
      //     toast.error('Enter Per Kg Rate', {
      //         position: toast.POSITION.BOTTOM_LEFT
      //     });
      // } else if (!printextraImpressionquantity) {
      //     await setSBtndis(false)
      //     toast.error('Enter Per Kg Rate', {
      //         position: toast.POSITION.BOTTOM_LEFT
      //     });
    } else {
      setEPaperType("");
      setEGsm("");
      setEPerKgRate("");
    try {
      setIsLoading(true);
      const obj = {
        paperType: papertype,
        perkgRate: perkgRate,
        gsm: gsm,
        // "printmincost": printmincost,
        // "printminImpressionquantity": printminImpressionquantity,
        // "printextraImpressioncost": printextraImpressioncost,
        // "printextraImpressionquantity": printextraImpressionquantity
      };
      console.log(obj, "objjj");
      const dert = await MasterService.addpapergsm(obj);
      console.log(dert.data, "dert.data");
      if (dert.data) {
        toast.success("Paper Gsm Added Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        navigate("/papergsm");
        getpapergsmAll(0, 10);
        setModalShow(false);
        setPaperType("");
        setPerKgRate("");
        setGsm("");
        
      } else {
        toast.error(dert.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (ex) {
      if (ex.response.status === 400) console.log(ex.response, "ex.response");
      toast.error(ex.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }finally{
      setIsLoading(false);
    }}
  };
  //edit integration
  const [edmill, setedmill] = useState({});
  const [papergsmtype, setgsmPaperType] = useState("");
  const [epapergsmtype, setEgsmPaperType] = useState("");
  // const [pgprintextraImpressionquantity, setPGPrintExtraImpressionQuantity] = useState('');
  const [pgperkgRate, setPGPerKgRate] = useState("");
  const [epgperkgRate, setEPGPerKgRate] = useState("");
  const [pgsm, setPGsm] = useState("");
  const [epgsm, setEPGsm] = useState("");
  // const [pgprintmincost, setPGPrintMinCost] = useState('');
  // const [pgprintextraImpressioncost, setPGPrintExtraImpressionCost] = useState('');
  // const [pgprintminImpressionquantity, setPGPrintMinImpressionQuantity] = useState('');
  // const [pgstatus, setPGStatus] = useState('');
  const handleChanges = (event) => {
    const { value } = event.target;
    setPGPerKgRate(value.replace(/\D/g, ''));
  };
  const handleCGsm = (event) => {
    const { value } = event.target;
    setPGsm(value.replace(/\D/g, ''));
  };

  const clickedit = (k) => {
    // console.log(k,'editmill');
    setedmill(k);
    setgsmPaperType(k.paperType);
    setPGPerKgRate(k.perkgRate);
    // setPGPrintExtraImpressionQuantity(k.printextraImpressionquantity);
    setPGsm(k.gsm);
    // setPGStatus(k.active);
    // setPGPrintMinCost(k.printmincost);
    // setPGPrintExtraImpressionCost(k.printextraImpressioncost);
    // setPGPrintMinImpressionQuantity(k.printminImpressionquantity);
    setEditModalShow(true);
  };
  const handlesubmit = async () => {
  
    if (!papergsmtype) {
      await setSBtndis(false);
      setEgsmPaperType("Paper Type Is Required!");
      toast.error("Enter Paper Type", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!pgsm) {
      await setSBtndis(false);
      setEgsmPaperType("");
      setEPGsm("Gsm Is Required!");
      toast.error("Enter Gsm", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!pgperkgRate) {
      await setSBtndis(false);
      setEgsmPaperType("");
      setEPGsm("");
      setEPGPerKgRate("Per Kg Rate Is Required!");
      toast.error("Enter Per Kg Rate", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    
      // } else if (!pgprintmincost) {
      //     await setSBtndis(false)
      //     toast.error('Enter Per Kg Rate', {
      //         position: toast.POSITION.BOTTOM_LEFT
      //     });
      // } else if (!pgprintminImpressionquantity) {
      //     await setSBtndis(false)
      //     toast.error('Enter Per Kg Rate', {
      //         position: toast.POSITION.BOTTOM_LEFT
      //     });
      // } else if (!pgprintextraImpressioncost) {
      //     await setSBtndis(false)
      //     toast.error('Enter Per Kg Rate', {
      //         position: toast.POSITION.BOTTOM_LEFT
      //     });
      // } else if (!pgprintextraImpressionquantity) {
      //     await setSBtndis(false)
      //     toast.error('Enter Per Kg Rate', {
      //         position: toast.POSITION.BOTTOM_LEFT
      //     });
    } else {
      setEgsmPaperType("");
      setEPGsm("");
      setEPGPerKgRate("");
    try {
      setIsLoading(true);
      const obj = {
        _id: edmill._id,
        paperType: papergsmtype,
        perkgRate: parseInt(pgperkgRate),
        gsm: parseInt(pgsm),
        // "printmincost": parseInt(pgprintmincost),
        // "printminImpressionquantity": parseInt(pgprintminImpressionquantity),
        // "printextraImpressioncost": parseInt(pgprintextraImpressioncost),
        // "printextraImpressionquantity": parseInt(pgprintextraImpressionquantity),
        // "_id": "63dce0d6d72dd80014d3bf58"
      };
      const dort = await MasterService.updatepapergsm(obj);
      if (dort.status === 200) {
        getpapergsmAll(rowsPerPage, rowsPerPage);
        await setSBtndis(false);
        navigate("/papergsm");
        setEditModalShow(false);
        toast.success("Paper Gsm Updated Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (ex) {
      await setSBtndis(false);
      toast.error(ex.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }finally{
      setIsLoading(false);
    }}
  };
  //edit integration close

  // download Excel

  const [isDownloading, setIsDownloading] = useState(false);

  // const handleDownloadExcel = async () => {
  //   try {
  //     setIsDownloading(true);
  //     const response = await axios.post(
  //       "/newMaster/papergsm/jsontoexcel",
  //       { data: [] },
  //       { responseType: "blob" }
  //     );
  //     const excelBlob = new Blob([response.data], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });
  //     const url = window.URL.createObjectURL(excelBlob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = "PaperGsms.xlsx";
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setIsDownloading(false);
  //   }
  // };

  const handleDownloadExcel = async () => {
    try {
      setIsDownloading(true);
      const requestData = {
        // searchkeys : searchtext 
        searchkeys: { paperType: searchtext },     
      };
      const response = await axios.post(
        "/newMaster/papergsm/jsontoexcel",
        requestData,
        { responseType: "blob" }
      );
      const excelBlob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(excelBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "PaperGsm.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    } finally {
      setIsDownloading(false);
    }
  };

  //  Download Excel Close

  //validations
  const [sbtndis, setSBtndis] = useState(false);
  //Validations close

  //pagination
  //     const [currentPage, setCurrentPage] = useState(1);
  //     const handlePageChange = (pageNumber) => {
  //         setCurrentPage(pageNumber);
  //     }

  //     const itemsPerPage = 5; // Change this as needed
  //     const totalItems = obj.length; // Replace myData with your data array
  //     const totalPages = Math.ceil(totalItems / itemsPerPage);

  //     const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;
  // const currentItems = obj.slice(startIndex, endIndex);

  //pagination close
  const Delete = async (item) => {
        
    try {
      const obj = {
       "_id":item._id,
      }
   
      const dert = await MasterService.deletePaperGsm(obj);
      console.log(dert, "dert");

      if (dert.data.message) {
        toast.success("Paper Gsm Deleted Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        getpapergsmAll(0, 10);       // setSearch(true)
        console.log(dert.data.value, "values");
      
           // setBreadth('');
           // setLength('');
           // setDetails('')
      }
    } catch (ex) {

    }
  }

  return (
    <>
      <Header />
      <Container fluid className="containerr">
        <Row>
          <Col lg={2} id="sidebar-wrapper" className="d-none d-md-block">
            <Sidebar />
          </Col>
          <Col lg={10} sm={4} className="containerr px-3 px-lg-4">
            <div className="pt-4 pb-2 d-flex justify-content-between align-items-center">
              <div>
                <h1 className="fs-3">Paper Gsm</h1>
                <nav aria-label="breadcrumb" className="fs-14">
                  <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item">
                      <a href="/dashboard">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Paper Gsm
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="pt-sm-4">
                <Button
                  onClick={() => setModalShow(true)}
                  id="pillStyle"
                  className="btnnew"
                  variant="primary"
                >
                  Create Paper Gsm
                </Button>
              </div>
            </div>
            <Row className="d-flex">
              <Col lg={12} sm={12} md={6} xs={12} className="mb-5 datacenter">
                <Paper className="col-lg-12 col-sm-8 rounded-4">
                  <Box sx={{}}>
                    <Row className="d-flex flex-column flex-lg-row justify-content-between">
                      <Col lg={4} sm={12} className="text-lg-start ">
                        <h6 className="datahead text-muted">Paper Gsm List</h6>
                      </Col>
                      {/* categories starts */}
                      <Col lg={8} sm={12} className="px-4 pb-2">
                        <Row className="d-flex justify-content-end ">
                          <Col lg={5}>
                            <Form className="mt-3 d-flex gap-2 searchField">
                              <div className="col-lg-8 d-flex">
                                <input
                                  value={searchtext}
                                  onChange={(e) => searchChange(e.target.value)}
                                  onKeyDown={handleKeyDown}
                                  className="form-control nosubmit"
                                  style={{ fontSize: "14px" }}
                                  type="search"
                                  placeholder="Search Paper Type"
                                />
                              </div>
                              <div className="col-lg-4">
                                {!search ? (
                                  <Button
                                    onClick={() => serachclick()}
                                    className="w-100 sortFilter1"
                                    variant="primary"
                                  >
                                    Search
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => resetclick()}
                                    className="w-100 sortFilter1"
                                    variant="primary"
                                  >
                                    Reset
                                  </Button>
                                )}{" "}
                                {/* <span className="searchcont" onClick={() => serachclick()} style={{cursor:'pointer'}}><i class="fa fa-search empsearch"></i></span> */}
                              </div>
                            </Form>
                          </Col>
                          {/* sort filter */}
                          {/* <Col lg={2} sm={6} >
                                                        <PopupState variant="popover" popupId="demo-popup-popover">
                                                            {(popupState) => (
                                                                <div >
                                                                    <Button id='sortfilter' className='sortFilter1 mt-3' variant="contained" {...bindTrigger(popupState)}>
                                                                        <img className="menuIcon mx-1" src={filterIcon} />
                                                                        Filter
                                                                    </Button>
                                                                    <Popover
                                                                        {...bindPopover(popupState)}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'center',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'center',
                                                                        }}
                                                                    >
                                                                        <Typography sx={{ p: 2 }}>
                                                                            <h6>Filter</h6>
                                                                            <h6>Order Type</h6>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Home Delivery"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Pickup"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-2`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <hr className='text-muted'></hr> <hr className='text-muted'></hr>
                                                                            <div>
                                                                                <h6>Status</h6>
                                                                                <Form.Select aria-label="Default select example">
                                                                                    <option>All</option>
                                                                                    <option value="1">One</option>
                                                                                    <option value="2">Two</option>
                                                                                </Form.Select>
                                                                                <h6>Customer</h6>
                                                                                <Form.Select aria-label="Default select example">
                                                                                    <option>All</option>
                                                                                    <option value="1">One</option>
                                                                                    <option value="2">Two</option>
                                                                                </Form.Select>
                                                                                <Form>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <Form.Label>From</Form.Label>
                                                                                            <Form.Control className='filterFields' placeholder="0.00" />
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <Form.Label>To</Form.Label>
                                                                                            <Form.Control placeholder="0.00" />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Form>
                                                                                <Button id='pillStyle' className='w-100 mt-3' variant="primary">Filter</Button>{' '}
                                                                            </div>
                                                                        </Typography>
                                                                    </Popover>
                                                                </div>
                                                            )}
                                                        </PopupState>
                                                    </Col> */}
                          {/* date filter */}
                          {/* <Col lg={2} sm={6}>
                                                        <PopupState variant="popover" popupId="demo-popup-popover">
                                                            {(popupState) => (
                                                                <div>
                                                                    <Button id='sortfilter' className='sortFilter2 mt-3' variant="contained" {...bindTrigger(popupState)}>
                                                                        <img className="menuIcon mx-1 mb-1" src={calenderIcon} />
                                                                        Filter
                                                                    </Button>
                                                                    <Popover
                                                                        {...bindPopover(popupState)}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'center',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'center',
                                                                        }}
                                                                    >
                                                                        <Typography sx={{ p: 2 }}>
                                                                            <h6 className='mb-3'>By Date</h6>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="This Week"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Last Week"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-2`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="This Month"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Last Month"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-2`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="This Year"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />&nbsp;
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Last Year"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-2`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <hr className='text-muted'></hr>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Date Range"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <Nav id='navPill' variant="pills" defaultActiveKey="/home">
                                                                                <Nav.Item>
                                                                                    <Nav.Link id='pillStyle' href="/home">From</Nav.Link>
                                                                                </Nav.Item>
                                                                                <Nav.Item>
                                                                                    <Nav.Link id='pillStyle' eventKey="link-1">To</Nav.Link>
                                                                                </Nav.Item>
                                                                            </Nav>
                                                                            <Col lg={2} sm={2} md={2}>
                                                                                <DateRange
                                                                                    editableDateInputs={true}
                                                                                    onChange={item => setState([item.selection])}
                                                                                    moveRangeOnFirstSelection={false}
                                                                                    ranges={state}
                                                                                /></Col>
                                                                            <Button className='w-100 mt-3' id='pillStyle' variant="primary">Filter</Button>{' '}
                                                                        </Typography>
                                                                    </Popover>
                                                                </div>
                                                            )}
                                                        </PopupState>
                                                    </Col> */}
                          {/* Export Excel */}
                          <Col lg={3} sm={2} className="middleFilter">
                            <Button
                              onClick={handleDownloadExcel}
                              disabled={isDownloading}
                              id="shareIcon"
                              variant="outlined"
                            >
                              <img
                                className="menuIcon mx-1 mb-1"
                                src={shareIcon}
                              />
                              {isDownloading ? "Downloading" : "Export Excel"}
                            </Button>
                          </Col>
                          {/* Bulk Select */}
                          {/* <Col lg={3} sm={2}>
                                                        <Form.Select id='selectBtn' aria-label="Default select example">
                                                            <option hidden className='text-center'>Bulk Action</option>
                                                            <option value="Cancel">Cancel</option>
                                                            <option value="Delete">Delete</option>
                                                        </Form.Select>
                                                    </Col> */}
                        </Row>
                      </Col>
                      {/* categories starts */}
                    </Row>
                    <Table responsive borderless>
                      <thead>
                        <tr className="theadborder">
                          {/* <th>
                                                        <div className='table-headFilter'>
                                                            <Form.Check aria-label="option 1" /></div>
                                                    </th> */}
                                                    <th>
                                                        <div className='table-headFilter ps-2'>
                                                            <span>#</span>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className='table-headFilter'>
                                                            <span>Paper</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th>

                                                    <th>
                                                        <div className='table-headFilter'>
                                                            <span>Gsm</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className='table-headFilter'>
                                                            <span>Rate</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th>
                                                    {/* <th>
                                                        <div className='table-headFilter'>
                                                            <span>Print Min Cost</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className='table-headFilter'>
                                                            <span>print Min Impression Quantity</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className='table-headFilter'>
                                                            <span>print Extra Impression Cost</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className='table-headFilter'>
                                                            <span>print Extra Impression Quantity</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th> */}
                          {/* <th>
                                                        <div className='table-headFilter'>
                                                            <span>Status</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th> */}
                                                </tr>
                                            </thead>
                                            <tbody className='tdata'>
                                                {PaperGsm && PaperGsm.length > 0 ?
                                                    PaperGsm.map((k, index) => {
                                                        return (
                                                            <tr>
                                                                {/* <Form.Check aria-label="option 1" /> */}
                                                                <td className="ps-3">{index + 1 + (page * rowsPerPage)}. </td>
                                                                <td>{k.paperType}</td>
                                                                <td>{k.gsm}</td>
                                                                <td>{k.perkgRate}</td>
                                                                {/* <td>{k.printmincost}</td>
                                                                <td>{k.printminImpressionquantity}</td>
                                                                <td>{k.printextraImpressioncost}</td>
                                                                <td>{k.printextraImpressionquantity}</td> */}
                                  {/* <td>{k.isActive === true ? 'Active' : 'In Active'}</td> */}
                                  <td onClick={() => clickedit(k)} className="hand-cursor">
                                    <img
                                      className="menuIcon mx-1"
                                      src={editIcon}
                                    />
                                    Edit
                                  </td>
                                  {user ? (
                                    user.access.includes("AA") ? (
                                      <td onClick={() => Delete(k)} className="hand-cursor">
                                          <img
                                            className="menuIcon mx-1"
                                            src={deleteIconRed}
                                          />
                                          Delete
                                      </td>
                                    ) : null
                                  ) : null}                                     </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </Table>
                    <Row className="d-flex">
                      <Col>
                        {/* <Pagination
                                                    className="justify-content-center"
                                                    size="sm"
                                                    activePage={currentPage}
                                                    itemsCountPerPage={itemsPerPage}
                                                    totalItemsCount={totalItems}
                                                    pageRangeDisplayed={5}
                                                    onChange={handlePageChange}
                                                /> */}
                        <TablePagination
                          component="div"
                          count={pgcount}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Col>
                    </Row>
                  </Box>
                </Paper>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // style={{ width: '35%', marginLeft: '30%' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add paper with Gsm                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className=" rounded-4">
                        <CardContent>
                            <div className="row">
                                <div className="form-group col-lg-12 col-md-12 col-12">
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group">
                                          
                                               <TextField
                                              className="w-100"
                                              value={papertype}
                                              onChange={(e) => setPaperType(e.target.value)}
                                               id="inputcolor"
                                                type="text"
                                                 label="Paper *"
                                                 variant="outlined"

                                                />
                                        </div>
                                        <div style={{ color: "#FF0000", fontSize: "10px" }}>
                                                {epapertype ? epapertype : null}
                                            </div>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mt-3">
                                          
                                            <TextField
                                              className="w-100"
                                              value={gsm}
                                              onChange={(e) => setGsm(e.target.value)}
                                               id="inputcolor"
                                                type="text"
                                                 label="Gsm*"
                                                 variant="outlined"

                                                />
                                        </div>
                                        <div style={{ color: "#FF0000", fontSize: "10px" }}>
                                                {egsm ? egsm : null}
                                            </div>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mt-3">
                                        
                                              <TextField
                                              className="w-100"
                                              value={perkgRate}
                                              onChange={(e) => setPerKgRate(e.target.value)}
                                               id="inputcolor"
                                                type="text"
                                                 label="Rate*"
                                                 variant="outlined"

                                                />
                                        </div>
                                        <div style={{ color: "#FF0000", fontSize: "10px" }}>
                                                {eperkgRate ? eperkgRate : null}
                                            </div>
                                    </div>
                              
                                    {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                value={printmincost}
                                                onChange={(e) => setPrintMinCost(e.target.value)}
                                                id='inputcolor'
                                                placeholder="Print Min Cost*"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                value={printminImpressionquantity}
                                                onChange={(e) => setPrintMinImpressionQuantity(e.target.value)}
                                                id='inputcolor'
                                                placeholder="Print Min Impression Quantity*"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                value={printextraImpressioncost}
                                                onChange={(e) => setPrintExtraImpressionCost(e.target.value)}
                                                id='inputcolor'
                                                placeholder="Print Extra Impression Cost*"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                value={printextraImpressionquantity}
                                                onChange={(e) => setPrintExtraImpressionQuantity(e.target.value)}
                                                id='inputcolor'
                                                placeholder="Print Extra Impression Quantity*"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            />
                                        </div>
                                    </div> */}
                  {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <select value={status} onChange={(e) => setStatus(e.target.value)} class="form-select text-muted" id='inputcolor' aria-label="Default select example">
                                                        <option selected hidden>Status</option>
                                                        <option value={true}>Active</option>
                                                        <option value={false}>In Active</option>
            
                                                    </select>
                                                </div>
                                            </div> */}
                </div>


                                    <div className="row mt-3">
                                        <div className="col-lg-1"></div>
                                        <div className="col-lg-5">
                                        <Button id='pillStyle' onClick={() => modalreset()}

value='modalreset' className='w-100' variant="outline-primary">Cancel</Button>
                                        </div>
                                        <div className="col-lg-6">
                                        {!isLoading ? (
                                            <Button onClick={() => create()} id='pillStyle' className='' variant="primary">Create PaperGsm</Button>
                                            ) : (
                                              <button className="buttonload w-100" id="pillStyle">
                          <i className="fa fa-spinner fa-spin"></i> Loading
                        </button>
                                            )}
                                        </div>
                                    </div>
                                </div>

                        </CardContent>
                    </Card>
                </Modal.Body>
            </Modal>
            <Modal
                show={editmodal}
                onHide={() => setEditModalShow(false)}
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // style={{ width: '35%', marginLeft: '30%' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Paper Gsm                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className=" rounded-4">
                        <CardContent>
                            <div className="row">
                                <div className="form-group col-lg-12 col-md-12 col-12">
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group">
                                           
                                                <TextField
                                              className="w-100"
                                              value={papergsmtype}
                                              onChange={(e) => setgsmPaperType(e.target.value)}
                                               id="inputcolor"
                                                type="text"
                                                 label="Paper*"
                                                 variant="outlined"

                                                />
                                        </div>
                                        <div style={{ color: "#FF0000", fontSize: "10px" }}>
                                                {epapergsmtype ? epapergsmtype : null}
                                            </div>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mt-3">
                                            {/* <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                value={pgsm}
                                                onChange={(e) => setPGsm(e.target.value)}
                                                id='inputcolor'
                                                placeholder="Gsm*"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            /> */}
                                               <TextField
                                              className="w-100"
                                              value={pgsm}
                                              onChange={handleCGsm}
                                               id="inputcolor"
                                                type="text"
                                                 label="Gsm*"
                                                 variant="outlined"

                                                />
                                        </div>
                                        <div style={{ color: "#FF0000", fontSize: "10px" }}>
                                                {epgsm ? epgsm : null}
                                            </div>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mt-3">
                                            {/* <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                value={pgperkgRate}
                                                onChange={(e) => setPGPerKgRate(e.target.value)}
                                                id='inputcolor'
                                                placeholder="Per Kg Rate*"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            /> */}
                                             <TextField
                                              className="w-100"
                                              value={pgperkgRate}
                                                onChange={handleChanges}
                                               id="inputcolor"
                                                type="text"
                                                 label=" Rate*"
                                                 variant="outlined"

                                                />
                                        </div>
                                        <div style={{ color: "#FF0000", fontSize: "10px" }}>
                                                {epgperkgRate ? epgperkgRate : null}
                                            </div>
                                    </div>
                                  
                                    {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                value={pgprintmincost}
                                                onChange={(e) => setPGPrintMinCost(e.target.value)}
                                                id='inputcolor'
                                                placeholder="Print Min Cost*"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                value={pgprintminImpressionquantity}
                                                onChange={(e) => setPGPrintMinImpressionQuantity(e.target.value)}
                                                id='inputcolor'
                                                placeholder="Print Min Impression Quantity*"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                value={pgprintextraImpressioncost}
                                                onChange={(e) => setPGPrintExtraImpressionCost(e.target.value)}
                                                id='inputcolor'
                                                placeholder="Print Extra Impression Cost*"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                value={pgprintextraImpressionquantity}
                                                onChange={(e) => setPGPrintExtraImpressionQuantity(e.target.value)}
                                                id='inputcolor'
                                                placeholder="Print Extra Impression Quantity*"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            />
                                        </div>
                                    </div> */}
                  {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <select value={pgstatus} onChange={(e) => setPGStatus(e.target.value)} class="form-select text-muted" id='inputcolor' aria-label="Default select example">
                                                        <option selected hidden>Status</option>
                                                        <option value={true}>Active</option>
                                                        <option value={false}>In Active</option>
            
                                                    </select>
                                                </div>
                                            </div> */}

                  <div className=" mt-3 gap-1 d-flex flex-column flex-lg-row">
                        <div className="col-lg-6">
                          <Button
                            onClick={() => setEditModalShow(false)}
                            id="pillStyle"
                            className="w-100"
                            variant="outline-primary"
                          >
                            Cancel
                          </Button>
                        </div>
                        <div className="col-lg-6">
                        {!isLoading ? (
                          <Button
                              onClick={() => handlesubmit()}
                            id="pillStyle"
                            className="w-100"
                            variant="primary"
                          >
                          Update PaperGsm
                          </Button>
                          ) : (
                            <button className="buttonload w-100" id="pillStyle">
                          <i className="fa fa-spinner fa-spin"></i> Loading
                        </button>
                          )}
                        </div>
                      </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    userdata: state.userdetails,
  };
};
export default connect(mapStateToProps)(PaperGsm);