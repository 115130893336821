import React, { useEffect, useState, useRef } from "react";

import "../../Styles/Orders.css";
import Header from "../header/Header";
import Container from "react-bootstrap/Container";
import Sidebar from "../Sidenav/Sidebar";
import aretsLogo from "../../Images/aretsLogo.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Moment from "moment";
// import Paper from '@mui/material/Paper';

// import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
// import Button from '@mui/material/Button';
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import QrCode from "../../Images/QrCode.png";
import Button from "react-bootstrap/Button";
import { NavLink, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Nav } from "react-bootstrap";
import estimateService from "../../services/estimateService";
import authService from "../../services/authService";
import ClientsService from "../../services/ClientsService";
import ReceiptService from "../../services/ReceiptService";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
// import numberToWords from "number-to-words";

function PrintReceipt() {
  
  // var numberToWords = require('number-to-words');
  const location = useLocation();
  const paidAmount =   location.state?.item?.paidAmount;
  // const amountInWords = numberToWords.toWords(paidAmount);
  const numWords = require('num-words')
  const amountInWords = numWords(paidAmount)
const uppercaseString = amountInWords.toUpperCase();
  // const [clients, setClients] = useState([]);
  // const [order, setOrder] = useState()
  console.log(location.state, "print-estimate item");
  // const [client, setClient] = useState(location.state?.item ? location.state.item.customerId : '');
  // useEffect(() => {
  //     getclients()
  // }, [])

  // const getclients = async () => {

  //     try {

  //         const dert = await ClientsService.getclients();
  //         if (dert.data.status === 'success') {
  //             console.log(dert.data.value, 'values');
  //             setClients(dert.data.value);
  //         }
  //     } catch (ex) {

  //     }
  // }

  // const [receipts, setreceipts] = useState([]);
  // useEffect(() => {
  //     getreceipts(0, 10);
  // }, [])

  // const getreceipts = async (skps, rpp) => {
  //     try {
  //         const obj = {
  //             "indentId": location.state.item?.orderId
  //         }
  //         const dert = await ReceiptService.getreceipts(obj);
  //         if (dert.data.status === 'success') {
  //             console.log(dert.data.value, 'values');
  //             setreceipts(dert.data.value);
  //             // setReceiptCount(dert.data.count)
  //         }
  //     } catch (ex) {

  //     }

  // }
   const scaleFactor = 210 / 100; // Assuming elementWidth is the width of the "capture" element
  const scaledHeight = 70 * scaleFactor;
  const payref = useRef();
  const [tab, setTab] = useState("Order Confirmation");
  const [payview, setpayview] = useState(true);
  const savepdf = () => {
    html2canvas(document.querySelector("#capture"), { dpi: 300 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 0, 0, 210, scaledHeight); 
            pdf.save("Cash Receipt.pdf");
    });
  };

  // const [orders, setOrders] = useState([])
  // useEffect(() => {
  //     getorders(0, 10);
  // }, [])

  // const getorders = async (skps, rpp) => {
  //     try {
  //         const obj = {
  //             skip: skps,
  //             limit: rpp,
  // "searchkeys": '',
  //         }
  //         const dert = await authService.getorders(obj);
  //         if (dert.data.status === 'success') {
  //             console.log(dert.data.value, 'values');
  //             setOrders(dert.data.value);
  //             // setOrderCount(dert.data.count)
  //         }
  //     } catch (ex) {

  //     }

  // }
  // console.log('order============', order)
  console.log(location.state.item, "location");
  // console.log(clients, 'clients');
  // console.log(order, 'order');
  return (
    <>
      <Header />
      <Container fluid className="containerr">
        <Row>
          {/* <Col lg={2} id="sidebar-wrapper" className='d-none d-md-block'>
            <Sidebar />
          </Col> */}
          <Col lg={12} className="p-3">
            <div>
              <Breadcrumb className="ms-lg-5">
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/receipt">Receipts</Breadcrumb.Item>
                <Breadcrumb.Item active>Print Receipt</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Card  className="mt-3 col-lg-12 rounded-4">
              <CardContent id="capture">
              <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12 col-sm-12 col-md-12">
                                                <div className="d-lg-flex">
                                                <div className="col-lg-8 mb-2 mt-5">
                              <img
                                src={aretsLogo}
                                alt="branding"
                                className="loginPage-brandImg mb-1"
                                style={{width: '70px', height: '60px', marginLeft : '85px',}}
                              />
                              <h3  style={{  fontSize: '29px', fontWeight: "400px" }}>
                               <span style={{color : '#1896cc'}}> AReTS GRAPHICS</span>
                                <h6 className='ms-lg-4 text-middle' style={{ fontSize: "16px", fontWeight: '400' }}>
                                  EVERYTHING IN PRINTING
                                </h6>
                                </h3>
                            </div>
                                                    <div className="col-lg-4 mt-5 mb-2">
                                                        <h6 className='fw-bolder m-0'>Regd. Office :</h6>
                                                        <h6 className='fw-normal m-0'>15-4-64, Gowliguda Chaman, Hyderabad 500012</h6>
                                                        <h6 className='fw-normal m-0'><span className='fw-bold'>Works :</span> 7-3-50, Airport road, Gagan Pahad,<br></br> Rajendra Nagar, GHMC</h6>
                                                        <h6 className='fw-normal m-0'>Phone: 9908085293 / 80192 09990 / 9393 00 9992</h6>
                                                        <h6 className='fw-normal m-0'>GSTIN: 36AEYPM2529L1ZR</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                <section>
                  {/* <hr></hr> */}
                  <div style={{}} className="container-fluid text-center border-dark border-top border-bottom pt-2 mb-1">
                    <h3>CASH RECEIPT</h3>
                  </div>                 
                   {/* <hr></hr> */}
                  <div className="container">
                  <div className="d-lg-flex col-lg-12">
                  <div style={{float : 'right'}} className=" col-lg-8 d-flex mt-5 mb-4">
                    <h6 className="me-3 mt-2">Rs.</h6>
                    <input className="col-lg-2  mt-1 text-center" style={{height : "30px"}} type ="text" value={"₹" +" " + paidAmount + " " + '/-'}></input>
                  </div>
                    <p className="col-lg-4">
                      <br />
                      Date : {Moment(location.state.item?.createDate).format(
                        "DD MMMM YYYY"
                      )}&nbsp;
                      <br />
                      Ref : {location.state?.item?.receiptId} <br />
                    </p>
                  </div>
                  <div className="d-lg-flex mt-1">
                    <div className="d-flex"><p style={{width : '205px'}} className="col-lg-3">Received with thanks from </p>
                  <p style={{width : '20px'}} className="col-lg-1">:</p></div>
                    <p style={{width : '890px'}} className="col-lg-10 pe-2 border-dark border-bottom">
                      {location.state?.item?.customerName}
                    </p>
                  </div>
                 
                  <div className="d-lg-flex">
                    <div className="d-flex">
                    <p style={{width : '205px'}} className="col-lg-3">The sum of Rupees</p>
                    <p style={{width : '20px'}} className="col-lg-1">:</p>
                    </div>
                    <p style={{width : '890px'}} className="col-lg-9 border-dark border-bottom">
                   {uppercaseString} RUPEES ONLY.
                    </p>
                  </div>
                  <div className="d-lg-flex mb-5">
                    <div className="d-flex">
                    <p style={{width : '205px'}} className="col-lg-3">Towards Printing Charges of </p>
                    <p style={{width : '20px'}} className="col-lg-1">:</p>
                    </div>
                    <p style={{width : '890px'}} className="col-lg-9 border-dark border-bottom">
                    {location.state?.item?.pendingAmount === 0 ? location.state.item.orderList[0].orderId :  "On Account" + " " +  location.state.item.orderList[0].orderId}                      
                    </p>
                  </div>
                  <div className="col-lg-12 d-flex">
                      <div className="col-lg-8"></div>
                    <div className="col-lg-2">
                    Total&nbsp; Amount&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;: </div>
                      <div className="col-lg-2 border-dark border-bottom text-end">
                        {location.state?.item?.totalAmount.toFixed(2)}
                      </div>
                    </div>
                  <div className="col-lg-12">
                    <div className="col-lg-12 d-flex">
                      <div className="col-lg-8"></div>
                    <div className="col-lg-2">
                      Paid Amount &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: </div>
                      <div className="col-lg-2 border-dark border-bottom text-end">
                      {location.state?.item?.paidAmount.toFixed(2)}
                      </div>
                    </div>
                    <div className="col-lg-12 d-flex">
                      <div className="col-lg-8"></div>
                    <div className="col-lg-2">
                    Pending Amount &nbsp;&nbsp;: </div>
                      <div className="col-lg-2 border-dark border-bottom text-end">
                      {location.state?.item?.pendingAmount.toFixed(2)}
                      </div>
                    </div>
                   
                    </div>
                    {/* <div className="col-lg-12 d-flex">
                    <div className="col-lg-8"></div>
                    <ul className="col-lg-9 alignMe">
                      <li><b>Amount Received</b> <span className="border-dark border-bottom">{location.state?.item?.paidAmount}</span></li>
                      <li><b>Pending Amount </b> {location.state?.item?.pendingAmount}</li>
                      <li><b>Total Amount </b> {location.state?.item?.totalAmount.toFixed(2)}</li>
                    </ul>
                    </div> */}
                  <div className="">
                    <p className="col-lg-10 mt-5">Authorised Signature</p>
                  </div>
                  </div>
                </section>
              </CardContent>
              <div className="col-lg-12 text-center mb-5">
                <Button
                  onClick={() => savepdf()}
                  id="pillStyle"
                  className="w-lg-25"
                  variant="primary"
                >
                  Print Cash Receipt
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PrintReceipt;
