import axios from "axios";
import { toast } from "react-toastify";

// axios.defaults.baseURL = 'http://localhost:2023/api/';
axios.defaults.baseURL = 'https://aretsapi.azurewebsites.net/api/';
// axios.defaults.baseURL = "https://aretsdevapi.azurewebsites.net/api/";

axios.interceptors.request.use(async (req) => {
  const fert = await localStorage.getItem("logindata");
  console.log(fert, "req");
  if (localStorage.getItem("logindata")) {
    console.log(`Bearer ${localStorage.getItem("logindata")}`);
    req.headers["authorization"] = `Bearer ${localStorage.getItem(
      "logindata"
    )}`;
  }

  return req;
});
axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;
  if (!expectedError) {
    toast.error("No Network, Please Connect to Internet", {
      position: toast.POSITION.BOTTOM_LEFT,
    });
    //   setTimeout(() => {
    // 	window.location.reload(false)
    //   }, 2000);
    // alert('No Network, Please Connect to Internet');
  }
  return Promise.reject(error);
});

function setJwt(jwt) {
  axios.defaults.headers["x_access_token"] = jwt;
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
};
