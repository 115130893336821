import React, { useEffect, useState, useRef } from "react";

import "../../Styles/Orders.css";
import Header from "../header/Header";
import Container from "react-bootstrap/Container";
import Sidebar from "../Sidenav/Sidebar";
import aretsLogo from "../../Images/aretsLogo.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Moment from "moment";
// import Paper from '@mui/material/Paper';

// import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
// import Button from '@mui/material/Button';
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import QrCode from "../../Images/QrCode.png";
import Button from "react-bootstrap/Button";
import { NavLink, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Nav } from "react-bootstrap";
import estimateService from "../../services/estimateService";
import authService from "../../services/authService";
import ClientsService from "../../services/ClientsService";
import ReceiptService from "../../services/ReceiptService";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
// import numberToWords from "number-to-words";

const PrintDelivery = () => {
    const location = useLocation();

    const scaleFactor = 190 / 105; // Assuming elementWidth is the width of the "capture" element
  const scaledHeight = 45 * scaleFactor;
  const payref = useRef();
  const [tab, setTab] = useState("Order Confirmation");
  const [payview, setpayview] = useState(true);
  const savepdf = () => {
    html2canvas(document.querySelector("#capture"), { dpi: 300 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 0, 0, 210, scaledHeight); 
            pdf.save("Delivery Receipt.pdf");
    });
  };
  console.log(location.state.item, 'delivery');
  return (
    <div>
<Header />
      <Container fluid className="containerr">
        <Row>
          <Col lg={12} className="p-3">
            <div>
              <Breadcrumb className="ms-lg-5">
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/delivery">Delivery</Breadcrumb.Item>
                <Breadcrumb.Item active>Print Delivery</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Card  className="mt-3 col-lg-12 rounded-4">
              <CardContent id="capture">
              <div className="container w-75 mt-5">
                <div className="row border-dark border p-3 mt-3 bg-white">
                <div className="col-lg-9 d-flex">
                    <img
                      src={aretsLogo}
                      alt="branding"
                      className=""
                      width={60}
                      height={50}
                      onClick={() => window.location.reload()}
                    />
                    <h3
                      style={{
                        fontSize: '25px',
                        fontFamily: "Yeseva One",
                        fontWeight: "400",
                      }}
                      className="ms-2"
                    >
                      AReTS Graphics
                      <h6
                        style={{
                          fontFamily: "Clash Display",
                          fontSize: "16px",
                        }}
                        className="ms-2"
                      >
                        Everything in Printing
                      </h6>
                    </h3>
                  </div>
                  <div className="col-lg-3 mt-3">
                    <h6 className="">TRANSPORT SLIP</h6>
                  </div>
                  </div>
                  <div className="row border-dark border-start border-end border-bottom">
                    <div className="col-lg-2 border-dark border-end p-3">
                        <h6 className="fs-16 fw-bold">M/s.</h6>
                    </div>
                    <div className="col-lg-6 border-dark border-end p-3">
                        <h6 className="fs-14 fw-semibold" >{location.state.item.customerName}</h6>
                        <h6 className="fs-14 fw-semibold">{location.state.item.phone}</h6>
                    </div>
                    <div className="col-lg-3 p-3">
                        <h6 className="fs-14 fw-semibold"><span className="text-dark fs-16 fw-bold">Date: </span>{Moment(location.state.item?.deliverDate).format(
                        "DD MMMM YYYY"
                      )}</h6>
                    </div>
                  </div>
                  <div className="row border-dark border-start border-end border-bottom">
                    <div className="col-lg-2 border-dark border-end p-3">
                        <h6 className="fs-16 fw-bold">Machine: </h6>
                        <h6 className="fs-16 fw-bold">Paper Bundle: </h6>
                        <h6 className="fs-16 fw-bold">Village: </h6>
                        <h6 className="fs-16 fw-bold">Transport Name: </h6>
                    </div>
                    <div className="col-lg-6 border-dark border-end p-3">
                        <h6 className="fs-14 fw-semibold">{location.state.item.orId.machine}</h6>
                        <h6 className="fs-14 fw-semibold">{""}</h6>
                        <h6 className="fs-14 fw-semibold mt-4 pt-3">{location.state.item.address}</h6>
                        <h6 className="fs-14 fw-semibold"></h6>
                    </div>
                    <div className="col-lg-3 p-3">
                    </div>
                  </div>
              </div>
              </CardContent>
              <div className="col-lg-12 text-center mb-5">
                <Button
                  onClick={() => savepdf()}
                  id="pillStyle"
                  className="w-lg-25"
                  variant="primary"
                >
                  Print Delivery Receipt
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default PrintDelivery