import React, { useEffect, useState } from "react";
import "../../../Styles/JobCards.css";
import Header from "../../header/Header";
import Sidebar from "../../Sidenav/Sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import filterIcon from "../../../Images/filterIcon.png";
import shareIcon from "../../../Images/shareIcon.png";
import sortIcon from "../../../Images/sortIcon.png";
import calenderIcon from "../../../Images/calenderIcon.png";
import UploadImageIcon from "../../../Images/UploadImageIcon.png";
import SaveIcon from "../../../Images/SaveIcon.png";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Table from "react-bootstrap/Table";
import { NavLink } from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router";
import Modal from "react-bootstrap/Modal";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MasterService from "../../../services/MasterService";
import { toast } from "react-toastify";
import axios from "axios";
import editIcon from "../../../Images/editIcon.svg";
import TextField from "@mui/material/TextField";
import deleteIconRed from '../../../Images/deleteIconRed.svg';
import { connect } from "react-redux";
import get_userdetails from "../../../redux/actions/getuserDetailsAction";

const JobType = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUserdata] = useState();
  useEffect(() => {
    getuserdetails();
  }, [props.userdata]);
  useEffect(() => {
    get_userdetails();
  }, []);

  const getuserdetails = async () => {
    console.log(props.userdata.user, "props.userdata.user");
    setUserdata(props.userdata.user);
  };
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [printCount, setPrintCount] = useState(0);
  const [searchtext, setSearchText] = useState("");
  const [search, setSearch] = useState(false);
  const handleChangePage = (event, newPage) => {
    const dett = newPage * rowsPerPage;
    setPage(newPage);
    getPrintJobType(dett, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getPrintJobType(0, event.target.value);
  };
  let navigate = useNavigate();
  function editjob() {
    navigate("/editjob");
  }
  const [modalShow, setModalShow] = React.useState(false);
  const [editmodal, setEditModalShow] = React.useState(false);

  const [printjobtype, setPrintJobType] = useState([]);
  useEffect(() => {
    getPrintJobType(0, 10);
  }, []);

  const getPrintJobType = async (skps, rpp) => {
    try {
      const obj = {
        skip: skps,
        limit: rpp,
        // "searchkeys": { "details": searchtext }
      };
      const dert = await MasterService.getPrintJobType(obj);
      if (dert.data.status === "success") {
        console.log(dert.data.value, "values");
        setPrintJobType(dert.data.value);
        setPrintCount(dert.data.count);
      }
    } catch (ex) {}
  };

  const [role, setRole] = useState("");
  const [sbtndis, setSBtndis] = useState(false);
  const [id, setID] = useState("");
  const [ejobtype, setEJobtype] = useState("");
  const [jobtype, setJobtype] = useState("");
  const [hsncode, setHsnCode] = useState("");
  // const [status, setStatus] = useState('');

  // const [role,setRole] = useState('');
  const handleHsnCode = (event) => {
    const { value } = event.target;
    setHsnCode(value.replace(/\D/g, ''));
  };
  console.log(role, "role");
  const create = async () => {
    setIsLoading(true);
    if (!jobtype) {
      await setSBtndis(false);
      setEJobtype("Job Type Is Required!");
      toast.error("Enter Job Type", {
        position: toast.POSITION.BOTTOM_LEFT,
      });

      // } else if (!status) {
      //     await setSBtndis(false)
      //     toast.error('Select Status', {
      //         position: toast.POSITION.BOTTOM_LEFT
      //     });
    } else{
      setEJobtype("");
    try {
      setIsLoading(true);
      const obj = {
        details: jobtype,
        hsnCode: hsncode,
        // "active": status,
      };
      console.log(obj, "objjj");
      const dert = await MasterService.addPrintJobType(obj);
      console.log(dert.data, "dert.data");
      if (dert.data.status === "success") {
        toast.success("Job Type Added Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        navigate("/jobtype");
        getPrintJobType(0, 10);
        setModalShow(false);
        setJobtype("");
        setHsnCode("");
      
      } else {
        toast.error(dert.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (ex) {
      if (ex.response.status === 400) console.log(ex.response, "ex.response");
      toast.error(ex.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }finally{
      setIsLoading(false);
    }}
  };
  const Delete = async (item) => {
        
    try {
      const obj = {
       "_id":item._id,
      }
   
      const dert = await MasterService.deleteJobType(obj);
      console.log(dert, "dert");

      if (dert.data.message){
        toast.success("Job Type Deleted Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        getPrintJobType(0, 10);
        // setSearch(true)
        console.log(dert.data.value, "values");
      
           // setBreadth('');
           // setLength('');
           // setDetails('')
      }
    } catch (ex) {

    }
  }
  // download Excel

  const [isDownloading, setIsDownloading] = useState(false);

  // const handleDownloadExcel = async () => {
  //   try {
  //     setIsDownloading(true);
  //     const response = await axios.post(
  //       "/masterData/printJobTypes/jsontoexcel",
  //       { data: [] },
  //       { responseType: "blob" }
  //     );
  //     const excelBlob = new Blob([response.data], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });
  //     const url = window.URL.createObjectURL(excelBlob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = "JobTypes.xlsx";
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setIsDownloading(false);
  //   }
  // };

  const handleDownloadExcel = async () => {
    try {
      setIsDownloading(true);
      const requestData = {
        // searchkeys : searchtext 
        searchkeys: { details: searchtext },
     
      };
      const response = await axios.post(
        "/masterData/printJobTypes/jsontoexcel",
        requestData,
        { responseType: "blob" }
      );
      const excelBlob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(excelBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "JobTypes.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    } finally {
      setIsDownloading(false);
    }
  };


  //  Download Excel Close

  //edit integration
  const [edmill, setedmill] = useState({});
  const [epjtdetails, setEPJTDetails] = useState("");
  const [pjtdetails, setPJTDetails] = useState("");
  const [pjthsncode, setPJTHsnCode] = useState("");
  //   const [pjtstatus, setPJTStatus] = useState('');

  const clickedit = (k) => {
    // console.log(k,'editmill');
    setedmill(k);
    setPJTDetails(k.details);
    setPJTHsnCode(k.hsnCode);
    //   setPJTStatus(k.active)
    setEditModalShow(true);
  };
  const handlesubmit = async () => {
   
    if (!pjtdetails) {
      await setSBtndis(false);
      setEPJTDetails("Job Type Is Required!");
      toast.error("Enter Job Type", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else{
      setEPJTDetails("");
    try {
      setIsLoading(true);
      const obj = {
        _id: edmill._id,
        //   "_id":"63d10a01cbdf97487ec28939",
        id: 29,
        details: pjtdetails,
        hsnCode: pjthsncode,
        //   "active":pjtstatus,
        __v: 0,
      };
      const dort = await MasterService.updateprintjobtype(obj);
      if (dort.status === 200) {
        getPrintJobType(0, 10);
        await setSBtndis(false);
        navigate("/jobtype");
        setEditModalShow(false);
        setIsLoading(false);
        toast.success("Job Type Updated Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (ex) {
      await setSBtndis(false);
      toast.error(ex.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }finally{
      setIsLoading(false);
    }}
  };

  //edit integration close
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      serachclick();
     
    }
  };
  //SearchAPI

  const searchChange = (value) => {
    setSearchText(value);
    // getestimates(0,10)
  };
  const serachclick = async () => {
    const trimmedSearchText = searchtext.trim(); 
    if (trimmedSearchText === "") {
      toast.error("Enter Text", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
   
    }
    try {
      const obj = {
        skip: 0,
        limit: rowsPerPage,
        searchkeys: { details: trimmedSearchText },
      };
      const dert = await MasterService.getPrintJobType(obj);
      if (dert.data.status === "success") {
        console.log(dert.data.value, "values");
        setPrintJobType(dert.data.value);
        setPrintCount(dert.data.count);
        setSearch(true)
      }
    } catch (ex) {}
  };
  const resetclick = () => {
    setSearchText("");
    setSearch(false);
    getPrintJobType(0, rowsPerPage);
  };
  function modalreset() {
    setJobtype("");
    setHsnCode("");
    setModalShow(false);
    setEPJTDetails("");
      setEJobtype("");
  }
  //SearchAPI Close
  return (
    <>
      <Header />
      <Container fluid className="containerr">
        <Row>
          <Col lg={2} id="sidebar-wrapper" className="d-none d-md-block">
            <Sidebar />
          </Col>
          <Col lg={10} sm={4} className="containerr px-3 px-lg-4">
            <div className="pt-4 pb-2 d-flex justify-content-between align-items-center">
              <div>
                <h1 className="fs-3">Job Type</h1>
                <nav aria-label="breadcrumb" className="fs-14">
                  <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item">
                      <a href="/dashboard">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Job Type
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="pt-sm-4">
                <Button
                  onClick={() => setModalShow(true)}
                  id="pillStyle"
                  className="btnnew"
                  variant="primary"
                >
                  Create Job Type
                </Button>
              </div>
            </div>
            <Row className="d-flex">
              <Col lg={12} sm={12} md={6} xs={12} className="mb-5 datacenter">
                <Paper className="col-lg-12 col-sm-8 rounded-4">
                  <Box sx={{}}>
                    <Row className="d-flex flex-column flex-lg-row justify-content-between">
                      <Col lg={4} sm={12} className="text-lg-start ">
                        <h6 className="datahead text-muted">Job Type List</h6>
                      </Col>
                      {/* categories starts */}
                      <Col className="px-4 pb-2">
                        <Row className="d-flex justify-content-end">
                          <Col lg={6}>
                            <Form className="mt-3 d-flex gap-2 searchField">
                              <div className="col-lg-8 d-flex">
                                <input
                                  value={searchtext}
                                  onChange={(e) => searchChange(e.target.value)}
                                  onKeyDown={handleKeyDown}
                                  className="form-control nosubmit"
                                  style={{ fontSize: "14px" }}
                                  type="search"
                                  placeholder="Search Job Type"
                                />
                              </div>
                              <div className="col-lg-4">
                              {!search ? (
                                  <Button
                                    onClick={() => serachclick()}
                                    className="w-100 sortFilter1"
                                    variant="primary"
                                  >
                                    Search
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => resetclick()}
                                    className="w-100 sortFilter1"
                                    variant="primary"
                                  >
                                    Reset
                                  </Button>
                                )}
                                {/* <span className="searchcont" onClick={() => serachclick()} style={{cursor:'pointer'}}><i class="fa fa-search empsearch"></i></span> */}
                              </div>
                            </Form>
                          </Col>
                          {/* sort filter */}
                          {/* <Col lg={2} sm={6} >
                                                        <PopupState variant="popover" popupId="demo-popup-popover">
                                                            {(popupState) => (
                                                                <div >
                                                                    <Button id='sortfilter' className='sortFilter1 mt-3' variant="contained" {...bindTrigger(popupState)}>
                                                                        <img className="menuIcon mx-1" src={filterIcon} />
                                                                        Filter
                                                                    </Button>
                                                                    <Popover
                                                                        {...bindPopover(popupState)}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'center',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'center',
                                                                        }}
                                                                    >
                                                                        <Typography sx={{ p: 2 }}>
                                                                            <h6>Filter</h6>
                                                                            <h6>Order Type</h6>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Home Delivery"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Pickup"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-2`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <hr className='text-muted'></hr> <hr className='text-muted'></hr>
                                                                            <div>
                                                                                <h6>Status</h6>
                                                                                <Form.Select aria-label="Default select example">
                                                                                    <option>All</option>
                                                                                    <option value="1">One</option>
                                                                                    <option value="2">Two</option>
                                                                                </Form.Select>
                                                                                <h6>Customer</h6>
                                                                                <Form.Select aria-label="Default select example">
                                                                                    <option>All</option>
                                                                                    <option value="1">One</option>
                                                                                    <option value="2">Two</option>
                                                                                </Form.Select>
                                                                                <Form>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <Form.Label>From</Form.Label>
                                                                                            <Form.Control className='filterFields' placeholder="0.00" />
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <Form.Label>To</Form.Label>
                                                                                            <Form.Control placeholder="0.00" />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Form>
                                                                                <Button id='pillStyle' className='w-100 mt-3' variant="primary">Filter</Button>{' '}
                                                                            </div>
                                                                        </Typography>
                                                                    </Popover>
                                                                </div>
                                                            )}
                                                        </PopupState>
                                                    </Col> */}
                          {/* date filter */}
                          {/* <Col lg={2} sm={6}>
                                                        <PopupState variant="popover" popupId="demo-popup-popover">
                                                            {(popupState) => (
                                                                <div>
                                                                    <Button id='sortfilter' className='sortFilter2 mt-3' variant="contained" {...bindTrigger(popupState)}>
                                                                        <img className="menuIcon mx-1 mb-1" src={calenderIcon} />
                                                                        Filter
                                                                    </Button>
                                                                    <Popover
                                                                        {...bindPopover(popupState)}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'center',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'center',
                                                                        }}
                                                                    >
                                                                        <Typography sx={{ p: 2 }}>
                                                                            <h6 className='mb-3'>By Date</h6>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="This Week"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Last Week"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-2`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="This Month"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Last Month"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-2`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="This Year"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />&nbsp;
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Last Year"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-2`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <hr className='text-muted'></hr>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Date Range"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <Nav id='navPill' variant="pills" defaultActiveKey="/home">
                                                                                <Nav.Item>
                                                                                    <Nav.Link id='pillStyle' href="/home">From</Nav.Link>
                                                                                </Nav.Item>
                                                                                <Nav.Item>
                                                                                    <Nav.Link id='pillStyle' eventKey="link-1">To</Nav.Link>
                                                                                </Nav.Item>
                                                                            </Nav>
                                                                            <Col lg={2} sm={2} md={2}>
                                                                                <DateRange
                                                                                    editableDateInputs={true}
                                                                                    onChange={item => setState([item.selection])}
                                                                                    moveRangeOnFirstSelection={false}
                                                                                    ranges={state}
                                                                                /></Col>
                                                                            <Button className='w-100 mt-3' id='pillStyle' variant="primary">Filter</Button>{' '}
                                                                        </Typography>
                                                                    </Popover>
                                                                </div>
                                                            )}
                                                        </PopupState>
                                                    </Col> */}
                          {/* Export Excel */}
                          <Col lg={3} sm={2} className="middleFilter">
                            <Button
                              onClick={handleDownloadExcel}
                              disabled={isDownloading}
                              id="shareIcon"
                              variant="outlined"
                            >
                              <img
                                className="menuIcon mx-1 mb-1"
                                src={shareIcon}
                              />
                              {isDownloading ? "Downloading" : "Export Excel"}
                            </Button>
                          </Col>
                          {/* Bulk Select */}
                          {/* <Col lg={3} sm={2}>
                                                        <Form.Select id='selectBtn' aria-label="Default select example">
                                                            <option hidden className='text-center'>Bulk Action</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </Col> */}
                        </Row>
                      </Col>
                      {/* categories starts */}
                    </Row>
                    <Table responsive borderless>
                      <thead>
                        <tr className="theadborder">
                          {/* <th>
                                                        <div className='table-headFilter'>
                                                            <Form.Check aria-label="option 1" /></div>
                                                    </th> */}
                          <th>
                            <div className="table-headFilter ps-2">
                              <span>#</span>
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Job Type</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Hsn Code</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="tdata">
                        {printjobtype && printjobtype.length > 0
                          ? printjobtype.map((k, index) => {
                              return (
                                <tr>
                                  {/* <Form.Check aria-label="option 1" /> */}
                                  <td className="ps-3">{index + 1 + (page * rowsPerPage)}. </td>
                                  <td>{k.details}</td>
                                  <td>{k.hsnCode}</td>
                                  {/* <td>{k.isActive === true ? 'Active' : 'In Active'}</td> */}
                                  <td onClick={() => clickedit(k)} className="hand-cursor">
                                    <img
                                      className="menuIcon mx-1"
                                      src={editIcon}
                                    />
                                    Edit
                                  </td>
                                  {user ? (
                                    user.access.includes("AA") ? (
                                      <td onClick={() => Delete(k)} className="hand-cursor">
                                          <img
                                            className="menuIcon mx-1"
                                            src={deleteIconRed}
                                          />
                                          Delete
                                      </td>
                                    ) : null
                                  ) : null}                                     
                                  </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </Table>
                    <Row className="d-flex">
                      <Col>
                        <TablePagination
                          component="div"
                          count={printCount}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Col>
                    </Row>
                  </Box>
                </Paper>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Modal
        show={modalShow}
        onHide={() => modalreset()}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{ width: '35%', marginLeft: '30%' }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Job Type
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card sx={{ minWidth: 275 }} className=" rounded-4">
            <CardContent>
              <div className="row">
                <div className="form-group col-lg-12 col-md-12">
                  <div className="row">
                    <div className="form-group col-lg-12 col-md-12 col-12">
                      <div className="input-group">
                        <TextField
                          className="w-100"
                          value={jobtype}
                          onChange={(e) => setJobtype(e.target.value)}
                          id="inputcolor"
                          type="text"
                          label="Job Type*"
                          variant="outlined"
                        />
                      </div>
                      <div style={{ color: "#FF0000", fontSize: "10px" }}>
                        {ejobtype ? ejobtype : null}
                      </div>
                    </div>
                    <div className="form-group col-lg-12 col-md-12 col-12">
                      <div className="input-group mt-3">
                        <TextField
                          className="w-100"
                          value={hsncode}
                        onChange={handleHsnCode}
                          id="inputcolor"
                          type="text"
                          label="HSN Code"
                          variant="outlined"
                        />
                      </div>
                    </div>

                    {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                            <div className="input-group mb-3">
                                                <select value={status} onChange={(e) => setStatus(e.target.value)} class="form-select text-muted" id='inputcolor' aria-label="Default select example">
                                                    <option selected hidden>Status*</option>
                                                    <option value={true}>Active</option>
                                                    <option value={false}>InActive</option>
                                                </select>
                                            </div>
                                        </div> */}

                    <div className=" mt-3 gap-1 d-flex flex-column flex-lg-row">
                      <div className="col-lg-6">
                        <Button
                          onClick={() => modalreset()}
                          id="pillStyle"
                          className="w-100"
                          variant="outline-primary"
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="col-lg-6">
                      {!isLoading ? (
                        <Button
                          onClick={() => create()}
                          id="pillStyle"
                          className="w-100"
                          variant="primary"
                        >
                          Create Job type
                        </Button>
                        ) : (
                          <button className="buttonload w-100" id="pillStyle">
                          <i className="fa fa-spinner fa-spin"></i> Loading
                        </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Modal.Body>
      </Modal>
      <Modal
        show={editmodal}
        onHide={() => setEditModalShow(false)}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{ width: '35%', marginLeft: '30%' }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Job Type{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card sx={{ minWidth: 275 }} className=" rounded-4">
            <CardContent>
              <div className="row">
                <div className="form-group col-lg-12 col-md-12">
                  <div className="row">
                    <div className="form-group col-lg-12 col-md-12 col-12">
                      <div className="input-group">
                        <TextField
                          className="w-100"
                          value={pjtdetails}
                          onChange={(e) => setPJTDetails(e.target.value)}
                          id="inputcolor"
                          type="text"
                          label="Job Type*"
                          variant="outlined"
                        />
                      </div>
                      <div style={{ color: "#FF0000", fontSize: "10px" }}>
                        {epjtdetails ? epjtdetails : null}
                      </div>
                    </div>
                    <div className="form-group col-lg-12 col-md-12 col-12">
                      <div className="input-group mt-3">
                        <TextField
                          className="w-100"
                          value={pjthsncode}
                          onChange={(e) => setPJTHsnCode(e.target.value)}
                          id="inputcolor"
                          type="text"
                          label="HSN Code"
                          variant="outlined"
                        />
                      </div>
                    </div>

                    {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                            <div className="input-group mb-3">
                                                <select value={pjtstatus} onChange={(e) => setPJTStatus(e.target.value)} class="form-select text-muted" id='inputcolor' aria-label="Default select example">
                                                    <option selected hidden>Status*</option>
                                                    <option value={true}>Active</option>
                                                    <option value={false}>InActive</option>
                                                </select>
                                            </div>
                                        </div> */}

                    <div className=" mt-3 gap-1 d-flex flex-column flex-lg-row">
                      <div className="col-lg-6">
                        <Button
                          onClick={() => setEditModalShow(false)}
                          id="pillStyle"
                          className="w-100"
                          variant="outline-primary"
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="col-lg-6">
                      {!isLoading ? (
                        <Button
                          onClick={() => handlesubmit()}
                          id="pillStyle"
                          className="w-100"
                          variant="primary"
                        >
                          Update Job Type
                        </Button>
                        ) : (
                          <button className="buttonload w-100" id="pillStyle">
                          <i className="fa fa-spinner fa-spin"></i> Loading
                        </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    userdata: state.userdetails,
  };
};
export default connect(mapStateToProps)(JobType);
